import React, { useState } from "react";
import PropTypes from "prop-types";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import WmsFile from "./../react/components/WmsFile/WmsFile.jsx";
import WmsGrid from "./../react/components/WmsGrid/WmsGrid";
import WmsRadio from "./../react/components/WmsRadio/WmsRadio";
import { ItemAliasImportParseGridDetail } from "./item-alias-import-parse-grid-detail.component";
import * as ItemAliasServices from "./item-alias.services";
import * as GridConfig from "./item-alias-grid-configuration";
import { StringWms } from "../../assets/ts/StringWms";
import * as GlobalService from "./../react/global_services";
import "./itemAlias.scss";
import _ from "lodash";

declare var window: any;
const { $ } = window;
const defaultImportItemAliasData = {
    CustomerId: 0,
    fileName: "",
    fileSize: "",
    fileObject: null,
    dupHandling: false,
};

interface Props {
    onCloseItemAliasImportModal: any;
    isOpenItemAliasImportModal: boolean;
    wmsSelector: string;
    windowId: string;
    onApplyItemAliasImport: any;
    customerId: number;
}

const ItemAliasImportModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const [importItemAliasData, setImportItemAliasData] = useState(defaultImportItemAliasData);
    const [isFileDrage, setFileDrage] = useState(false);
    const [importParserData, setImportParserData] = useState([]);
    const [importParserResponseData, setImportParserResponseData] = useState(null);
    const [isAllDuplicates, setIsAllDuplicates] = useState(false);
    const accepsFileTypes =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    const itemAliasImportGridHeaders = StringWms.convertSringToOption(window.translate("ItemAlias_Import_GridHeaders"));
    const importParseDataColumns = GridConfig.importParseDataGridColumns(itemAliasImportGridHeaders);
    const importParseDataGridSchema = GridConfig.importParseDataGridSchema();
    const parseGridId = props.wmsSelector + "parse-data-grid";
    const handleChangeData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "CustomerId") {
            finalValue = parseInt(value);
            // handleCustomerChange(finalValue);
        } else if (name === "onErrorCancleImport") {
            finalValue = event.target.checked;
        }

        setImportItemAliasData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    // const handleCustomerChange = (CustomerID: any) => {
    //     var selectedCustomer = CustomerOptions.find((cus: any) => CustomerID !== 0 && cus.id === CustomerID);

    // };
    const allValidationDone = () => {
        return (
            !(props.customerId !== 0 && importItemAliasData.fileName !== "" && importItemAliasData.fileSize !== "") ||
            (isAllDuplicates && !importItemAliasData.dupHandling)
        );
    };
    const handleFileUpload = (fileUploadedData) => {
        if (fileUploadedData.FileSize > 1048576 * 2) {
            GlobalService.notification("error", window.translate("Label_LargeFileUpload_Error"));
            return false;
        }
        if (fileUploadedData.fileName) {
            fileUploadedData.fileName = fileUploadedData.fileName.replace(
                // eslint-disable-next-line no-useless-escape
                /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi,
                "-"
            );
        }

        let fileSize = GlobalService.getFileSizeString(fileUploadedData.FileSize);
        setImportItemAliasData((prevState: any) => ({
            ...prevState,
            fileName: fileUploadedData.fileName,
            fileSize: fileSize,
        }));
        setFileDrage(false);

        // let fileContent = fileUploadedData.FileContents;
        let fileContentType = fileUploadedData.ContentType;
        let base64File = fileUploadedData.base64;

        const importFile = {
            ContentType: fileContentType,
            Content: base64File, // Convert byte array to JSON-compatible array
        };

        ItemAliasServices.getAttachFileParserData(props.customerId, importFile)
            .then((response) => {
                let tempParseData = [];
                if (response.data.Errors.Faults.length) {
                    let _data = {
                        "Source": 1,
                        "ServerName": "3PL-QA-APP",
                        "Descriptions": [],
                        "ErrorClass": "ModelValidation",
                        "ErrorCode": "System",
                        "type": "error",
                        "customeErrorTitleMessage": "",
                    };
                    response.data.Errors.Faults.map((error: any, index) => {
                        if (error.WmsException.ErrorCode === "DoesNotExist") {
                            _data.ErrorClass = "";
                            _data.customeErrorTitleMessage = window.translate(
                                "Label_ImportFile_Invalid_sku_Error_message"
                            );
                        }
                        _data.Descriptions.push({
                            "ErrorClass": "Http",
                            "ErrorCode": error.WmsException.ErrorCode,
                            "Descriptors": [],
                            "Hint": error.WmsException.Hint,
                        });
                        return false;
                    });
                    GlobalService.showErrorMessage(_data);
                    removeFile();
                    return false;
                }
                // else if (response.data.ItemAliases.ResourceList.length > 200) {
                //     let message = window.translate("Label_InputFile_Contain_n_rows").replace("%no_of_rows%", 200);
                //     GlobalService.notification("error", message);
                //     removeFile();
                //     return false;
                // }
                if (response.data.ItemAliases.ResourceList) {
                    setImportParserResponseData(response.data.ItemAliases);
                    const duplicateAliasSkus = response.data.DuplicateAliasSkus;
                    response.data.ItemAliases.ResourceList.map((itemAlias: any, index) => {
                        tempParseData.push({
                            index: index + 1,
                            alias: itemAlias.AliasIdentifier.Sku,
                            description: itemAlias.Description,
                            duplicate:
                                Array.isArray(duplicateAliasSkus) &&
                                duplicateAliasSkus.includes(itemAlias.AliasIdentifier.Sku),
                            skuItems: itemAlias.Details.map((A_Item: any) => {
                                return {
                                    index: "",
                                    alias: "",
                                    sku: A_Item.ItemIdentifier.Sku + " ( " + A_Item.Qty + " )",
                                    description: A_Item.Description,
                                    qty: A_Item.Qty,
                                    duplicate: false,
                                };
                            }),
                            noOfItemsText: itemAlias.Details.length + " Items",
                        });
                        return false;
                    });
                }

                setImportParserData(tempParseData);
                let tempisAllDuplicates = _.some(tempParseData, (dt) => !dt.duplicate);
                setIsAllDuplicates(!tempisAllDuplicates);
            })
            .finally(() => {
                // setIsApplyingAlias(false);
                // kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
            });
    };
    const onClickSave = () => {
        ItemAliasServices.submitAttachFileParserData(
            props.customerId,
            importItemAliasData.dupHandling,
            importParserResponseData
        )
            .then((response) => {
                if (response.data.TotalResults) {
                    GlobalService.notification(
                        "success",
                        window
                            .translate("Create_Item_Alias_Completed_Successfully")
                            .replace("%imported_aliases%", response.data.TotalResults)
                    );
                    props.onCloseItemAliasImportModal(true);
                } else {
                    GlobalService.notification("error", window.translate("Create_Item_Alias_Import_error"));
                }
            })
            .finally(() => {
                // setIsApplyingAlias(false);
                // kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
            });
    };
    const onClickDownloadTemplateFile = () => {
        window.open("https://help.3plcentral.com/hc/en-us/articles/360038228512-Import-Templates", "_blank");
    };
    const onDragOver = () => {
        setFileDrage(true);
    };
    const onDragStop = () => {
        setFileDrage(false);
    };
    const removeFile = () => {
        setImportItemAliasData(defaultImportItemAliasData);
        setFileDrage(false);
        $("#" + props.wmsSelector + "ImportFileDrag").val("");
        $("#" + props.wmsSelector + "ImportFile").val("");
    };

    return (
        <WmsModel
            id="ItemAliasImportModel"
            title={window.translate("Label_Import_Item_Alias")}
            width={800}
            height={800}
            onCloseFunc={props.onCloseItemAliasImportModal}
            isOpen={props.isOpenItemAliasImportModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className={"model-content-wrapper"}>
                <div className="template-upload-wrapper">
                    <div className="">
                        <WmsDropdown
                            id="customer"
                            name="CustomerId"
                            label={CustomLabels.Label_Customer}
                            wmsSelector={props.wmsSelector + "SelectCustomer"}
                            onChangeFunc={handleChangeData}
                            options={CustomerOptions}
                            valueField={"id"}
                            textField={"name"}
                            disabled={true}
                            value={props.customerId}
                        />
                    </div>
                    {importItemAliasData.fileName !== "" && importItemAliasData.fileSize !== "" ? (
                        <div className="import-file-content-wapper">
                            <div className="import-file-name-and-size">
                                <span className="import-file-name-lable">
                                    <i className="fa fa-file-o sharp-regular"></i>
                                    {importItemAliasData.fileName}
                                </span>
                                <span className="import-file-size-lable">
                                    {importItemAliasData.fileSize}
                                    <i className="fa fa-trash sharp-solid" onClick={removeFile}></i>
                                </span>
                            </div>
                            <div className="import-parse-grid-header">
                                <span className="title">Aliases to Import</span>
                            </div>
                            <div className="import-parse-grid-container">
                                {isAllDuplicates && (
                                    <div className="duplicate-error-message">
                                        {window.translate("Label_ImportFile_All_Duplicate_Alias_Message")}
                                    </div>
                                )}
                                <WmsGrid
                                    id={parseGridId}
                                    wmsSelector={props.wmsSelector}
                                    gridName={parseGridId}
                                    gridKeySelection={"index"}
                                    inlineSelectAll={true}
                                    gridHeight={500}
                                    defaultColumns={importParseDataColumns}
                                    columns={importParseDataColumns}
                                    schema={importParseDataGridSchema}
                                    staticGridData={importParserData}
                                    detailTemplate={ItemAliasImportParseGridDetail}
                                    virtual={false}
                                    shawCheckbox={false}
                                    isAlignIndexesByProperty={true}
                                    onSelection={() => {}}
                                    onSubGridSelection={() => {}}
                                    resetButtonLabel={"Options"}
                                    showGridSummary={true}
                                    showResetButton={false}
                                    onDataBound={() => {}}
                                    menu={[]}
                                    resizable={{ rows: false, columns: false }}
                                    layoutData={""}
                                />
                            </div>
                            <div className="import-file-duplicate-selection-wapper">
                                <div className="display-inline">
                                    <WmsRadio
                                        id="import-file-duplicate-selection-radio-do-not-duplicates"
                                        name="dupHandling"
                                        label={window.translate("Label_Do_Not_Import_Duplicates")}
                                        wmsSelector={props.wmsSelector + "do-not-import-duplicates"}
                                        onChangeFunc={(e) =>
                                            handleChangeData({ target: { name: "dupHandling", value: false } })
                                        }
                                        checked={!importItemAliasData.dupHandling}
                                    />
                                    <WmsRadio
                                        id="import-file-duplicate-selection-radio-duplicates"
                                        name="dupHandling"
                                        label={window.translate("Label_Replace_Duplicates")}
                                        wmsSelector={props.wmsSelector + "replace-duplicates"}
                                        onChangeFunc={(e) =>
                                            handleChangeData({ target: { name: "dupHandling", value: true } })
                                        }
                                        checked={importItemAliasData.dupHandling}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className=" import-form-area">
                            <label>{window.translate("Label_file_Upload")}</label>
                            <div className="import-area">
                                <div className={"import-drag-area" + (isFileDrage ? " is-dragged" : "")}>
                                    <div className="file-icon-and-name">
                                        <i className="fa fa-cloud-arrow-up"></i>
                                    </div>
                                    <label>
                                        {window.translate("Label_Drag_file_here").replace("%file.max.size%", "2 MB")}
                                    </label>
                                    <hr />
                                    <label>{window.translate("Label_Or")}</label>
                                    <WmsFile
                                        id={props.wmsSelector + "ImportFileDrag"}
                                        name={props.wmsSelector + "ImportFileDrag"}
                                        label={window.translate("Label_Browse_Files")}
                                        wmsSelector={props.wmsSelector + "ImportFileDrag"}
                                        onChangeFunc={handleFileUpload}
                                        fileProcessType="FileSelectReadAsDataUrl"
                                        value={""}
                                        icon={"fa-file-image"}
                                        accept={accepsFileTypes}
                                        showSelectedFileName={false}
                                        clearInputOnClick={true}
                                        loading={false}
                                        onDragOver={onDragOver}
                                        onDragStop={onDragStop}
                                    />
                                </div>
                                <div className="button-area">
                                    <WmsButton
                                        label={window.translate("Label_Download_Template_File")}
                                        wmsSelector={props.wmsSelector + "DownloadTemplate"}
                                        onClickFunc={onClickDownloadTemplateFile}
                                        icon={"fa-file-download"}
                                    />
                                    <WmsFile
                                        id={props.wmsSelector + "ImportFile"}
                                        name={props.wmsSelector + "ImportFile"}
                                        label={window.translate("Label_Browse_Files")}
                                        wmsSelector={props.wmsSelector + "ImportFiles"}
                                        onChangeFunc={handleFileUpload}
                                        fileProcessType="FileSelectReadAsDataUrl"
                                        value={""}
                                        icon={"fa-file-image"}
                                        accept={accepsFileTypes}
                                        showSelectedFileName={false}
                                        clearInputOnClick={true}
                                        loading={false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={props.wmsSelector + "DiscardExit"}
                    onClickFunc={props.onCloseItemAliasImportModal}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Label_Import")}
                    wmsSelector={props.wmsSelector + "Save"}
                    onClickFunc={onClickSave}
                    icon={"fa-floppy-o"}
                    disabled={allValidationDone()}
                    parentClassName={"apply-adjustment-button"}
                />
            </div>
        </WmsModel>
    );
};
ItemAliasImportModal.propTypes = {
    onCloseItemAliasImportModal: PropTypes.any.isRequired,
    isOpenItemAliasImportModal: PropTypes.bool.isRequired,
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    onApplyItemAliasImport: PropTypes.any.isRequired,
    customerId: PropTypes.any.isRequired,
};

ItemAliasImportModal.defaultProps = {
    onCloseItemAliasImportModal: (): void => {},
    isOpenItemAliasImportModal: false,
    wmsSelector: "",
    windowId: "",
    onApplyItemAliasImport: (): void => {},
    customerId: 0,
};

export default React.memo(ItemAliasImportModal);
