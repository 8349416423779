import React from 'react';
import VirtualGridComponent from './../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import WmsButton from './../../components/WmsButton/WmsButton.jsx';
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import { AttachDocuments } from './../../components/AttachDocuments/AttachDocuments.jsx';
import { LoadingModel } from './../../components/LoadingModel/LoadingModel.jsx';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import { KendoWms } from '../../../../assets/ts/KendoWms.ts';
import { ConcurrencyManager } from '../../../../assets/vendor/concurrencyManager/concurrencyManager.js';
import * as GridConfig from './grid_configuration.virtual.jsx';
import * as GlobalService from './../../global_services';
import * as metaDataService from '../meta-data.service';
// import * as findOrdersService from './findOrders.virtual.services.jsx';
import * as findOrdersService from './findOrders.services.jsx';
import * as DynamicMenu from './grid_dynamic_menu.virtual.jsx';
import { CarrierRoutingModelProto } from './modal-proto/carrier-routing-model-proto.component.jsx';
import { AddEditColumnModel } from './../../components/AddEditColumn/add-edit-columns.component.jsx';
import { BillOfLadingModel } from './model/bill-of-lading.component.jsx';
import { TransferOrderModel } from './model/transfer-order/transfer-order.component.jsx';
import { ReprintEODReport } from './model/reprint-end-of-day-reoprt/reprint-end-of-day-reoprt.component.jsx';
import { CreatePickJobModel } from './model/create-pick-job-model.component.jsx';
import { MassShipAndCloseModel } from './model/mass-ship-and-close-model.component.jsx';
import { BatchingModel } from './model/batching-model.component.jsx';
import { ShippingLabel } from './model/shipping-label-model.component.jsx';
import { ConfirmationModal } from './confirmationModal/confirmationModal.component.jsx';
import { PickTicketModel } from './model/pick-ticket-model.component.jsx';
import { PackingSlipsModel } from './model/packing-slips-model.component.jsx';
import { SendAsnModel } from './model/send-asn-model.component.jsx';
import { EditBolOptionModel } from './model/edit-bol-option-model.component.jsx';
import { ConsolidatePackages } from './model/consolidate-packages/consolidate-packages.component.jsx';
import { ReprintShippingLabel } from './model/reprint-shipping-label/reprint-shipping-label.component.jsx';
import { ProcessedBatchedShippingLabels } from './model/processed-batched-shipping-labels/processed-batched-shipping-labels.component.jsx';
import { ManageOrdersFormProto } from '../../orders/manageOrders/manage-orders-form-proto.component.jsx';
import { importOrdersHolder } from '../../importOrders/import-orders-holder.component.jsx';
import { reportIframe } from '../../reportIframe/report-iframe.component.jsx';
import { CancelModel } from '../../components/CancelModel/cancel-model.component.jsx';
import { _, kendo } from "./../../global_variable_service.jsx";
var axios = require('axios');

export class FindOrdersGridVirtual extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;
        this.translate = window.translate;

        this.filterCountAxiosSource = null;
        this.firstFiftyAxiosSource = null;
        this.axiosSource = null;

        let Orders_FindOrders_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_FindOrders_GridHeaders'));
        this.defaultSelectionObj = {
            carrierAndRouting: { disabled: false },
            markOrdersComplete: { disabled: false },
            createPickJob: { disabled: false },
            removeFromPickJob: { disabled: false },
            deletePickJob: { disabled: false },
            shipAndClose: { disabled: false },
            cancel: { disabled: false },
            reopen: { disabled: false },
            tmw: { disabled: false },
            splitOverallocated: { disabled: false },
            shortShipOrders: { disabled: false },
            reallocate: { disabled: false },
            unallocate: { disabled: false },
            removeFromBatch: { disabled: false },
            autoBatch: { disabled: false },
            smartParcel: { disabled: false },
            addToNewBatch: { disabled: false },
            addToExistingBatch: { disabled: false },
            recalcPkgs: { disabled: false },
            processPkgs: { disabled: false },
            consolidatePkgs: { disabled: false },
            editOrder: { disabled: false },
            attachFiles: { disabled: false },
            transferOrder: { disabled: false, label: this.translate('Label_Transfer_Order')  }
        };
        this.findOrderGridId = "grid_with_remote_data";
        this.isApiCanceled = false;
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.findOrderGridId : '#' + this.findOrderGridId,
            Selector: "FindOrders",
            GridName: "findOrdersGrid",
            isFilterRowCountLoading: false,
            isOrderGridDataLoading: false,
            MenuPrintData: [],
            SameFacility: false,
            isSamePrintedFormat: false,
            SameFacilityId: 0,
            SameCustomer: false,
            SameBatchId: false,
            FullyAllocated: false,
            TplSettings: GlobalService.getTplSettings(),
            Rights: GlobalService.GetRights(),
            ParcelLabelType: 0,
            EnablePickOption: false,
            SelectedOrders: 0,
            Selection: this.defaultSelectionObj,
            staticGridData: [],
            defaultColumns: GridConfig.getGridDemoColumns(Orders_FindOrders_GridHeaders, GlobalService.getCustomLabels()).columns,

            schema: GridConfig.getGridDemoSchema(),
            allColumnList: GridConfig.getAllColumnList(Orders_FindOrders_GridHeaders, GlobalService.getCustomLabels()),
            allSortColumnList: [],
            MenuSmartParcelData: [],
            SharedIsOpenCarrierRoutingModelProto: false,
            SharedIsOpenCreatePickJobModel: false,
            sharedIsOpenMassShipAndCloseModel: false,
            sharedSelectedOrderData: null,
            InitialPrintMenuResponse: [],
            LocalKendoGrid: null,
            SharedSelectedOrderIds: [],
            isOpenCancelModal: false,
            isOpenReopenModal: false,
            isOpenUnallocateModal: false,
            isOpenReallocateModal: false,
            isOpenSplitOverallocateModal: false,
            isOpenConfirmForShipOrders: false,
            isOpenPickTicketModel: false,
            isOpenBatchConfirmModal: false,
            sharedIsOpenBatchingModel: false,
            isOpenRemoveBatchConfirmModal: false,
            sharedBatchProcess: null,
            sharedAttachObj: null,
            isOpenShippingLabelModel: false,
            sharedShippingLabelOption: {},
            isOpenAttachDoc: false,
            isOpenPackingSlipsModel: false,
            isOpenSendAsnModel: false,
            isOpenColumnModel: false,
            scacCodeAvailable: false,
            gifLoaderText: '',
            ticketModelConfig: {
                title: '',
                height: 0,
                batchOrderId: null,
                flow: '',
                pickTicketId: "modalPickticket"
            },
            orderSummary:{
                "TotVolumeMetric":0,
                "TotVolumeImperial":0,
                "TotWeightMetric":0,
                "TotWeightImperial":0,
                "TotPackages":0,
                "TotPrimaryUnit":0,
            },
            gridExportLimit: GlobalService.constants.gridExportLimit,
            totalOrders: 0,
            totalInLineColumnFilters: 0,
            filterText: "",
            fullFilterText: "",
            inlineGridFilter: false,
            inlineGridFilterCount: 0,
            tooltipShow: false,
            findOrderGridConfig: metaDataService.getFindOrderGridOptionInMetaData(),
            gridPageSize: 2000,
            overlayScreenShow: false,
            isAllDataLoad: false,
            totalRequest: 0,
            pendingRequest: 0,
            completeRequest: 0,
            isOpenBillOfLadingModel: false,
            isNewColumnAdded:false,
            isOpenEditBolTemplateUI:false,
            bolTemplateOptions:[],
            customers: {},
            sharedSelectedCustomerIds:[],
            sharedSelectedFacilityId:0,
            SharedSelectedOverAllocatedOrderIds: [],
            isOpenAllocationMessageModel:false,
            allocationResultMessage:'',
            faultResultsMessage:'',
            sharedIsOpenTransferModel:false,
            sharedIsOpenReprintEndOfDayReport:false,
            sharedIsOpenConsolidatePackages:false,
            sharedIsReprintShippingLabelOpen:false,
            isOpenPrintBatchedShippingModel:false,
            isOpenCustomerNotFoundModel: false,
            facilities: {},
            uidSelectionString:'',
            recordsCount: 0,
            showLoadingError: false
        };

    }
    componentDidMount() {
        this.getInitialPrintMenu();
        let customerData = GlobalService.getCustomerList(false);
        let facilities = GlobalService.getFacilityList(false);
        let that = this;
        document.addEventListener("EventOrderChanged", this.eventOrderChanged);
        document.addEventListener("customerFacilityOptionsUpdate", this.updateCustomerOptions);

        // document.addEventListener("EventOrderChangedRemove", function handler(e) {
        //     document.removeEventListener("EventOrderChanged", that.eventOrderChanged);
        //     e.currentTarget.removeEventListener(e.type, handler);
        //     /* whatever */
        // });
        // sort all column list title
        let allSortColumnList = this.state.allColumnList.sort(function (first, second) {
            var columnTitle1 = first.title.toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });
        var selection = Object.assign({},this.state.Selection);

        if (!this.state.Rights.hasOwnProperty('UserInterface.FindOrdersAutoBatchEnabled')) {
            selection.autoBatch.disabled = true;
            selection.autoBatch.message = this.translate('note_autoBatch_autoBatchEnabled');
        }

        this.setState({ allSortColumnList: allSortColumnList, customers: customerData, facilities: facilities, Selection:selection });
    }

    componentWillUnmount() {
        console.log('FindOrderGridVirtual unmount');
        document.removeEventListener("EventOrderChanged", this.eventOrderChanged);
        document.removeEventListener("customerFacilityOptionsUpdate", this.updateCustomerOptions);
    }

    updateCustomerOptions = () => {
        this.setState({
            optionsCustomer: GlobalService.getCustomerList(false),
            customers: GlobalService.getCustomerList(false),
        });
    }

    callForGetTotalCount = (clearData = true) => {
        this.setState({
            isFilterRowCountLoading: true,
            recordsCount: 0
        });
        this.getGridData(clearData);
    }
    refreshOrderGrid = () => {
        this.resetGridSelectionAndButton();
    }

    getGridData = (clearData) => {

        var param_filterObj = Object.assign({}, this.getSharedFindOrderFilter());
        param_filterObj.StartDate = StringWms.convertFilterStringToISODate(param_filterObj.StartDate,'',false,true).isoStringUTC;
        param_filterObj.EndDate = StringWms.convertFilterStringToISODate(param_filterObj.EndDate, '23:59:59',false,true).isoStringUTC;

        if (this.filterCountAxiosSource) {
            this.filterCountAxiosSource.cancel();
            this.filterCountAxiosSource = null;
        }
        // on filter update cancel orderDataAxios call if any running
        this.cancelLoadAllData();
        this.filterCountAxiosSource = axios.CancelToken.source();
        this.setState({
            showLoadingError: false
        });
        axios.put('/WebUI/Shipping/FindOrders/FilteredRowCount', param_filterObj, { cancelToken: this.filterCountAxiosSource.token }).then((response) => {
            var newStateData = {
                recordsCount: response.data
            };
            if (clearData == true) {
                newStateData.staticGridData = [];
            }
            this.filterCountAxiosSource = null;

            this.setState(
                newStateData
                , () => {
                    if (response.data > 0) {
                        this.callForGetNewData();
                    }
                });

        }).finally(() => {
            this.setState({ isFilterRowCountLoading: false });
        });
    }
    callForGetNewData = () => {

        if (this.state.recordsCount > 0 && this.state.recordsCount <= GlobalService.constants.virtualOrderGridDataLimit) {
            this.loadAllRecords(false);
        }else{
            // reset selection of grid on filter data change
            // this.setState({ staticGridData: [] });
            console.log("resetGridAllMessage : call");
            this.resetGridAllMessage();
        }

    }
    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                inlineGridFilter: false,
                inlineGridFilterCount: 0,
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map(function (item) {
            var columnObj = self.state.allColumnList.find(x => x.field === item.field);
            if(columnObj) {
            var title = columnObj.title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        var recordsOnCurrentView = dataSource.view().length;

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
            inlineGridFilter: true,
            inlineGridFilterCount: recordsOnCurrentView
        });
    }
    eventOrderChanged = (e) => {
        if(e && e.detail.source && e.detail.source == "pickTicketApiSuccess"){
            this.resetGridSelectionAfterGridRefresh();
        } else {
            this.resetGridSelectionAndButton();
        }
    }


    getInitialPrintMenu() {
        //setting to the first customer in the list for now until we talk to Jeff and come to a resolution
        var customerId = GlobalService.GetCurrentUser().CustomerIds[0];

        GlobalService.initialPrintMenu(customerId).then((response) => {
            if (response) {
                this.setState({
                    InitialPrintMenuResponse: response
                });
                this.updateInitialPrintMenu();
            }
        }).finally(() => { });
    }



    openTransferOrderModel = () =>{
        var customerIds = [];
        var FacilityId = '';
        let orderIds = this.state.LocalKendoGrid.getKeys();
        orderIds.forEach(key => {
            var selectedItem = this.state.LocalKendoGrid.getItem(key);
            if(!customerIds.includes(selectedItem.CustomerId)){
                customerIds.push(selectedItem.CustomerId);
                FacilityId = selectedItem.FacilityId;
            }
        });
        console.log("customerIds :",customerIds);

        var optionsCustomer = GlobalService.getCustomerList(false);
        var optionsFacility = GlobalService.getFacilityList(false);
        var facilityObj = [];
        var facilityOptions = [];
        var facilityName = '';

        _.filter(optionsCustomer, function(objCust){
            if(customerIds.includes(objCust.id)){
                console.log("Customer facility options ",objCust.facilities);
                if(facilityObj.length > 0){
                    facilityObj = _.intersection(facilityObj, objCust.facilities);
                }else{
                    facilityObj = objCust.facilities;
                }
            }
       });

       _.filter(optionsFacility, function(objFac){
            if(facilityObj.includes(objFac.id) && FacilityId != objFac.id){
                facilityOptions.push(objFac);
            }

            if(FacilityId == objFac.id){
                facilityName = objFac.name;
            }
        });

        this.setState({
            sharedIsOpenTransferModel:true,
            transferFacilityOptions:facilityOptions,
            transferFacilityName:facilityName,
        });
    }
    closeTransferOrderModel = () =>{
        this.setState({ sharedIsOpenTransferModel:false });
    }
    updateInitialPrintMenu = () => {
        var params = {
            Selector: this.state.Selector,
            SelectedOrders: this.state.SelectedOrders,
            SameFacility: this.state.SameFacility,
            SameCustomer: this.state.SameCustomer,
            ParcelLabelType: this.state.ParcelLabelType,
            SameBatchId: this.state.SameBatchId,
            FullyAllocated: this.state.FullyAllocated,
            EnablePickOption: this.state.EnablePickOption,
            TplSettings: this.state.TplSettings,
            isSamePrintedFormat:this.state.isSamePrintedFormat,
            Rights: this.state.Rights,
            SelectedOrderIds: this.state.SharedSelectedOverAllocatedOrderIds,
            fnNames: ['RunBOLReport', 'RunMasterBOLReport', 'RunReportShippingLabels', 'RunReportViewTransaction'],
            isSmartParcelDisable:this.state.Selection.smartParcel.disabled
        };

        const updatedMenus = DynamicMenu.getInitialPrintMenu(this.state.InitialPrintMenuResponse, params, this.dynamicMenuClick, 'findOrder');

        this.setState({
            MenuPrintData: updatedMenus.PrintData,
            MenuSmartParcelData: updatedMenus.SmartParcelData,
        });
    }

    UpdateGridSummary = () => {
        if (this.state.LocalKendoGrid != null) {
            let KendoGrid = this.state.LocalKendoGrid;

            var selection = JSON.parse(JSON.stringify(this.defaultSelectionObj));

            var IsClosed = KendoGrid.valueExists("IsClosed", true);
            var customCompletLabel = GlobalService.getCustomLabels().Label_Complete;
            var IsComplete = KendoGrid.valueExists("Status", customCompletLabel);
            var IsOpen = KendoGrid.valueExists("Status", "Open");
            var CanConfirm = KendoGrid.valueExists("CanConfirm", false);
            var CanCancel = KendoGrid.valueExists("CanCancel", false);
            var CanUnconfirm = KendoGrid.valueExists("CanUnconfirm", false);
            var SelectedOrders = KendoGrid.getNumberOfSelections();
            var SameFacility = KendoGrid.valuesIdentical("FacilityId");
            var isSamePrintedFormat = KendoGrid.valuesIdentical("ParcelLabelType");
            var isIdenticalStatusOpen = KendoGrid.identicalValuesInSelectedSet("Status","Open");

            var SameCustomer = KendoGrid.valuesIdentical("CustomerId");
            var SameBatchId = KendoGrid.valuesIdentical("BatchOrderId");
            var CanSplit = KendoGrid.valueExists("CanSplit", false);
            var CanAllocate = KendoGrid.valueExists("CanAllocate", true);
            var OneOrMoreInPickJob = KendoGrid.theNotValueDoesExist("PickJobId", null);
            var IsPickDone = KendoGrid.valueExists("IsPickDone", true);
            var SamePickJobId = KendoGrid.valuesIdentical("PickJobId");
            var CanDeallocate = KendoGrid.valueExists("CanDeallocate", false);
            var orderPartOfBatch = KendoGrid.theNotValueDoesExist("BatchOrderId", null);
            var CanExportTMW = KendoGrid.valueExists("CanExportToTmw", false);
            var NoLineItems = KendoGrid.valueExists("TotalLines", 0);
            var notFullyAllocated = KendoGrid.valueExists("FullyAllocated", false);
            var hasAllocations = KendoGrid.valueDoesNotExist("HasAllocations", false);
            var isFullyAllocate = KendoGrid.valueExists("FullyAllocated", true);
            var IdenticalSetOfOrders = KendoGrid.valuesIdentical("FullyAllocated");
            var CanRegenPkgs = KendoGrid.valueDoesNotExist("CanRegenPkgs", false);
            var selectedOverAllocatedOrders = KendoGrid.getSelectedOrderIdsByValue("FullyAllocated", false);

            var TrackingNumDoesNotExists;
            var TrackingNumRequired = false;

            if (SelectedOrders >= 1) {
                var orderData = this.state.LocalKendoGrid.getItem(KendoGrid.getKeys()[0]);
                var selectedCustomerData = KendoGrid.getSelectedCustomerData(this.state.customers, orderData.CustomerId);
                if (!selectedCustomerData) {
                    this.setState({ isOpenCustomerNotFoundModel: true });
                    return;
                }
                TrackingNumDoesNotExists = KendoGrid.valuesNullOrEmpty("TrackingNumber");
                TrackingNumRequired = selectedCustomerData.options.requireTrackingNumber;

                // Count only change if selected order is 1 or more
                var TotVolumeMetric = KendoGrid.sumDictionaryParameter("TotVolumeMetric");
                var TotVolumeImperial = KendoGrid.sumDictionaryParameter("TotVolumeImperial");
                var TotWeightMetric = KendoGrid.sumDictionaryParameter("TotWeightMetric");
                var TotWeightImperial = KendoGrid.sumDictionaryParameter("TotWeightImperial");
                var TotPackages = KendoGrid.sumDictionaryParameter("TotPackages");
                var TotPrimaryUnit = KendoGrid.sumDictionaryParameter("TotalItemQty");
                this.setState({
                    orderSummary:{
                        "TotVolumeMetric":TotVolumeMetric,
                        "TotVolumeImperial":TotVolumeImperial,
                        "TotWeightMetric":TotWeightMetric,
                        "TotWeightImperial":TotWeightImperial,
                        "TotPackages":TotPackages,
                        "TotPrimaryUnit":TotPrimaryUnit,
                    }
                });
            } else {
                this.totalOrderSummary();
            }


            if (IsOpen){
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_completeorder');
            }
            if (orderPartOfBatch == false) {
                selection.removeFromBatch.disabled = true;
                selection.removeFromBatch.message = this.translate('note_removeFromBatch_orderPartOfBatch');
            }
            if (CanSplit == true) {
                selection.splitOverallocated.disabled = true;
                selection.splitOverallocated.message = this.translate('note_split_order_fullyallocate');
            }

            // shortShipOrders only enable for partial orders
            selection.shortShipOrders.message = this.translate('Label_Short_Ship_Orders_Disabled_Note');
            if(!isFullyAllocate && hasAllocations) {
                // selection.shortShipOrders.disabled = true;
            } else {
                selection.shortShipOrders.disabled = true;
            }

            if (CanConfirm == true) {
                selection.shipAndClose.disabled = true;
                selection.shipAndClose.message = this.translate('note_ship_and_close_canConfirm');
            }
            if(TrackingNumRequired && TrackingNumDoesNotExists){
                selection.shipAndClose.disabled = true;
                selection.shipAndClose.message = this.translate('Label_Disabled_ShipAndClose');
            }
            if (CanCancel == true) {
                selection.cancel.disabled = true;
                selection.cancel.message = this.translate('note_cancel_closedorder');
            }
            if (CanUnconfirm == true) {
                selection.reopen.disabled = true;
                selection.reopen.message = this.translate('note_reopen_closedorder');
            }
            if (CanExportTMW == true) {
                selection.tmw.disabled = true;
            }
            if (CanAllocate == false) {
                selection.reallocate.disabled = true;
                selection.reallocate.message = this.translate('note_reallocate_closedorder');
            }
            else {
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_underallocated');
            }
            if (OneOrMoreInPickJob == true) {
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_inpickjob');
            }
            else {
                selection.removeFromPickJob.disabled = true;
                selection.removeFromPickJob.message = this.translate('note_removefrom_pick_job_inpickjob');
                selection.deletePickJob.disabled = true;
                selection.deletePickJob.message = this.translate('note_delete_pick_job_inpickjob');
            }
            if (IsPickDone == true) {
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_ispickdone');
            }
            if (SamePickJobId == false) {
                selection.removeFromPickJob.disabled = true;
                selection.removeFromPickJob.message = this.translate('note_removefrom_pick_job_samepickjobid');
                selection.deletePickJob.disabled = true;
                selection.deletePickJob.message = this.translate('note_delete_pick_job_samepickjobid');
            }
            if (CanDeallocate == true) {
                selection.unallocate.disabled = true;
                selection.unallocate.message = this.translate('note_unallocate_closedorder');
            }
            if (NoLineItems == true) {
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_underallocated');
            }
            if (CanRegenPkgs == false) {
                selection.recalcPkgs.disabled = true;
                selection.recalcPkgs.message = this.translate('note_cant_recalcpkgs');

                selection.consolidatePkgs.disabled = true;
                selection.consolidatePkgs.message = this.translate('note_cant_consolidatepkgs');
            }
            if (SameFacility !== true || IsClosed == true) {
                selection.transferOrder.disabled = true;
                selection.transferOrder.message = this.translate('note_Transfer_Order');
            }
            if (IsComplete == true && !this.state.Rights.hasOwnProperty('isWarehouseUser')) {
                selection.transferOrder.disabled = true;
                selection.transferOrder.message = this.translate('note_Transfer_Complete_Order_For_Cust_User');
            }
            if (SelectedOrders > 1) {
                selection.transferOrder.label = this.translate('Label_Transfer_Order') + 's';
            }
            if (SameCustomer !== true) {
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_samecustomer');
                selection.shipAndClose.disabled = true;
                selection.shipAndClose.message = this.translate('note_ship_and_close_samecustomer');
                selection.reallocate.disabled = true;
                selection.reallocate.message = this.translate('note_reallocate_samecustomer');
                selection.addToNewBatch.disabled = true;
                selection.addToNewBatch.message = this.translate('note_addToNewBatch_samecustomer');
                selection.addToExistingBatch.disabled = true;
                selection.addToExistingBatch.message = this.translate('note_addToExistingBatch_samecustomer');
            }
            if (SameFacility !== true) {
                selection.carrierAndRouting.disabled = true;
                selection.carrierAndRouting.message = this.translate('note_edit_carrier_samefacility');
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_samefacility');
                selection.shipAndClose.disabled = true;
                selection.shipAndClose.message = this.translate('note_ship_and_close_samefacility');
                selection.addToNewBatch.disabled = true;
                selection.addToNewBatch.message = this.translate('note_addToNewBatch_samefacility');
                selection.addToExistingBatch.disabled = true;
                selection.addToExistingBatch.message = this.translate('note_addToExistingBatch_samefacility');
            }


            if (IsClosed == true) {
                selection.carrierAndRouting.disabled = true;
                selection.carrierAndRouting.message = this.translate('note_edit_carrier_closedorder');
                selection.createPickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_closedorder');
                selection.removeFromPickJob.disabled = true;
                selection.removeFromPickJob.message = this.translate('note_removefrom_pick_job_closedorder');
                selection.deletePickJob.disabled = true;
                selection.deletePickJob.message = this.translate('note_delete_pick_job_closedorder');
                selection.editOrder.disabled = true;
                selection.editOrder.message = this.translate('note_edit_order');
                selection.smartParcel.disabled = true;
            }

            if(IsComplete && !this.state.Rights.hasOwnProperty('isWarehouseUser')){
                selection.editOrder.disabled = true;
                selection.editOrder.message = this.translate('note_Customer_User_Disable_Note');
                selection.carrierAndRouting.disabled = true;
                selection.carrierAndRouting.message = this.translate('note_edit_carrier_customer_user');
            }

            if (SelectedOrders != 1) {
                selection.editOrder.message = this.translate('note_edit_order');
                selection.attachFiles.disabled = true;
                selection.attachFiles.message = this.translate('note_attach_files');
            }

            if (isIdenticalStatusOpen == false) {
                selection.markOrdersComplete.disabled = true;
                selection.markOrdersComplete.message = this.translate('Label_Mark_Orders_Complete_Disable');
            }

            // Because of partial allocations, we are implementing a rule that you cannot allocate if you are not in the same warehouse
            // So this doesn't apply unless there is more than 1 selection obviously.  We are including this here because it is
            // AFTER the normal check for allocations as we want to display to the user first if the normal rules for allocation apply
            // In other words the priority for displaying to users is this
            // First normal allocation message (You can't allocate on no line item orders etc)
            // Then would be you cannot allocate from 2 different facilities
            // So is there more than 1 order selected, are those orders allocatable and are then in different warehouses
            if (SelectedOrders >= 1 && CanAllocate === true && SameFacility !== true) {
                selection.reallocate.disabled = true;
                selection.reallocate.message = this.translate('Note_Allocate_Must_Be_Same_Facility');
            }

            // Condition for parcel type start
            var ParcelLabelType = 0;
            var ParcelLabelTypeArr = [];

            // We may need the currently selected Facility ID, we will instantiate a variable here although we will only
            // change or populate this ID, if the current selection contains only the same Facility ID
            // We will check this when we loop through the selection loop below
            var FacilityIdSelected = 0;

            // This appears to be looping through our selections.
            // Use this below if you need to get specific data out of the current selections
            // SELECTION LOOP
            KendoGrid.getKeys().forEach(key => {
                var selectedItem = KendoGrid.getItem(key);
                ParcelLabelTypeArr.push(selectedItem.ParcelLabelType);
                // We only want to select a facilityID if they are all the same facility.  We don't want to assign it though
                // everytime through the loop though, so we will check to see if it is it's default setting.  If it is still its
                // default setting then change it, if not (in other words it has already changed then just do nothing)
                if (SameFacility === true && FacilityIdSelected === 0){
                    if(selectedItem.hasOwnProperty("FacilityId")){
                        FacilityIdSelected = selectedItem.FacilityId;
                    } else {
                        console.log("WE SHOULD NEVER REACH THIS STATE, SOMEHOW THE GRID DID NOT HAVE A FACILITYID PROPERTY IN ITS ROW");
                    }

                }
            });

            if (ParcelLabelTypeArr.includes(1)) {
                ParcelLabelType = 1;
            } else if (ParcelLabelTypeArr.includes(2)) {
                ParcelLabelType = 2;
            }
            // Condition for parcel type end

            // conditional enabling of the PickTicket option for overallocated and multiselected set of orders
            var enablePickTicketOption = true;
            if (SelectedOrders == 1 && notFullyAllocated && !this.state.TplSettings.Shipping.PrintOverAllocPickTickets) {
                enablePickTicketOption = false;
            } else if (SelectedOrders > 1 && !IdenticalSetOfOrders) {
                enablePickTicketOption = false;
            } else if (SelectedOrders > 1 && (notFullyAllocated && IdenticalSetOfOrders) && !this.state.TplSettings.Shipping.PrintOverAllocPickTickets) {
                enablePickTicketOption = false;
            }

            if(IsComplete && !this.state.Rights.hasOwnProperty('isWarehouseUser')){
                selection.unallocate.disabled = true;
                selection.unallocate.message = this.translate('note_unallocate_closedorder_customer_user');
                selection.reallocate.disabled = true;
                selection.reallocate.message = this.translate('note_reallocate_closedorder_customer_user');
            }

            if(!this.state.Rights.hasOwnProperty('isWarehouseUser')){
                selection.createPickJob.disabled = true;
                selection.removeFromPickJob.disabled = true;
                selection.deletePickJob.disabled = true;
                selection.createPickJob.message = this.translate('note_create_pick_job_completeorder_customer_user');
                selection.removeFromPickJob.message = this.translate('note_removefrom_pick_job_closedorder_customer_user');
                selection.deletePickJob.message = this.translate('note_delete_pick_job_closedorder_customer_user');
            }

            if (CanRegenPkgs == true && IsComplete && !this.state.Rights.hasOwnProperty('isWarehouseUser')) {
                selection.consolidatePkgs.disabled = true;
                selection.consolidatePkgs.message = this.translate('note_cant_consolidatepkgs_customer_user');

                selection.recalcPkgs.disabled = true;
                selection.recalcPkgs.message = this.translate('note_cant_recalculatepkgs_customer_user');
            }
            if (IsComplete && !this.state.Rights.hasOwnProperty('isWarehouseUser')) {
                selection.processPkgs.disabled = true;
                selection.processPkgs.message = this.translate('note_cant_processpackgs_customer_user');
			}
			if (IsComplete && !this.state.Rights.hasOwnProperty('isWarehouseUser')) {
				selection.cancel.disabled = true;
				selection.cancel.message = this.translate('note_cant_cancel_customer_user');
			}
            this.setState({
                Selection: selection,
                SelectedOrders: SelectedOrders,
                SameFacility: SameFacility,
                isSamePrintedFormat: isSamePrintedFormat,
                SameFacilityId: FacilityIdSelected,
                SameCustomer: SameCustomer,
                SameBatchId: SameBatchId,
                FullyAllocated: !notFullyAllocated,
                SharedSelectedOrderIds: KendoGrid.getKeys(),
                sharedSelectedOrderData: this.state.LocalKendoGrid.getItem(KendoGrid.getKeys()[0]),
                ParcelLabelType: ParcelLabelType,
                EnablePickOption: enablePickTicketOption,
                SharedSelectedOverAllocatedOrderIds: selectedOverAllocatedOrders
            });

            this.updateInitialPrintMenu();

            }
        }

    selectRow = (KendoGrid, isCallFrom) => {

        this.setState({
            LocalKendoGrid: KendoGrid
        });
        let tempkey = KendoGrid.getKeys();
        if (isCallFrom != 'contextMenuClick' && tempkey.length == 1) {
            let dataItem = {
                'isGridSelectionUpdate': true,
                'dataItem': KendoGrid.getItem(tempkey)
            };
            this.props.onViewSummary(dataItem);
        }
        this.UpdateGridSummary();
    }
    closeCustomerNotFoundModel = () => {
        this.setState({ isOpenCustomerNotFoundModel: false });
    }

    onCreateOrderProto = (e) => {
        this.props.onCreateOrderProto();
    }

    onCreateOrderProtoInNewTab = (e) => {
        this.$rootScope.OpenWindow({
            url: "<manage-orders-form-proto></manage-orders-form-proto>",
            reactComponent: ManageOrdersFormProto,
            singleton: false,
            id: 0,
            title: this.translate('Window_Title_Label_CreateOrder'),
            active: true,
            lineItem: [{
                name: '',
                value: 'Window_Title_Label_CreateOrder'
            }],
            icon: 'submenu-icon fa fa-plus',
            urlParam: 'manageorder-v3'
        });
    }
    onImportOrder = (e) => {
        this.$rootScope.OpenWindow({
            // url: '<react-import-order-holder></react-import-order-holder>',
            url: '<react-import-order-holder></react-import-order-holder>',
            reactComponent: importOrdersHolder,
            singleton: true,
            title: this.translate('Label_Import_Order'),
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: this.translate('Label_Import_Order')
            }],
            icon: 'submenu-icon fa fa-download',
            urlParam: 'importorders'
        });
    }
    onEditOrderProto = (e) => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let dataItem = this.state.LocalKendoGrid.getItem(tempkey); // technically the key is the orderId as well so this step is redundant but just in case we run into a wierd scenario

        this.props.onEditOrderProto(dataItem);
        this.$rootScope.setLoaderTimer('Edit Order');
    }
    onEditOrderProtoNewTab = () => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let dataItem = this.state.LocalKendoGrid.getItem(tempkey);
        this.$rootScope.setLoaderTimer('Edit Order');
        this.$rootScope.OpenWindow({
            url: "<manage-orders-form-proto></manage-orders-form-proto>",
            reactComponent: ManageOrdersFormProto,
            singleton: false,
            sharedSelectedOrder: dataItem,
            id: 'proto_'+dataItem.OrderId,
            title: this.translate('Window_Title_Label_EditOrder'),
            active: true,
            lineItem: [{
                name: 'Ref: ',
                value: dataItem.ReferenceNum
            }, {
                name: 'Trans: ',
                value: dataItem.OrderId
            }],
            icon: 'submenu-icon fa fa-edit',
            close: {
                'Title': this.translate('Label_Confirmation_Warning_Title'),
                'Button1Title': this.translate('Label_SaveAndExit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': this.translate('Label_OrderChangesLos')
            },
            urlParam: 'manageorder-v3'
        });
    }
    onCloneOrderClick = () => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let dataItem = this.state.LocalKendoGrid.getItem(tempkey);
        this.$rootScope.setLoaderTimer('Edit Order');
        this.$rootScope.OpenWindow({
            url: "<manage-orders-form-proto></manage-orders-form-proto>",
            reactComponent: ManageOrdersFormProto,
            singleton: false,
            sharedSelectedOrder: dataItem,
            isCloneOrder: true,
            id: 'cloneOrder_'+dataItem.OrderId,
            title: this.translate('Window_Title_Label_CloneOrder'),
            active: true,
            lineItem: [{
                name: 'Ref: ',
                value: dataItem.ReferenceNum
            }, {
                name: 'Trans: ',
                value: dataItem.OrderId
            }],
            icon: 'submenu-icon fa fa-edit',
            close: {
                'Title': this.translate('Label_Confirmation_Warning_Title'),
                'Button1Title': this.translate('Label_SaveAndExit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': this.translate('Label_OrderChangesLos')
            },
            urlParam: 'manageorder-v3'
        });
    }
    onViewSummary = () => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let dataItem = {
            'isOnViewSummaryClick': true,
            'dataItem': this.state.LocalKendoGrid.getItem(tempkey)  // technically the key is the orderId as well so this step is redundant but just in case we run into a wierd scenario
        };
        this.props.onViewSummary(dataItem);
    }

    onRemoveFromPickJob = (e) => {
        var orderIds = this.state.LocalKendoGrid.getKeys().map(function (value) {
            return parseInt(value);
        });

        this.setState({ gifLoaderText: this.translate('Label_RemoveFrom_Pick_Job') });
        findOrdersService.removeFromPickJob(orderIds).then((response) => {
            GlobalService.notification('success', this.translate('Label_RemoveFrom_Pick_Job_Success'));
            this.resetGridSelectionAndButton();
        }).catch((error) => {
            var errorMessage = error.response.data.Descriptions[0].ErrorCode;
            var title = '';
            if (errorMessage.includes('OrderHasSomePicked')) {
                var pickedIds = error.response.data.Descriptions[0].Hint;
                title = this.translate('Label_General_Notification');
                errorMessage = this.translate('Label_Pick_Job_OrderHasSomePicked_Error')
                    .replace(new RegExp('{' + 'ordersPicked' + '}', 'gi'), pickedIds);
            }
            else if (errorMessage.includes('NoOrdersFound')) {
                var uri = error.response.data.Descriptions[0].Hint;
                title = this.translate('Label_Error');
                errorMessage = this.translate('Label_Pick_Job_MissingPickJob_Error')
                    .replace(new RegExp('{' + 'pickJobUri' + '}', 'gi'), uri);
            }

            GlobalService.notification('error', title, errorMessage);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    onDeletePickJob = (e) => {
        let orderIds = this.state.LocalKendoGrid.getKeys();
        let row = this.state.LocalKendoGrid.getItem(orderIds[0]);
        let pickJobId = row.PickJobId;

        this.setState({ gifLoaderText: this.translate('Label_Delete_Pick_Job') });
        findOrdersService.deletePickJob(pickJobId).then((response) => {
            GlobalService.notification('success', this.translate('Label_Delete_Pick_Job_Success'));
            this.resetGridSelectionAndButton();
        }).catch((error) => {
            var errorMessage = error.response.data.Descriptions[0].ErrorCode;
            if (errorMessage.includes('OrderHasSomePicked')) {
                var pickedIds = error.response.data.Descriptions[0].Hint;
                errorMessage = this.translate('Label_Pick_Job_OrderHasSomePicked_Error')
                    .replace(new RegExp('{' + 'ordersPicked' + '}', 'gi'), pickedIds);
            }
            else if (errorMessage.includes('NoOrdersFound')) {
                var uri = error.response.data.Descriptions[0].Hint;
                errorMessage = this.translate('Label_Pick_Job_MissingPickJob_Error')
                    .replace(new RegExp('{' + 'pickJobUri' + '}', 'gi'), uri);
            }

            GlobalService.notification('error', errorMessage);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    setModelState = (param_model_state, param_bool) => {
        this.setState({ [param_model_state]: param_bool });
    }
    closeReallocateConfirmationModal = (showLoadingMessage) =>{
        if(showLoadingMessage){
            this.setState({ gifLoaderText: this.translate('Label_Allocation') });
        }
        this.setState({
            isOpenReallocateModal:false
        });
    }
    onCloseReallocateModal = (param_result_msg) =>{
        this.setState({ gifLoaderText: "" });

        if(param_result_msg){
            setTimeout(() =>{
                var tmpAllocationmsg = this.translate('Label_Allocation_Results_Msg')
                .replace(new RegExp('{' + 'NotAllocatedOrders' + '}', 'gi'), param_result_msg.TotalOrdersNotReallocated
                ).replace(new RegExp('{' + 'TotalOrders' + '}', 'gi'), param_result_msg.TotalOrders);
                var tmpFaultResultsMessage = '';
                if(
                    param_result_msg.FaultedOrders &&
                    param_result_msg.FaultedOrders.Faults &&
                    param_result_msg.FaultedOrders.Faults.length
                ) {
                    var faultArr = param_result_msg.FaultedOrders.Faults;
                    for (let i = 0; i < faultArr.length; i++) {
                        const fultElement = faultArr[i];
                        var orderId = 0;
                        if(fultElement.EntryInfo) {
                            fultElement.EntryInfo = JSON.parse(fultElement.EntryInfo);
                            var orderId = fultElement.EntryInfo.OrderId;
                        }
                        if(
                            fultElement.WmsException &&
                            fultElement.WmsException.Message
                        ) {
                            var faultMsg = fultElement.WmsException.Message;
                            if(
                                fultElement.WmsException.ErrorCode &&
                                fultElement.WmsException.ErrorCode == 'OrderConfirmed'
                            ) {
                                faultMsg = this.translate('Label_Reallocation_Order_Confirm');
                            }
                            tmpFaultResultsMessage += "<div><b>"+orderId+" : </b><span>" + faultMsg + " </span></div>";
                        }

                    }
                }
                this.setState({
                    isOpenAllocationMessageModel: true,
                    allocationResultMessage:tmpAllocationmsg,
                    faultResultsMessage:tmpFaultResultsMessage,
                });
            },650);
        }
    }

    exitRallocatonResultModel = () => {
        this.setState({
            isOpenAllocationMessageModel: false
        });
    }

    onSendToTMW = (e) => {
        var orderIds = this.state.LocalKendoGrid.getKeys().map(function (value) {
            return parseInt(value);
        });

        this.setState({ gifLoaderText: this.translate('Label_Send_To_TMW') });
        findOrdersService.tmwExporter(orderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Send_To_TMW_Success'));
                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    processPackages = (e) => {
        let orderids = JSON.stringify(this.state.SharedSelectedOrderIds[0]);
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: '/WebUI/V1/ProcessPackages?values=' + orderids,
            isIframe: true,
            title: 'Label_UpdateEdit_UPS_Packages',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_UpdateEdit_UPS_Packages'
            }],
            icon: 'submenu-icon fa fa-pencil-square-o',
            urlParam: 'manage-ups-packages'
        });
    }

    recalcPackages = (e) => {
        var orderIds = this.state.LocalKendoGrid.getKeys().map(function (value) {
            return parseInt(value);
        });

        this.setState({ gifLoaderText: this.translate('Label_Recalc_Packages') });
        findOrdersService.recalcPackages(orderIds).then((response) => {
            GlobalService.notification('success', this.translate('Label_Recalc_Packages_Success'));
            this.resetGridSelectionAndButton();
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    closeBatchingModel = (e) => {
        this.setState({
            sharedIsOpenBatchingModel: false,
            sharedBatchProcess: null
        });
    }
    onOpenBatch = (param_batch_process) => {
        let ordersFromIndenticalCustomer = this.state.LocalKendoGrid.valuesIdentical("CustomerId");
        let orderPartOfBatch = this.state.LocalKendoGrid.valueDoesNotExist("BatchOrderId", null);

        if (ordersFromIndenticalCustomer == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else if (orderPartOfBatch == true) {
            this.setState({
                isOpenBatchConfirmModal: true,
                sharedBatchProcess: param_batch_process
            });
        } else {
            this.setState({
                sharedIsOpenBatchingModel: true,
                sharedBatchProcess: param_batch_process
            });
        }
    }

    onRemoveFromBatch = (e) => {
        let orderPartOfBatch = this.state.LocalKendoGrid.valueDoesNotExist("BatchOrderId", null);
        if (orderPartOfBatch == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else {
            this.setState({ isOpenRemoveBatchConfirmModal: true });
        }
    }

    attachFiles = (e) => {
        var sharedAttachObj = {
            'isOrderAttachBatch': false,
            'isOrder': true,
            'uri': this.state.sharedSelectedOrderData.EditUri,
            'fileSummariesUri': this.state.sharedSelectedOrderData.FileSummariesUri,
            'getAttachedDocsInfoUrl': '/WebUI/Shipping/Orders/GetAttachedDocsInfo',
            'getAttachedDocsInfoPayload': {
                'orderUri': this.state.sharedSelectedOrderData.EditUri
            },
            'attachdocumentUrl': '/WebUI/Shipping/Orders/AttachDocsToOrder?orderFileSummariesUri=' + this.state.sharedSelectedOrderData.FileSummariesUri,
            'removeAttachDocUrl': '/WebUI/Shipping/Orders/DeleteAttachedDoc',
            'selectedOrderData': this.state.sharedSelectedOrderData,
            'customer': this.state.sharedSelectedOrderData.Customer,
            'transactionId': this.state.sharedSelectedOrderData.OrderId,
        };
        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }
    attachFilesToBatch = (e) => {
        var sharedAttachObj = {
            'isOrderAttachBatch': true,
            'uri': '',
            'fileSummariesUri': '',
            'getAttachedDocsInfoUrl': '/WebUI/Shipping/Orders/GetBatchAttachedDocsInfo?uri=',
            'getAttachedDocsInfoPayload': {},
            'attachdocumentUrl': '/WebUI/Shipping/Orders/AttachDocsToBatch?batchFileSummariesUri=',
            'removeAttachDocUrl': '/WebUI/Shipping/Orders/DeleteBatchAttachedDoc?batchFileUri=',
            'selectedOrderData': this.state.sharedSelectedOrderData
        };
        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }

    pickTicket = (e) => {
        var customerIds = [];
        this.state.LocalKendoGrid.getKeys().forEach(key => {
            var selectedItem = this.state.LocalKendoGrid.getItem(key);
            if(!customerIds.includes(selectedItem.CustomerId)){
                customerIds.push(selectedItem.CustomerId);
            }
        });
        console.log("customerIds :",customerIds);
        this.setState({
            isOpenPickTicketModel: true,
            sharedSelectedCustomerIds:customerIds,
            ticketModelConfig: {
                title: this.translate('Label_Pick_Ticket'),
                height: 470,
                batchOrderId: null,
                flow: 'regular',
                pickTicketId: "pickTicketModel"
            },
        });
    }
    masterPickTicket = (e) => {
        this.setState({
            isOpenPickTicketModel: true,
            ticketModelConfig: {
                title: this.translate('Label_Master_Pick_Ticket'),
                height: 325,
                batchOrderId: (this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.BatchOrderId : null,
                flow: 'master',
                pickTicketId: "masterPickTicketModel"
            },
        });
    }
    editMasterBol = (e) => {
        let orderids = JSON.stringify(this.state.SharedSelectedOrderIds);
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: true,
            iframeMethod:'PUT',
            orderids:orderids,
            id: '/WebUI/V1/EditMasterBol',
            isIframe: true,
            reloadIframe:true,
            title: 'Label_Edit_Master_BOL',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Edit_Master_BOL'
            }],
            uniqName:"editMasterBol",
            icon: 'submenu-icon fa fa-pencil-square-o'
        });
    }
    shippingManifest = (e) => {
        let orderids = JSON.stringify(this.state.SharedSelectedOrderIds);
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: true,
            iframeMethod:'PUT',
            orderids:orderids,
            id: '/WebUI/V1/ShippingManifest',
            isIframe: true,
            reloadIframe:true,
            title: 'Label_Shipping_Manifest',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Shipping_Manifest'
            }],
            uniqName:"shippingManifest",
            icon: 'submenu-icon fa fa-pencil-square-o'
        });
    }
    closePickTicketModel = () => {
        this.setState({
            isOpenPickTicketModel: false,
            ticketModelConfig: {
                title: '',
                height: 0,
                batchOrderId: null,
                flow: '',
                pickTicketId: "modalPickticket"
            },
        });
    }

    runReportPackingSlips = (e) => {
        let isSameCustomerID = this.state.LocalKendoGrid.valuesIdentical("CustomerId");

        if (isSameCustomerID == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else {

            this.setState({ isOpenPackingSlipsModel: true });
        }
    }

    sendASN = (e) => {
        let scacCodeAvailable = false;
        if (!this.state.LocalKendoGrid.valueExists("ScacCode", null) &&
            !this.state.LocalKendoGrid.valueExists("ScacCode", '')) {
            scacCodeAvailable = true;
        }

        this.setState({
            scacCodeAvailable: scacCodeAvailable,
            isOpenSendAsnModel: true
        });
    }

    RunBOLReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunBOLReport');
    }
    EditBol = (e) => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let slectedOrederObj = this.state.LocalKendoGrid.getItem(tempkey);

        findOrdersService.getBOLTemplates(slectedOrederObj.CustomerId).then((response) => {

            if (response && response.status == 200) {
                if(response.data.length >= 1){
                    let bolTemplates = response.data;
                    bolTemplates.push({
                        "Name": "Default",
                        "BolTemplateId": 0
                    });
                    this.setState({
                        isOpenEditBolTemplateUI:true,
                        bolTemplateOptions:bolTemplates
                    });
                } else {
                    let templateId = 0;
                    if(response.data.length == 1){
                        templateId = response.data[0].BolTemplateId;
                    }
                    this.openEditBolClassicPage(templateId);
                }
            }
        }).finally(() => {
        });
    }
    onCloseEditBolOptionsModel = () =>{
        this.setState({
            isOpenEditBolTemplateUI:false,
            bolTemplateOptions:[]
        });
    }
    openEditBolClassicPage = (param_template_id) =>{
        let orderid = this.state.SharedSelectedOrderIds[0];

        let tempkey = this.state.LocalKendoGrid.getKeys();
        let selectedOrder = this.state.LocalKendoGrid.getItem(tempkey);

        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: '/WebUI/V1/EditBol?orderid='+orderid+'&templateid='+param_template_id+'&customerid='+selectedOrder.CustomerId,
            isIframe: true,
            title: 'Label_Bill_of_Lading_Editor',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Bill_of_Lading_Editor'
            }],
            icon: 'submenu-icon fa fa-pencil-square-o',
            urlParam: 'bol-editor'
        });
    }
    RunMasterBOLReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunMasterBOLReport');
    }

    RunReportShippingLabels = (e) => {
        console.log("clicked on RunReportShippingLabels");
    }
    RunReportViewTransaction = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunReportViewTransaction');
    }

    reprintPdf = (e) => {
        this.setState({ sharedIsReprintShippingLabelOpen: true });
    }
    onCloseReprintShippingLabel = () => {
        this.setState({ sharedIsReprintShippingLabelOpen: false });
    }

    returnPdf = (e) => {
        var reportUri = $(e.target).parent().attr('data-run-report');
        var TemplatedRunnerKey = $(e.target).parent().attr('data-runner-key');
        this.downloadPdf(reportUri, this.state.sharedSelectedOrderData.CustomerId, this.state.SharedSelectedOrderIds, TemplatedRunnerKey, "ReturnLabel");
    }

    reprintZpl = (e) => {
        var reportUri = "/WebUI/Shipping/FindOrders/ParcelReprintZplContents";
        this.setState({ gifLoaderText: this.translate('Label_ReprintZpl') });
        findOrdersService.reprintOrReturnZpl(reportUri, this.state.SharedSelectedOrderIds)
            .then((response) => {
                if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                    GlobalService.printDataInZplPrinter(response.data);
                    // for (let index = 0; index < response.data.length; index++) {
                    //   const element = response.data[index];
                    //   GlobalService.printDataInZplPrinter(element);
                    // }
                }
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }

    returnZpl = (e) => {
        var reportUri = $(e.target).parent().attr('data-run-report');
        this.setState({ gifLoaderText: this.translate('Label_Return_Print') });
        findOrdersService.reprintOrReturnZpl(reportUri, this.state.SharedSelectedOrderIds)
            .then((response) => {
                if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                    GlobalService.printDataInZplPrinter(response.data);
                    // for (let index = 0; index < response.data.length; index++) {
                    //   const element = response.data[index];
                    //   GlobalService.printDataInZplPrinter(element);
                    // }
                }
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }

    downloadPdf = (reportUri, CustomerId, selectedDataIds, TemplatedRunnerKey, pdfFileName) => {
        var pdfDownloadLink = reportUri + '?customerId=' + CustomerId + '&orderIds=' +
            selectedDataIds.toString() + '&templatedRunnerKey=' + TemplatedRunnerKey;
        this.setState({ gifLoaderText: this.translate('Label_Return_Print') });
        findOrdersService.reprintOrReturnPdf(pdfDownloadLink)
            .then((response) => {
                GlobalService.downloadFileBaseOnContentType(response.data, 'application/pdf', pdfFileName, false, 'pdfViewer');
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }

    reprintShippingLabel = (e) => {
        if(this.state.ParcelLabelType == 2){
            this.reprintZpl();
        }else{
            this.reprintPdf();
        }
    }

    smartParcel = (e) => {
        findOrdersService.parcelPreShip(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined"
                && (response.status == 204 || response.status == 200)) {

                let key = response.data;

                if (key == null || key == '')
                {
                    GlobalService.notification('error', 'Failed to store ids.');
                    return;
                }

                this.$rootScope.OpenWindow({
                    url: '<report-iframe-component></report-iframe-component>',
                    reactComponent: reportIframe,
                    singleton: false,
                    id: '/WebUI/V1/SmartParcelShip?key=' + key,
                    isIframe: true,
                    title: 'Label_Smart_Parcel',
                    active: true,
                    useLineItem: false,
                    lineItem: [{
                        name: '',
                        value: 'Label_Smart_Parcel'
                    }],
                    icon: 'submenu-icon fa fa-pencil-square-o',
                    urlParam:'smart-parcel'
                });
            }
        });
    }

    recreateIntlDocs = (e) => {
        let orderids = JSON.stringify(this.state.SharedSelectedOrderIds);
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            iframeMethod:'PUT',
            orderids:orderids,
            id: '/WebUI/V1/SmartParcelRecreateIntlDocs',
            isIframe: true,
            title: 'Label_Recreate_IntlDocs',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Recreate_IntlDocs'
            }],
            icon: 'submenu-icon fa fa-pencil-square-o',
            urlParam:'recreate-international-documents'
        });
    }
    voidShippingLabel = (e) => {
        this.setState({ gifLoaderText: this.translate('Label_Void_Shipping_Label') });

        findOrdersService.parcelCanceler(this.state.SharedSelectedOrderIds).then((response) => {
            var targetElement = $('[data-wms-selector="FindOrdersDocuments"]');
            this.$rootScope.animationMoveIcon(targetElement);
            this.$rootScope.taskStarted();
            var transferOrderObj = {
                "type": "voidShippingLabel",
                "relativeUri": response.data.StatusRelativeUri,
                "callBackFunc": this.resetGridSelectionAndButton
            }
            var event = new CustomEvent("showProgressModal", {
                detail: transferOrderObj
            });
            document.dispatchEvent(event);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    createEndofDayRequest = (e) => {
        this.setState({ gifLoaderText: this.translate('Label_End_of_Day_Request') });

        findOrdersService.parcelFinalizer(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_End_of_Day_Request'));

                if (response != null && response.data != null && response.data.length > 0)
                {
                    var blob = ArrayWms.convertBase64ToByteArray(response.data, 'application/pdf');
                    ArrayWms.displayBlobInWindow(blob, 'Manifest.pdf');
                }

                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    reprintEndofDayRequest = (e) => {
        this.setState({ sharedIsOpenReprintEndOfDayReport:true });
    }
    onCloseReprintEndOfDayReport = (e) => {
        this.setState({ sharedIsOpenReprintEndOfDayReport:false });
    }
    openConsolidatePackages = (e) => {
        this.setState({ sharedIsOpenConsolidatePackages:true });
    }
    onCloseConsolidatePackages = (e) => {
        this.setState({ sharedIsOpenConsolidatePackages:false });
    }
    cancelandVoidShippingLabel = (e) => {
        this.setState({ gifLoaderText: this.translate('Label_Cancel_and_Void_Shipping_Labels') });

        findOrdersService.parcelVoidCanceler(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_Cancel_and_Void_Shipping_Labels'));
                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    resetFailedHvp = (e) => {
        this.setState({ gifLoaderText: this.translate('Reset_Failed_Hvp') });

        findOrdersService.parcelReseter(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_Reset_Failed_Hvp'));
                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    printBatchedShippingLabel = (e) => {
        this.setState({ isOpenPrintBatchedShippingModel: true });
    }
    closePrintBatchedShippingLabel = () =>{
        this.setState({ isOpenPrintBatchedShippingModel: false });
    }
    autoRateShop = (e) => {
        this.setState({ gifLoaderText: this.translate('Label_Auto_Rate_Shop') });

        findOrdersService.parcelAutoRateShopper(this.state.SharedSelectedOrderIds).then((response) => {
            var respStr = this.translate('Label_Smart_Parcel_Auto_Rate_Shopping_Success');
            if (response.data.length > 0)
                respStr += this.translate('Label_Smart_Parcel_Auto_Rate_Shopping_Fail').replace('{ids}', response.data.join());

            GlobalService.notification('success', respStr);
            this.resetGridSelectionAndButton();
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr('data-wmsid');

        if (typeof this[wmsid] !== "undefined") {
            this[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    }
    ShippingLabel = () => {

        var shippingLblObj = this.state.InitialPrintMenuResponse.filter((el) => {
            if (el['RdlName'] == 'ShippingLabel') {
                return el;
            }
        })[0];
        this.setState({
            isOpenShippingLabelModel: true,
            sharedShippingLabelOption: shippingLblObj
        });
    }
    // function call when we click billOfLading
    BOL = () => {
        this.setState({
            isOpenBillOfLadingModel: true
        });
    }
    onCloseShippingLabelModel = () => {
        this.setState({ isOpenShippingLabelModel: false });
    }
    continueBatchModal = () => {
        this.setState({ isOpenBatchConfirmModal: false }, () => {
            setTimeout(() => { this.setState({ sharedIsOpenBatchingModel: true }); }, 600);
        });
    }
    continueRemoveBatchModal = () => {
        let batchSelections = [];
        for (var i = 0; i < this.state.SharedSelectedOrderIds.length; i++) {
            var orderDataRow = this.state.LocalKendoGrid.getItem(this.state.SharedSelectedOrderIds[i]);
            batchSelections.push({
                BatchUri: orderDataRow.BatchUri,
                OrderId: orderDataRow.OrderId
            });
        }

        findOrdersService.removeFromBatch(batchSelections).then((response) => {

            if (response != 'undefined' && (response.status == 200 || response.status == 204)) {

                GlobalService.notification('success', this.translate('Label_BatchRemove_Success'));
                this.setState({ isOpenRemoveBatchConfirmModal: false });
                this.resetGridSelectionAndButton();

            }
        }).finally(() => { });
    }
    resetGridSelectionAfterGridRefresh = () => {
        if (this.state.LocalKendoGrid != null) {
            let orderIds = this.state.LocalKendoGrid.getKeys();
            let uidSelectionString = [];
            orderIds.forEach(key => {
                var selectedItem = this.state.LocalKendoGrid.getItem(key);
                uidSelectionString.push("[data-uid='" + selectedItem.uid + "']");
            });
            this.setState({ uidSelectionString: uidSelectionString });
            this.getUpdateDataArray();
            this.closeCustomerNotFoundModel();
        }
    }

    resetGridSelectionAndButton = () => {
        if (this.state.LocalKendoGrid != null) {
            this.state.LocalKendoGrid.clearSelections(() => {
                this.state.LocalKendoGrid.repaintSelections();
            });
        }
        this.setState({
            SelectedOrders: 0
        });
        // call for get new update data
        this.getUpdateDataArray();
    }
    // updateSelectedOrderData = () => {
    //     let enableColumns = this.props.getEnableColumns();
    //     let self = this;
    //     axios.put('/WebUI/Shipping/FindOrders/OrderData?sorts=[]&filters=[]&pgnum=1&pgsiz=2000', enableColumns).then(function (response) {
    //         self.logicForAddUpdateOrderData(response.data.Data);
    //     });
    // }
    logicForAddUpdateOrderData = (responseData) => {
        // var orderIdArray = this.state.SharedSelectedOrderIds;
        // var responseOrderIdArray = [...new Set(responseData.map(item => item.OrderId))];
        // var staticGridData = _.cloneDeep(this.state.staticGridData);
        // // lodash remove take first dublicate order id and remove remaining and merge both array into single array
        // var updateOrderData = _.unionBy(responseData, staticGridData, "OrderId");


        // // convert array key string to number
        // orderIdArray = _.map(orderIdArray, Number);
        // // remaining order ids remove from static data

        // var remainingOrderIds = _.difference(orderIdArray, responseOrderIdArray);
        // remainingOrderIds.forEach((currentOrderId) => {
        //     updateOrderData.splice(updateOrderData.findIndex(function (i) {
        //         return i.OrderId === currentOrderId;
        //     }), 1);
        // });
        // this.props.updateStateOfData(updateOrderData);
        // // update total result count manually
        // if (remainingOrderIds.length > 0) {
        //     this.props.updateTotalCount(this.props.recordsCount - remainingOrderIds.length);
        // }
    }
    getUpdateDataArray = () => {
        this.props.getUpdatedData();
    }
    clickEventOfContextMenu = (obj, e) => {
        if (obj.wmsid == 'editOrderProto') {
            this.onEditOrderProto();
        }  else if (obj.wmsid == 'onEditOrderProtoNewTab') {
            this.onEditOrderProtoNewTab();
        }  else if (obj.wmsid == 'onCloneOrderClick') {
            this.onCloneOrderClick();
        } else if (obj.wmsid == 'orderViewSummary') {
            this.onViewSummary();
        } else if (obj.wmsid == 'carrierRoutingProto') {
            this.setModelState('SharedIsOpenCarrierRoutingModelProto', true);
        } else if (obj.wmsid == 'markOrdersComplete') {
            this.markOrdersComplete();
        } else if (obj.wmsid == 'createPickJob') {
            this.setModelState('SharedIsOpenCreatePickJobModel', true);
        } else if (obj.wmsid == 'removeFromPickJob') {
            this.onRemoveFromPickJob();
        } else if (obj.wmsid == 'deletePickJob') {
            this.onDeletePickJob();
        } else if (obj.wmsid == 'shipAndClose') {
            this.setModelState('sharedIsOpenMassShipAndCloseModel', true);
        } else if (obj.wmsid == 'canceling') {
            this.setModelState('isOpenCancelModal', true);
        } else if (obj.wmsid == 'reopen') {
            this.setModelState('isOpenReopenModal', true);
        } else if (obj.wmsid == 'sendToTMW') {
            this.onSendToTMW();
        } else if (obj.wmsid == 'reallocate') {
            this.setModelState('isOpenReallocateModal', true);
        } else if (obj.wmsid == 'unallocate') {
            this.setModelState('isOpenUnallocateModal', true);
        } else if (obj.wmsid == 'splitOverallocated') {
            this.setModelState('isOpenSplitOverallocateModal', true);
        } else if (obj.wmsid == 'shortSlipoverAllocate') {
            this.setModelState('isOpenConfirmForShipOrders', true);
        } else if (obj.wmsid == 'addToNew') {
            this.onOpenBatch('addToNew');
        } else if (obj.wmsid == 'addToExistingBatch') {
            this.onOpenBatch('addToExisting');
        } else if (obj.wmsid == 'removeFromBatch') {
            this.onRemoveFromBatch();
        } else if (obj.wmsid == 'attachFiles') {
            this.attachFiles();
        } else if (obj.wmsid == 'pickTicket') {
            this.pickTicket();
        } else if (obj.wmsid == 'masterPickTicket') {
            this.masterPickTicket();
        } else if (obj.wmsid == 'editMasterBol') {
            this.editMasterBol();
        } else if (obj.wmsid == 'shippingManifest') {
            this.shippingManifest();
        } else if (obj.wmsid == 'runReportPackingSlips') {
            this.runReportPackingSlips();
        } else if (obj.wmsid == 'ShippingLabel') {
            this.ShippingLabel();
        } else if (obj.wmsid == 'sendASN') {
            this.sendASN();
        } else if (obj.wmsid == 'BOL') {
            this.dynamicServiceForDynamicMenu(e, 'RunBOLReport');
            // this.BOL();
        } else if (obj.wmsid == 'EditBol') {
            this.EditBol(e);
            // this.BOL();
        } else if (obj.wmsid == 'MasterBOL') {
            this.dynamicServiceForDynamicMenu(e, 'RunMasterBOLReport');
        } else if (obj.wmsid == 'ViewTransaction') {
            this.dynamicServiceForDynamicMenu(e, 'RunReportViewTransaction');
        } else if (obj.wmsid == 'processPackages') {
            this.processPackages();
        } else if (obj.wmsid == 'recalcPackages') {
            this.recalcPackages();
        } else if (obj.wmsid == 'consolidatePackages') {
            this.openConsolidatePackages();
        }else if(obj.wmsid == 'transferOrder'){
            this.openTransferOrderModel();
        }
    }

    dynamicServiceForDynamicMenu = (event, fnName) => {
        var runReport = $(event.target).parent().attr('data-run-report');
        var rdlName = $(event.target).parent().attr('data-rdl-name');
        var parentLabel = $("[data-wmsid='" + rdlName + "'] span").first().text();

        this.setState({ gifLoaderText: parentLabel });
        findOrdersService.dynamicServiceForDynamicMenu(fnName, runReport, this.state.SharedSelectedOrderIds, null, 'FindOrders').then((response) => {
            this.handleResponseMenuPrint(response);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    markOrdersComplete = () => {
        console.log("markOrdersComplete SharedSelectedOrderIds:",this.state.SharedSelectedOrderIds);
        //GlobalService.notification('success', this.translate('Label_Mark_Orders_Complete_Success'));

        this.setState({ gifLoaderText: this.translate('Label_Mark_Orders_Complete') });
        findOrdersService.markOrdersComplete(this.state.SharedSelectedOrderIds).then((response) => {
            GlobalService.notification('success', this.translate('Label_Mark_Orders_Complete_Success'));
            this.resetGridSelectionAndButton();
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, 'application/pdf');
        ArrayWms.displayBlobInWindow(blob, 'report.pdf');
    }
    onGridDataBound = (e, kendoGrid) => {
        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var TotalInLineColumnFilters = KendoWms.getLengthOffFilters(grid.dataSource.filter());
        this.setState({
            totalOrders: grid.dataSource.total(),
            totalInLineColumnFilters: TotalInLineColumnFilters,
            LocalKendoGrid: kendoGrid
        });
        var gridData = grid.dataSource.view();
        for (var i = 0; i < gridData.length; i++) {
            // if (gridData[i].FullyAllocated == false) {
            //     grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-over-allocated");
            // }
            if(gridData[0] && gridData[0].CustomFields !== undefined && gridData[0].CustomFields !== "") {
                if (grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-custom-field-available");
                }
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-custom-field-available");
            }
            if(gridData[i].TotalLines == 0){ // No Line Items : Order does not have any line items.

                if (grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-no-line-items");
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-no-line-items-left-border");
                } else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-no-line-items-left-border");
                }
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-no-line-items");


            } else if(gridData[i].FullyAllocated){ // Fully Allocated: Order has line items and all line items are fully allocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-fully-allocated")
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-fully-allocated-left-border")
                } else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-fully-allocated-left-border")
                }
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-fully-allocated");

            } else if(!gridData[i].FullyAllocated && gridData[i].HasAllocations){ //Partially Allocated: Order has line items, with some being allocated and others unallocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-partially-allocated");
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-partially-allocated-left-border");
                } else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-partially-allocated-left-border");
                }
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-partially-allocated");

            } else { // Unallocated: Order has line items but none of them are allocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-un-allocated")
                    grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-un-allocated-left-border")
                } else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-un-allocated-left-border");
                }
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-un-allocated");

            }
        }
        this.$rootScope.setLoaderTimerEndTime("Find Orders");

        if(typeof $(this.state.gridDomSelector()).data("kendoTooltip") == 'undefined'){
            $(this.state.gridDomSelector()).kendoTooltip({
                filter: "tr:not('.wms-fully-allocated') td, tr.wms-custom-field-tooltips td.wms-custom-field-tooltips",
                position: "bottom",
                content: (e) => {
                    if(e.target.hasClass("wms-custom-field-tooltips") && e.target[0].innerText !== "") {
                        return e.target[0].innerText;
                    }
                    var dataItem = $(this.state.gridDomSelector()).data("kendoGrid").dataItem(e.target.closest("tr"));
                    var content = "";
                    if(typeof dataItem != 'undefined' && dataItem.TotalLines == 0){ // No Line Items
                        content = "No Line Items";
                    } else if(typeof dataItem != 'undefined' && dataItem.TotalLines > 0 && dataItem.CanDeallocate && dataItem.CanAllocate){ //Partially Allocated
                        content = "Partially Allocated";
                    } else if(typeof dataItem != 'undefined' && dataItem.TotalLines > 0 && dataItem.CanAllocate){ // Unallocated
                        content = "Unallocated";
                    }
                    return content;
                }
            }).data("kendoTooltip");

        }

    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
    }

    // on apply saved filter data save changes in meta data object
    onApplySavedFilterData = (filterObj) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

        metaDataService.applySavedFilterDataToFindOrderGrid(finalSubmission, filterObj);
    }

    // on new filter data clear selection of grid
    clearSelectionOfGrid = () => {
        if (this.state.LocalKendoGrid != null) {
            this.state.LocalKendoGrid.clearSelections(() => {
                this.state.LocalKendoGrid.repaintSelections();
            });
        }
        this.setState({
            SelectedOrders: 0,
            isAllDataLoad: false
        },() => {
            this.updateInitialPrintMenu();
         });
    }

    resetGridAllMessage = () =>{
        if(this.state.isAllDataLoad){
            this.setState({
                isNewColumnAdded:true
            });
        }
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        };
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);

        }, 100);

    }
    eventClickExportGrid = () => {
        $(this.state.gridDomSelector()).getKendoGrid().saveAsExcel();
    }
    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        });
    }
    onCloseColumnModel = (e) => {
        this.setState({
            isOpenColumnModel: false
        });
    }
    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
    }

    // Load all records of order
    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        if (isLoaderPopupShow == false) {
            this.setState({
                isOrderGridDataLoading: true,
                staticGridData: []
            });
        }
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Shipping/FindOrders",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });

        const MAX_CONCURRENT_REQUESTS = 3;

        let enableColumns = this.props.getEnableColumns();
        let orderSearchInfo = _.cloneDeep(this.getSharedFindOrderFilter());
        orderSearchInfo.StartDate = StringWms.convertFilterStringToISODate(orderSearchInfo.StartDate,'',false,true).isoStringUTC;
        orderSearchInfo.EndDate = StringWms.convertFilterStringToISODate(orderSearchInfo.EndDate, '23:59:59',false,true).isoStringUTC;
        if (orderSearchInfo.OrderNote) {
            orderSearchInfo.OrderNote = orderSearchInfo.OrderNote.trim();
        }

        let totalRequest = Math.ceil(this.state.recordsCount / this.state.gridPageSize);
        this.setState({
            totalRequest: totalRequest,
            pendingRequest: totalRequest,
            completeRequest: 0
        });

        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);

        Promise.all(sequence(totalRequest).map(i => api.put('/OrderData?sorts=[]&filters=[]&pgnum=' + (i + 1) + '&pgsiz=' + this.state.gridPageSize, { "columns": enableColumns, "orderSearchInfo": orderSearchInfo })))
            .then(responses => {
                let orderData = responses.map(r => r.data);
                this.getAllResponseData(orderData);
            }).catch((error) => {
                console.log("Promise.all catch",  error);
                this.setState({
                    showLoadingError: true
                });
                this.cancelLoadAllData();
            }).then(() => {
                if(manager) {
                    manager.detach();
                }
            });
    }

    // click on load all records first call for fifty records
    onClickLoadAllRecords = () => {
        let enableColumns = this.props.getEnableColumns();
        let self = this;
        let orderSearchInfo = _.cloneDeep(this.getSharedFindOrderFilter());
        orderSearchInfo.StartDate = StringWms.convertFilterStringToISODate(orderSearchInfo.StartDate,'',false,true).isoStringUTC;
        orderSearchInfo.EndDate = StringWms.convertFilterStringToISODate(orderSearchInfo.EndDate, '23:59:59',false,true).isoStringUTC;
        if (orderSearchInfo.OrderNote) {
            orderSearchInfo.OrderNote = orderSearchInfo.OrderNote.trim();
        }
        this.setState({
            overlayScreenShow: true
        });

        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/Shipping/FindOrders/OrderData?sorts=[]&filters=[]&pgnum=1&pgsiz=50', { "columns": enableColumns, "orderSearchInfo": orderSearchInfo }, { cancelToken: this.firstFiftyAxiosSource.token })
            .then((response) => {
                this.firstFiftyAxiosSource = "";
                this.setState({
                    staticGridData: response.data.Data
                },this.loadAllRecords);
            });
    }
    totalOrderSummary = () =>{
        var staticGridData = this.state.staticGridData;
        // _.sumBy(objects, 'n');
        var TotVolumeMetric = _.sumBy(staticGridData, "TotVolumeMetric");
        var TotVolumeImperial = _.sumBy(staticGridData, "TotVolumeImperial");
        var TotWeightMetric = _.sumBy(staticGridData, "TotWeightMetric");
        var TotWeightImperial = _.sumBy(staticGridData, "TotWeightImperial");
        var TotPackages = _.sumBy(staticGridData, "TotPackages");
        var TotPrimaryUnit = _.sumBy(staticGridData, "TotalItemQty");
        this.setState({
            orderSummary:{
                "TotVolumeMetric":TotVolumeMetric,
                "TotVolumeImperial":TotVolumeImperial,
                "TotWeightMetric":TotWeightMetric,
                "TotWeightImperial":TotWeightImperial,
                "TotPackages":TotPackages,
                "TotPrimaryUnit":TotPrimaryUnit,
            }
        });
    }
    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            if(this.state.uidSelectionString){
                let grid = $(this.state.gridDomSelector()).data("kendoGrid");
                grid.select(this.state.uidSelectionString.join(", "));
                this.setState({ uidSelectionString:'' });
            }
            this.setState({
                staticGridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isOrderGridDataLoading: false
            },this.totalOrderSummary);
        } else {
            this.setState({
                isOrderGridDataLoading: false
            });
        }
    }
    // Cancel axios call if new filter applied
    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        if(this.axiosSource){
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            isOrderGridDataLoading: false,
            overlayScreenShow: false,
            staticGridData: [],
            isAllDataLoad: false,
        });
    }

    // click on cancel button of bill of landing model
    onCloseBillOfLadingModel = () => {
        this.setState({
            isOpenBillOfLadingModel: false
        });
    }

    autoFilterWithBatch = (batchData) => {
        let orderSearchInfo = Object.assign(
            {},
            this.getSharedFindOrderFilter(),
            {
            IndexOptionsCustomerId: batchData.CustomerId,
            BatchOrderid: batchData.BatchOrderId,
            }
        );

        orderSearchInfo.IndexOptionsCustomerId = batchData.CustomerId;
        orderSearchInfo.BatchOrderid = batchData.BatchOrderId;
        // //call read for get new option
        var dropdownlist = $("#DropdownlistCustomerBatches").data("kendoDropDownList");
        if (dropdownlist) {
            this.props.onFilterDataSet(orderSearchInfo, true);
        } else {
            this.props.onFilterDataSet(orderSearchInfo, true);
        }

    }

    // ---- If customer is deactivated and still that customer is set in filter, we are removing it before grid loading ----
    getSharedFindOrderFilter = () => {
        let findOrderFilterData = Object.assign({}, this.props.sharedFindOrderFilter);
        if (findOrderFilterData.IndexOptionsCustomerId != 0 ) {
            let selectedCustomer = GlobalService.getCustomerById(findOrderFilterData.IndexOptionsCustomerId);
            if (selectedCustomer == null) {
                findOrderFilterData.IndexOptionsCustomerId = 0;
            }
        }

        return findOrderFilterData
    }

    render() {
        return (
            <div className="find-order-grid new-layout-grid-wrapper">
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                    />
                )}
                <div className="wms-search-grid-data">
                    {this.state.MenuPrintData.length > 0 && (
                        <VirtualGridComponent
                            loading={this.state.isFilterRowCountLoading || this.state.isOrderGridDataLoading}
                            virtualGridDataLimit={GlobalService.constants.virtualOrderGridDataLimit}
                            isNewColumnAdded={this.state.isNewColumnAdded}
                            gifLoaderText={this.state.gifLoaderText}
                            staticGridData={this.state.staticGridData}
                            wmsSelector={this.state.Selector + "Grid"}
                            showSortColumns={true}
                            showGridSummary={true}
                            callForGetNewData={this.callForGetNewData}
                            gridKeySelection={'OrderId'}
                            showGridResult={true}
                            totalResult={this.state.recordsCount}
                            gridName={this.state.GridName}
                            isBindFilterColumnHoverEvent={true}
                            filterText={this.state.filterText}
                            fullFilterText={this.state.fullFilterText}
                            virtual={true}
                            metaDataFieldsName={'Orders'}
                            exportExcelObj={{
                                fileName: "orderGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                                allPages: true,
                                filterable: true
                            }}
                            isLegendSet={true}
                            exportable={true}
                            // exportDisable={(this.state.totalOrders > this.state.gridExportLimit || this.state.totalInLineColumnFilters > 1) ? true : false}
                            // exportGridDisabledText={this.translate('note_Export_GridLimit').replace(new RegExp('{' + 'dynamicAmount' + '}', 'gi'), this.state.gridExportLimit)}
                            id={this.findOrderGridId}
                            defaultColumns={this.state.defaultColumns}
                            allColumns={this.state.allColumnList}
                            schema={this.state.schema}
                            onSelection={this.selectRow}
                            onDataBound={this.onGridDataBound}
                            onSort={this.onSort}
                            onColumnReorder={this.onColumnReorder}
                            onColumnResize={this.onColumnResize}
                            onClickClearSorts={this.onClickClearSorts}
                            onClickResetGrid={this.onClickResetGrid}
                            windowId={this.props.windowId}
                            clickEventOfContextMenu={this.clickEventOfContextMenu}
                            eventClickExportGrid={this.eventClickExportGrid}
                            eventClickColumns={this.eventClickColumns}
                            onGridFilterApplied={this.onGridFilterApplied}
                            metaGridConfig={this.state.findOrderGridConfig}
                            loadAllRecords={this.onClickLoadAllRecords}
                            isAllDataLoad={this.state.isAllDataLoad}
                            isFindOrderSummary={true}
                            orderSummary={this.state.orderSummary}
                            loadAllMessageFirst={this.translate('Label_Grid_Filter_Message')}
                            loadAllMessageSecond={this.translate('Label_Grid_Filter_Message_2')}
                            showLoadingError={this.state.showLoadingError}
                            menu={
                                [{
                                    "name": this.translate('Label_Manage'),
                                    "wmsSelector": this.state.Selector + "Manage",
                                    "value": [
                                        {
                                            "name": this.translate('Label_Edit_Order'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders != 1 || this.state.Selection.editOrder.disabled),
                                            "disabledText": this.state.Selection.editOrder.message,
                                            "icon": "fa-pencil",
                                            "color": "yellow",
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            "wmsSelector": this.state.Selector + "SameEditOrderProto",
                                            "onClickFunc": this.onEditOrderProto,
                                            'wmsid': 'editOrderProto'
                                        },
                                        {
                                            "name": this.translate('Label_Edit_Order_New_tab'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders != 1 || this.state.Selection.editOrder.disabled),
                                            "disabledText": this.state.Selection.editOrder.message,
                                            "icon": "fa-pencil",
                                            "color": "yellow",
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            "wmsSelector": this.state.Selector + "EditOrderProto",
                                            "onClickFunc": this.onEditOrderProtoNewTab,
                                            'wmsid': 'onEditOrderProtoNewTab'
                                        },
                                        {
                                            "name": this.translate('Label_Clone_Order'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders != 1),
                                            "disabledText": this.state.Selection.editOrder.message,
                                            "icon": "fa-pencil",
                                            "color": "yellow",
                                            'isContextMenu': true,
                                            'isButtonMenu': true,
                                            "wmsSelector": this.state.Selector + "CloneOrder",
                                            "onClickFunc": this.onCloneOrderClick,
                                            'wmsid': 'onCloneOrderClick',
                                            'isNewMenu': false
                                        },
                                        {
                                            "name": this.translate('Label_Find_Order_Summary'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders != 1),
                                            "disabledText": this.state.Selection.editOrder.message,
                                            "icon": "fa-pencil",
                                            "color": "yellow",
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.view'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.view'),
                                            "wmsSelector": this.state.Selector + "ViewSummary",
                                            "onClickFunc": this.onViewSummary,
                                            'wmsid': 'orderViewSummary'
                                        },
                                        {
                                            "name": this.translate('Label_Edit_Carrier_Routing'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.carrierAndRouting.disabled),
                                            "disabledText": this.state.Selection.carrierAndRouting.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                            "wmsSelector": this.state.Selector + "EditCarrierRoutingProto",
                                            "onClickFunc": (e) => this.setModelState('SharedIsOpenCarrierRoutingModelProto', true),
                                            'wmsid': 'carrierRoutingProto'
                                        },
                                        {
                                            "name": this.translate('Label_Mark_Orders_Complete'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.markOrdersComplete.disabled),
                                            "disabledText": this.state.Selection.markOrdersComplete.message,
                                            'isContextMenu': true,
                                            'isButtonMenu': true,
                                            "wmsSelector": this.state.Selector + "MarkOrdersComplete",
                                            "onClickFunc":  this.markOrdersComplete,
                                            'wmsid': 'markOrdersComplete'
                                        },
                                        {
                                            "name": this.translate('Label_Create_Pick_Job'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.createPickJob.disabled),
                                            "disabledText": this.state.Selection.createPickJob.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            "wmsSelector": this.state.Selector + "CreatePickJob",
                                            "onClickFunc": (e) => this.setModelState('SharedIsOpenCreatePickJobModel', true),
                                            'wmsid': 'createPickJob'
                                        },
                                        {
                                            "name": this.translate('Label_RemoveFrom_Pick_Job'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.removeFromPickJob.disabled),
                                            "disabledText": this.state.Selection.removeFromPickJob.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            "wmsSelector": this.state.Selector + "RemoveFromPickJob",
                                            "onClickFunc": this.onRemoveFromPickJob,
                                            'wmsid': 'removeFromPickJob'
                                        },
                                        {
                                            "name": this.translate('Label_Delete_Pick_Job'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.deletePickJob.disabled),
                                            "disabledText": this.state.Selection.deletePickJob.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.pickjob'),
                                            "wmsSelector": this.state.Selector + "DeletePickJob",
                                            "onClickFunc": this.onDeletePickJob,
                                            'wmsid': 'deletePickJob'
                                        },
                                        {
                                            "name": this.translate('Label_Ship_And_Close'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.shipAndClose.disabled),
                                            "disabledText": this.state.Selection.shipAndClose.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.confirm'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.confirm'),
                                            "wmsSelector": this.state.Selector + "ShipAndClose",
                                            "onClickFunc":
                                                (e) => this.setModelState('sharedIsOpenMassShipAndCloseModel', true),
                                            'wmsid': 'shipAndClose'
                                        },
                                        {
                                            "name": this.translate('Label_Canceling'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.cancel.disabled),
                                            "disabledText": this.state.Selection.cancel.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.cancel'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.cancel'),
                                            "wmsSelector": this.state.Selector + "Canceling",
                                            "onClickFunc": (e) => this.setModelState('isOpenCancelModal', true),
                                            'wmsid': 'canceling'
                                        },
                                        {
                                            "name": this.translate('Label_Reopen'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.reopen.disabled),
                                            "disabledText": this.state.Selection.reopen.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.reopen'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.reopen'),
                                            "wmsSelector": this.state.Selector + "Reopen",
                                            "onClickFunc": (e) => this.setModelState('isOpenReopenModal', true),
                                            'wmsid': 'reopen'
                                        },
                                        {
                                            "name": this.translate('Label_Send_To_TMW'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.tmw.disabled),
                                            'isContextMenu': this.state.Rights.hasOwnProperty('Integrations.TmwExportEnabled'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('Integrations.TmwExportEnabled'),
                                            "wmsSelector": this.state.Selector + "SendToTMW",
                                            "onClickFunc": this.onSendToTMW,
                                            'wmsid': 'sendToTMW'
                                        },
                                        {
                                            "name": this.translate('Label_Process_Packages'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders != 1 || this.state.Selection.processPkgs.disabled),
                                            "disabledText": this.state.Selection.processPkgs.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            "wmsSelector": this.state.Selector + "ProcessPackages",
                                            "onClickFunc": this.processPackages,
                                            'wmsid': 'processPackages'
                                        },
                                        {
                                            "name": this.translate('Label_Recalc_Packages'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.recalcPkgs.disabled),
                                            "disabledText": this.state.Selection.recalcPkgs.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            "wmsSelector": this.state.Selector + "RecalcPackages",
                                            "onClickFunc": this.recalcPackages,
                                            'wmsid': 'recalcPackages'
                                        }, {
                                            "name": this.translate('Label_Consolidate_Packages'),
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.consolidatePkgs.disabled),
                                            "disabledText": this.state.Selection.consolidatePkgs.message,
                                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.pack'),
                                            "wmsSelector": this.state.Selector + "ConsolidatePackages",
                                            "onClickFunc": this.openConsolidatePackages,
                                            'wmsid': 'consolidatePackages'
                                        }, {
                                            "name": this.state.Selection.transferOrder.label,
                                            "value": [],
                                            "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.transferOrder.disabled || this.state.facilities.length == 1),
                                            "disabledText": this.state.Selection.transferOrder.message,
                                            'isContextMenu': true,
                                            'isButtonMenu': true,
                                            "wmsSelector": this.state.Selector + "TransferOrder",
                                            "onClickFunc": this.openTransferOrderModel,
                                            'wmsid': 'transferOrder'
                                        }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true
                                },
                                {
                                    "name": this.translate('Button_Label_CreateOrders'),
                                    "value": [
                                        {
                                            "name": this.translate('Label_Create_Order'),
                                            "value": [],
                                            "disabled": false,
                                            "disabledText": this.translate('Label_Create_Order'),
                                            "icon": "fa-bandcamp",
                                            "color": "green",
                                            'isContextMenu': false,
                                            'isButtonMenu': true,
                                            'wmsid': 'copyField',
                                            "wmsSelector": "createOrderProto",
                                            "onClickFunc": this.onCreateOrderProto
                                        }, {
                                            "name": this.translate('Label_Create_Order_New_tab'),
                                            "value": [],
                                            "disabled": false,
                                            "disabledText": this.translate('Label_Create_Order_New_tab'),
                                            "icon": "fa-wrench",
                                            "color": "green",
                                            'isContextMenu': false,
                                            'isButtonMenu': true,
                                            'wmsid': 'createOrderInNewTabProto',
                                            "onClickFunc": this.onCreateOrderProtoInNewTab
                                        }
                                    ],
                                    "disabled": false,
                                    "icon": "fa-plus",
                                    "color": "green",
                                    "clickFunc": (e) => null,
                                    'isContextMenu': false,
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.edit.view'),
                                    "wmsSelector": this.state.Selector + "CreateOrders",
                                    'wmsid': 'createOrders'
                                },
                                {
                                    "name": this.translate('Label_FindOrder_Allocate'),
                                    "wmsSelector": this.state.Selector + "Allocation",
                                    "value": [{
                                        "name": this.translate('Label_Reallocate_Selected'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.reallocate.disabled),
                                        "disabledText": this.state.Selection.reallocate.message,
                                        'isContextMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "Reallocate",
                                        "onClickFunc": (e) => this.setModelState('isOpenReallocateModal', true),
                                        'wmsid': 'reallocate'
                                    },
                                    {
                                        "name": this.translate('Label_Unallocate_Selected'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.unallocate.disabled),
                                        "disabledText": this.state.Selection.unallocate.message,
                                        'isContextMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "Unallocate",
                                        "onClickFunc": (e) => this.setModelState('isOpenUnallocateModal', true),
                                        'wmsid': 'unallocate'
                                    },
                                    {
                                        "name": this.translate('Label_Split_Overallocated'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.splitOverallocated.disabled),
                                        "disabledText": this.state.Selection.splitOverallocated.message,
                                        'isContextMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "SplitOverallocated",
                                        "onClickFunc": (e) => this.setModelState('isOpenSplitOverallocateModal', true),
                                        'wmsid': 'splitOverallocated'
                                    },
                                    {
                                        "name": this.translate('Label_Short_Ship_Orders'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.shortShipOrders.disabled),
                                        "disabledText": this.state.Selection.shortShipOrders.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "SplitOvershortSlipoverAllocateallocated",
                                        "onClickFunc": (e) => this.setModelState('isOpenConfirmForShipOrders', true),
                                        'wmsid': 'shortSlipoverAllocate'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-pallet-box",
                                    "color": "green",
                                    'isContextMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                                },
                                {
                                    "name": this.translate('Label_Batching'),
                                    "wmsSelector": this.state.Selector + "Batching",
                                    "value": [{
                                        "name": this.translate('Label_Add_To_New'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.addToNewBatch.disabled),
                                        "disabledText": this.state.Selection.addToNewBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "AddToNewBatch",
                                        "onClickFunc": () => this.onOpenBatch('addToNew'),
                                        'wmsid': 'addToNew'
                                    },
                                    {
                                        "name": this.translate('Label_Add_Orders_To_Existing_Batch'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.addToExistingBatch.disabled),
                                        "disabledText": this.state.Selection.addToExistingBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "AddToExistingBatch",
                                        "onClickFunc": () => this.onOpenBatch('addToExisting'),
                                        'wmsid': 'addToExistingBatch'
                                    },
                                    {
                                        "name": this.translate('Label_Remove_From_Batch'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.Selection.removeFromBatch.disabled),
                                        "disabledText": this.state.Selection.removeFromBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "RemoveFromBatch",
                                        "onClickFunc": this.onRemoveFromBatch,
                                        'wmsid': 'removeFromBatch'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-layer-group",
                                    "color": "green",
                                    'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.batch'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.batch'),
                                },
                                {
                                    "name": this.translate('NavBarV1_Btn_Documents'),
                                    "wmsSelector": this.state.Selector + this.translate('NavBarV1_Btn_Documents'),
                                    "value": this.state.MenuPrintData,
                                    "clickFunc": (e) => null,
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-print",
                                    "color": "green",
                                    'isContextMenu': (this.state.Rights.hasOwnProperty('orders.docs') ||
                                    (
                                        this.state.Rights.hasOwnProperty('orders.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled')
                                    )),
                                    'isButtonMenu': (this.state.Rights.hasOwnProperty('orders.docs') ||
                                    (
                                        this.state.Rights.hasOwnProperty('orders.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled')
                                    ))
                                },
                                {
                                    "name": this.translate('Label_Smart_Parcel'),
                                    "wmsSelector": this.state.Selector + this.translate('Label_Smart_Parcel'),
                                    "value": this.state.MenuSmartParcelData,
                                    "clickFunc": (e) => null,
                                    // "disabled": (this.state.SelectedOrders < 1),
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-box-open",
                                    "color": "green",
                                    'isContextMenu': false,
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.smartparcel') &&
                                    this.state.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') &&
                                    this.state.Rights.hasOwnProperty('UxPrototypingDirectivesShowClassicSP')
                                },
                                {
                                    "name": "Copy",
                                    "value": [{
                                        "name": this.translate('Label_Copy_Field'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Field'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyField',
                                        "wmsSelector": "copyToClicpboard",
                                    }, {
                                        "name": this.translate('Label_Copy_Row'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Row'),
                                        "icon": "fa-wrench",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyRow'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false
                                },
                                {
                                    "name": this.translate('Label_Import'),
                                    "value": [],
                                    "disabled": false,
                                    "icon": "fa-download",
                                    "color": "green",
                                    "clickFunc": this.onImportOrder,
                                    'isContextMenu': false,
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.import.manage'),
                                    "wmsSelector": this.state.Selector + "Import",
                                    'wmsid': 'import'
                                },
                                {
                                    "name": '',
                                    "wmsSelector": this.props.selector + 'RefreshGrid',
                                    "value": [],
                                    "clickFunc": this.refreshOrderGrid,
                                    "disabled": false,
                                    "icon": "fa-sync-alt",
                                    "color": "blue",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'parentClassName':'findOrferGridRefreshBtn'
                                }]
                            }
                        />
                    )}
                </div>
                {this.state.isOpenColumnModel && (
                <AddEditColumnModel
                    id={"addEditColumnFindOrder"}
                    height={700}
                    windowId={this.props.windowId}
                    onCloseColumnModel={this.onCloseColumnModel}
                    isOpenColumnModel={this.state.isOpenColumnModel}
                    allSortColumnList={this.state.allSortColumnList}
                    gridId={this.findOrderGridId}
                    updateGridData={this.callForGetNewData}
                    saveChangesInMetaData={this.saveChangesInMetaData}
                    isShawRowNumberColumnInList={true}
                />
                )}

                {this.state.SharedIsOpenCarrierRoutingModelProto && (
                    <CarrierRoutingModelProto
                        SharedIsOpenCarrierRoutingModelProto={this.state.SharedIsOpenCarrierRoutingModelProto}
                        windowId={this.props.windowId}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        customerId={(this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.CustomerId : 0}
                        facilityId={this.state.SameFacilityId}
                        onEditCarrierRoutingClose={(e) => this.setModelState('SharedIsOpenCarrierRoutingModelProto', false)}
                    />
                )}

                {this.state.SharedIsOpenCreatePickJobModel &&(
                <CreatePickJobModel
                    sharedIsOpenCreatePickJobModel={this.state.SharedIsOpenCreatePickJobModel}
                    windowId={this.props.windowId}
                    sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    onCreatePickJobClose={(e) => this.setModelState('SharedIsOpenCreatePickJobModel', false)}
                    onResetGridSelection={this.resetGridSelectionAndButton}
                />
                )}
                {this.state.sharedIsOpenMassShipAndCloseModel && (

                <MassShipAndCloseModel
                    sharedIsOpenMassShipAndCloseModel={this.state.sharedIsOpenMassShipAndCloseModel}
                    sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                    windowId={this.props.windowId}
                    sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    onCloseShipAndClose={(e) => this.setModelState('sharedIsOpenMassShipAndCloseModel', false)}
                    onResetGridSelection={this.resetGridSelectionAndButton}
                />
                )}
                {this.state.sharedIsOpenBatchingModel && (
                    <BatchingModel
                        sharedIsOpenBatchingModel={this.state.sharedIsOpenBatchingModel}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        sharedBatchProcess={this.state.sharedBatchProcess}
                        onCloseBatchingModel={this.closeBatchingModel}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        windowId={this.props.windowId}
                        autoFilterWithBatch={this.autoFilterWithBatch}
                    />
                )}
                {this.state.isOpenShippingLabelModel && (
                    <ShippingLabel
                        sharedIsOpenShippingLabelModel={this.state.isOpenShippingLabelModel}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        onCloseShippingLabelModel={this.onCloseShippingLabelModel}
                        sharedShippingLabelOption={this.state.sharedShippingLabelOption}
                        windowId={this.props.windowId}
                    />
                )}

                {this.state.isOpenBillOfLadingModel && (
                    <BillOfLadingModel
                        windowId={this.props.windowId}
                        isOpenLadingModel={this.state.isOpenBillOfLadingModel}
                        onCloseFunc={this.onCloseBillOfLadingModel}
                        orderId={this.state.SharedSelectedOrderIds[0]}
                        customerId={(this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.CustomerId : 0}
                    />
                )}
                {/* WMS-15986 - Please do not unload this modal as its using callback function for reallocateOrders
                And it might be used in that way for other async methods also */}
                <ConfirmationModal
                    isOpenReopenModal={this.state.isOpenReopenModal}
                    onCloseReopenModal={(e) => this.setModelState('isOpenReopenModal', false)}
                    isOpenUnallocateModal={this.state.isOpenUnallocateModal}
                    onCloseUnallocateModal={(e) => this.setModelState('isOpenUnallocateModal', false)}
                    isOpenReallocateModal={this.state.isOpenReallocateModal}
                    onCloseReallocateModal={this.onCloseReallocateModal}
                    closeReallocateConfirmationModal={this.closeReallocateConfirmationModal}
                    isOpenSplitOverallocateModal={this.state.isOpenSplitOverallocateModal}
                    onCloseSplitOverallocateModal={(e) => this.setModelState('isOpenSplitOverallocateModal', false)}
                    onResetGridSelection={this.resetGridSelectionAndButton}
                    SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    SameFacilityId={this.state.SameFacilityId}
                    isOpenBatchConfirmModal={this.state.isOpenBatchConfirmModal}
                    onCloseBatchModal={(e) => this.setModelState('isOpenBatchConfirmModal', false)}
                    onContinueBatchModal={this.continueBatchModal}
                    isOpenRemoveBatchConfirmModal={this.state.isOpenRemoveBatchConfirmModal}
                    onCloseRemoveBatchModal={(e) => this.setModelState('isOpenRemoveBatchConfirmModal', false)}
                    onContinueRemoveBatchModal={this.continueRemoveBatchModal}
                    isOpenConfirmForShipOrders={this.state.isOpenConfirmForShipOrders}
                    onCloseShipOrders={(e) => this.setModelState('isOpenConfirmForShipOrders', false)}
                />
                {this.state.isOpenPickTicketModel && (
                <PickTicketModel
                    id={this.state.ticketModelConfig.pickTicketId}
                    title={this.state.ticketModelConfig.title}
                    width={600}
                    height={this.state.ticketModelConfig.height}
                    sharedIsOpenPickTicketModel={this.state.isOpenPickTicketModel}
                    customerId={(this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.CustomerId : 0}
                    sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    batchOrderId={this.state.ticketModelConfig.batchOrderId}
                    flow={this.state.ticketModelConfig.flow}
                    onClose={this.closePickTicketModel}
                    windowId={this.props.windowId}
                    fullyAllocated={this.state.FullyAllocated}
                    sharedSelectedCustomerIds={this.state.sharedSelectedCustomerIds}
                />
                )}
                {this.state.isOpenEditBolTemplateUI && (
                    <EditBolOptionModel
                        sharedTemplateOption = {this.state.bolTemplateOptions}
                        onOpenEditBolClassicPage={this.openEditBolClassicPage}
                        onCloseEditBolOptionsModel={this.onCloseEditBolOptionsModel}/>
                )}
                {this.state.isOpenAttachDoc &&
                <AttachDocuments
                    isOpenAttachDoc={this.state.isOpenAttachDoc}
                    sharedAttachObj={this.state.sharedAttachObj}
                    windowId={this.props.windowId}
                    onClose={(e) => this.setModelState('isOpenAttachDoc', false)}
                />
                }
                {this.state.isOpenPackingSlipsModel && (
                <PackingSlipsModel
                    sharedIsOpenPackingSlipsModel={this.state.isOpenPackingSlipsModel}
                    windowId={this.props.windowId}
                    sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                    onClose={(e) => this.setModelState('isOpenPackingSlipsModel', false)}
                />
                )}
                {this.state.isOpenSendAsnModel && (
                <SendAsnModel
                    sharedIsOpenSendAsnModel={this.state.isOpenSendAsnModel}
                    windowId={this.props.windowId}
                    sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                    sharedScacCodeAvailable={this.state.scacCodeAvailable}
                    onResetGridSelection={this.resetGridSelectionAndButton}
                    onClose={(e) => this.setModelState('isOpenSendAsnModel', false)}
                />
                )}
                {this.state.sharedIsOpenTransferModel &&(
                    <TransferOrderModel
                        sharedIsOpenTransferModel={this.state.sharedIsOpenTransferModel}
                        onCloseTransferOrderModel={this.closeTransferOrderModel}
                        transferFacilityName={this.state.transferFacilityName}
                        transferFacilityOptions={this.state.transferFacilityOptions}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    />
                )}
                {this.state.sharedIsOpenReprintEndOfDayReport &&(
                    <ReprintEODReport
                        sharedIsOpenReprintEndOfDayReport={this.state.sharedIsOpenReprintEndOfDayReport}
                        onCloseReprintEndOfDayReport={this.onCloseReprintEndOfDayReport}
                        windowId={this.props.windowId}
                    />
                )}
                {this.state.isOpenAllocationMessageModel && (
                    <WmsModel
                        id={'allocationResultMessage'}
                        title={this.translate('Label_Allocation_Results')}
                        width={600}
                        height={175}
                        class='allocationResultMessage'
                        onCloseFunc={this.exitRallocatonResultModel}
                        customClass='wms-model-new-ui-wrapper'
                        isOpen={this.state.isOpenAllocationMessageModel}>
                        <div className='model-content-wrapper'>
                            {this.state.allocationResultMessage}
                            {this.state.faultResultsMessage && (
                                <div>
                                    <hr/>
                                    <label className="fault-results-msg" dangerouslySetInnerHTML={{ __html: this.state.faultResultsMessage }} ></label>
                                </div>
                            )}
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate('Label_Close')}
                                wmsSelector={this.state.selector + 'Cancel'}
                                onClickFunc={this.exitRallocatonResultModel}
                                disabled={false}
                                loading={false}
                                buttonType='orange'
                                icon={'fa-times-circle'}
                            />
                        </div>

                    </WmsModel>
                )}
                {this.state.isOpenCustomerNotFoundModel && (
                    <WmsModel
                        id={'customerNotFound'}
                        title={this.translate('Label_Find_Order_Customer_Not_Found')}
                        width={600}
                        height={175}
                        class='customerNotFound'
                        onCloseFunc={this.closeCustomerNotFoundModel}
                        customClass='wms-model-new-ui-wrapper'
                        isOpen={this.state.isOpenCustomerNotFoundModel}>
                        <div className='model-content-wrapper'>
                            {this.translate('Label_Find_Order_Customer_Deactive')}
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate('Label_Close')}
                                wmsSelector={this.state.selector + 'Cancel'}
                                onClickFunc={this.closeCustomerNotFoundModel}
                                disabled={false}
                                loading={false}
                                buttonType='orange'
                                icon={'fa-times-circle'}
                            />
                            <WmsButton
                                label={this.translate('Label_Refresh_Button')}
                                wmsSelector={this.state.selector + 'Cancel'}
                                onClickFunc={this.resetGridSelectionAfterGridRefresh}
                                disabled={false}
                                loading={false}
                                buttonType='blue'
                                icon={'fa-sync-alt'}
                            />
                        </div>

                    </WmsModel>
                )}
                {this.state.sharedIsOpenConsolidatePackages && (
                    <ConsolidatePackages
                        sharedIsOpenConsolidatePackages={this.state.sharedIsOpenConsolidatePackages}
                        onCloseConsolidatePackages={this.onCloseConsolidatePackages}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        facilityId={this.state.SameFacilityId}
                    />
                )}
                {this.state.sharedIsReprintShippingLabelOpen && (
                    <ReprintShippingLabel
                        sharedIsReprintShippingLabelOpen={this.state.sharedIsReprintShippingLabelOpen}
                        onCloseReprintShippingLabel={this.onCloseReprintShippingLabel}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        SharedReprintLabelsReport={this.state.InitialPrintMenuResponse}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        sharedIsSameCustomer={this.state.SameCustomer}
                    />
                )}
                {this.state.isOpenPrintBatchedShippingModel && (
                    <ProcessedBatchedShippingLabels
                        closePrintBatchedShippingLabel={this.closePrintBatchedShippingLabel}
                        isOpenPrintBatchedShippingModel={this.state.isOpenPrintBatchedShippingModel} />
                )}
                {this.state.isOpenCancelModal && (
                    <CancelModel
                        modalType={'Order'}
                        sharedIsOpenCancelModel={this.state.isOpenCancelModal}
                        onCloseCancelModel={(e) => this.setModelState('isOpenCancelModal', false)}
                        sharedSelectedIds={this.state.SharedSelectedOrderIds}
                        resetGridSelection={this.resetGridSelectionAndButton}
                    />
                )}
            </div>
        );
    }
}