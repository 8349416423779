import React, { useRef, useState } from "react";
import WmsModel from "../../components/WmsModel/WmsModel";
import WmsButton from "../../components/WmsButton/WmsButton";
import AuditInventoryReviewAuditJobGrid from "./audit-inventory-review-audit-job-grid.component";
import "./audit-inventory-review-audit-job-modal.scss";
import PropTypes from "prop-types";
import WmsConfirmModel from "../../components/WmsConfirmModel/WmsConfirmModel";
import { InventoryAuditReconcile } from "./review-audit-job.service";
import * as GlobalService from "../../global_services";

interface Props {
    selector: string;
    auditCountsUris: Array<string>;
    selectedRowData: any;
    isOpen: boolean;
    onReviewAuditJobModalClose: Function;
    facilityUri: string;
}

declare var window: any;
const AuditInventoryReviewAuditJobModal: React.FC<Props> = (props: Props): any => {
    const [isRconcileButtonDisabled, setIsReconcileButtonDisabled] = useState(true);
    const subGridSelectedData = useRef([]);
    const [isConfirmationModalShow, setIsConfirmationModalShow] = useState(false);

    const sendSubGridSelectedDataToModalComponent = (data: any) => {
        if (data !== null && data.length !== 0) {
            let unmodifiedJobs = subGridSelectedData.current.filter(
                (id) => !data.some((row) => row.id.toString() === id.toString())
            );
            const newJobs = data.filter((row) => row.checked).map((row) => row.id);
            subGridSelectedData.current = [...unmodifiedJobs, ...newJobs];
        }
        const tempData = subGridSelectedData.current ? subGridSelectedData.current.length : 0;
        setIsReconcileButtonDisabled(tempData === 0);
    };

    const onReconcileJobs = () => {
        setIsConfirmationModalShow(true);
    };

    const onCloseConfirmModal = () => {
        setIsConfirmationModalShow(false);
    };
    const onReconcileConfirm = () => {
        const param = {
            "auditCountsUris": props.auditCountsUris,
            "detailIds": subGridSelectedData.current,
        };
        InventoryAuditReconcile(param).then((response) => {
            GlobalService.notification("success", window.translate("Label_ConfirmReconciliation_Success"));
            onCloseConfirmModal();
            props.onReviewAuditJobModalClose();
        });
    };
    return (
        <div className={"audit-inventory-review-audit-job-modal"}>
            <WmsModel
                isOpen={props.isOpen}
                id={props.selector + "ReviewAuditJobModel"}
                title={window.translate("Review_Audit_Job_Model_title_Prefix") + " " + props.selectedRowData.Name}
                width={800}
                height={706}
                onCloseFunc={props.onReviewAuditJobModalClose}
                customClass={"wms-model-new-ui-wrapper"}
            >
                <div className={"model-content-wrapper"}>
                    <AuditInventoryReviewAuditJobGrid
                        selector={props.selector}
                        auditCountsUris={props.auditCountsUris}
                        reviewAuditJobTitle={props.selectedRowData.Name}
                        facilityUri={props.facilityUri}
                        mainGridSelectedRowData={props.selectedRowData}
                        sendSubGridSelectedDataToModalComponent={sendSubGridSelectedDataToModalComponent}
                    />
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Reconcile_Jobs")}
                        wmsSelector={"reviewAuditReconcileJobsButton"}
                        onClickFunc={onReconcileJobs}
                        disabled={isRconcileButtonDisabled}
                        loading={false}
                        buttonType="black"
                    />
                    <WmsButton
                        label={window.translate("Label_Close")}
                        wmsSelector={"reviewAuditCloseButton"}
                        onClickFunc={props.onReviewAuditJobModalClose}
                        disabled={false}
                        loading={false}
                        buttonType="orange"
                        icon={"fa-times-circle-o"}
                    />
                    <WmsConfirmModel
                        id={"AuditReviewReconciliation"}
                        isOpen={isConfirmationModalShow}
                        type={window.translate("Label_ConfirmReconciliation_Audit_Review_Job")}
                        width={500}
                        height={175}
                        firstBtnLabel={window.translate("Label_Select_Confirm")}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={onReconcileConfirm}
                        onCloseFunc={onCloseConfirmModal}
                        sawCheckBox={false}
                        conFormModelText={window.translate(
                            "Label_ConfirmReconciliation_Audit_Review_Job_Modal_Content"
                        )}
                        thirdBtnLabel={window.translate("Label_Cancel")}
                        thirdBtnIcon={"fa-times-circle"}
                        closeModel={onCloseConfirmModal}
                    >
                        <div className="note">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: window.translate(
                                        "Label_ConfirmReconciliation_Audit_Review_Job_Modal_Content"
                                    ),
                                }}
                            ></div>
                            <div className="warning-text-wrapper">
                                <span className="warning-icon-text">
                                    <i className="fa fa-exclamation-triangle"></i> &nbsp;Warning!
                                </span>
                                &nbsp; The reconciliation process ignores inventory not assigned to a location. Before
                                reconciling
                                <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inventory, make sure that all stock for the
                                item being reconciled has been assigned a valid location.
                            </div>
                        </div>
                    </WmsConfirmModel>
                </div>
            </WmsModel>
        </div>
    );
};

AuditInventoryReviewAuditJobModal.propTypes = {
    selector: PropTypes.string.isRequired,
    auditCountsUris: PropTypes.arrayOf(PropTypes.string),
    selectedRowData: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onReviewAuditJobModalClose: PropTypes.func,
    facilityUri: PropTypes.string.isRequired,
};

AuditInventoryReviewAuditJobModal.defaultProps = {
    auditCountsUris: [],
    selectedRowData: {},
    isOpen: false,
    onReviewAuditJobModalClose: (): void => {},
};
export default React.memo(AuditInventoryReviewAuditJobModal);
