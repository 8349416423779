
import React from 'react';
import Navbar from '../navbar/navbar.component.jsx';
import SuperNavComponent from '../supernav/supernav.component.jsx';
import style from './body.scss';
import * as GlobalService from "./../global_services";
import WmsNotification from '../components/WmsNotification/WmsNotification.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { ProgressHolder } from '../progress/progress-holder.component.jsx';
import { manageOrdersHolderVirtual } from '../orders/manage-orders-holder.virtual.component.jsx';
import { ApiSimulator } from '../api-simulator/api-simulator.component.jsx';
import ReactDOM from 'react-dom';
import { reportIframe } from '../reportIframe/report-iframe.component.jsx';
import ErrorBoundary from '../ErrorBoundary';
var axios = require('axios');

var WmsWindow;
export default class BodyHolder extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        window.ChildBulletin = {};
        window.dirtyWindow = [];


        window.ChildBulletin.Data = {};
        this.state = {
            hideHigherElements: true,
            loadLoginIframe: true,
            taskStart: false,
            LoggedInUserName: "",
            progressColor: "#fff",
            ActiveMenuType: "showIconWithTextNav",
            isClientLogCalled: false,
            navThumbnailData: [],
            showGreenIndicator: false,
            isOpenLoginChangeConfirmModel: false,
            isPreloadComplete:false,
            isCustomerUser : true,
            currentDisableArrow:'left',
            isShowArrow:false
        }
    }
    componentDidMount = () => {
        window.setLoaderData = {};
        // Intialize all window before use
        var that = this;
        WmsWindow = {
            WindowData: [], // this will store the current state of windows
            WindowAmounts: 8, // How many window templates we will allow
            ReusableSpots: [1, 2, 3, 4, 5, 6, 7, 8],
            Padding: 0,
            OtherUiHeight: 60,
            OtherUiWidth: 160,
            NextAvailableWindow: function () { // lets see if there is available window room so we can open up a new window if there is space
                if (WmsWindow.WindowData.length >= WmsWindow.WindowAmounts) {
                    return -1;
                } else {
                    var GetUsedWindows = []; // storage variable to compare with our array to help find the lowest window used, we will always try to use the lowest available window
                    for (var x = 0; x < WmsWindow.WindowData.length; x++) {
                        GetUsedWindows.push(WmsWindow.WindowData[x].WindowNumber);
                    }
                    var difference = WmsWindow.ReusableSpots.filter(function (i) {
                        return GetUsedWindows.indexOf(i) < 0;
                    });
                    var lowest = Math.min.apply(Math, difference);
                    return ({
                        "StringName": "Window" + (lowest),
                        "NumberName": (lowest)
                    });
                }
            },
            InsertWindow: function (obj) {
                // call function for set start time of when window open
                // for find order condition

                /* Order sub nav bar */
                if (obj.url == "<react-manage-orders-virtual-holder></react-manage-orders-virtual-holder>") {
                    that.setLoaderTimer("Find Orders Prototype");
                    that.setClickedTabDate("Find Orders Prototype");
                }
                if (obj.url == "<manage-orders-form-virtual></manage-orders-form-virtual>") {
                    if (obj.id) {
                        that.setLoaderTimer('Edit Order');
                        that.setClickedTabDate("Edit Order");
                    } else {
                        that.setClickedTabDate("Create Order");
                    }
                }
                if (obj.url == "<react-find-orders-holder></react-find-orders-holder>") {
                    that.setLoaderTimer("Find Orders");
                    that.setClickedTabDate("Find Orders");
                }
                if (obj.url == "<react-manage-orders-holder></react-manage-orders-holder>") {
                    if (typeof obj.id != "undefined") {
                        that.setLoaderTimer('Edit Order');
                        that.setClickedTabDate("Edit Order");
                    } else {
                        that.setClickedTabDate("Create Order");
                    }
                }
                if (obj.url == "<react-import-order-holder></react-import-order-holder>") {
                    that.setClickedTabDate("Import Orders");
                }
                if (obj.url == "<order-import-preference-component></order-import-preference-component>") {
                    that.setClickedTabDate("Import Order Preferences");
                }

                /* Receipt sub nav bar */

                if (obj.url == "<react-find-receipt-holder></react-find-receipt-holder>") {
                    that.setLoaderTimer('Find Receipts');
                    that.setClickedTabDate('Find Receipts');
                }
                if (obj.url == "<react-find-receipt-holder-proto></react-find-receipt-holder-proto>") {
                    that.setLoaderTimer('Find Receipts proto');
                    that.setClickedTabDate('Find Receipts proto');
                }
                if (obj.url == "<react-manage-receipt-holder></react-manage-receipt-holder>") {
                    if (typeof obj.id != "undefined") {
                        that.setLoaderTimer('Edit Receipts');
                        that.setClickedTabDate("Edit Receipts");
                    } else {
                        that.setClickedTabDate("Create Receipts");
                    }
                }
                if (obj.url == "<import-Asn-Component></import-Asn-Component>") {
                    that.setClickedTabDate("Import Asn");
                }
                if (obj.url == "<asn-Import-Preference-Component></asn-Import-Preference-Component>") {
                    that.setClickedTabDate("Import Asn Preferences");
                }

                /* Inventory sub nav bar */
                if (obj.url == "<react-inventory-holder></react-inventory-holder>") {
                    that.setClickedTabDate('Manage Inventory');
                }
                if (obj.url == "<cyclecount-holder></cyclecount-holder>") {
                    that.setClickedTabDate('Audit Inventory');
                }

                /* Users sub nav bar */
                if (obj.url == "<react-user-holder></react-user-holder>") {
                    that.setLoaderTimer('Manage Users');
                    that.setClickedTabDate('Manage Users');
                }
                if (obj.url == "<react-role-holder></react-role-holder>") {
                    that.setClickedTabDate('Manage Roles');
                }

                /* Customers sub nav bar */
                if (obj.url == "<react-customers-holder></react-customers-holder>") {
                    that.setLoaderTimer('Manage Customers');
                    that.setClickedTabDate('Manage Customers');
                }
                if (obj.url == "<manage-barcode-component></manage-barcode-component>") {
                    that.setClickedTabDate('Manage Barcodes');
                }

                /* items sub nav bar */
                if (obj.url == "<react-items-holder></react-items-holder>") {
                    that.setClickedTabDate('Manage Items');
                }

                /* Warehouse sub nav bar */
                if (obj.url == "<react-warehouse-holder></react-warehouse-holder>") {
                    that.setClickedTabDate('Manage Warehouses');
                }
                if (obj.url == "<react-locations-holder></react-locations-holder>") {
                    that.setClickedTabDate('Manage Locations');
                }


                var nextWindow = WmsWindow.NextAvailableWindow();
                if (nextWindow != -1) { // logic for if there is room for a new window
                    var urlParam = ''
                    if (obj.urlParam) {
                        urlParam += obj.urlParam;
                        window.history.replaceState("object or string", "Title", `?page=${urlParam}`);
                    }
                    that.setState({
                        loadLoginIframe: false
                    })
                    WmsWindow.ResetActiveStatus();
                    var GetWindow = $("#" + nextWindow.StringName);
                    //. var WindowWidth = window.innerWidth - WmsWindow.OtherUiWidth - WmsWindow.Padding; // - WmsWindow.Padding; // REFACTOR to dynamic width 225 for UI element 30 for padding
                    // var WindowHeight = window.innerHeight - WmsWindow.OtherUiHeight - WmsWindow.Padding; // - WmsWindow.Padding - 65; // REFACTOR to dynamic height 90 for UI height 30 for padding
                    // var sideNav = $("#wms_side_nav");
                    // if (sideNav.hasClass('expand')) {
                    //     WindowWidth = WindowWidth + 160;
                    // }
                    var WindowTop = WmsWindow.OtherUiHeight + WmsWindow.Padding;
                    var WindowLeft = WmsWindow.OtherUiWidth + WmsWindow.Padding;
                    var WindowWidth = 0;
                    var WindowHeight = 0;
                    if (that.state.ActiveMenuType == 'showIconNav') {
                        WindowWidth = window.innerWidth - WmsWindow.OtherUiWidth - WmsWindow.Padding - WmsWindow.Padding + 100;
                        WindowHeight = window.innerHeight - WmsWindow.OtherUiHeight - WmsWindow.Padding
                        WindowLeft = WmsWindow.OtherUiWidth + WmsWindow.Padding - 100;
                    } else if (that.state.ActiveMenuType == 'showIconWithTextNav') {
                        WindowWidth = window.innerWidth - WmsWindow.OtherUiWidth - WmsWindow.Padding;
                        WindowHeight = window.innerHeight - WmsWindow.OtherUiHeight;
                        if (window.innerWidth <= 1199) {
                            WindowWidth = WindowWidth + 20;
                            WindowLeft = WindowLeft - 20;
                        }
                    }
                    // WindowHeight = WindowHeight - 41; // remove height of custom title bar
                    $("#" + nextWindow.StringName).show();
                    that.resizeAllGrid(200);
                    // alert('$("#" + nextWindow.StringName)'+nextWindow.StringName);

                    obj.Window = nextWindow.StringName; // we attach the window location that this section will occupy to object
                    obj.WindowNumber = nextWindow.NumberName;
                    obj.active = true;
                    WmsWindow.WindowData.push(_.cloneDeep(obj)); // push object into our array structure so nav controller can control windows
                    that.setState({
                        navThumbnailData: WmsWindow.WindowData
                    });
                    window.ChildBulletin.Data = obj;
                    // GetWindow.refresh({
                    //     template:'<div id="Test"></div>'
                    // });
                    // Load dynamic react component
                    ReactDOM.render(<ErrorBoundary><obj.reactComponent /></ErrorBoundary>, $("#" + nextWindow.StringName + ' .dynamic-react-component')[0]);

                    // scroll right when tab added in end

                    setTimeout(() => {
                        that.tabScrollLeft(false)();
                    }, 100);

                    // Set title in title bar

                    var sideNav = $("#navbar-component");
                    if (sideNav.hasClass('expand')) {
                        that.toggleWindowLeftCss("showIconNav");
                    }
                } else { // logic for if there is not room for a new window
                    // $rootScope.Nav.Notifications("error", {
                    //     title: that.translate(obj.title),
                    //     message: that.translate('Notification_Label_Window_Limit_Reached'),
                    //     detail: " "
                    // });
                    GlobalService.notification("errorAutoClose", that.translate('Notification_Label_Window_Limit_Reached'));
                }
            },
            FindObjectByProperty: function (property, value) { // find the object by one of its properties
                var result = WmsWindow.WindowData.filter(function (obj) {
                    return obj[property] == value;
                });
                if (result == "") {
                    result = false;
                }
                if (result) {
                    return result;
                } else {
                    return false;
                }
            },
            FindIndexByProperty: function (property, value) { // find the index of array of objects by one of objects properties
                var result = WmsWindow.WindowData.map(function (obj) {
                    return obj[property];
                }).indexOf(value);
                if (result) {
                    return result;
                } else if (result == 0) { // haha so the 0 index is 0 however that will not pass if(result) because that reads if(true) which 0 is false
                    return 0;
                } else {
                    return false;
                }
            },
            ResetActiveStatus: function () { // when something loses focus or active we need to change or data to update the UI
                for (var x = 0; x < WmsWindow.WindowData.length; x++) {
                    WmsWindow.WindowData[x].active = false;
                }
                $('.custom-tab-content').hide();
            },
            BringWindowToFront: function (win) {
                var FoundIndex = WmsWindow.FindIndexByProperty("Window", win);
                var FoundObject = WmsWindow.FindObjectByProperty("Window", win); // get the object
                WmsWindow.ResetActiveStatus();
                var GetWindow = $("#" + FoundObject[0].Window);
                GetWindow.show();
                that.resizeAllGrid(200);
                var navThumbnailData = that.state.navThumbnailData;
                WmsWindow.WindowData[FoundIndex].active = true; // The window was brought to the front so the thumbnails need to display the active class for the correct thumbnail
                navThumbnailData[FoundIndex].active = true; // right now we have 2 parallel data structures so it is redundant but just doing it for now incase we need something outside of the scope or angular
                that.setState({
                    navThumbnailData: navThumbnailData
                })

                var urlParam = ''
                if (FoundObject[0].urlParam) {
                    urlParam += FoundObject[0].urlParam;
                    window.history.replaceState("object or string", "Title", `?page=${urlParam}`);
                }

            },
            CloseWindow: function (obj) {
                that.windowClose(obj);
            },
            OnCloseFocusNextWindow: function (index) {
                if (WmsWindow.WindowData.length == 0) {
                    window.history.replaceState("object or string", "Title", `?page=home`);
                    return; // all windows are closed so no need to highlight
                } else if (typeof WmsWindow.WindowData[index] !== 'undefined') { // if something exists at this position it would be because we deleted the current index and something floated down to this position so we would use this index to display
                    WmsWindow.BringWindowToFront(WmsWindow.WindowData[index].Window);
                } else if (typeof WmsWindow.WindowData[index] === 'undefined' && typeof WmsWindow.WindowData[index - 1] !== 'undefined') { // if we cant go right then lets go left
                    WmsWindow.BringWindowToFront(WmsWindow.WindowData[index - 1].Window);
                }
            },
            ExistsNonSingletonDuplicateEdit: function () { }
        };

        //disable window close on escape key
        /* istanbul ignore next */
        kendo.ui.Window.fn._keydown = function (originalFn) {
            var KEY_ESC = 27;
            return function (e) {
                if (e.which !== KEY_ESC) {
                    originalFn.call(this, e);
                }
            };
        }(kendo.ui.Window.fn._keydown);
        setInterval(() => {
            this.checkCurrentUserLoginStatus();
        }, 300000);
        // check login every 10 minutes
        setInterval(() => {
            this.checkLoginStatus();
        }, 60 * 10 * 1000);
        $(window).on('focus', function () {
            that.checkCurrentUserLoginStatus();
        });
        $(window).on('resize', function () {
            that.resizeAllGrid(200);
        });
        // set home on first time load
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const tplguid = params.get('tplguid');
        if (!tplguid) {
            window.history.replaceState("object or string", "Title", `?page=home`);
        }
        document.addEventListener("EventCheckLoginUser", function (e) {
            that.checkCurrentUserLoginStatus();
        });
        document.addEventListener("CloseAllCurrentTab", function (e) {
            that.closeAllTabAndClearTab();
        });
        this.GetUserName();
        this.changeTheme('default')('');
        document.addEventListener("EventNewReceiptData", function (e) {
            var eventItem = new CustomEvent("EventReceiptData", {
                detail: {
                    "action": "update"
                }
            });
            document.dispatchEvent(eventItem);
            if (e.detail.hasOwnProperty("thumbnail")) {
                that.EditThumbnail(e.detail);
            }
        });
        document.addEventListener("EventNewOrderData", function (e) {
            if (e.detail != null) {
                if (e.detail.action == 'new') {
                    var eventItem = new CustomEvent("EventOrderChanged", {
                        detail: {
                            "action": "new",
                            'source': e.detail.source
                        }
                    });
                    document.dispatchEvent(eventItem);
                } else {
                    var eventItem = new CustomEvent("EventOrderChanged", {
                        detail: {
                            "action": "update",
                            'source': e.detail.source
                        }
                    });
                    document.dispatchEvent(eventItem);
                }
                if (e.detail.hasOwnProperty("thumbnail")) {
                    that.EditThumbnail(e.detail);
                } // if we get an thumbnail update on this event we will probably need to update thumbnail
            }
        });
        document.addEventListener("EventOpenFindOrders", function (event) {
            that.OpenWindow({
                url: '<react-find-orders-holder></react-find-orders-holder>',
                reactComponent: manageOrdersHolderVirtual,
                singleton: true,
                title: 'Window_Title_Label_FindOrders',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_FindOrders'
                }],
                icon: 'submenu-icon fa fa-search'
            })

            var eventItem = new CustomEvent("EventOrderChanged", { detail: { "action": "update" } });
            document.dispatchEvent(eventItem);

        });
        document.addEventListener("EventOpenApiSimulator", function (event) {
            console.log("EventOpenApiSimulator >> ", event);
            that.OpenWindow({
                url: '<style-guide-component></style-guide-component>',
                reactComponent: ApiSimulator,
                singleton: true,
                title: 'Window_Title_Label_Apisimulator',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Apisimulator'
                }],
                icon: 'submenu-icon fa fa-info'
            });
        });

        document.addEventListener("EventGenerateJavascriptError", function (event) {
            var abc = abc.abc;
        });

        //disable window close on escape key
        /* istanbul ignore next */
        kendo.ui.Window.fn._keydown = function (originalFn) {
            var KEY_ESC = 27;
            return function (e) {
                if (e.which !== KEY_ESC) {
                    originalFn.call(this, e);
                }
            };
        }(kendo.ui.Window.fn._keydown);

    }

    closeAllTabAndClearTab = () => {
        this.setState({
            navThumbnailData:[]
        });
        WmsWindow.WindowData = [];
        // clear dynamic-react-component html and hide all window
        $('.custom-tab-content').hide();
        $( ".dynamic-react-component" ).each( function( index, element ){
            if(!$( this ).is(':empty')){
                ReactDOM.unmountComponentAtNode($( this )[0]);
                $( this ).html("");
            }
        });
    }
    static getDerivedStateFromProps (nextProps, prevState) {
        if(nextProps.isPreloadComplete && typeof $("#dropdown").data('kendoMenu') == 'undefined'){
            setTimeout(() => {
                $("#dropdown").kendoMenu();
            }, 100);
            return { isPreloadComplete : nextProps.isPreloadComplete }
        }
        return null;
    }
    windowClose = (obj) => {
        if (!obj) {
            return;
        }
        var that = this;
        var id = obj.Window;
        if (WmsWindow.WindowData.length == 1) {
            that.setState({
                loadLoginIframe: false
            })
        }
        var index = WmsWindow.FindIndexByProperty("Window", id); // get the index of the window we just closed
        var currentWindowData = WmsWindow.WindowData[index];
        // if the window is not dirty then we of course just need to close it
        if (obj.url == "<react-manage-receipt-holder></react-manage-receipt-holder>") {
            // Remove customer event to prevent multiple call
            // $scope.$broadcast("destroyListenMethod" + id);
        }
        if (obj.url == "<manage-orders-form-virtual></manage-orders-form-virtual>" && window.dirtyWindow[id] && currentWindowData.id) {
            // Remove customer event to prevent multiple call
            var eventName = "onDirtyWindowCloseOrder" + id;

            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
            return;
        } else {
            var eventName = "removeDirtyWindowCloseOrder" + id;
            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);

        }
        if (obj.url == "<manage-orders-form-beta-virtual></manage-orders-form-beta-virtual>" && window.dirtyWindow[id] && currentWindowData.id) {
            // Remove customer event to prevent multiple call
            var eventName = "onDirtyWindowCloseOrderBeta" + id;

            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
            return;
        } else {
            var eventName = "removeDirtyWindowCloseOrderBeta" + id;
            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
        }
        if (obj.url == "<manage-orders-form-proto></manage-orders-form-proto>" && window.dirtyWindow[id] && currentWindowData.id) {
            // Remove customer event to prevent multiple call
            var eventName = "onDirtyWindowCloseOrderPrototype" + id;

            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
            return;
        } else {
            var eventName = "removeDirtyWindowCloseOrderPrototype" + id;
            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
        }
        if (obj.url == "<react-small-parcel-settings-holder></react-small-parcel-settings-holder>" && window.dirtyWindow[id]) {
            // Remove customer event to prevent multiple call
            var eventName = "onDirtyWindowCloseSmallParcelSettings" + id;
            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);

            return;
        } else {
            var eventName = "removeDirtyWindowCloseSmallParcelSettings" + id;
            var eventItem = new CustomEvent(eventName);
            document.dispatchEvent(eventItem);
        }
        // Set dirty false for close window
        window.dirtyWindow[id] = false;
            WmsWindow.WindowData.splice(index, 1); // remove the index from the array of our data windows
            $("#" + obj.Window).hide();
            var getWindow = $("#" + obj.Window + ' .dynamic-react-component');
            ReactDOM.unmountComponentAtNode(getWindow[0]);
            getWindow.html('');
            that.setState({
                navThumbnailData: WmsWindow.WindowData
            });
            WmsWindow.OnCloseFocusNextWindow(index);
            // ReactDOM.unmountComponentAtNode($("#" + id)[0]);

            if (obj.url == "<react-role-holder></react-role-holder>") {
                // Remove customer event to prevent multiple call
                var eventItem = new CustomEvent("onUserRoleUpdateRemove");
                document.dispatchEvent(eventItem);
            }
            if (obj.url == "<report-iframe-component></report-iframe-component>") {
                // Remove customer event to prevent multiple call
                var eventItem = new CustomEvent("onBolGeneratePDFRemove");
                document.dispatchEvent(eventItem);
            }
            if (obj.url == "<react-find-receipt-holder></react-find-receipt-holder>") {
                // Remove customer event to prevent multiple call
                var eventItem = new CustomEvent("EventReceiptDataRemove");
                document.dispatchEvent(eventItem);
            }
    }
    checkCurrentUserLoginStatus = () => {
        if (GlobalService.isNotIframe() && GlobalService.currentLoginUser && window.localStorage.getItem('currentLoginUser') && GlobalService.currentLoginUser != window.localStorage.getItem('currentLoginUser')) {
            console.log("checkCurrentUserLoginStatus  => failed");
            this.setState({ isOpenLoginChangeConfirmModel: true });
        }
    }
    refreshPage = () => { window.location.reload(); }

    checkLoginStatus = () => {
        GlobalService.GetUSerInformation().then(function (response) { });
    }
    setGreenIndicator = window.setGreenIndicator = (param_boolean) => {
        this.setState({
            showGreenIndicator: param_boolean
        });
    }

    EventClickLogOut = () => {
        GlobalService.SetRights([]);
        GlobalService.ResetCustomerFacilityList();
        GlobalService.MainLogout().then(function success(response) {
            if (response.Code == 0) {

            }
        }, function errorCallback(error) {

        });
    }
    getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    changeTheme = (colorName) => (event) => {
        if (colorName) {
            this.setCookie('themeColor', colorName)
        }
        var color = colorName || this.getCookie('themeColor');
        if (color) {
            setTimeout(() => {
                $('#colorCSS').attr('href', './assets/themes/' + color + '/' + color + '.css');
            }, 0);
        }
    }
    setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    setLoaderTimer = window.setLoaderTimer = (name) => {
        window.setLoaderData[name] = {};
        window.setLoaderData[name]['category'] = 3;
        window.setLoaderData[name]['startTime'] = Date.now();
    }
    setClickedTabDate = window.setClickedTabDate = (name) => {
        // proceed local storage if logs url define
        if (globalsMsg.clientLogs && globalsMsg.clientLogs.pageview) {

            var objPayload = {
                userId: GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login,
                page: name,
                tplId: parseInt(GlobalService.CurrentUser.TplId),
                loginId: GlobalService.CurrentUser.Login,
                versionId: 2,
                typeId: 4
            };
            // typeId 1 is logins, 2 is errors, 3 is pageload timing and 4 is pageviews
            var pageViewDataObj = [];

            if (GlobalService.isNotIframe() && window.localStorage.getItem("pageViewData")) {
                pageViewDataObj = JSON.parse(window.localStorage.getItem("pageViewData"));
            }

            pageViewDataObj.push(objPayload);
            if(GlobalService.isNotIframe()){
                window.localStorage.setItem('pageViewData', JSON.stringify(pageViewDataObj));
            }

            if (pageViewDataObj.length >= globalsMsg.clientLogs.pageview.limit && this.state.isClientLogCalled == false) {
                this.callLogApi(globalsMsg.clientLogs.pageview.url, pageViewDataObj, 'pageViewData');
            }
        }
    }
    EditThumbnail = (data) => {
        var FoundIndex = WmsWindow.FindIndexByProperty("Window", data.fromWindow); // the emit should have told us what window sent the data so lets find the window index in our
        WmsWindow.WindowData[FoundIndex].id = data.trans;
        WmsWindow.WindowData[FoundIndex].lineItem = [{
            name: 'Ref: ',
            value: data.ref
        }, {
            name: 'Trans: ',
            value: data.trans
        }]; // now that we have the index lets change the lineItem thumbnail data in our model
        this.setState({
            navThumbnailData: WmsWindow.WindowData
        });

    }
    setLogInLocalstorage = window.setLogInLocalstorage = (data) => {
        // proceed local storage if logs url define
        if (globalsMsg.clientLogs && globalsMsg.clientLogs.login && data.category == 2) {

            var objPayload = {
                userId: GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login,
                browser: this.getBrowserName(),
                tplId: parseInt(GlobalService.CurrentUser.TplId),
                loginId: GlobalService.CurrentUser.Login,
                versionId: 2,
                typeId: 1
            };
            // typeId 1 is logins, 2 is errors, 3 is pageload timing and 4 is pageviews
            var loginLocalDataObj = [];

            if (GlobalService.isNotIframe() && window.localStorage.getItem("loginLocalData")) {
                loginLocalDataObj = JSON.parse(window.localStorage.getItem("loginLocalData"));
            }

            loginLocalDataObj.push(objPayload);
            if(GlobalService.isNotIframe()){
                window.localStorage.setItem('loginLocalData', JSON.stringify(loginLocalDataObj));
            }

            if (loginLocalDataObj.length >= globalsMsg.clientLogs.login.limit &&  this.state.isClientLogCalled == false) {
                this.callLogApi(globalsMsg.clientLogs.login.url, loginLocalDataObj, 'loginLocalData');
            }
        }
    }
    setApiInLocalstorage = window.setApiInLocalstorage = (data) => {

        // proceed local storage if logs url define
        if (globalsMsg.clientLogs && globalsMsg.clientLogs.request && data.category == 1) {

            var userId = '-';
            if(!GlobalService.CurrentUser) {
                return false;
            }
            if (GlobalService.CurrentUser.Login) // Check if current user set or not
            {
                userId = GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login;
            }

            var objPayload = {}
            if (data.type === 'error') {
                objPayload = {
                    userId: GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login,
                    browser: this.getBrowserName(),
                    tplId: parseInt(GlobalService.CurrentUser.TplId),
                    loginId: GlobalService.CurrentUser.Login,
                    versionId: 2,
                    typeId: 2,
                    statusId: data.statusCode

                };
            }
            else if (data.type === 'slow') {
                objPayload = {
                    userId: GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login,
                    browser: this.getBrowserName(),
                    tplId: parseInt(GlobalService.CurrentUser.TplId),
                    loginId: GlobalService.CurrentUser.Login,
                    versionId: 2,
                    typeId: 5,
                    statusId: data.statusCode,
                    timing: data.time
                };
            }

            var apiTimerDataObj = [];

            if (GlobalService.isNotIframe() && window.localStorage.getItem("apiTimerData")) {
                apiTimerDataObj = JSON.parse(window.localStorage.getItem("apiTimerData"));
            }

            apiTimerDataObj.push(objPayload);
            if(GlobalService.isNotIframe()){
                window.localStorage.setItem('apiTimerData', JSON.stringify(apiTimerDataObj));
            }

            if (apiTimerDataObj.length >= globalsMsg.clientLogs.request.limit &&  this.state.isClientLogCalled == false) {
                this.callLogApi(globalsMsg.clientLogs.request.url, apiTimerDataObj, 'apiTimerData');
            }

        }
    }
    callLogApi = (param_url, param_payload, param_storageName) => {
        if (!GlobalService.isLocalhost()) {
            this.setState({
                isClientLogCalled: true
            })
            axios.post(param_url, param_payload).then((response) => {
                this.setState({
                    isClientLogCalled: false
                });
                if (GlobalService.isNotIframe() && param_storageName) {
                    window.localStorage.removeItem(param_storageName);
                }
            }).catch(() => {

            }).then(() => {
                this.setState({
                    isClientLogCalled: false
                });
            });
        }
    }
    //get broser name
    getBrowserName = () => {
        var aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
            sUsrAg = navigator.userAgent, nIdx = aKeys.length - 1;
        for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);
        return aKeys[nIdx];
    }
    OpenWindow = window.OpenWindow = (obj) => {
        var that = this;
        //alert('OpenWindow');
        if (obj.hasOwnProperty("isIframe")) {
            if (obj.isIframe) {
                var method = obj.iframeMethod ? obj.iframeMethod : 'GET';
                var orderids = obj.orderids ? obj.orderids : '';
                GlobalService.setIframeUrl(obj.id,method,orderids);
            }
        }
        if (obj.singleton) { // if it is a singleton and exists we want to move it towards the front else if it does not exist we want to add it
            var FoundObject = '';
            var FoundIndex = '';
            if (obj.hasOwnProperty("isIframe")) {
                // We find tab using uniqName if it's iframe
                // Because all iframe have same url <report-iframe-component></report-iframe-component>
                // and if iframe is singlton(means open only one tab) than we need to check it's already open or not
                // IN case of Find order > Document > Edit Master Bol && Find Order > Document > Shipping manifest
                // We need to change logic for find object
                FoundObject = WmsWindow.FindObjectByProperty("uniqName", obj.uniqName); // get the object
                FoundIndex = WmsWindow.FindIndexByProperty("uniqName", obj.uniqName);
            } else {
                FoundObject = WmsWindow.FindObjectByProperty("url", obj.url); // get the object
                FoundIndex = WmsWindow.FindIndexByProperty("url", obj.url);
            }
            // REFACTOR what if we find multiple objects with the same url as singletons here, we should never have this happen but maybe good to put some checking in or conditions for this.
            if (FoundObject) {
                var urlParam = ''
                if (obj.urlParam) {
                    urlParam += obj.urlParam;
                    window.history.replaceState("object or string", "Title", `?page=${urlParam}`);
                }

                WmsWindow.ResetActiveStatus();
                var GetWindow = $("#" + FoundObject[0].Window);
                GetWindow.show();
                that.resizeAllGrid(200);
                WmsWindow.WindowData[FoundIndex].active = true; // The window was brought to the front so the thumbnails need to display the active class for the correct thumbnail
                that.setState({
                    navThumbnailData: WmsWindow.WindowData
                });
                // for master pick ticket referece data if url change
                if (WmsWindow.WindowData[FoundIndex].id != obj.id && obj.reloadIframe) {
                    WmsWindow.WindowData[FoundIndex].id = obj.id;
                    $("#" + FoundObject[0].Window + ' .dynamic-react-component').find("iframe").attr({ "src": obj.id });
                }

                window.ChildBulletin.Data = obj;
                if (obj.sendToTab == "Location") {
                    var receiptObj = obj.receiptObj;
                    var event = new CustomEvent("showLocation", {
                        detail: {
                            receiptObj: receiptObj
                        }
                    });
                    document.dispatchEvent(event);
                } else if (obj.sendToTab == "Item") {
                    var receiptObj = obj.receiptObj;
                    var eventItem = new CustomEvent("showItem", {
                        detail: {
                            receiptObj: receiptObj
                        }
                    });
                    document.dispatchEvent(eventItem);
                } else if (obj.sendToTab == "SmallParcel-openPackAndShipModal") {
                    var eventItem = new CustomEvent("SmallParcel-openPackAndShipModal", {
                        detail: {
                            orderId: obj.sharedSelectOrder
                        }
                    });
                    document.dispatchEvent(eventItem);
                } else if (obj.sendToTab == "SmallParcel-searchHVPBatchName") {
                    var eventItem = new CustomEvent("SmallParcel-searchHVPBatchName", {
                        detail: {
                            HvpBatchName: obj.HvpBatchName,
                            HvpFacilityName: obj.HvpFacilityName,
                            HvpCustomerName: obj.HvpCustomerName
                        }
                    });
                    document.dispatchEvent(eventItem);
                }
            } else {
                WmsWindow.InsertWindow(obj);
            }

        } else if (obj.hasOwnProperty("id") && obj.id != 0) { // we want to see if order is already open
            var isNonSingletonEditAlreadyExists = WmsWindow.FindIndexByProperty("id", obj.id); // some non singletons for example editing should only have one edit of a certain id open at a time

            if (isNonSingletonEditAlreadyExists === -1) {

                var nextWindow = WmsWindow.NextAvailableWindow();
                // we dont care if this window already exists because it can have multiple instances, we just care if we have room in our window array

                if (nextWindow) {
                    WmsWindow.InsertWindow(obj);
                }

            } else {

                var FoundObject2 = WmsWindow.FindObjectByProperty("id", obj.id); // get the object
                var FoundIndex2 = WmsWindow.FindIndexByProperty("id", obj.id);

                WmsWindow.ResetActiveStatus();
                var GetWindow = $("#" + FoundObject2[0].Window);
                GetWindow.show();
                that.resizeAllGrid(200);
                WmsWindow.WindowData[FoundIndex2].active = true; // The window was brought to the front so the thumbnails need to display the active class for the correct thumbnail

                that.setState({
                    navThumbnailData: WmsWindow.WindowData
                });
                var urlParam = ''
                if (obj.urlParam) {
                    urlParam += obj.urlParam;
                    window.history.replaceState("object or string", "Title", `?page=${urlParam}`);
                }
            }
        } else { // if its not a singleton
            var nextWindow = WmsWindow.NextAvailableWindow();
            // we dont care if this window already exists because it can have multiple instances, we just care if we have room in our window array

            if (nextWindow) {
                WmsWindow.InsertWindow(obj);
            }

        }
    }
    setLoaderTimerEndTime = window.setLoaderTimerEndTime = (name) => {

        // assign rootscope if logs url define
        if (typeof window.setLoaderData[name] != "undefined" && globalsMsg.clientLogs && globalsMsg.clientLogs.pageload) {

            window.setLoaderData[name]['endTime'] = Date.now();
            var pageLoadTime = Date.now() - window.setLoaderData[name]['startTime'];
            var objPayload = {
                userId: GlobalService.CurrentUser.TplId + "_" + GlobalService.CurrentUser.Login,
                timing: pageLoadTime,
                page: name,
                tplId: parseInt(GlobalService.CurrentUser.TplId),
                loginId: GlobalService.CurrentUser.Login,
                versionId: 2,
                typeId: 3
            };
            // typeId 1 is logins, 2 is errors, 3 is pageload timing and 4 is pageviews
            var pageLoadTimerDataObj = [];

            if (GlobalService.isNotIframe() && window.localStorage.getItem("pageLoadTimerData")) {
                pageLoadTimerDataObj = JSON.parse(window.localStorage.getItem("pageLoadTimerData"));
            }

            pageLoadTimerDataObj.push(objPayload);
            if(GlobalService.isNotIframe()){
                window.localStorage.setItem('pageLoadTimerData', JSON.stringify(pageLoadTimerDataObj));
            }
            delete window.setLoaderData[name];

            if (pageLoadTimerDataObj.length >= globalsMsg.clientLogs.pageload.limit && this.state.isClientLogCalled == false) {
                this.callLogApi(globalsMsg.clientLogs.pageload.url, pageLoadTimerDataObj, 'pageLoadTimerData');
            }
        }
    }
    ClickThumbnail = (clickedWindow) => (event) => { // when a thumbnail is clicked this is called and passes the window that was clicked EX: Window : Window1 (just Window1 is passed)
        WmsWindow.BringWindowToFront(clickedWindow);
        // scroll view for active tab
        setTimeout(() => {
            if ($(".tab-list div.active") && $(".tab-list div.active").length) {
                $(".tab-list div.active")[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
        }, 100);
        setTimeout(() => {
            this.enableDisableLeftRightArrow();
        }, 800);
    }
    closeWindowByTabClick = (clickedWindow) => (event) => { // when a thumbnail is clicked this is called and passes the window that was clicked EX: Window : Window1 (just Window1 is passed)
        WmsWindow.CloseWindow(clickedWindow);
        setTimeout(() => {
            var scrollNumb = $('.tab-list').get(0).scrollWidth - $('.tab-list').outerWidth();
            if(scrollNumb <= 0) {
                this.setState({
                    isShowArrow: false
                });
            }
        }, 100);
    }
    adjustZIndexOfSidebar = window.adjustZIndexOfSidebar = (type) => {
        if (type == "open") {
            this.setState({
                hideHigherElements: false
            });
        } else {
            this.setState({
                hideHigherElements: true
            });
        }
    }
    setHigherElementsOnGridColumnInit = window.setHigherElementsOnGridColumnInit = (paramFlag) => {
        this.setState({ hideHigherElements: paramFlag });
    }

    GetUserName = () => {
        GlobalService.GetUSerInformation().then((response) => {
            let CurrentJwtUser = {};
            let JWTToken = response.data?.Jwt;
            if (JWTToken && JWTToken != "") {
                var z = atob(JWTToken.split('.')[1]);
                if(z != ""){
                    CurrentJwtUser = JSON.parse(z);
                }
            }
            GlobalService.SetJwtUser(CurrentJwtUser);
            // update state after set data in currentUser
            this.setState({
                isCustomerUser:GlobalService.GetCurrentUser().CustomerIds.length > 0 ? true : false,
                LoggedInUserName: GlobalService.CurrentUser.Name
            },() => {
                $("#dropdown").kendoMenu();
            });
            let user_type;
            let isC2 = false;
            if (GlobalService.CurrentUser.CustomerIds.length === 0) {
                user_type = "warehouse";
            }
            else {
                user_type = "customer";
                isC2 = true;
            }
            let user_name = GlobalService.CurrentUser?.Name;
            var envIndicator = GlobalService.getEnvironmentIndicator();
            if (globalsMsg.pendo && typeof pendo != 'undefined') {
                if (!GlobalService.isLocalhost()) {
                    let JWTUser = GlobalService.GetJwtUser();

                    const pendoInitObj = {
                        visitor: {
                            id: JWTUser.email || envIndicator + GlobalService.CurrentUser.TplId + '_' + GlobalService.CurrentUser.Login,
                            'hub_id': JWTUser.sub || '',
                            'email': JWTUser.email || '',
                            'full_name': user_name,
                            'environment': envIndicator,
                            'isSuperUser': JWTUser.issuperuser || '',
                            'isC2': isC2,
                            'tpl_app': "SV",
                            'usersAppAccess': JWTUser.appaccess || '',
                            'user_type': user_type,
                        },
                        account: {
                            id: JWTUser.extensivId || envIndicator + GlobalService.CurrentUser.TplId.toString(),
                            tplguid: GlobalService.CurrentUser.TplGuid.toString(),
                            'orgKey': JWTUser.org || '',
                            'name': JWTUser.org || '',
                            'product': '3pl-warehouse-manager',
                            'isTest': JWTUser.istestorg || '',
                        }
                    }
                    console.log("Pendo ping should call.", pendoInitObj);

                    // in visitor
                    // id: envIndicator + GlobalService.CurrentUser.TplId + '_' + GlobalService.CurrentUser.Login,

                    // in account
                    // id: envIndicator + GlobalService.CurrentUser.TplId.toString(),
                    pendo.initialize(pendoInitObj);
                }
            }
        });
    }
    toggleWindowLeftCss = window.toggleWindowLeftCss = (menutype) => {
        if (!menutype) {
            menutype = this.state.ActiveMenuType;
        } else {
            this.setState({
                ActiveMenuType: menutype
			});
			// setTimeout(() => {
			// 	if (menutype == 'showIconNav') {
			// 		$("#navbar-component .with-texticon-menu").hide();
			// 	}
			// }, 800);
			// if (menutype == 'showIconWithTextNav') {
			// 	$("#navbar-component .with-texticon-menu").show();
			// }
        }
        var WindowHeight = window.innerHeight - WmsWindow.OtherUiHeight - WmsWindow.Padding - WmsWindow.Padding;
        if (menutype == 'showIconNav') {
            var WindowWidth = window.innerWidth - WmsWindow.OtherUiWidth - WmsWindow.Padding - WmsWindow.Padding + 120;
            var WindowTop = WmsWindow.OtherUiHeight + WmsWindow.Padding;
            var WindowLeft = WmsWindow.OtherUiWidth + WmsWindow.Padding - 120;
        } else if (menutype == 'showIconWithTextNav') {
            var WindowWidth = window.innerWidth - WmsWindow.OtherUiWidth - WmsWindow.Padding - WmsWindow.Padding;
            var WindowTop = WmsWindow.OtherUiHeight + WmsWindow.Padding;
            var WindowLeft = WmsWindow.OtherUiWidth + WmsWindow.Padding;
            if (window.innerWidth <= 1199) {
                WindowWidth = WindowWidth + 20;
                WindowLeft = WindowLeft - 20;
            }
        }
        // $('.custom-tab-content').css({ top: WindowTop, left: WindowLeft, width: WindowWidth, height: WindowHeight });
        // _.forEach($('.intialize-window'), function (value, key) {
        //     if ($(value).data("kendoWindow")) {
        //         $(value).data("kendoWindow").setOptions({
        //             width: WindowWidth,
        //             height: WindowHeight,
        //             position: {
        //                 top: WindowTop,
        //                 left: WindowLeft
        //             }
        //         });
        //     }
        // });
        this.resizeAllGrid(500);
    }
    resizeAllGrid = (timeout) => {
        if (typeof $(".k-grid").data("kendoGrid") != "undefined") {
            setTimeout(() => {
                _.forEach($('.k-grid'), function (value, key) {
                    if ($(value).data("kendoGrid")) {
                        $(value).data("kendoGrid").resize(true);
                    }
                });
            }, timeout);
        }
        if ($(".k-grid").hasClass("k-treelist")) {
            setTimeout(() => {
                _.forEach($('.k-grid'), function (value, key) {
                    if ($(value).data("kendoTreeList")) {
                        $(value).data("kendoTreeList").resize();
                    }
                });
            }, timeout);
        }
    }
    // Open progress window
    openProgressWindow = () => {
        var event = new CustomEvent("showProgressModal");
        document.dispatchEvent(event);
    }
    changeColorOfProgress = window.changeColorOfProgress = (color) => {
        this.setState({
            progressColor: color
        });
    }
    animationMoveIcon = window.animationMoveIcon = (targetElement) => {
        $('#animIcon').remove();
        var gearIcon = $('<div id="animIcon" class="gear-icn"><i class="submenu-icon fa fa-download"></i></div>');

        var position = $(targetElement).offset();
        gearIcon.css(position);
        $("#body-component").append(gearIcon);
        var posTarget = $("#process-icon-nav").offset();
        var posTargetTop = posTarget.top;
        var posTargetLeft = posTarget.left;
        $.keyframe.define([{
            name: 'anim1',
            '0%': {
                'opacity': '1',
                'transform': 'scale(1)',
                'left': position.left + 'px',
                'top': position.top + 'px'
            },
            '20%': {
                'opacity': '1',
                'transform': 'scale(1)',
                'left': (position.left + 50) + 'px',
                'top': (position.top + 50) + 'px'
            },
            '90%': {
                'opacity': '1',
                'transform': 'scale(1)',
                'left': (posTarget.left + 0) + 'px',
                'top': (posTarget.top + 25) + 'px'
            },
            '95%': {
                'opacity': '1',
                'transform': 'scale(1.5)',
                'left': (posTarget.left + 0) + 'px',
                'top': (posTarget.top + 25) + 'px'
            },
            '100%': {
                'opacity': '0',
                'transform': 'scale(0.5)',
                'left': (posTarget.left + 0) + 'px',
                'top': (posTarget.top + 25) + 'px'
            }
        }]);
        $(gearIcon).playKeyframe({
            name: 'anim1', // name of the keyframe you want to bind to the selected element
            duration: '1s', // [optional, default: 0, in ms] how long you want it to last in milliseconds
            timingFunction: 'ease', // [optional, default: ease] specifies the speed curve of the animation
            // delay: '0s', //[optional, default: 0s]  how long you want to wait before the animation starts
            iterationCount: '1', //[optional, default:1]  how many times you want the animation to repeat
            // direction: 'normal', //[optional, default: 'normal']  which direction you want the frames to flow
            fillMode: 'forwards', //[optional, default: 'forward']  how to apply the styles outside the animation time, default value is forwards
            complete: function () { } //[optional] Function fired after the animation is complete. If repeat is infinite, the function will be fired every time the animation is restarted.
        });
    }
    taskStarted = window.taskStarted = () => {
        this.setState({
            taskStart: true,
            progressColor: 'yellow'
        });
        var that = this;
        setTimeout(() => {
            this.setState({
                taskStart:false
            })
        }, 5000);
    }
    closeWindowFromTitlebar = window.closeWindowFromTitlebar = (windowId) => {
        var obj = _.find(WmsWindow.WindowData, function (o) { return o.Window == windowId; });
        WmsWindow.CloseWindow(obj);
    }
    // openMessageAlertIframe = () => {
    //     this.OpenWindow({
    //         url: '<report-iframe-component></report-iframe-component>',
    //         reactComponent: reportIframe,
    //         singleton: false,
    //         id: '/WebUI/V1/V1Link/ClientMessagingView.aspx',
    //         isIframe: true,
    //         title: 'Label_Message_Title',
    //         active: true,
    //         useLineItem: false,
    //         lineItem: [{
    //             name: '',
    //             value: 'Label_Message_Title'
    //         }],
    //         icon: 'submenu-icon fa fa-commenting'
    //     })
    // }
    tabScrollLeft = (isScrollLeft) => (event) => {
        // console.log("enableDisableLeftRightArrow tabScrollLeft called");
        var scrollNumb = 0;
        scrollNumb = $('.tab-list').get(0).scrollWidth - $('.tab-list').outerWidth();
        if(scrollNumb > 0) {
            this.setState({
                isShowArrow: true
            });
        }
        setTimeout(() => {
            scrollNumb = isScrollLeft ? 0 : $('.tab-list').get(0).scrollWidth - $('.tab-list').outerWidth();
            // $('.tab-list').scrollLeft(scrollNumb);
            $('.tab-list').animate( { scrollLeft: scrollNumb }, 300);
            setTimeout(() => {
                this.enableDisableLeftRightArrow();
            }, 300);
        }, 300);
    }
    enableDisableLeftRightArrow = () => {
        var currPos = $('.tab-list').scrollLeft();
        var maxLeftPos = $('.tab-list').get(0).scrollWidth - $('.tab-list').outerWidth();
        // console.log("enableDisableLeftRightArrow currPos: " + currPos + "  maxLeftPos: " + maxLeftPos);
        this.setState({
            currentDisableArrow : 'none',
        });
        if(currPos <= 5){
            this.setState({
                currentDisableArrow : 'left'
            });
        }
        if(currPos >= (maxLeftPos - 5)) {
            this.setState({
                currentDisableArrow : 'right'
            });
        }
    }

    openSalesForce = () => {
        GlobalService.VerifySsoToSalesForceConfig().then((response) => {
            console.log("VerifySsoToSalesForceConfig response", response);
            if (response.status == 204 || response.status == 200) {
                $("body").append('<form id="salesforceSSO" action="/WebUI/Shared/GlobalServices/SsoToSalesForceForums" method="post" target="_blank"></form>');
                $("#salesforceSSO").submit();
                $("#salesforceSSO").remove();
            }
        }).finally(() => {
        });
    }


    render() {
        const { hideHigherElements, isCustomerUser } = this.state
        const isHubLogin = GlobalService.GetCurrentUser()?.IsJwtLogin;
        const headerClasses = [], bodyClasses = [];
        const rights= GlobalService.GetRights();


        if(!hideHigherElements) headerClasses.push("sidenav-z")
        if(isCustomerUser) bodyClasses.push('wms-customer-user-body')
        if(isHubLogin) {
            headerClasses.push("hub-header")
            bodyClasses.push('body-hub')
        }

        return (
            <div id="body-component" className={bodyClasses.join(" ")}>
                <div className="overlay" style={{ display: 'none' }}></div>
                {this.props.isPreloadComplete && !this.state.isCustomerUser && !isHubLogin &&(
                    <SuperNavComponent></SuperNavComponent>
                )}
                {this.props.isPreloadComplete && (
                    <header className={headerClasses.join(" ")}>
                        <div className={`${isHubLogin ? 'logo hub-logo' : 'logo'}`}>
                            <a>
                                <img id="img-logo-upperleft" src="assets/images/extensive-logo-mono-200.svg" alt="Logo" />
                            </a>
                        </div>
                        <div className={"tabs" + (this.state.isShowArrow ? ' tab-arrow-show' : '') + (isHubLogin ? ' tabs-hub' : '')}>
                            <div className={"tab-list"}>
                                {this.state.navThumbnailData.map((obj, index) => {
                                    return (
                                        <div key={index} className={obj.active ? `tab-item active ${isHubLogin ? 'hub-tab-item-active' : ''}` : `tab-item ${isHubLogin ? 'hub-tab-item' : ''}`}>
                                            <a tabIndex="-1" onClick={this.ClickThumbnail(obj.Window)}>
                                                <i className={obj.icon}></i>
                                                <div className="detail">
                                                    <h5>{this.translate(obj.title)}</h5>
                                                    <div className={isHubLogin ? "hub-tab-detail" : ""}>
                                                    {obj.lineItem && obj.lineItem.map((lstItem, inkey) => {
                                                        return (
                                                            <div key={inkey} className={inkey === 0 ? "first" : ""}>
                                                                {obj.lineItem.length > 1 && (
                                                                    <>
                                                                    <b>{lstItem['name']}</b>
                                                                    <span>{lstItem['value']}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                                {obj.isFreeTrial && (
                                                    <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                )}
                                                {obj.isNewMenu && (
                                                    <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                )}
                                            </a>
                                            <button className="close" onClick={this.closeWindowByTabClick(obj)}>
                                                <i className="far fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>


                            <a className={"arrow arrow-left" + (this.state.currentDisableArrow == "left" ? ' disable-arrow' : '')} onClick={this.tabScrollLeft(true)}>
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </a>
                            <a className={"arrow arrow-right"  + (this.state.currentDisableArrow == "right" ? ' disable-arrow' : '')} onClick={this.tabScrollLeft(false)}>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </a>


                        </div>
                        <div className={isHubLogin ? "cogs-setting hub" : "cogs-setting"}>
                            {/* {this.state.rights.hasOwnProperty('Shipping.SmallParcelHighVolumeProcessing') && (
                                    <div className='message-alert' onClick={this.openMessageAlertIframe} data-wms-selector="MessageIcon">
                                        <i className="fas fa-comment-alt"></i>
                                    </div>
                                )} */}

                            <div className={isHubLogin ? "wms-info-dialog hub" : "wms-info-dialog"} data-wms-selector="ResuorcesIcon">
                                <i className="fas fa-question-circle"></i>
                            </div>
                            <a className={"gear-holder"} onClick={this.openProgressWindow} data-wms-selector="RunningTaskIcon">
                                <i id="process-icon-nav" className={"fa fa-gear " + (this.state.taskStart ? 'fa-spin animated' : '')} style={{ color: this.state.progressColor }}></i>
                                {this.state.showGreenIndicator && (
                                    <span className="wms-progress-online"></span>
                                )}
                            </a>
                            {isHubLogin && rights.hasOwnProperty('UxPrototypingShowSupportPortal') && (
                                    <a className="wms-hub-support-portal-link" data-wms-selector={"WMSHubSuportPortalLink"} onClick={this.openSalesForce}>
                                        <i className="fa fa-life-ring main-icon" aria-hidden="true"></i>{this.translate('Window_Title_Label_Support')}
                                    </a>
                                )}
                            {!isHubLogin && <div className="user">
                                <ul id="dropdown">
                                    <li>
                                        <a data-wms-selector="LoginUserMenu" className="user-name">
                                            <i className="fa fa-user-circle" aria-hidden="true"></i>
                                        </a>
                                        <ul>
                                            <li>
                                                <a>{this.state.LoggedInUserName}</a>
                                            </li>
                                            <li className="wms-border-bottom">
                                                <a onClick={this.EventClickLogOut} data-wms-selector="LoginUserMenuSignOut">Sign out</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            }
                        </div>
                    </header>
                )}
                <div className={"bottom-wrapper "+(this.state.ActiveMenuType == 'showIconWithTextNav' ? 'showIconWithTextNav' : ' showIconNav')}>
                    {this.props.isPreloadComplete && (
                        <Navbar hideHigherElements={this.state.hideHigherElements} ActiveMenuType={this.state.ActiveMenuType }></Navbar>
                    )}
                    <div className="custom-tab-wrapper">
                        <div id="Window1" data-wms-selector="Window1" className="Window1 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window2" data-wms-selector="Window2" className="Window2 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window3" data-wms-selector="Window3" className="Window3 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window4" data-wms-selector="Window4" className="Window4 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window5" data-wms-selector="Window5" className="Window5 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window6" data-wms-selector="Window6" className="Window6 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window7" data-wms-selector="Window7" className="Window7 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                        <div id="Window8" data-wms-selector="Window8" className="Window8 custom-tab-content" style={{ display:'none' }}>
                            <div className="dynamic-react-component"></div>
                        </div>
                    </div>
                </div>
                {this.props.isPreloadComplete && (
                    <img src="assets/images/extensiv-background.svg" id="managerLogin" />
                )}
                {/* Intialize progress components so he can listen events */}
                <WmsNotification></WmsNotification>
                {this.props.isPreloadComplete && (
                    <ProgressHolder></ProgressHolder>
                )}
                {GlobalService.isNotIframe() && this.state.isOpenLoginChangeConfirmModel && (
                    <WmsConfirmModel
                        isOpen={this.state.isOpenLoginChangeConfirmModel}
                        width={390}
                        height={160}
                        firstBtnLabel={this.translate("Label_Select_Confirm")}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={this.refreshPage}
                        sawCheckBox={false}
                        isThirdBtnSaw={false}
                        conFormModelText={"Looks like login has been changed to user: " + window.localStorage.getItem('currentLoginUserName') + ' and tpl: ' + window.localStorage.getItem('currentLoginTplName') + ". Page will refresh on click of OK."}
                        onCloseFunc={() => { }}
                        thirdBtnLabel={''}
                    />
                )}
            </div>
        )

    }
}