import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';

//Services
import * as roleService from './roles.service.jsx';
import * as GlobalService from './../global_services';

export class RolesFormPermission extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            customLabels: GlobalService.getCustomLabels(),
            Selector: 'Roles',
            defaultTreeData: null,
            roleSelectAllCheckbox: false,
            showExpandAll: true,
            filterTreevalue: '',
            rollHoverText: '',
            roleType: '',
            firstTime: true
        }
    }

    componentDidMount() {
        if (this.props.sharedSelectRoleData != null && this.props.sharedSelectRoleData != '') {
            this.getRoleData(this.props.sharedSelectRoleData.Uri, this.props.sharedSelectRoleData.IsTemplate, this.props.sharedSelectRoleData.Scope);
        }

        if (this.props.isRoleForCopy == true) {
            this.setState({ roleType: this.props.sharedRoleType });
        }
        // if (this.props.sharedSelectRoleData.Uri) {
        //     this.setState({ roleType: '' });
        // }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.sharedSelectRoleData != null &&
            nextProps.sharedSelectRoleData != '' &&
            (nextProps.isRoleForCopy != this.props.isRoleForCopy || this.props.sharedSelectRoleData.Uri != nextProps.sharedSelectRoleData.Uri)) {

            this.setState({
                sharedSelectRoleData: nextProps.sharedSelectRoleData,
                showDiscard: false,
            });
            this.getRoleData(nextProps.sharedSelectRoleData.Uri, nextProps.sharedSelectRoleData.IsTemplate, nextProps.sharedSelectRoleData.Scope == 1 ? 'Customer' : 'Warehouse');

        }
        if (nextProps.sharedSelectRoleData == '' &&
            nextProps.sharedSelectRoleData != this.props.sharedSelectRoleData) {
            this.setState({
                defaultTreeData: null,
                roleType: ''
            });
        }

        if (nextProps.isRoleForCopy == true && nextProps.sharedRoleType != this.props.nextProps ) {
            this.setState({ roleType: nextProps.sharedRoleType });
        }
        // if (nextProps.sharedSelectRoleData.Uri) {
        //     this.setState({ roleType: '' });
        // }
    }

    validateAndUpdateOverrideInventory = () => {
        const treeView = $("#TreeRole").data("kendoTreeView");
        const nodes = treeView.dataSource.view();

        // Locate "Mobile Scanner" → "Replenishment Module" nodes
        const replenishmentModuleNode = nodes
            .find(node => node.text === "Mobile Scanner")
            ?.items.find(child => child.text === "Replenishment Module");

        if (replenishmentModuleNode?.hasChildren) {
            // Locate child nodes under "Replenishment Module"
            const bulkStorageNode = replenishmentModuleNode.items.find(child => child.text === "Replenish Bulk Storage");
            const pickLinesNode = replenishmentModuleNode.items.find(child => child.text === "Replenish Pick Lines");
            const overrideNode = replenishmentModuleNode.items.find(child => child.text === "Override Selected Inventory");

            if (overrideNode) {
                // Check dependencies and update node state
                const dependenciesChecked = bulkStorageNode?.checked || pickLinesNode?.checked;

                overrideNode.set("enabled", dependenciesChecked);
                if (!dependenciesChecked) overrideNode.set("checked", false);

                // Update the checkbox DOM element
                const overrideElement = treeView.findByUid(overrideNode.uid);
                const checkbox = $(overrideElement).find("input[type='checkbox']");

                checkbox.prop({
                    disabled: !dependenciesChecked,
                    checked: dependenciesChecked ? checkbox.prop("checked") : false
                });
            }
        }
    };

    getRoleData = (param_roleuri, param_isTemplate, param_roleType) => {

        let loadingTarget = 'role_form_block';
        if (param_roleuri == '') {
            loadingTarget = 'loadingContent';
        }
        kendo.ui.progress($('#' + loadingTarget), true);

        if (param_roleType == 0) {
            param_roleType = '';
        } else if (param_roleType == 1) {
            param_roleType = 'Customer';
        } else if (param_roleType == 2) {
            param_roleType = 'Warehouse';
        }


        roleService.getRoleData(param_roleuri, param_isTemplate, param_roleType).then((response) => {

            if (typeof response != "undefined") {

                this.setState({ defaultTreeData: response.data.items });
                let treeview = $("#TreeRole").data("kendoTreeView");
                response.data.items[0].param_roleType = param_roleType;
                if (treeview) {
                    treeview.setDataSource(new kendo.data.HierarchicalDataSource({
                        data: response.data.items
                    }));
                } else {
                    $("#TreeRole").kendoTreeView({
                        checkboxes: { checkChildren: true },
                        check: this.handleRoleChanged,
                        dataTextField: "text",
                        dataSource: response.data.items,
                        // template: "# if (item.scope === 2 ) { # " +
                        //     "<span class='warehouseText'> #: item.text #</span> " +
                        //     " # } else { # " +
                        //     " #: item.text # # } #",
                        template: "#= getTreeViewTemplate(item) #"
                    });
                    //check role items scope for hide&show customer role warning label

                    this.bindTreeHoverEffect();
                }

            }

        }).finally(() => {
            this.setState({
                firstTime: false,
                showExpandAll: true
            });
            let treeview = $("#TreeRole").data("kendoTreeView");
            if (treeview) {
                treeview.collapse(".k-item");
                this.validateAndUpdateOverrideInventory();
            }
            kendo.ui.progress($('#' + loadingTarget), false);
        });
    }

    bindTreeHoverEffect = () => {
        //set role description on tree list hover
        $("#TreeRole").delegate("span", "mouseover", (e) => {
            var treeview = $("#TreeRole").data("kendoTreeView");
            var uid = $(e.target).closest('li').attr('data-uid');
            var node = treeview.findByUid(uid);
            var item = treeview.dataItem(node);
            this.setState({ rollHoverText: item.description });
        });
    }

    handleRoleChanged = () => {

        let checkedNodes = [];
        let totalNodes = [];
        let treeView = $("#TreeRole").data("kendoTreeView");
        this.checkedNodeIds(treeView.dataSource.view(), checkedNodes, totalNodes);
        this.validateAndUpdateOverrideInventory();
        this.setState({
            roleSelectAllCheckbox: (checkedNodes.length == totalNodes.length) ? true : false
        });
        this.props.onPermissionChange();
    }

    eventClickSelectAllRole = () => {
        let treeview = $("#TreeRole").data("kendoTreeView");
        let nodes = treeview.dataSource.view();
        for (let i = 0; i < nodes.length; i++) {
            nodes[i].set("checked", !this.state.checkboxCheroleSelectAllCheckboxcked);
            if (nodes[i].loaded() && nodes[i].hasChildren) {
                this.checkedChildRole(nodes[i].children.view(), !this.state.roleSelectAllCheckbox);
            }
        }
        this.setState({
            roleSelectAllCheckbox: !this.state.roleSelectAllCheckbox
        });
        this.props.onPermissionChange();
    }

    checkedChildRole = (data, checkedStatus) => {
        for (let i = 0; i < data.length; i++) {
            data[i].set("checked", checkedStatus);
            if (data[i].loaded() && data[i].hasChildren) {
                this.checkedChildRole(data[i].children.data(), checkedStatus);
            }
        }
    }

    eventClickExpandCollapse = () => {

        var treeview = $("#TreeRole").data("kendoTreeView");
        if (this.state.showExpandAll === true) {
            treeview.expand(".k-item");
        } else if (this.state.showExpandAll === false) {
            treeview.collapse(".k-item");
        }
        //Toggle which button we show
        this.setState({ showExpandAll: !this.state.showExpandAll });
    }

    eventClickResetTree = () => {
        this.setState({
            roleSelectAllCheckbox: false,
            filterTreevalue: ''
        });

        let treeview = $("#TreeRole").data("kendoTreeView");
        treeview.setDataSource(new kendo.data.HierarchicalDataSource({
            data: this.state.defaultTreeData
        }));
        this.setSelectallbuttonchecked();
        this.setState({ showExpandAll: true });
    }

    setSelectallbuttonchecked = () => {
        let checkedNodes = [];
        let totalNodes = [];
        var treeView = $("#TreeRole").data("kendoTreeView");
        this.checkedNodeIds(treeView.dataSource.view(), checkedNodes, totalNodes);
        if (checkedNodes.length == totalNodes.length) {
            this.setState({ roleSelectAllCheckbox: false });
        }
    }

    handlefilterTreeChange = (event) => {

        var convertedText = StringWms.initialCaps(event.target.value);

        // Expanding Tree if its not expanded
        var treeview = $("#TreeRole").data("kendoTreeView");
        // treeview.expand(".k-item");
        // console.log("this.state.showExpandAll: "+this.state.showExpandAll);
        if (this.state.showExpandAll === true) {
            treeview.expand(".k-item");
        }

        this.setState({
            filterTreevalue: event.target.value,
            showExpandAll: false
        });

        if (event.target.value !== "") {
            $("#TreeRole .k-group .k-group .k-in").closest("li").hide();
            $("#TreeRole .k-group > li").hide();
            $("#TreeRole").closest("li").hide();
            $("#TreeRole .k-group .k-group .k-in:contains(" + convertedText + ")").each(function () {
                $(this).parents("ul, li").each(function () {
                    $(this).show();
                });
            });
            $("#TreeRole .k-group .k-in:contains(" + convertedText + ")").each(function () {
                $(this).parents("ul, li").each(function () {
                    $(this).show();
                });
            });
            $("#TreeRole .k-in:contains(" + convertedText + ")").each(function () {
                $(this).parents("ul, li").each(function () {
                    $(this).show();
                });
            });
        } else {
            $("#TreeRole .k-group").find("li").show();
        }
    }

    handleRolenameChange = (event) => {
        this.props.onRoleDataSet(event.target.value);
    }

    checkedNodeIds = function (nodes, checkedNodes, totalNodes) {

        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].checked) {
                checkedNodes.push(nodes[i].id);
            }
            totalNodes.push(nodes[i].id);
            if (nodes[i].hasChildren) {
                this.checkedNodeIds(nodes[i].children.view(), checkedNodes, totalNodes);
            }
        }
    }

    handleRoleTypeChange = (param_roleType) => {
        this.props.onRoleDataSet(param_roleType);
        this.setState({ roleType: param_roleType });
        this.getRoleData('', false, param_roleType);
    }

    render() {


        return (
            <WmsCollapse id={'collapseRole2'} headerLabel={this.translate('Permissions_RolePermissionsGrid_Permisisons')} >

                <WmsContainer title={this.translate('Role_Label_Details_Section')}>

                    <WmsInput
                        id='roleName'
                        inputName='roleName'
                        wmsSelector={this.state.Selector + 'Name'}
                        onChangeFunc={this.handleRolenameChange}
                        label={this.translate('Role_Label_Name')}
                        value={this.props.sharedSelectRoleData.Name}
                        required={true}
                        disabled={typeof this.props.sharedSelectRoleData.Uri != 'undefined' &&
                            this.props.isRoleForCopy == false} />


                    {typeof this.props.sharedSelectRoleData.Uri != 'undefined' && this.props.sharedSelectRoleData.Scope != 0 && this.props.isRoleForCopy == false && (
                        <div className="wms_details_labels">
                            <label>
                                {
                                    this.translate('Role_Label_Type').replace(new RegExp('{' + 'Warehouse' + '}', 'gi'), this.props.sharedSelectRoleData.Scope == 1 ? this.state.customLabels.Label_Customer : this.state.customLabels.Label_Warehouse)
                                }
                            </label>
                        </div>
                    )}


                    {(typeof this.props.sharedSelectRoleData.Uri == 'undefined'
                        || this.props.isRoleForCopy == true) && (
                            <div className="role-type-btn">
                                <WmsRadio
                                    id="warehouseRole"
                                    name="roleType"
                                    label={this.translate('Role_Label_Warehouse')}
                                    wmsSelector={this.state.Selector + 'warehouse'}
                                    onChangeFunc={(e) => this.handleRoleTypeChange('Warehouse')}
                                    value={this.state.roleType}
                                    checked={this.state.roleType == 'Warehouse'}
                                />

                                <WmsRadio
                                    id="customerRole"
                                    name="roleType"
                                    label={this.translate('Role_Label_Customer')}
                                    wmsSelector={this.state.Selector + 'customer'}
                                    onChangeFunc={(e) => this.handleRoleTypeChange('Customer')}
                                    value={this.state.roleType}
                                    checked={this.state.roleType == 'Customer'}
                                />
                            </div>
                        )}

                </WmsContainer>
                <div id="loadingContent" className={this.state.firstTime ? 'firstTime' : ''}>
                    {this.state.defaultTreeData != null &&
                        <div className="wms-treeview-content">
                            <WmsContainer
                                areYouSureTitle={this.translate('Label_AreYouSure_Title')} title={this.translate('Permissions_RolePermissionsGrid_Permisisons')} showWarningText={
                                    (
                                        this.state.roleType == 'Customer' || (this.props.isRoleForCopy == false && this.props.sharedSelectRoleData.Scope == 1)) ?
                                        this.translate('Note_For_Customer_Role') :
                                        ''}>
                                <div className="wms-permission-wrap">
                                <WmsCheckBox
                                    id={'checkbox'}
                                    name={'checkbox'}
                                    wmsSelector={'selectAllRoles'}
                                    onChangeFunc={this.eventClickSelectAllRole}
                                    label={this.translate('RoleManager_Grid_SelectAll')}
                                    value={this.state.roleSelectAllCheckbox}
                                    checked={this.state.roleSelectAllCheckbox == true} />


                                <WmsButton
                                    label={this.state.showExpandAll == true ?
                                        this.translate('RoleManager_Label_ExpandAll') :
                                        this.translate('RoleManager_Label_CollapseAll')
                                    }
                                    wmsSelector={'expandCollapseButton'}
                                    onClickFunc={this.eventClickExpandCollapse}
                                    icon={this.state.showExpandAll == true ? 'fa-plus' : 'fa-minus'} />

                                <WmsButton
                                    label={this.translate('RoleManager_Label_Reset')}
                                    wmsSelector={'resetButton'}
                                    onClickFunc={this.eventClickResetTree}
                                    icon='fa-repeat' />

                                <WmsInput
                                    id='filter'
                                    inputName='filter'
                                    wmsSelector={'filter'}
                                    onChangeFunc={this.handlefilterTreeChange}
                                    label={this.translate('Rolemanager_Label_Filter')}
                                    value={this.state.filterTreevalue} />
                                </div>

                                <div className={((this.state.roleType == 'Customer' ||
                                    (this.props.isRoleForCopy == false && this.props.sharedSelectRoleData.Scope == 1)) ? 'wms-customer-role-tree' : '') + ' wms_tree_role_wrapper'}>
                                    <div id="TreeRole" data-wms-selector={this.state.Selector + 'TreeData'} className="wms_tree_role"></div>
                                    <div className="wms_permissions_desc">
                                        <label className="wms-section-title">{this.translate('RoleManager_Label_Description')}</label>
                                        <div className="rollhovertext wms-section-body"> {this.state.rollHoverText} </div>
                                    </div>
                                </div>
                            </WmsContainer>
                        </div>
                    }
                </div>
            </WmsCollapse>
        )
    }
}
