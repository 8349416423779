import React from "react";
import WmsCollapse from "../components/WmsCollapse/WmsCollapse.jsx";
import WmsCheckBox from "../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsButton from "../components/WmsButton/WmsButton.jsx";
import WmsInput from "../components/WmsInput/WmsInput.jsx";
import WmsDropdown from "../components/WmsDropdown/WmsDropdown.jsx";
import * as GlobalService from "./../global_services";
import * as threeplSettingServices from './threeplSettings_services.jsx';

import style from "./threepl-settings.scss";

export class ThreeplSettingsHolder extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.state = {
            selector: 'adminThreepl',
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            threeplSettings: {},
            hyperLinkForSettings: {
                "Mobile_Scanning": 'https://3plcentral.com/3pl-warehouse-manager-platform-support-ss-sg1/',
                "SmartParcel": 'https://3plcentral.com/3pl-warehouse-manager-platform-support-spl-sg3/',
                "EDI": 'https://3plcentral.com/3pl-warehouse-manager-platform-support-edi-sg5/',
                "SmartPack": 'https://3plcentral.com/3pl-warehouse-manager-platform-support-spk-sg2/',
                "Manage_Connections": 'https://3plcentral.com/3pl-warehouse-manager-platform-support-api-sg4/'
            },
            editableOptions: [],
            isDisabledSaveBtn: true,
            replyToAddressValidate: true,
            replyToAddressValidateToolTip: '',
            savingGif: false,
            smartParceloptions: [
                { name: 'Select one...', value: '' },
                { name: 'Abol', value: 'Abol' },
                { name: 'ShipEngine', value: 'ShipEngine' },
            ],
            quickBooksOptions: [
                { name: 'Off', value: 0 },
                { name: 'Desktop', value: 1 },
                { name: 'Online', value: 2 }
            ]
        };

    }

    componentDidMount() {
        kendo.ui.progress($('#' + this.state.windowId), true);

        threeplSettingServices.getThreePlOptions().then((response) => {
            var tmpEditableOptions = [];
            if (response.data.UserInterface && response.data.UserInterface.EditableOptions) {
                tmpEditableOptions = response.data.UserInterface.EditableOptions.split(',');
            }

            this.setState({
                threeplSettings: response.data,
                editableOptions: tmpEditableOptions
            });

            $(".parent-settings-tooltip").kendoTooltip({
                filter: "label",
                showAfter: 500,
                width: 240,
                position: "top",
                animation: {
                    open: {
                        effects: "zoom",
                        duration: 150
                    }
                }
            }).data("kendoTooltip");
            $(".from-email-address").kendoTooltip({
                showAfter: 800,
                width: 240,
                position: "bottom",
                content: (e) => {
                    return '<p class="model-tooltip-title-p" data-wms-selector="ReplyToAddressTooltipContent">' + this.translate('Label_Reply_To_Email_Address_Tooltip') + '</p>';
                },
                animation: {
                    open: {
                        effects: "zoom",
                        duration: 150
                    }
                }
            }).data("kendoTooltip");
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });

    }

    handleSettingsChange = (event) => {

        var name = event.target.name;
        var parentObj = name.substr(0, name.indexOf('.'));
        var property = name.substr(name.indexOf('.') + 1);

        var optionsObj = Object.assign({}, this.state.threeplSettings);
        if (name == 'Integrations.QbEnabled') {
            if (optionsObj[parentObj][property] == 0) {
                optionsObj[parentObj][property] = 1; //set desktop option if Quickbook option previous unchecked
            } else {
                optionsObj[parentObj][property] = 0; //set  Integrations.QbEnabled  = 0  value if  on Quickbook option is unchecked
            }
            optionsObj[parentObj][property] = optionsObj[parentObj][property] == 0 ? 0 : optionsObj[parentObj][property];
        }
        else {
            optionsObj[parentObj][property] = !optionsObj[parentObj][property];

            if (name == 'Shipping.SmallParcelShipEngineHighVolumeBatches') {
                optionsObj['Shipping']['SmallParcelHighVolumeProcessing'] = optionsObj[parentObj][property];
            }
        }

        this.setState({ threeplSettings: optionsObj, isDisabledSaveBtn: false });

    }
    handleOptionsChange = (event) => {

        var name = event.target.name;
        var parentObj = name.substr(0, name.indexOf('.'));
        var property = name.substr(name.indexOf('.') + 1);
        var replyToAddressValidate = this.state.replyToAddressValidate;
        var replyToAddressValidateToolTip = this.state.replyToAddressValidateToolTip;

        var optionsObj = Object.assign({}, this.state.threeplSettings);
        optionsObj[parentObj][property] = event.target.value;

        if (property == 'ReplyToAddress') {
            var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            replyToAddressValidate = true;
            replyToAddressValidateToolTip = this.translate('Label_Reply_Email_Validation_Note');

            if (optionsObj.Email.ReplyToAddress) {
                replyToAddressValidate = emailRegex.test(String(optionsObj[parentObj][property]).toLowerCase());
            }
            if (replyToAddressValidate && (optionsObj.Email.ReplyToAddress.indexOf("3plcentral.com") !== -1 || optionsObj.Email.ReplyToAddress.indexOf("secure-wms.com") !== -1)) {
                replyToAddressValidate = false;
                replyToAddressValidateToolTip = this.translate('Label_Reply_Email_Not_Allow');
            }
        }

        this.setState({
            threeplSettings: optionsObj,
            isDisabledSaveBtn: false,
            replyToAddressValidate: replyToAddressValidate,
            replyToAddressValidateToolTip: replyToAddressValidateToolTip
        });

    }

    saveTplSettings = () => {

        this.setState({ savingGif: true });

        var optionsObj = _.cloneDeep(this.state.threeplSettings);
        delete optionsObj.UserInterface.EditableOptions;
        delete optionsObj.OtherUserFeatures.MobileEnabled;
        delete optionsObj.Shipping.SmallParcelEnabled;
        delete optionsObj.Shipping.WarehousePackEnabled;

        threeplSettingServices.saveThreePlOptions(optionsObj).then((response) => {
            GlobalService.notification('success', this.translate('Label_Setting_Update_Success'));

            GlobalService.setTplSettings(response.data)
            GlobalService.addRightsBaseOnTplSettings(response.data);

            setTimeout(() => {
                var eventItem = new CustomEvent("UpdateRightsForMenuList");
                document.dispatchEvent(eventItem);
                GlobalService.closeWindow(this.state.windowId);

            }, 100);

        }).finally(() => {
            this.setState({ savingGif: false });
        });
    }

    onClickExitWindow = () => {
        GlobalService.closeWindow(this.state.windowId);
    }

    render() {
        return (
            <div className="threepl-settings-wrapper">

                <div className="button-wrapper">
                    <WmsButton
                        label={this.translate("Label_Save")}
                        wmsSelector={"threeplSettingsSave"}
                        onClickFunc={this.saveTplSettings}
                        icon="fa-floppy-o"
                        loading={this.state.savingGif}
                        showToolTip={true}
                        tooltipText={
                            this.state.replyToAddressValidate == false ?
                                this.state.replyToAddressValidateToolTip :
                                this.translate('Tooltip_No_changes')
                        }
                        disabled={this.state.isDisabledSaveBtn || this.state.replyToAddressValidate == false} />

                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + 'CancelButton'}
                        onClickFunc={this.onClickExitWindow}
                        icon='fa-ban'
                        buttonType="orange" />
                </div>
                <WmsCollapse
                    id={'threeplSettings'}
                    headerLabel={this.translate('Label_Threepl_Settings_Standard_Header')}
                    showCollapseText={false}
                    isShawHeaderInfoIcon={true}
                    headerInfoTooltipText={this.translate('Label_Threepl_Settings_Standard_Header_Tooltip')}
                    showCollapseIcon={true}>
                    {/* Allocated Stock Move */}
                    <WmsCheckBox
                        id="allocatedStockMoveChk"
                        name="OtherUserFeatures.AllowAllocatedStockMoveEnabled"
                        wmsSelector={this.state.selector + "allocatedStockMoveChk"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Allocated_Stock_Move')}
                        checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.AllowAllocatedStockMoveEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("OtherUserFeatures.AllowAllocatedStockMoveEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("OtherUserFeatures.AllowAllocatedStockMoveEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("OtherUserFeatures.AllowAllocatedStockMoveEnabled") == -1) ?
                                this.translate('Label_Allocated_Stock_Move_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Allocated_Stock_Move_Tooltip')}
                    />

                    {/* Attach Files */}
                    <WmsCheckBox
                        id="AttachedDocsEnabled"
                        name="Shipping.AttachedDocsEnabled"
                        wmsSelector={this.state.selector + "AttachedDocsEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Attach_Files')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.AttachedDocsEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.AttachedDocsEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Shipping.AttachedDocsEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.AttachedDocsEnabled") == -1) ?
                                this.translate('Label_Threepl_Settings_Attach_File_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Threepl_Settings_Attach_File_Tooltip')}
                    />

                    {/* Attach Files to Customer Notifies */}
                    <WmsCheckBox
                        id="AttachedDocsEmailEnabled"
                        name="Email.AttachedDocsEmailEnabled"
                        wmsSelector={this.state.selector + "AttachedDocsEmailEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Attach_Files_to_Customer_Notifies')}
                        checked={this.state.threeplSettings.Email ? this.state.threeplSettings.Email.AttachedDocsEmailEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Email.AttachedDocsEmailEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Email.AttachedDocsEmailEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Email.AttachedDocsEmailEnabled") == -1) ?
                                this.translate('Label_Attach_Files_to_Customer_Notifies_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Attach_Files_to_Customer_Notifies_Tooltip')}
                    />
                    {/* Auto Reallocate On Receive */}
                    <WmsCheckBox
                        id="AutoReallocateOnReceive"
                        name="Receiving.AutoReallocOnReceiveEnabled"
                        wmsSelector={this.state.selector + "AutoReallocateOnReceive"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Auto_Reallocate_On_Receive')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.AutoReallocOnReceiveEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.AutoReallocOnReceiveEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.AutoReallocOnReceiveEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.AutoReallocOnReceiveEnabled") == -1) ?
                                this.translate('Label_Auto_Reallocate_On_Receive_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Auto_Reallocate_On_Receive_Tooltip')}
                    />


                    {/* Auto Batch*/}
                    <WmsCheckBox
                        id="Auto_Batch"
                        name="Shipping.AutoBatchEnabled"
                        wmsSelector={this.state.selector + "Auto_Batch"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Setting_Auto_Batch')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.AutoBatchEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.AutoBatchEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.AutoBatchEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.AutoBatchEnabled") == -1) ?
                                this.translate('Label_Setting_Auto_Batch_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Setting_Auto_Batch_Tooltip')}
                    />

                    {/* Automatic Billing Scripts */}
                    <WmsCheckBox
                        id="AutoBillEnabled"
                        name="Shipping.AutoBillEnabled"
                        wmsSelector={this.state.selector + "AutoBillEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Automatic_Billing_Scripts')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.AutoBillEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.AutoBillEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Shipping.AutoBillEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.AutoBillEnabled") == -1) ?
                                this.translate('Label_Automatic_Billing_Scripts_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Automatic_Billing_Scripts_Tooltip')}
                    />

                    {/* Bill-To Contacts */}
                    <WmsCheckBox
                        id="BillToEnabled"
                        name="Shipping.BillToEnabled"
                        wmsSelector={this.state.selector + "BillToEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Bill_To_Contacts')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.BillToEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.BillToEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Shipping.BillToEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.BillToEnabled") == -1) ?
                                this.translate('Label_Bill_To_Contacts_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Bill_To_Contacts_Tooltip')}
                    />

                    {/* Close Orders Upon Tracking Update */}
                    <WmsCheckBox
                        id="SpsCloseOnUpdate"
                        name="Receiving.SpsCloseOnUpdate"
                        wmsSelector={this.state.selector + "SpsCloseOnUpdate"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_SmartParcel_Service_Close_on_Update')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.SpsCloseOnUpdate : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.SpsCloseOnUpdate") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Receiving.SpsCloseOnUpdate") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.SpsCloseOnUpdate") == -1) ?
                                this.translate('Label_SmartParcel_Service_Close_on_Update_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_SmartParcel_Service_Close_on_Update_Tooltip')}
                    />

                    {/* Consolidate ASN */}
                    <WmsCheckBox
                        id="consolidateAsn"
                        name="Receiving.ConsolidateAsnEnabled"
                        wmsSelector={this.state.selector + "consolidateAsn"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Consolidate_ASN')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.ConsolidateAsnEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.ConsolidateAsnEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.ConsolidateAsnEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.ConsolidateAsnEnabled") == -1) ?
                                this.translate('Label_Consolidate_ASN_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Consolidate_ASN_Tooltip')}
                    />

                    {/* Saved Elements */}
                    <WmsCheckBox
                        id="SavedElements"
                        name="OtherUserFeatures.SavedElementsEnabled"
                        wmsSelector={this.state.selector + "SavedElements"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_custom_fields')}
                        checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.SavedElementsEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("OtherUserFeatures.SavedElementsEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("OtherUserFeatures.SavedElementsEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("OtherUserFeatures.SavedElementsEnabled") == -1) ?
                                this.translate('Label_custom_fields_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_custom_fields_Tooltip')}
                    />

                    {/* Directed Putaway */}
                    <WmsCheckBox
                        id="DirectedPutAwayEnabled"
                        name="Receiving.DirectedPutAwayEnabled"
                        wmsSelector={this.state.selector + "DirectedPutAwayEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Directed_Putaway')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.DirectedPutAwayEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.DirectedPutAwayEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Receiving.DirectedPutAwayEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.DirectedPutAwayEnabled") == -1) ?
                                this.translate('Label_Threepl_Settings_Directed_Putaway_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Threepl_Settings_Directed_Putaway_Tooltip')}
                    />

                    {/* Track-By's Edit*/}
                    <WmsCheckBox
                        id="Track_Bys_Edit"
                        name="Receiving.TrackBysEditEnabled"
                        wmsSelector={this.state.selector + "Track_Bys_Edit"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Track_Bys_Edit')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.TrackBysEditEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.TrackBysEditEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.TrackBysEditEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.TrackBysEditEnabled") == -1) ?
                                this.translate('Label_Track_Bys_Edit_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Track_Bys_Edit_Tooltip')}
                    />

                    {/* Fulfillment Invoicing */}
                    <WmsCheckBox
                        id="fulfillmentInvoicing"
                        name="Shipping.FulfillInvEnabled"
                        wmsSelector={this.state.selector + "fulfillmentInvoicing"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Fulfillment_Invoicing')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.FulfillInvEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.FulfillInvEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.FulfillInvEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.FulfillInvEnabled") == -1) ?
                                this.translate('Label_Fulfillment_Invoicing_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Fulfillment_Invoicing_Tooltip')}
                    />
                    {/* HazMat */}
                    <WmsCheckBox
                        id="HazMatEnabled"
                        name="Items.HazMatEnabled"
                        wmsSelector={this.state.selector + "HazMatEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_HazMat')}
                        checked={this.state.threeplSettings.Items ? this.state.threeplSettings.Items.HazMatEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Items.HazMatEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Items.HazMatEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Items.HazMatEnabled") == -1) ?
                                this.translate('Label_HazMat_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_HazMat_Tooltip')}
                    />

                    {/* SKU Alias */}
                    <WmsCheckBox
                        id="SKUAlias"
                        name="Items.SkuAliasEnabled"
                        wmsSelector={this.state.selector + "SKUAlias"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Item_Alias')}
                        checked={this.state.threeplSettings.Items ? this.state.threeplSettings.Items.SkuAliasEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Items.SkuAliasEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Items.SkuAliasEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Items.SkuAliasEnabled") == -1) ?
                                this.translate('Label_Item_Alias_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Item_Alias_Tooltip')}
                    />

                    {/* Item Classification */}
                    <WmsCheckBox
                        id="ItemClassification"
                        name="Items.ItemClassificationEnabled"
                        wmsSelector={this.state.selector + "ItemClassification"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Item_Classification')}
                        checked={this.state.threeplSettings.Items ? this.state.threeplSettings.Items.ItemClassificationEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Items.ItemClassificationEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Items.ItemClassificationEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Items.ItemClassificationEnabled") == -1) ?
                                this.translate('Label_Item_Classification_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Item_Classification_Tooltip')}
                    />

                    {/* Item Images */}
                    <WmsCheckBox
                        id="ItemImagesEnabled"
                        name="Items.ItemImagesEnabled"
                        wmsSelector={this.state.selector + "ItemImagesEnabled"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Item_Images')}
                        checked={this.state.threeplSettings.Items ? this.state.threeplSettings.Items.ItemImagesEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Items.ItemImagesEnabled") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Items.ItemImagesEnabled") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Items.ItemImagesEnabled") == -1) ?
                                this.translate('Label_Item_Images_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Item_Images_Tooltip')}
                    />

                    {/* Movable Units */}
                    <WmsCheckBox
                        id="MovableUnits"
                        name="Receiving.PalletsEnabled"
                        wmsSelector={this.state.selector + "MovableUnits"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Movable_Units')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.PalletsEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.PalletsEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.PalletsEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.PalletsEnabled") == -1) ?
                                this.translate('Label_Movable_Units_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Movable_Units_Tooltip')}
                    />


                    {/* Order Queue */}
                    <WmsCheckBox
                        id="OrderQueue"
                        name="Shipping.AllowOrderQueue"
                        wmsSelector={this.state.selector + "OrderQueue"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Order_Queue')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.AllowOrderQueue : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.AllowOrderQueue") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.AllowOrderQueue") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.AllowOrderQueue") == -1) ?
                                this.translate('Label_Order_Queue_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Order_Queue_Tooltip')}
                    />

                    {/* Outbound Mobile Serialization */}
                    <WmsCheckBox
                        id="OutboundMobileSerialization"
                        name="Shipping.OutboundMobileSerializationEnabled"
                        wmsSelector={this.state.selector + "OutboundMobileSerialization"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Outbound_Serialization')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.OutboundMobileSerializationEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.OutboundMobileSerializationEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.OutboundMobileSerializationEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.OutboundMobileSerializationEnabled") == -1) ?
                                this.translate('Label_Outbound_Serialization_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Outbound_Serialization_Tooltip')}
                    />
                    {/* Overallocated Item Alias Validation */}
                    <WmsCheckBox
                        id="AliasOverAllocateCheckOnImport"
                        name="Shipping.AliasOverAllocateCheckOnImport"
                        wmsSelector={this.state.selector + "AliasOverAllocateCheckOnImport"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Overallocated_Item_Alias_Validation')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.AliasOverAllocateCheckOnImport : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.AliasOverAllocateCheckOnImport") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Shipping.AliasOverAllocateCheckOnImport") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.AliasOverAllocateCheckOnImport") == -1) ?
                                this.translate('Label_Overallocated_Item_Alias_Validation_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Overallocated_Item_Alias_Validation_Tooltip')}
                    />


                    {/* Over allocated check create order (External) */}
                    <WmsCheckBox
                        id="ExternalSoapOverAllocatedCheckOnCreateOrder"
                        name="Shipping.ExternalSoapOverAllocatedCheckOnCreateOrder"
                        wmsSelector={this.state.selector + "ExternalSoapOverAllocatedCheckOnCreateOrder"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Over_allocated_check_create_order_External')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.ExternalSoapOverAllocatedCheckOnCreateOrder : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.ExternalSoapOverAllocatedCheckOnCreateOrder") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("Shipping.ExternalSoapOverAllocatedCheckOnCreateOrder") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.ExternalSoapOverAllocatedCheckOnCreateOrder") == -1) ?
                                this.translate('Label_Over_allocated_check_create_order_External_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Over_allocated_check_create_order_External_Tooltip')}
                    />
                    {/* Pricing */}
                    <WmsCheckBox
                        id="Pricing"
                        name="Items.PricingEnabled"
                        wmsSelector={this.state.selector + "Pricing"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Pricing')}
                        checked={this.state.threeplSettings.Items ? this.state.threeplSettings.Items.PricingEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Items.PricingEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Items.PricingEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Items.PricingEnabled") == -1) ?
                                this.translate('Label_Pricing_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Pricing_Tooltip')}
                    />


                    {/* Printing of Over Allocated Pick Tickets */}
                    <WmsCheckBox
                        id="PrintingofOverAllocatedPickTickets"
                        name="Shipping.PrintOverAllocPickTickets"
                        wmsSelector={this.state.selector + "PrintingofOverAllocatedPickTickets"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Printing_of_Over_Allocated_Pick_Tickets')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.PrintOverAllocPickTickets : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.PrintOverAllocPickTickets") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.PrintOverAllocPickTickets") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.PrintOverAllocPickTickets") == -1) ?
                                this.translate('Label_Printing_of_Over_Allocated_Pick_Tickets_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Printing_of_Over_Allocated_Pick_Tickets_Tooltip')}
                    />


                    {/* Purchase Orders */}
                    <WmsCheckBox
                        id="PurchaseOrders"
                        name="Receiving.AllowPurchaseOrders"
                        wmsSelector={this.state.selector + "PurchaseOrders"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Purchase_Orders')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.AllowPurchaseOrders : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.AllowPurchaseOrders") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.AllowPurchaseOrders") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.AllowPurchaseOrders") == -1) ?
                                this.translate('Label_Purchase_Orders_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Purchase_Orders_Tooltip')}
                    />


                    {/* Semi-Automatic Monthly Billing */}
                    {/* <WmsCheckBox
                        id="SemiAutoMonthlyBillingEnable"
                        name="OtherUserFeatures.SemiAutoMonthlyBillingEnable"
                        wmsSelector={this.state.selector + "SemiAutoMonthlyBillingEnable"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Semi_Automatic_Monthly_Billing')}
                        checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.SemiAutoMonthlyBillingEnable : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("OtherUserFeatures.SemiAutoMonthlyBillingEnable") == -1}
                        parentClassName={(this.state.editableOptions.indexOf("OtherUserFeatures.SemiAutoMonthlyBillingEnable") == -1 ? 'disabled-chk' : '')}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("OtherUserFeatures.SemiAutoMonthlyBillingEnable") == -1) ?
                                this.translate('Label_Semi_Automatic_Monthly_Billing_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Semi_Automatic_Monthly_Billing_Tooltip')}
                    /> */}


                    {/* Short Ship */}
                    <WmsCheckBox
                        id="ShortShip"
                        name="Shipping.ShortShipEnabled"
                        wmsSelector={this.state.selector + "ShortShip"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Short_Ship')}
                        checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.ShortShipEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Shipping.ShortShipEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Shipping.ShortShipEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Shipping.ShortShipEnabled") == -1) ?
                                this.translate('Label_Short_Ship_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Short_Ship_Tooltip')}
                    />


                    {/* Show Handling Charges with Zero Amounts */}
                    <WmsCheckBox
                        id="ShowHandlingChargeswithZeroAmounts"
                        name="OtherUserFeatures.ShowZeroHandlingCharges"
                        wmsSelector={this.state.selector + "ShowHandlingChargeswithZeroAmounts"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Show_Handling_Charges_with_Zero_Amounts')}
                        checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.ShowZeroHandlingCharges : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("OtherUserFeatures.ShowZeroHandlingCharges") == -1}
                        parentClassName={this.state.editableOptions.indexOf("OtherUserFeatures.ShowZeroHandlingCharges") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("OtherUserFeatures.ShowZeroHandlingCharges") == -1) ?
                                this.translate('Label_Show_Handling_Charges_with_Zero_Amounts_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Show_Handling_Charges_with_Zero_Amounts_Tooltip')}
                    />


                    {/* Suggested Put-Away */}
                    <WmsCheckBox
                        id="SuggestedPut-Away"
                        name="Receiving.SuggestedPutAwayEnabled"
                        wmsSelector={this.state.selector + "SuggestedPut-Away"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Suggested_Put_Away')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.SuggestedPutAwayEnabled : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.SuggestedPutAwayEnabled") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.SuggestedPutAwayEnabled") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.SuggestedPutAwayEnabled") == -1) ?
                                this.translate('Label_Suggested_Put_Away_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Suggested_Put_Away_Tooltip')}
                    />

                    {/* Unconfirm Receivers*/}
                    <WmsCheckBox
                        id="UnconfirmReceivers"
                        name="Receiving.AllowUnconfirmReceiver"
                        wmsSelector={this.state.selector + "UnconfirmReceivers"}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Unconfirm_Receivers')}
                        checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.AllowUnconfirmReceiver : false}
                        value=""
                        disabled={this.state.editableOptions.indexOf("Receiving.AllowUnconfirmReceiver") == -1}
                        parentClassName={this.state.editableOptions.indexOf("Receiving.AllowUnconfirmReceiver") == -1 ? 'disabled-chk' : ''}
                        showToolTip={true}
                        tooltipText={
                            (this.state.editableOptions.indexOf("Receiving.AllowUnconfirmReceiver") == -1) ?
                                this.translate('Label_Unconfirm_Receivers_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                this.translate('Label_Unconfirm_Receivers_Tooltip')}
                    />

                    {/* Zone Billing */}
                    {/* <WmsCheckBox
                            id="Zone_Billing"
                            name="Shipping.ZoneBillingEnabled"
                            wmsSelector={this.state.selector + "Zone_Billing" }
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_Zone_Billing')}
                            checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.ZoneBillingEnabled : false}
                            value=""
                            disabled={this.state.editableOptions.indexOf("Shipping.ZoneBillingEnabled") == -1}
                            parentClassName={this.state.editableOptions.indexOf("Shipping.ZoneBillingEnabled") == -1 ? 'disabled-chk' : ''}
                            showToolTip={true}
                            tooltipText={
                                (this.state.editableOptions.indexOf("Shipping.ZoneBillingEnabled") == -1) ?
                                    this.translate('Label_Zone_Billing_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip'):
                                    this.translate('Label_Zone_Billing_Tooltip')  }
                        />                      */}


                    {/* QuickBooks */}

                    <div className="parent-child-content quick-box-parent-child">

                        <div className="chk-dropdown-wrapper">
                            <div className={"parent-settings-wrapper parent-settings-tooltip " + ((this.state.editableOptions.indexOf("Integrations.QbEnabled") == -1) ? 'disabled-section' : '')}>
                                <label
                                    className={"parent-settings-label"}
                                    title={
                                        ((this.state.editableOptions.indexOf("Integrations.QbEnabled") == -1) ?
                                            this.translate('Label_QuickBooks_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                            this.translate('Label_QuickBooks_Tooltip'))
                                    }>{this.translate('Label_QuickBooks')}</label>
                            </div>
                            <WmsDropdown
                                id="quickBooksOptions"
                                name="Integrations.QbEnabled"
                                label={''}
                                wmsSelector={this.state.selector + "Integrations.QbEnabled"}
                                onChangeFunc={this.handleOptionsChange}
                                value={this.state.threeplSettings.Integrations ? this.state.threeplSettings.Integrations.QbEnabled : ''}
                                options={this.state.quickBooksOptions}
                                valueField='value'
                                disabled={(this.state.editableOptions.indexOf("Integrations.QbEnabled") == -1)}
                                textField='name' />
                        </div>

                        {/* QuickBooks Online Canadian Tax child of QuickBooks*/}
                        <WmsCheckBox
                            id="QuickBooksOnlineCanadianTax"
                            name="Integrations.QbOnlineCanadianTax"
                            wmsSelector={this.state.selector + "QuickBooksOnlineCanadianTax"}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate('Label_QuickBooks_Online_Canadian_Tax')}
                            checked={this.state.threeplSettings.Integrations ? this.state.threeplSettings.Integrations.QbOnlineCanadianTax : false}
                            value=""
                            disabled={(this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.QbEnabled > 0) && (this.state.editableOptions.indexOf("Integrations.QbEnabled") != -1) ? false : true}
                            parentClassName={(this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.QbEnabled > 0) && (this.state.editableOptions.indexOf("Integrations.QbEnabled")) != -1 ? '' : 'disabled-chk'}
                            showToolTip={true}
                            tooltipText={
                                (this.state.editableOptions.indexOf("Integrations.QbEnabled") == -1) ?
                                    this.translate('Label_QuickBooks_Online_Canadian_Tax_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip') :
                                    this.translate('Label_QuickBooks_Online_Canadian_Tax_Tooltip')}
                        />
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'threeplSettingsAddOnPriorityHeaders'}
                    headerLabel={this.translate('Label_Threepl_Settings_Premium_Header')}
                    showCollapseText={false}
                    isShawHeaderInfoIcon={true}
                    headerInfoTooltipText={this.translate('Label_Threepl_Settings_Premium_Header_Tooltip')}
                    showCollapseIcon={true}>
                    <div className="add-on-priority-wrapper">
                        {/* EDI */}
                        <div className="connection-edit-combination">
                            <div className="parent-child-content">
                                <div className={"parent-settings-wrapper" + ((this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.EdiEnabled) ? '' : 'disabled-section')}>
                                    <label
                                        className={"parent-settings-label"}>{this.translate('Label_Stock_Status_Report')}</label>
                                </div>
                                {(this.state.hyperLinkForSettings['EDI'] && this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.EdiEnabled == false) && (
                                    <a href={this.state.hyperLinkForSettings['EDI']} target="_blank" className="learnmore-settings">{this.translate('Label_Learn_More')}</a>
                                )}

                                {/* Stock Status Export child of EDI */}
                                <WmsCheckBox
                                    id="StockStatusExportEnabled"
                                    name="OtherUserFeatures.StockStatusExportEnabled"
                                    wmsSelector={this.state.selector + "StockStatusExportEnabled"}
                                    onChangeFunc={this.handleSettingsChange}
                                    label={this.translate('Label_Stock_Status_Export')}
                                    checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.StockStatusExportEnabled : false}
                                    value=""
                                    disabled={(this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.EdiEnabled) ? false : true}
                                    parentClassName={(this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.EdiEnabled) ? '' : 'disabled-chk'}
                                    showToolTip={true}
                                    tooltipText={
                                        (this.state.threeplSettings.Integrations && this.state.threeplSettings.Integrations.EdiEnabled) ?
                                            this.translate('Label_Stock_Status_Export_Tooltip') :
                                            this.translate('Label_Stock_Status_Export_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')}
                                />
                            </div>
                            <div className="parent-child-content manage-connection">
                                {/* Can be revert if we founf lern more button should active/visible */}
                                {/* <div className={"parent-settings-wrapper parent-settings-tooltip" + ((this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled) ? '' : ' disabled-section')}>
                                    <label
                                        className={"parent-settings-label"}
                                        title={
                                            (this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled) ?
                                                this.translate('Label_connections_Tooltip') :
                                                this.translate('Label_connections_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                        }>{this.translate('Label_Manage_Connections')}</label>
                                </div>
                                {(this.state.hyperLinkForSettings['Manage_Connections'] && this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled == false) && (
                                    <a href={this.state.hyperLinkForSettings['Manage_Connections']} target="_blank" className="learnmore-settings">{this.translate('Label_Learn_More')}</a>
                                )} */}

                                {/* Periodic Stock Status Export child of Manage Connection */}
                                <WmsCheckBox
                                    id="PeriodicReportExportEnabled"
                                    name="OtherUserFeatures.PeriodicReportExportEnabled"
                                    wmsSelector={this.state.selector + "PeriodicReportExportEnabled"}
                                    onChangeFunc={this.handleSettingsChange}
                                    label={this.translate('Label_Periodic_Stock_Status_Export')}
                                    checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.PeriodicReportExportEnabled : false}
                                    value=""
                                    disabled={(this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled) ? false : true}
                                    parentClassName={(this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled) ? '' : 'disabled-chk'}
                                    showToolTip={true}
                                    tooltipText={
                                        (this.state.threeplSettings.UserInterface && this.state.threeplSettings.UserInterface.ConnectionsEnabled) ?
                                            this.translate('Label_Periodic_Stock_Status_Export_Tooltip') :
                                            this.translate('Label_Periodic_Stock_Status_Export_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')}
                                />
                            </div>
                        </div>
                        <div className="parent-child-content">
                            <div className={"parent-settings-wrapper"}>
                                <label
                                    className={"parent-settings-label"}>{this.translate('Label_General_Notifications')}</label>
                            </div>
                            {/* Stock Status Export child of EDI */}
                            <WmsCheckBox
                                id="weebhooks"
                                name="weebhooks"
                                wmsSelector={this.state.selector + "weebhooks"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_General_Webhooks')}
                                checked={this.state.threeplSettings.Integrations ? this.state.threeplSettings.Integrations.WebhooksEnabled : false}
                                value=""
                                disabled={true}
                                parentClassName={'disabled-chk'}
                                showToolTip={true}
                                tooltipText={this.translate('Label_Webhooks_Tooltip')}
                            />
                        </div>
                        <div className="parent-child-content">
                            <div className={"parent-settings-wrapper parent-settings-tooltip" + ((this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ? '' : ' disabled-section')}>
                                <label
                                    className={"parent-settings-label"}
                                    title={
                                        (this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ?
                                            this.translate('Label_Threepl_Settings_Mobile_Tooltip') :
                                            this.translate('Label_Threepl_Settings_Mobile_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                    } > {this.translate('Label_Threepl_Settings_Mobile')} </label>

                            </div>
                            {(this.state.hyperLinkForSettings['Mobile_Scanning'] && this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled == false) && (
                                <a href={this.state.hyperLinkForSettings['Mobile_Scanning']} target="_blank" className="learnmore-settings">{this.translate('Label_Learn_More')}</a>
                            )}

                            
                            {/* Reallocation During Pick */}
                            <WmsCheckBox
                                id="ReallocationDuringPick"
                                name="OtherUserFeatures.ReallocateAtPickTimeEnabled"
                                wmsSelector={this.state.selector + "ReallocationDuringPick"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_Reallocation_During_Pick')}
                                checked={this.state.threeplSettings.OtherUserFeatures ? this.state.threeplSettings.OtherUserFeatures.ReallocateAtPickTimeEnabled : false}
                                value=""
                                disabled={(this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ? false : true}
                                parentClassName={(this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ? '' : 'disabled-chk'}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ?
                                        this.translate('Label_Reallocation_During_Pick_Tooltip') :
                                        this.translate('Label_Reallocation_During_Pick_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')}
                            />
                            {/* Receive Against ASNs */}
                            <WmsCheckBox
                                id="ReceiveAgainstASNs"
                                name="Receiving.AllowReceiveAgainstAsn"
                                wmsSelector={this.state.selector + "ReceiveAgainstASNs"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_Receive_Against_ASNs')}
                                checked={this.state.threeplSettings.Receiving ? this.state.threeplSettings.Receiving.AllowReceiveAgainstAsn : false}
                                value=""
                                disabled={(this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ? false : true}
                                parentClassName={(this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ? '' : 'disabled-chk'}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.threeplSettings.OtherUserFeatures && this.state.threeplSettings.OtherUserFeatures.MobileEnabled) ?
                                        this.translate('Label_Receive_Against_ASNs_Tooltip') :
                                        this.translate('Label_Receive_Against_ASNs_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')}
                            />
                        </div>
                        <div className="parent-child-content">

                            {/* SmartParcel */}
                            <div className={"parent-settings-wrapper  parent-settings-tooltip" + ((this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? '' : ' disabled-section')} >
                                <label
                                    className={"parent-settings-label"}
                                    title={
                                        (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ?
                                            this.translate('Label_Smart_Parcel_Tooltip') :
                                            this.translate('Label_Smart_Parcel_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                    }>{this.translate('Label_Small_Parcel_Suite')}</label>

                                {/* <WmsDropdown
                                    id="smartParceloptions"
                                    name="Integrations.SmallParcelPartner"
                                    label={''}
                                    wmsSelector={"smartParceloptions"}
                                    onChangeFunc={this.handleOptionsChange}
                                    value={this.state.threeplSettings.Integrations ? this.state.threeplSettings.Integrations.SmallParcelPartner : ''}
                                    options={this.state.smartParceloptions}
                                    valueField='value'
                                    disabled={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? false  : true}
                                    extraWrapperClass={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? ''  : 'disabled-chk'}
                                    textField='name'/> */}
                            </div>
                            {(this.state.hyperLinkForSettings['SmartParcel'] && this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled == false) && (
                                <a href={this.state.hyperLinkForSettings['SmartParcel']} target="_blank" className="learnmore-settings">{this.translate('Label_Learn_More')}</a>
                            )}
                            {/* SmartParcel - High Volume Batches Enabled -  child of smartparcel */}
                            <WmsCheckBox
                                id="SmallParcelShipEngineHighVolumeBatches"
                                name="Shipping.SmallParcelShipEngineHighVolumeBatches"
                                wmsSelector={this.state.selector + "SmallParcelShipEngineHighVolumeBatches"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_SmartParcel_High_Volume_Batches_Enabled')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.SmallParcelShipEngineHighVolumeBatches : false}
                                value=""
                                // disabled={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? false  : true}
                                disabled={true}
                                parentClassName={'disabled-chk'}
                                // parentClassName={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? ''  : 'disabled-chk'}
                                showToolTip={true}
                                // tooltipText={
                                //     (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ?
                                //     this.translate('Label_SmartParcel_High_Volume_Batches_Enabled_Tooltip') :
                                //         this.translate('Label_SmartParcel_High_Volume_Batches_Enabled_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                // }
                                tooltipText={this.translate('Label_SmartParcel_High_Volume_Batches_Enabled_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Default_Disable_Tooltip')}
                            />
                            {/* `SmartParcel - Return Label Enabled` -  child of smartparcel */}
                            <WmsCheckBox
                                id="SmallParcelReturnLabelEnabled"
                                name="Shipping.SmallParcelReturnLabelEnabled"
                                wmsSelector={this.state.selector + "SmallParcelReturnLabelEnabled"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_SmartParcel_Return_Label_Enabled')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.SmallParcelReturnLabelEnabled : false}
                                value=""
                                disabled={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? false : true}
                                parentClassName={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? '' : 'disabled-chk'}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ?
                                        this.translate('Label_SmartParcel_Return_Label_Enabled_Tooltip') :
                                        this.translate('Label_SmartParcel_Return_Label_Enabled_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                }
                            />
                            {/* SmartParcel - Rate Shoping Enabled -  child of smartparcel */}
                            <WmsCheckBox
                                id="SmallParcelRateShoppingEnabled"
                                name="Shipping.SmallParcelRateShoppingEnabled"
                                wmsSelector={this.state.selector + "SmallParcelRateShoppingEnabled"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_SmartParcel_Rate_Shoping_Enabled')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.SmallParcelRateShoppingEnabled : false}
                                value=""
                                disabled={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? false : true}
                                parentClassName={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ? '' : 'disabled-chk'}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.SmallParcelEnabled) ?
                                        this.translate('Label_SmartParcel_Rate_Shoping_Enabled_Tooltip') :
                                        this.translate('Label_SmartParcel_Rate_Shoping_Enabled_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                }
                            />
                             <WmsCheckBox
                                id="SmallParcelIncludeMediaMail"
                                name="Shipping.SmallParcelIncludeMediaMail"
                                wmsSelector={this.state.selector + "SmallParcelIncludeMediaMail"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_SmartParcel_Include_Mail_Rate')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.SmallParcelIncludeMediaMail : false}
                                value=""
                            />

                        </div>
                        <div className="parent-child-content">
                            {/* Smart Pack */}
                            {/* <WmsCheckBox
                                id="WarehousePackEnabled"
                                name="Shipping.WarehousePackEnabled"
                                wmsSelector={this.state.selector + "WarehousePackEnabled" }
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_Threepl_Settings_Smart_Pack')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.WarehousePackEnabled : false}
                                value=""
                                disabled={this.state.editableOptions.indexOf("Shipping.WarehousePackEnabled") == -1}
                                parentClassName={(this.state.editableOptions.indexOf("Shipping.WarehousePackEnabled") == -1 ? 'black_Chk' : '')}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.editableOptions.indexOf("Shipping.WarehousePackEnabled") == -1) ?
                                        this.translate('Label_Threepl_Settings_Smart_Pack_Tooltip') + '<br/><br/>' + this.translate('Label_Setting_Disable_Tooltip'):
                                        this.translate('Label_Threepl_Settings_Smart_Pack_Tooltip')  }
                            />  */}
                            <div className={"parent-settings-wrapper parent-settings-tooltip " + ((this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled) ? '' : ' disabled-section')}>
                                <label
                                    className={"parent-settings-label"}
                                    title={
                                        (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled) ?
                                            this.translate('Label_Threepl_Settings_Smart_Pack_Tooltip') :
                                            this.translate('Label_Threepl_Settings_Smart_Pack_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')
                                    }>{this.translate('Label_Threepl_Settings_Smart_Pack')}</label>
                            </div>
                            {(this.state.hyperLinkForSettings['SmartPack'] && this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled == false) && (
                                <a href={this.state.hyperLinkForSettings['SmartPack']} target="_blank" className="learnmore-settings">{this.translate('Label_Learn_More')}</a>
                            )}

                            {/* SSmart Pack Scale - child of smart Pack */}
                            <WmsCheckBox
                                id="SmartPackScaleEnabled"
                                name="Shipping.SmartPackScaleEnabled"
                                wmsSelector={this.state.selector + "SmartPackScaleEnabled"}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Label_Threepl_Settings_Smart_Pack_Scale')}
                                checked={this.state.threeplSettings.Shipping ? this.state.threeplSettings.Shipping.SmartPackScaleEnabled : false}
                                value=""
                                disabled={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled) ? false : true}
                                parentClassName={(this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled) ? '' : 'disabled-chk'}
                                showToolTip={true}
                                tooltipText={
                                    (this.state.threeplSettings.Shipping && this.state.threeplSettings.Shipping.WarehousePackEnabled) ?
                                        this.translate('Label_Threepl_Settings_Smart_Pack_Scale_Tooltip') :
                                        this.translate('Label_Threepl_Settings_Smart_Pack_Scale_Tooltip') + '<br/><br/>' + this.translate('Label_Threepl_Settings_Premium_Setting_Disable_Tooltip')}
                            />
                        </div>

                    </div>
                </WmsCollapse>
                <WmsCollapse
                    id={'customerNotification'}
                    headerLabel={this.translate('Label_Event_Notifications')}
                    showCollapseText={false}
                    isShawHeaderInfoIcon={true}
                    headerInfoTooltipText={this.translate('Label_Event_Notifications_Tooltip')}
                    showCollapseIcon={true}>
                    <div className="notification-wrapper">
                        <div className="send-from-name-wrapper">
                            <WmsInput
                                id='SenderName'
                                inputName='Email.SenderName'
                                wmsSelector={this.state.Selector + 'SenderName'}
                                onChangeFunc={this.handleOptionsChange}
                                label={this.translate('Label_Sent_From_Name')}
                                value={this.state.threeplSettings.Email ? this.state.threeplSettings.Email.SenderName : ''}
                                extraWrapperClass="sender-name-input" />

                            <WmsInput
                                id='senderNameLbl'
                                inputName='senderNameLbl'
                                wmsSelector={this.state.Selector + 'senderNameLbl'}
                                onChangeFunc={() => { }}
                                label={''}
                                value={'Example: ' + (this.state.threeplSettings.Email ? this.state.threeplSettings.Email.SenderName : '') + ' <notifications@secure-wms.com>'}
                                extraWrapperClass="sender-name-label" />
                        </div>

                        <WmsInput
                            id='ReplyToAddress'
                            inputName='Email.ReplyToAddress'
                            wmsSelector={this.state.Selector + 'ReplyToAddress'}
                            onChangeFunc={this.handleOptionsChange}
                            label={this.translate('Label_Reply_To_Email_Address')}
                            value={this.state.threeplSettings.Email ? this.state.threeplSettings.Email.ReplyToAddress : ''}
                            extraWrapperClass="from-email-address" />
                    </div>
                </WmsCollapse>
            </div>
        );
    }
}
