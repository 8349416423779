import React, { useState, useCallback, useEffect } from "react";

import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import { StringWms } from "../../assets/ts/StringWms";
import * as GlobalService from "../react/global_services";
import * as GridConfig from "./item-alias-grid-configuration";
import PropTypes from "prop-types";
import * as ItemAliasServices from "./item-alias.services";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
import { ArrayWms } from "../../assets/ts/ArrayWms";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import { LoadingModel } from "../react/components/LoadingModel/LoadingModel";
import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox";
import ItemAliasModal from "./item-alias.modal.component";
import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsConfirmModel from "../react/components/WmsConfirmModel/WmsConfirmModel";
import ItemAliasImportModal from "./item-alias-import.modal.component";

import "./itemAlias.scss";
// import _ from "lodash";
var axios = require("axios");
interface gridFilterType {
    customerId: number;
    text: string;
    status: boolean;
}

interface Props {
    wmsSelector: string;
    windowId: string;
    sharedFilterObject: gridFilterType;
}

declare var window: any;
const { $ } = window;

const gridId = "ItemAliasGrid";

const defaultItemAliasData = {
    rowIndex: 0,
    customerId: 0,
    Alias: "",
    Description: "",
    Active: true,
    AliasItems: [],
    EditUri: "",
};
let localKendoGrid = null;

const ItemAliasGrid: React.FC<Props> = (props: Props) => {
    const itemAliasGridHeaders = StringWms.convertSringToOption(window.translate("ItemAlias_GridHeaders"));
    const defaultColumns = GridConfig.getItemAliasColumns(itemAliasGridHeaders).columns;
    const allColumnList = GridConfig.getItemAliasColumns(itemAliasGridHeaders).columns;
    const [schema] = useState(GridConfig.getItemAliasSchema());
    const gridDomSelector = (props.windowId ? `.${props.windowId} ` : "") + "#" + gridId;
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);

    let filterCountAxiosSource = null;
    let firstFiftyAxiosSource = null;
    let axiosSource = null;
    let isApiCanceled = false;

    const [gridConfig, setGridConfig] = useState("");
    const [filterText, setFilterText] = useState("");
    const [fullFilterText, setFullFilterText] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(defaultItemAliasData);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [isOpenColumnModal, setIsOpenColumnModal] = useState(false);
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [isFilterRowCountLoading, setIsFilterRowCountLoading] = useState(false);
    const [showLoadingError, setShowLoadingError] = useState(false);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);
    const [gridPageSize] = useState(GlobalService.constants.virtualGridDataLimit);
    const [overlayScreenShow, setOverlayScreenShow] = useState(false);
    const [totalRequest, setTotalRequest] = useState(0);
    const [showGrid, setShowGrid] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [filterItemAlias, setFilterItemAlias] = useState(props.sharedFilterObject);
    const [isApplyingAlias, setIsApplyingAlias] = useState(false);
    const [isOpenCreateNewItemAliasModal, setIsOpenCreateNewItemAliasModal] = useState(false);
    const [isOpenImportItemAliasModal, setIsOpenImportItemAliasModal] = useState(false);
    const [isOpenDeleteConfirmModel, setIsOpenDeleteConfirmModel] = useState(false);
    const [isOpenCancelConfirmModel, setIsOpenCancelConfirmModel] = useState(false);
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
    const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false);
    const rights = GlobalService.GetRights();

    const handleCancelClick = () => {
        setIsOpenCancelConfirmModel(true);
    };

    const handleConfirmCancel = () => {
        setIsOpenCancelConfirmModel(false);
        onCloseCreateNewItemAliasModal();
    };

    const handleCloseConfirmDialog = () => {
        setIsOpenCancelConfirmModel(false);
    };

    const onselectGridRow = (KendoGrid: any) => {
        localKendoGrid = KendoGrid;
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());

        let selectedTR = grid.select();
        let tempIsDeleteButtonActive = true;
        for (const i in selectedTR) {
            if (!selectedTR.hasOwnProperty(i)) {
                continue;
            }
            const selectedData = grid.dataItem(selectedTR[`${i}`]);
            if (selectedData && selectedData.Active === "Active") {
                tempIsDeleteButtonActive = false;
                break;
            }
        }
        setIsDeleteButtonActive(tempIsDeleteButtonActive);
        if (data) {
            setSelectedRow(data);
            setSelectedRowCount(KendoGrid.getNumberOfSelections());
        } else {
            setSelectedRow(defaultItemAliasData);
            setSelectedRowCount(0);
        }
    };
    const clickEventOfContextMenu = (obj: any, e: any) => {
        if (obj.wmsid === "EditItemAlias") {
            openEditModel();
        } else if (obj.wmsid === "DeleteItemAlias") {
            openDeleteConfirmModel();
        } else if (obj.wmsid === "ActiveItemAlias") {
            updateItemAliasStatus(true);
        } else if (obj.wmsid === "InActiveItemAlias") {
            updateItemAliasStatus(false);
        }
    };

    const onGridDataBound = (e, kendoGrid) => {
        localKendoGrid = kendoGrid;
        var grid = $(gridDomSelector).data("kendoGrid");
        const trData = "tr[data-uid='";
        const gridData = grid.dataSource.view();

        for (const dataItem of gridData) {
            if (dataItem.HasDeactivatedItem) {
                grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-alias-inactive");
                grid.table.find(trData + dataItem.uid + "']").addClass("wms-alias-inactive");
            }
        }

        if (typeof $(gridDomSelector).data("kendoTooltip") == "undefined") {
            $(gridDomSelector)
                .kendoTooltip({
                    filter: "tr.wms-alias-inactive td",
                    position: "bottom",
                    content: (e) => {
                        var dataItem = $(gridDomSelector).data("kendoGrid").dataItem(e.target.closest("tr"));
                        if (typeof dataItem != "undefined") {
                            return "This alias contains inactive items";
                        }
                        return "";
                    },
                })
                .data("kendoTooltip");
        }
    };
    // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter();
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
            ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
            setFilterAppliedText(filter);
        }, 50);
    };
    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText("");
            setFullFilterText("");
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    };
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            gridColumn,
            sortArray,
            grid.dataSource.filter()
        );
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };
    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            switchedArray,
            sortArray,
            grid.dataSource.filter()
        );
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };

    // on grid column resize save changes in meta data object
    const onColumnResize = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };

    // on click grid clear sort menu
    const onClickClearSorts = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };

    const eventClickExportGrid = () => {
        $(gridDomSelector).getKendoGrid().saveAsExcel();
    };

    const getGridFilter = useCallback(() => {
        let GridFilterData = filterItemAlias;

        if (GridFilterData.customerId !== 0) {
            let selectedCustomer = GlobalService.getCustomerById(GridFilterData.customerId);
            if (selectedCustomer == null) {
                GridFilterData.customerId = 0;
            }
        } else {
            resetGridAll();
        }
        return GridFilterData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterItemAlias]);

    const listGrid_dataBind = (data) => {
        const selectedCustomer = CustomerOptions.find(
            (cus: any) => parseInt(cus.id) === parseInt(filterItemAlias.customerId as any)
        );
        const transformedData = data.map((item) => ({
            ...item,
            Active: window.translate(item.Active ? "Label_Active" : "Label_Inactive"),
            CustomerName: selectedCustomer && selectedCustomer.name,
        }));
        setGridData(transformedData);
    };
    // on new filter data clear selection of grid
    const clearSelectionOfGrid = () => {
        setSelectedRowCount(0);
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };

    const refreshGrid = () => {
        clearSelectionOfGrid();
        callForGetTotalCount();
    };

    const onToggleColumnModal = (isOpen) => () => {
        setIsOpenColumnModal(isOpen);
    };

    const saveMetaData = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sort, grid.dataSource.filter());
        ItemAliasServices.setItemAliasGridOptionInMetaData(finalSubmission);
    };

    const onChangeFilter = () => {
        ItemAliasServices.setItemAliasFilterMetaData(filterItemAlias);
        callForGetTotalCount();
    };

    let callForGetTotalCountDebounce = GlobalService.useFCDebounce(onChangeFilter, 1000);

    useEffect(() => {
        setGridConfig(ItemAliasServices.getItemAliasGridOptionInMetaData());
    }, []);
    useEffect(() => {
        if (getGridFilter()) {
            callForGetTotalCountDebounce();
        }
        setShowGrid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGridFilter]);

    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordsCount]);

    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setOverlayScreenShow(false);
        setGridData([]);
        setIsAllDataLoad(false);
    };
    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        var param_filterObj = Object.assign({}, getGridFilter());
        if (param_filterObj.text !== "" && param_filterObj.text.length < 2) {
            return false;
        }
        if (!param_filterObj.customerId || param_filterObj.customerId === 0) {
            return false;
        }

        setRecordsCount(0);
        setIsFilterRowCountLoading(true);
        setShowLoadingError(false);

        if (clearGridSelection) {
            setSelectedRowCount(0);
            setIsAllDataLoad(false);
        }

        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        cancelLoadAllData();
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        ItemAliasServices.setGridFiltere(param_filterObj, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data);
                if (clearData) {
                    setGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsFilterRowCountLoading(false);
            });
    };
    const resetGridAllMessage = () => {
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };
    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(false);
        } else {
            resetGridAllMessage();
        }
    };
    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };
    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj, ...allData];
            });
            listGrid_dataBind(allData);
            setOverlayScreenShow(false);
            setIsGridDataLoading(false);
            setIsAllDataLoad(true);
            setIsNewColumnAdded(false);
        } else {
            setIsGridDataLoading(false);
        }
    };
    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setGridData([]);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        if (!param_filterObj.customerId) {
            return false;
        }

        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Items/ItemAliases",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        setTotalRequest(totalRequest);
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);
        var urlParams = `&pgsiz=${gridPageSize}&customerId=${param_filterObj.customerId}&showInactive=${param_filterObj?.status}&searchString=${param_filterObj.text}`;

        Promise.all(
            sequence(totalRequest).map((i) =>
                api.get("/ItemAliasesData?sorts=[]&filters=[]&pgnum=" + (i + 1) + urlParams)
            )
        )
            .then((responses) => {
                let data = responses.map((r) => r.data);
                getAllResponseData(data);
            })
            .catch((error) => {
                console.log("Promise.all catch", error);
                setShowLoadingError(true);
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };

    const onClickLoadAllRecords = () => {
        setOverlayScreenShow(true);
        loadAllRecords(false);
    };
    const openEditModel = () => {
        ItemAliasServices.getItemAliasDetailRow(selectedRow.EditUri).then((res) => {
            if (res && res.data && res.data.Details) {
                setIsEditMode(true);
                setSelectedRow((ps) => {
                    return {
                        ...ps,
                        Details: res.data.Details,
                        Active: res.data.Active,
                    };
                });
                setIsOpenCreateNewItemAliasModal(true);
            } else {
                GlobalService.notification("error", window.translate("Item_alias_error_on_detail_call"));
            }
        });
    };
    const openDeleteConfirmModel = () => {
        setIsOpenDeleteConfirmModel(true);
    };
    const clickDeleteAliasExit = () => {
        setIsOpenDeleteConfirmModel(false);
    };
    const deleteItemAliass = () => {
        setIsFilterRowCountLoading(true);
        setIsDeleteButtonLoading(true);
        let processedArr = [];
        let grid = $(gridDomSelector).data("kendoGrid");
        if (grid && localKendoGrid) {
            let selectedTR = grid.select();
            for (const i in selectedTR) {
                if (!selectedTR.hasOwnProperty(i)) {
                    continue;
                }
                const selectedData = grid.dataItem(selectedTR[`${i}`]);
                if (selectedData && selectedData.Alias) {
                    const alreadyProcessed = processedArr.findIndex((el) => el.Alias === selectedData.Alias);
                    if (alreadyProcessed === -1 && selectedData.Active !== "active") {
                        processedArr.push(selectedData);
                    }
                }
            }
            ItemAliasServices.deleteItemAliases(filterItemAlias.customerId, processedArr)
                .then(() => {
                    GlobalService.notification("success", window.translate("Delete_Item_Alias_Completed_Successfully"));
                })
                .finally(() => {
                    setIsFilterRowCountLoading(false);
                    setIsDeleteButtonLoading(false);
                    setIsOpenDeleteConfirmModel(false);
                    resetGridAll();
                    refreshGrid();
                    // kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
                });
        } else {
            GlobalService.notification(
                "error",
                "There is some error in item selection, please refresh grid and try again"
            );
        }
    };
    const activeSelectedRow = () => {
        updateItemAliasStatus(true);
    };
    const inActiveSelectedRow = () => {
        updateItemAliasStatus(false);
    };
    const updateItemAliasStatus = async (status: boolean) => {
        let grid = $(gridDomSelector).data("kendoGrid");
        let processedArr = [];
        setIsFilterRowCountLoading(true);
        if (grid && localKendoGrid) {
            let selectedTR = grid.select();
            for (const i in selectedTR) {
                if (!selectedTR.hasOwnProperty(i)) {
                    continue;
                }
                const selectedData = grid.dataItem(selectedTR[`${i}`]);
                if (selectedData && selectedData.Alias) {
                    const alreadyProcessed = processedArr.findIndex((el) => el === selectedData.Alias);
                    if (
                        (alreadyProcessed === -1 && selectedData.Active !== "Active" && status) ||
                        (alreadyProcessed === -1 && selectedData.Active === "Active" && !status)
                    ) {
                        await ItemAliasServices.ItemAliasesActivate(selectedData.EditUri, status);
                        processedArr.push(selectedData.Alias);
                    }
                }
            }
            refreshGrid();
        }
    };

    const handleChangeFilterData = (event: any) => {
        let { name, value } = event.target;
        if (name === "status") {
            value = event.target.checked;
        }

        setFilterItemAlias((prevState: any) => {
            const newState = { ...prevState, [name]: value };
            if (name === "customerId") {
                newState.text = "";
            }
            return newState;
        });

        //refresh gird selection
        if (selectedRowCount > 0) {
            callForGetTotalCount();
            setTimeout(() => {
                clearSelectionOfGrid();
            }, 50);
        }
    };
    const resetGridAll = () => {
        setGridData([]);
        setSelectedRow(defaultItemAliasData);
        clearSelectionOfGrid();
        setSelectedRowCount(0);
    };
    const onOpenCreateNewItemAliasModal = () => {
        setIsEditMode(false);
        clearSelectionOfGrid();
        setSelectedRow(defaultItemAliasData);
        setIsOpenCreateNewItemAliasModal(true);
    };
    const onCloseCreateNewItemAliasModal = () => {
        setIsOpenCreateNewItemAliasModal(false);
    };
    const onApplySaveAlias = (data) => {
        setIsApplyingAlias(true);
        if (isEditMode) {
            ItemAliasServices.saveItemAliasData(data, selectedRow.EditUri)
                .then((response) => {
                    afterSaveDone();
                })
                .finally(() => {
                    setIsApplyingAlias(false);
                    // kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
                });
        } else {
            ItemAliasServices.createItemAliasData(data, filterItemAlias.customerId)
                .then((response) => {
                    afterSaveDone();
                })
                .finally(() => {
                    setIsApplyingAlias(false);
                    // kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
                });
        }
    };
    const afterSaveDone = () => {
        setSelectedRow(defaultItemAliasData);
        setIsOpenCreateNewItemAliasModal(false);
        GlobalService.notification("success", window.translate("Create_Item_Alias_Completed_Successfully"));
        refreshGrid();
    };

    const resetDescriptionFilter = () => {
        setFilterItemAlias((prevState: any) => {
            return { ...prevState, text: "" };
        });
    };

    const isActivateDeactiveMenuEnable = (match) => {
        let grid = $(gridDomSelector).data("kendoGrid");
        let isRowFound = false;
        if (grid && localKendoGrid) {
            let selectedTR = grid.select();
            selectedTR.each((i, sRow) => {
                const selectedData = grid.dataItem(sRow);
                if (selectedData.Active === match) {
                    isRowFound = true;
                    return false;
                }
            });
            return !isRowFound;
        }
        return true;
    };
    const onOpenImportItemAliasModal = () => {
        setSelectedRow(defaultItemAliasData);
        setIsOpenImportItemAliasModal(true);
    };
    const onCloseItemAliasImportModal = (doRefresh = false) => {
        setSelectedRow(defaultItemAliasData);
        setIsOpenImportItemAliasModal(false);
        if (doRefresh) {
            refreshGrid();
        }
    };
    const onApplyItemAliasImport = () => {
        setSelectedRow(defaultItemAliasData);
        refreshGrid();
    };

    return (
        <div id={`item-alias-page-wrapper`}>
            {!filterItemAlias.customerId && filterItemAlias.customerId === 0 && (
                <div className="customer-required">{window.translate("Customer_Require_Filter")}</div>
            )}
            <div id={"item-alias-button-area"}>
                <WmsButton
                    label={window.translate("Label_create_new_alias")}
                    wmsSelector={props.wmsSelector + "OpenCreateModel"}
                    onClickFunc={onOpenCreateNewItemAliasModal}
                    parentClassName={"noIconButton"}
                    disabled={filterItemAlias.customerId <= 0}
                />
                <WmsButton
                    label={window.translate("Label_Import")}
                    wmsSelector={props.wmsSelector + "OpenImportModel"}
                    onClickFunc={onOpenImportItemAliasModal}
                    parentClassName={"noIconButton"}
                    disabled={filterItemAlias.customerId <= 0}
                />
            </div>
            <div id={"item-alias-grid-area"}>
                <WmsCollapse
                    id={"item-alias-grid-colleps"}
                    headerLabel={window.translate("Window_Title_Label_Item_aleas")}
                    showCollapseText={false}
                >
                    <div className="item-alias-grid-filter-area">
                        <WmsDropdown
                            id="customerId"
                            name="customerId"
                            label={CustomLabels.Label_Customer}
                            wmsSelector={props.wmsSelector + "SelectCustomerFilter"}
                            value={filterItemAlias.customerId}
                            onChangeFunc={handleChangeFilterData}
                            options={CustomerOptions}
                            valueField={"id"}
                            textField={"name"}
                            required={true}
                        />
                        <WmsInput
                            id="text"
                            inputName="text"
                            wmsSelector={props.wmsSelector + "textFilter"}
                            onChangeFunc={handleChangeFilterData}
                            label={window.translate("Label_search_alias_or_description")}
                            value={filterItemAlias.text}
                            extraWrapperClass="width-40 read-icon"
                            onIconClick={resetDescriptionFilter}
                            showIconAfterInput={filterItemAlias.text.length >= 2}
                            icon={"fa-times-circle"}
                        />
                        <WmsCheckBox
                            id={"status"}
                            name={"status"}
                            wmsSelector={props.wmsSelector + "statusFilter"}
                            onChangeFunc={handleChangeFilterData}
                            label={window.translate("Label_show_Inactive")}
                            checked={filterItemAlias.status}
                        />
                    </div>
                    <div className={`item-alias-grid-div`}>
                        {showGrid && (
                            <VirtualGridComponent
                                virtualGridDataLimit={gridPageSize}
                                isNewColumnAdded={isNewColumnAdded}
                                callForGetNewData={callForGetNewData}
                                isAllDataLoad={isAllDataLoad}
                                loadAllRecords={onClickLoadAllRecords}
                                loading={isFilterRowCountLoading || isGridDataLoading}
                                loadAllMessageFirst={window.translate("Label_Item_Alias_Grid_Filter_Message")}
                                loadAllMessageSecond={window.translate("Label_Item_Alias_Grid_Filter_Message_2")}
                                showLoadingError={showLoadingError}
                                shawCheckbox={false}
                                id={gridId}
                                gridHeight={"400"}
                                showSortColumns={true}
                                showGridSummary={true}
                                showGridResult={true}
                                gridName={gridId}
                                windowId={props.windowId}
                                wmsSelector={props.wmsSelector + gridId}
                                gridKeySelection={"RowNumber"}
                                virtual={true}
                                totalResult={recordsCount}
                                staticGridData={gridData}
                                exportable={true}
                                isExportSelectedData={true}
                                exportExcelObj={{
                                    fileName:
                                        "ItemAliasExport-" +
                                        kendo.toString(kendo.parseDate(new Date().toString()), "yyyyMMddhhmmss") +
                                        ".xlsx",
                                    allPages: true,
                                    filterable: true,
                                }}
                                csvExportFileName={"ItemAliasExport"}
                                eventClickExportGrid={eventClickExportGrid}
                                allColumns={allColumnList}
                                defaultColumns={defaultColumns}
                                schema={schema}
                                onSelection={onselectGridRow}
                                clickEventOfContextMenu={clickEventOfContextMenu}
                                metaDataFieldsName={"ItemAlias"}
                                onGridFilterApplied={onGridFilterApplied}
                                filterText={filterText}
                                fullFilterText={fullFilterText}
                                onDataBound={onGridDataBound}
                                onSort={onSort}
                                onColumnReorder={onColumnReorder}
                                onColumnResize={onColumnResize}
                                onClickClearSorts={onClickClearSorts}
                                onClickResetGrid={onClickResetGrid}
                                metaGridConfig={gridConfig}
                                eventClickColumns={onToggleColumnModal(true)}
                                menu={[
                                    {
                                        "name": window.translate("Label_Manage"),
                                        "wmsSelector": props.wmsSelector + "Manage",
                                        "value": [
                                            {
                                                "name": window.translate("Label_Edit"),
                                                "wmsSelector": props.wmsSelector + "edit",
                                                "value": [],
                                                "onClickFunc": openEditModel,
                                                "disabled": selectedRowCount !== 1, //|| selectedRow?.Status !== "Open"
                                                "disabledText": "",
                                                "isContextMenu": true,
                                                "isButtonMenu": true,
                                                "wmsid": "EditItemAlias",
                                            },
                                            {
                                                "name": window.translate("Label_Delete"),
                                                "wmsSelector": props.wmsSelector + "delete",
                                                "value": [],
                                                "onClickFunc": openDeleteConfirmModel,
                                                "isContextMenu": true,
                                                "isButtonMenu": true,
                                                "disabled":
                                                    !isDeleteButtonActive ||
                                                    !rights.hasOwnProperty("customer.items.deletealias"),
                                                "disabledText": !rights.hasOwnProperty("customer.items.deletealias")
                                                    ? window.translate("Delete_Item_Alias_NoAccess_Text")
                                                    : window.translate("Delete_Item_Alias_Can_not_delete_active"),
                                                "wmsid": "DeleteItemAlias",
                                            },
                                            {
                                                "name": window.translate("Label_Activate"),
                                                "wmsSelector": props.wmsSelector + "activeRow",
                                                "value": [],
                                                "onClickFunc": activeSelectedRow,
                                                "isContextMenu": true,
                                                "isButtonMenu": true,
                                                "disabled": isActivateDeactiveMenuEnable("Inactive"),
                                                "wmsid": "ActiveItemAlias",
                                            },
                                            {
                                                "name": window.translate("Label_Deactivate"),
                                                "wmsSelector": props.wmsSelector + "inactiveRow",
                                                "value": [],
                                                "onClickFunc": inActiveSelectedRow,
                                                "isContextMenu": true,
                                                "isButtonMenu": true,
                                                "disabled": isActivateDeactiveMenuEnable("Active"),
                                                "wmsid": "InActiveItemAlias",
                                            },
                                        ],
                                        "disabled": false,
                                        "disabledText": "You may not add blahssss0",
                                        "icon": "fa-layer-group",
                                        "color": "green",
                                        "isContextMenu": true,
                                        "isButtonMenu": true,
                                    },
                                    {
                                        name: "",
                                        wmsSelector: props.wmsSelector + "RefreshGrid",
                                        value: [],
                                        clickFunc: refreshGrid,
                                        disabled: false,
                                        icon: "fa-sync-alt",
                                        color: "blue",
                                        isContextMenu: false,
                                        isButtonMenu: true,
                                        parentClassName: "item-alias-refresh-btn",
                                    },
                                ]}
                            />
                        )}
                    </div>
                </WmsCollapse>
            </div>
            {isOpenColumnModal && (
                <AddEditColumnModel
                    id={"addEditColumnEventNotification"}
                    height={700}
                    windowId={props.windowId}
                    onCloseColumnModel={onToggleColumnModal(false)}
                    isOpenColumnModel={isOpenColumnModal}
                    allSortColumnList={allColumnList}
                    gridId={gridId}
                    updateGridData={() => {}}
                    saveChangesInMetaData={saveMetaData}
                />
            )}
            {overlayScreenShow && (
                <LoadingModel
                    sharedOpenLoadingmodel={overlayScreenShow}
                    onCancelLoadAllData={cancelLoadAllData}
                    sharedTotalRequest={totalRequest}
                />
            )}
            {isOpenCreateNewItemAliasModal && (
                <ItemAliasModal
                    onCloseItemAliasModal={handleCancelClick}
                    isOpenItemAliasModal={isOpenCreateNewItemAliasModal}
                    wmsSelector={props.wmsSelector + "AddModel"}
                    selectedRow={selectedRow}
                    windowId={props.windowId}
                    onApplySaveAlias={onApplySaveAlias}
                    applyingAlias={isApplyingAlias}
                    isEditMode={isEditMode}
                    customerId={filterItemAlias.customerId}
                    CustomerOptions={CustomerOptions}
                />
            )}
            {isOpenImportItemAliasModal && (
                <ItemAliasImportModal
                    onCloseItemAliasImportModal={onCloseItemAliasImportModal}
                    wmsSelector={props.wmsSelector + "ImportModel"}
                    isOpenItemAliasImportModal={isOpenImportItemAliasModal}
                    windowId={props.windowId}
                    onApplyItemAliasImport={onApplyItemAliasImport}
                    customerId={filterItemAlias.customerId}
                />
            )}
            {isOpenDeleteConfirmModel && (
                <WmsModel
                    id={props.windowId + "confirmDeleteItemAlias"}
                    title={
                        window.translate("Label_Delete") +
                        (selectedRowCount > 1 ? " Multiple Aliases" : ' "' + selectedRow.Alias + '"')
                    }
                    width={500}
                    height={175}
                    class="confirmDeleteItemAlias"
                    onCloseFunc={clickDeleteAliasExit}
                    customClass="wms-model-new-ui-wrapper"
                    isOpen={isOpenDeleteConfirmModel}
                    actions={["Close"]}
                >
                    <div className="model-content-wrapper">
                        {window.translate("Delete_Item_Alias_Confirm_Line1")}
                        {selectedRowCount > 1 ? "multiple Aliases" : ' "' + selectedRow.Alias + '"'}?&nbsp;
                        {window.translate("Delete_Item_Alias_Confirm_Line2")}
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={props.wmsSelector + "Cancel"}
                            onClickFunc={clickDeleteAliasExit}
                            disabled={isDeleteButtonLoading}
                            buttonType="yellow"
                            parentClassName={"noIconButton"}
                        />
                        <WmsButton
                            label={window.translate("Label_Delete")}
                            wmsSelector={props.wmsSelector + "ConfirmDelete"}
                            onClickFunc={deleteItemAliass}
                            disabled={false}
                            buttonType="red"
                            parentClassName={"noIconButton"}
                            loading={isDeleteButtonLoading}
                        />
                    </div>
                </WmsModel>
            )}
            <div>
                {
                    <WmsConfirmModel
                        id="CancelConfirmationDialog"
                        isOpen={isOpenCancelConfirmModel}
                        width={500}
                        height={250}
                        firstBtnLabel={window.translate("Label_Yes")}
                        firstBtnIcon="fa fa-check-circle"
                        firstBtnOnClickFunc={handleConfirmCancel}
                        thirdBtnLabel={window.translate("Label_No")}
                        thirdBtnIcon="fa fa-times-circle"
                        onCloseFunc={handleCloseConfirmDialog}
                        conFormModelText="Your unsaved changes will be lost. Are you sure you want to leave this page?"
                        sawCheckBox={false}
                        isLoadingStop={false}
                    />
                }
            </div>
        </div>
    );
};
ItemAliasGrid.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
};

ItemAliasGrid.defaultProps = {
    wmsSelector: "",
    windowId: "",
    sharedFilterObject: {
        customerId: 0,
        text: "",
        status: true,
    },
};

export default React.memo(ItemAliasGrid);
