import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "ItemAlias";
var metaDataItemAliasObject = {
    ItemAlias: {
        filterData: {},
        gridConfig: {},
    },
};
var axios = require("axios");
declare var window: any;
const { $ } = window;

export const setGridFiltere = function (param_filterObj, cancleToken) {
    let url = `/WebUI/Items/ItemAliases/ItemAliasesRowCount?customerId=${param_filterObj.customerId}&showInactive=${param_filterObj?.status}&searchString=${param_filterObj.text}`;

    return axios.get(url, { cancelToken: cancleToken }).then((response: any) => {
        return response;
    });
};
// export const clearGridFiltere = function (param_filterObj) {
//     let url = "/WebUI/Inventory/UpdateSession/AdjustmentSearchInfo";

//     return axios.delete(url).then((response: any) => {
//         return response;
//     });
// };

export const getGridData = function (param_filterObj) {
    let url = `/WebUI/Items/ItemAliases/ItemAliasesData?customerId=${param_filterObj.customerId}&showInactive=${param_filterObj?.status}&searchString=${param_filterObj.text}`; // ?sort=&page=1&pageSize=50&group=&filter=

    return axios.get(url).then(function (response: any) {
        return response;
    });
};

export const ItemAliasesActivate = async function (uri, status) {
    let url = `/WebUI/Items/ItemAliases/ItemAliasActivator`;
    return await axios.put(url, { editUri: uri, activate: status }).then(function (response: any) {
        return response;
    });
};

export const createItemAliasData = function (data, customerId) {
    let url = "/WebUI/Items/ItemAliases/ItemAlias";

    return axios.post(url, { customerId: customerId, ItemAlias: data }).then(function (response) {
        return response;
    });
};
export const saveItemAliasData = function (data, editUri) {
    let url = "/WebUI/Items/ItemAliases/ItemAlias";

    return axios.put(url, { editUri: editUri, ItemAlias: data }).then(function (response) {
        return response;
    });
};
export const getItemAliasDetailRow = function (uri) {
    let url = `/WebUI/Items/ItemAliases/ItemAlias?editUri=${uri}`;
    return axios.get(url).then((response) => response);
};
export const deleteItemAliasRow = function (uri) {
    let url = `/WebUI/Items/ItemAliases/ItemAlias?editUri=${uri}`;
    return axios.delete(url).then((response) => response);
};
export const deleteItemAliases = function (customerId, data) {
    let url = `/WebUI/Items/ItemAliases/ItemAliasDeleter`;
    return axios.put(url, { customerId: customerId, ItemAliases: data }).then((response) => response);
};
export const updateSessionFilter = function (searchData) {
    let url = `/WebUI/Items/UpdateSession/SearchInfo`;
    return axios.put(url, { "searchInfo": searchData }).then(function (response) {
        return response;
    });
};
// call api for get metaData of ItemAlias is it's open first time
export const getItemAliasClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(metaDataItemAliasObject[`${keyName}`], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setItemAliasFilterMetaData = function (filterData) {
    metaDataItemAliasObject[`${keyName}`]["filterData"] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataItemAliasObject[`${keyName}`]));
};

export const getItemAliasFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataItemAliasObject[`${keyName}`]["filterData"])) {
        return metaDataItemAliasObject[`${keyName}`]["filterData"];
    } else {
        return "";
    }
};
export const setItemAliasGridOptionInMetaData = function (gridConfig) {
    metaDataItemAliasObject[`${keyName}`]["gridConfig"] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataItemAliasObject[`${keyName}`]));
};
export const getItemAliasGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataItemAliasObject[`${keyName}`]["gridConfig"])) {
        return metaDataItemAliasObject[`${keyName}`]["gridConfig"];
    } else {
        return "";
    }
};

export const clearItemAliasFilterMetaData = function () {
    metaDataItemAliasObject[`${keyName}`]["filterData"] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataItemAliasObject[`${keyName}`]));
};

export const getItemGridData = function (customerId, cancleToken) {
    let url = `/WebUI/Items/Items/ItemsGridRead`;

    return axios
        .post(
            url,
            {
                page: 1,
                pageSize: 100,
                customerUri: `/customers/${customerId}`,
            },
            { cancelToken: cancleToken }
        )
        .then(function (response: any) {
            return response;
        });
};

export const getAttachFileParserData = function (customerId, importFileObj) {
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //     // Use a regex to remove data url part
    //     const base64String = reader.result; // .replace("data:", "").replace(/^.+,/, "");

    //     console.log(base64String);
    //     // Logs wL2dvYWwgbW9yZ...
    // };
    // reader.readAsDataURL(fileObj);

    //const fileBlob = new Blob([fileContent], { type: "application/vnd.ms-excel" });

    // Create a FormData object and append the file
    //const formData = new FormData();
    //formData.append("file", fileBlob, "uploadedFile.xlsx"); // 'uploadedFile.xlsx' is the file name

    const url = `/WebUI/Items/ItemAliases/FileParser?id=${customerId}`;

    // Prepare the ImportFile object
    // const response = await axios.put(apiUrl, formData, {
    //     headers: {
    //         'Content-Type': 'multipart/form-data', // For file uploads
    //     },
    // });

    return axios
        .put(url, importFileObj, {
            headers: {
                "Content-Type": "application/json", // For file uploads
            },
        })
        .then((response) => response);
    // .put(url, fileObj, {
    //     "Content-Type": contentType,
    // })
};

export const submitAttachFileParserData = function (customerId, dupHandling, importParserResponseData) {
    const url = `/WebUI/Items/ItemAliases/Importer?id=${customerId}&dupHandling=${dupHandling ? 1 : 0}`;
    return axios
        .put(url, importParserResponseData, {
            headers: {
                "Content-Type": "application/json", // For file uploads
            },
        })
        .then((response) => response);
};
