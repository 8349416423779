import React from 'react';
import ReactDOM from 'react-dom';
import * as GlobalService from '../global_services';
import * as MenuConfig from './menu_configuration.jsx';
import { reportIframe } from '../reportIframe/report-iframe.component.jsx';
import style from './navbar.scss';
import { default as WmsConfirmModel } from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import * as metaDataService from './meta-data.service';
import * as navbarService from './navbar.service.jsx';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.state = {
            menuList: [],
            menuId:'',
            menuIdIcon:'',
            Rights: GlobalService.GetRights(),
            currentyear: new Date().getFullYear(),
            companyName: '',
            isHoveringFooterCopyRight: '',
            footerTooltip: '',
            serverInfo: '',
            isConfirmToSwitchSmall:false,
            isConfirmToSwitchWms:false,
            collaspseClassName: '',
            showViewList:false,
            isDefaultHide:true,
            currentView:"",
            isHubLogin: GlobalService.GetCurrentUser()?.IsJwtLogin,
            showUnprovisioned: false,
        }
        this.viewList = [{
            "title":this.translate('Window_Title_Label_WMS'),
            "name":"wms",
            "selector":"WMS_MainMenu",
            "icon":"fas fa-warehouse",
            'rights': ['default']
        },{
            "title":this.translate('Window_Title_Label_Small_Parcel'),
            "name":"smallParcel",
            "selector":"Small_Parcel_MainMenu",
            "icon":"fas fa-box-open",
            'rightsOperator': 'and',
            'rights': ['smallParcelMenuVisible']
        }];
        var that = this;
        // setTimeout(() => {
        //     $(".showBatchItemsTooltip").kendoTooltip({
        //         width: 240,
        //         position: "bottom",
        //         content: function (e) {
        //             return '<p class="model-tooltip-title-p">' + that.translate('Batch_Item_Link_Tooltip') + '</p>';
        //         },
        //         animation: {
        //             open: {
        //                 effects: "zoom",
        //                 duration: 150
        //             }
        //         }
        //     }).data("kendoTooltip");
        // }, 100);
    }
    componentDidMount() {
        var currentView = GlobalService.currentActiveView;
        if (!this.state.showUnprovisioned && !GlobalService.CurrentUser.Provisioned) {
            this.setState({
                showUnprovisioned: true,
            });
        } else {
            if(currentView == "wms" || !this.state.Rights.hasOwnProperty('smallParcelMenuVisible')) {
                this.openWmsView();
            } else {
                this.openSmallParcelView();
            }
            this.getFooterTooltip();
            document.addEventListener("UpdateRightsForMenuList",  (e) => {
                this.setState({
                    Rights: GlobalService.GetRights(),
                });
            });
        }
    }
    openSubMenu = (menuId) => (e) => {
        var verticalMenu = $("#verticalMenu " + menuId + " .vertical-dropdown");
        // $scope.menuId = '';
        this.closeSubMenu();
        this.setState({ menuId:menuId });
        $("#verticalMenu " + menuId).addClass('active-icon');
        verticalMenu.addClass('active');
        verticalMenu.css('width', '0px')
        verticalMenu.animate({
            width: "305px"
        }, 0.02);

    }
    closeSubMenu  = (e) => {
        if(e){
            e.stopPropagation();
        }
        $("#verticalMenu .dropdown").removeClass('active-icon');
        var verticalMenu = $("#verticalMenu .vertical-dropdown");
        // verticalMenu.css('width', '190px');
        if (this.state.menuId != '') {
            this.closeSubMenuWithId();
        } else {
            verticalMenu.removeClass('active');
            this.setState({ menuId:'' });
        }

        var sideNav = $("#navbar-component");
        if (sideNav.hasClass('expand')) {
            window.toggleWindowLeftCss("showIconNav");
        }
    }
    closeSubMenuWithId = () => {
        $("#verticalMenu .dropdown").removeClass('active-icon');
        var verticalMenu = $("#verticalMenu " + this.state.menuId + " .vertical-dropdown");
        if (verticalMenu.hasClass('active')) {
            verticalMenu.animate({
                width: "0px"
            }, 0.02);
            // .then(function () {
            //     verticalMenu.removeClass('active');
            //     console.log('then')
            // });
        }
        this.setState({ menuId:'' });
    }
    openWindow = (windowObj) => (e) => {
        e.stopPropagation();
        window.OpenWindow(windowObj);
        this.closeSubMenu();
        this.closeSubMenuIcon();
    }
    openModal = (modalObj) => (e) => {
        console.log("Remain open modal implement");
    }
    redirectUrlClickEvent = (submenu) => (e) => {
        window.setLogInLocalstorage({
            'category': 2 ,
            'platform': "v1"
        });

    }
    clickEvent = (methodFor) => (e) => {
        if (methodFor == "sync") {
            this.flushApiCache();
        }
    }

    getFooterTooltip = () => {
        GlobalService.getFooterInfo().then((respnose) => {
            this.setState({
                companyName:respnose.data.CompanyName,
                footerTooltip:"Version: " + respnose.data.Version + " Server: " + respnose.data.Server,
                serverInfo:respnose.data.Server
            });
        }).finally(() => {
            this.closeSubMenu();
        });
    }

    handleMouseHover = () => {
        this.setState({ isHoveringFooterCopyRight: true });
    }
    handleMouseLeave = () => {
        this.setState({ isHoveringFooterCopyRight: false });
    }
     /* istanbul ignore next */
     flushApiCache = () => {
        GlobalService.flushApiCache().then((response) => {
            GlobalService.notification('success', this.translate('Label_Operation_Successful'));
        }).finally(() => {
            this.closeSubMenu();

        });
    }

    checkRights = (submenu) => {
        if (typeof submenu.rightsOperator != 'undefined' && submenu.rightsOperator == 'and') {
            for (var x = 0; x < submenu.rights.length; x++) {
                if (!this.state.Rights.hasOwnProperty(submenu.rights[x]) == true) {
                    return false;
                }
            }
            return true;
        }else if(typeof submenu.rightsOperator != 'undefined' && submenu.rightsOperator == 'or'){
            for (var x = 0; x < submenu.rights.length; x++) {
                if (this.state.Rights.hasOwnProperty(submenu.rights[x]) == true) {
                    return true;
                }
            }
            return false;
        } else {
            for (var x = 0; x < submenu.rights.length; x++) {
                if (this.state.Rights.hasOwnProperty(submenu.rights[x])) {
                    return true;
                }
            }
            return false;
        }
    }

    toggleSideMenu = () => {
        this.setState({
            menuId:'',
            menuIdIcon: '',
            collaspseClassName: 'collaspse'
         },()=>{
             this.closeSubMenu();
             this.closeSubMenuIcon();
         });
        var sideNav = $("#navbar-component"); //.sidenav");
        var mainBody = $('.mainBody');
        if (sideNav.hasClass('expand')) {
            mainBody.removeClass('body_width');
            window.toggleWindowLeftCss("showIconWithTextNav");
        } else {
            mainBody.addClass('body_width');
            window.toggleWindowLeftCss("showIconNav");
        }
     }
    openSubMenuIcon = (paramMenuIdIcon) => (e) => {
        var verticalMenuWithIcon = $("#verticalMenuWithIcon " + paramMenuIdIcon + " .vertical-dropdown");
        this.closeSubMenuIcon();
        this.setState({ menuIdIcon: paramMenuIdIcon });
        $("#verticalMenuWithIcon " + paramMenuIdIcon).addClass('active-icon');
        verticalMenuWithIcon.addClass('active');
        verticalMenuWithIcon.css('width', '0px')
        verticalMenuWithIcon.animate({
            width: "310px"
        }, 0.02)
     }
    closeSubMenuIconWithId = () => {
        $("#verticalMenuWithIcon .dropdown").removeClass('active-icon');
        var verticalMenuWithIcon = $("#verticalMenuWithIcon " + this.state.menuIdIcon + " .vertical-dropdown");
        if (verticalMenuWithIcon.hasClass('active')) {
            verticalMenuWithIcon.css('width', '190px');
            verticalMenuWithIcon.animate({
                width: "0px"
            }, 0.02);
            // .then(function () {
            //     verticalMenuWithIcon.removeClass('active');
            // });
        }
        this.setState({ menuIdIcon : '' });
    }
    closeSubMenuIcon = () => {
        $("#verticalMenuWithIcon .dropdown").removeClass('active-icon');
        var verticalMenuWithIcon = $("#verticalMenuWithIcon .vertical-dropdown");
        // verticalMenuWithIcon.css('width', '190px');
        if (this.state.menuIdIcon != '') {
            this.closeSubMenuIconWithId();
        } else {
            verticalMenuWithIcon.removeClass('active');
            this.setState({ menuIdIcon : '' });
        }
    }
    openWmsView = () => {
        this.setState({
            menuList: MenuConfig.getMenuList(GlobalService.getCustomLabels()),
            isConfirmToSwitchWms:false,
            showViewList : false,
            currentView:"wms"
        });
        var eventItem = new CustomEvent("CloseAllCurrentTab");
        document.dispatchEvent(eventItem);
        if (this.state.Rights.hasOwnProperty("UxPrototypingDirectivesOutBound")) {
            setTimeout(() => {
                $('#wms_dashboard [data-wms-selector="SupportLink_OrderProductivity_SubMenu"]')[0].click();
            }, 10);
        }
        metaDataService.setActiveViewInMetaData("wms");
    }
    openSmallParcelView = () => {
        this.setState({
            menuList: MenuConfig.getMenuListForSmallParcel(GlobalService.getCustomLabels()),
            isConfirmToSwitchSmall:false,
            showViewList : false,
            currentView:"smallParcel"
        });
        var eventItem = new CustomEvent("CloseAllCurrentTab");
        document.dispatchEvent(eventItem);
        setTimeout(() => {
            $("#SmallParcel_Parcel_Ship a")[0].click();
        }, 10);
        metaDataService.setActiveViewInMetaData("smallParcel");
    }
    onCancelSmallParcelClick = () => {
        this.setState({
            isConfirmToSwitchSmall:false
        });
    }
    onCancelWmsClick = () => {
        this.setState({
            isConfirmToSwitchWms:false
        });
    }
    closeViewList = () => {
        this.setState({
            showViewList : false
        })
    }
    openViewList = () => {
        this.setState({
            showViewList : true,
            isDefaultHide : false
        })
    }
    onChangeView = (name) => {
        if(name === "wms"){
            if($('.tab-item').length == 0){
                this.openWmsView();
            } else {
                this.setState({
                    isConfirmToSwitchWms:true
                });
            }
        } else if(name === "smallParcel"){
            if($('.tab-item').length == 0){
                this.openSmallParcelView();
            } else {
                this.setState({
                    isConfirmToSwitchSmall:true
                });
            }
        }
    }
    onOpenUnprovisioned = () => {
        this.setState({
            showUnprovisioned: false,
        });
    }
    render() {
        const { isHubLogin } = this.state

        return (
            <div id="navbar-component" className={"sidenav " + (isHubLogin ? 'sidenav-hub ' : '') + (!this.props.hideHigherElements ? ' sidenav-z ' : '') + (this.props.ActiveMenuType == 'showIconWithTextNav' ? this.state.collaspseClassName : ' expand')}>
                <div className="nav-content icon-menu">
                <div className="wms-scroll-wrapper">
                    <div className="title">
                        <button className={`k-primary ${isHubLogin ? 'hub-navbar-title' : '' }`} onClick={this.toggleSideMenu}>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="menu-section">
                        <ul id="verticalMenuWithIcon">
                            {this.state.menuList.map((menu, index) => {
                                return (
                                    this.checkRights(menu) &&(
                                        <li key={index} className={menu.class} id={'icon_'+menu.id}>
                                            {menu.isMethodFor != true && menu.directOpen != true && menu.directlink != true && (
                                               <div  onClick={this.openSubMenuIcon('#icon_'+menu.id)}>
                                                <a title={this.translate(menu.title)} data-wms-selector={'icon_'+menu.dataWmsSelector}>
                                                    <i className={menu.icon}></i>
                                                </a>
                                            </div>
                                            )}
                                            {menu.isMethodFor == true && (
                                                <div onClick={this.clickEvent(menu.methodFor)}>  {/* kendo-tooltip k-content="'{{ menu.toolTip | translate }}'" */}
                                                    <a title={this.translate(menu.title)} data-wms-selector={"icon_"+menu.dataWmsSelector}>
                                                        <i className={menu.icon}></i>
                                                    </a>
                                                </div>
                                            )}
                                            {menu.directOpen == true && (
                                                <div onClick={this.openWindow(menu.clickParamObj)}>
                                                    <a title={this.translate(menu.title)} data-wms-selector={'icon_'+menu.dataWmsSelector}>
                                                        <i className={menu.icon}></i>
                                                    </a>
                                                </div>
                                            )}
                                            {menu.directlink == true && (
                                                <a title={this.translate(menu.title)} target="_blank" href={menu.redirectUrl} data-wms-selector={'icon_'+menu.dataWmsSelector}>
                                                    <i className={menu.icon}></i>
                                                </a>
                                            )}
                                            {menu.subMenu && menu.subMenu.length > 0 && (
                                                <ul className="vertical-dropdown">
                                                    <li className="title">
                                                        <div className="title-text">{this.translate(menu.title)}</div>
                                                        <div className="wms_icon_slideout_title_close" onClick={this.closeSubMenuIcon}>
                                                            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                                                        </div>
                                                    </li>
                                                    {menu.subMenu.map((submenu, subkey) => {
                                                        let hoverText = submenu.hoverText != undefined ? submenu.hoverText : submenu.title;
                                                         return (
                                                            this.checkRights(submenu) && (
                                                                <li key={subkey} className={submenu.class} >
                                                                    {submenu.url && (
                                                                        <a target="_blank" title={this.translate(hoverText)} onClick={this.redirectUrlClickEvent(submenu)} href={submenu.redirectUrl} data-wms-selector={"icon_"+submenu.dataWmsSelector}>
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                    {submenu.isMethodFor && (
                                                                        <a title={this.translate(hoverText)} onClick={this.clickEvent(submenu.methodFor)} data-wms-selector={"icon_"+submenu.dataWmsSelector}>
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}

                                                                    {!submenu.url && !submenu.isModal && !submenu.isMethodFor && (
                                                                        <a title={this.translate(hoverText)} onClick={this.openWindow(submenu.clickParamObj)} data-wms-selector={"icon_"+submenu.dataWmsSelector}>
                                                                            <i className={submenu.clickParamObj.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                    {submenu.isModal && (
                                                                        <a title={this.translate(hoverText)} onClick={this.openModal({id: 'Modal1',title: submenu.clickParamObj.title,url: submenu.clickParamObj.url,width: 800,height: 500,flow:'create'})} data-wms-selector={"icon_"+submenu.dataWmsSelector }>
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                </li>
                                                            )
                                                        )
                                                    })}
                                                    {/* {menu.subLink && menu.subLink.map((submenu, index) => {
                                                        this.checkRights(submenu) && (
                                                            <li  key={index} className={submenu.class}>
                                                                <a target="_blank" href={submenu.redirectUrl}>
                                                                    <i className={submenu.icon}></i>sub menu title
                                                                </a>
                                                            </li>
                                                        )
                                                    })} */}
                                                </ul>
                                             )}
                                        </li>
                                    )
                                )
                            })}

                        </ul>
                    </div>
                </div>
                </div>
                <div className="nav-content with-texticon-menu" >
                    <div className="wms-scroll-wrapper">
                    <div className={`title ${isHubLogin ? 'hub-navbar-title' : '' }`}>
                        <h3>Menu</h3>
                        <div className="btn-wrapper">
                        <button data-wms-selector="CollapseExpandMenu" className={`k-primary ${isHubLogin ? 'hub-collapse-button' : '' }`} onClick={this.toggleSideMenu}>
                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        {GlobalService.CurrentUser.Provisioned && this.state.Rights.hasOwnProperty('smallParcelMenuVisible') && (
                            <button data-wms-selector="CollapseExpandSmallParcel" className="k-primary" onClick={this.openViewList}>
                                <i className="fa fa-bars" aria-hidden="true"></i>
                            </button>
                        )}
                        </div>
                    </div>
                    <div className="menu-section">
                        <ul id="verticalMenu">
                            {this.state.menuList.map((menu, index) => {
                                return (
                                    this.checkRights(menu) &&(
                                        <li key={index} className={menu.class} id={menu.id}>
                                            {menu.isMethodFor != true && menu.directOpen != true && menu.directlink != true && (
                                                <div className="title-section" onClick={this.openSubMenu('#'+menu.id)}>
                                                    <a title={this.translate(menu.title)} data-wms-selector={menu.dataWmsSelector}>
                                                        <i className={menu.icon}></i>{this.translate(menu.title)}
                                                    </a>
                                                    {menu.isNewMenu == true && (
                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                    )}
                                                    {menu.isFreeTrial && (
                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                    )}
                                                </div>
                                            )}
                                            {menu.isMethodFor == true && (
                                                <div className="title-section" onClick={this.clickEvent(menu.methodFor)}>  {/* kendo-tooltip k-content="'{{ menu.toolTip | translate }}'" */}
                                                    <a title={this.translate(menu.title)} data-wms-selector={menu.dataWmsSelector}>
                                                        <i className={menu.icon}></i>{this.translate(menu.title)}
                                                    </a>
                                                    {menu.isNewMenu == true && (
                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                    )}
                                                    {menu.isFreeTrial && (
                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                    )}
                                                </div>
                                            )}
                                            {menu.directOpen == true && (
                                                <div className="title-section" onClick={this.openWindow(menu.clickParamObj)}>
                                                    <a title={this.translate(menu.title)} data-wms-selector={menu.dataWmsSelector}>
                                                        <i className={menu.icon}></i>{this.translate(menu.title)}
                                                    </a>
                                                    {menu.isNewMenu == true && (
                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                    )}
                                                    {menu.isFreeTrial && (
                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                    )}
                                                </div>
                                            )}
                                            {menu.directlink == true && (
                                                <div className="title-section">
                                                    <a title={this.translate(menu.title)} target="_blank" href={menu.redirectUrl}>
                                                        <i className={menu.icon}></i>{this.translate(menu.title)}
                                                    </a>
                                                    {menu.isNewMenu == true && (
                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                    )}
                                                    {menu.isFreeTrial && (
                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                    )}
                                                </div>
                                            )}
                                            {(menu.subMenu) && (menu.subMenu.length > 0) && (
                                                <ul className={`vertical-dropdown ${isHubLogin ? 'vertical-dropdown-hub' : ''}`} >
                                                    <li className="title">
                                                        <div className="title-text">{this.translate(menu.title)}</div>
                                                        <div className="wms_icon_slideout_title_close" onClick={this.closeSubMenu}>
                                                            <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                                                        </div>
                                                    </li>
                                                    {menu.subMenu.map((submenu, subkey) => {
                                                        let hoverText = submenu.hoverText != undefined ? submenu.hoverText : submenu.title;
                                                        return(
                                                            this.checkRights(submenu) && (
                                                                <li key={subkey} className={submenu.class}>
                                                                    {submenu.url && (
                                                                        <a target="_blank" title={this.translate(hoverText)} onClick={this.redirectUrlClickEvent(submenu)} href={submenu.redirectUrl} data-wms-selector={submenu.dataWmsSelector}  >
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                    {submenu.isMethodFor && (
                                                                        <a title={this.translate(hoverText)} onClick={this.clickEvent(submenu.methodFor)} data-wms-selector={submenu.dataWmsSelector} >
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}

                                                                    {!submenu.url && !submenu.isModal && !submenu.isMethodFor && (
                                                                        <a title={this.translate(hoverText)} onClick={this.openWindow(submenu.clickParamObj)} data-wms-selector={submenu.dataWmsSelector} className={submenu.parentClass}>
                                                                            <i className={submenu.clickParamObj.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                    {submenu.isModal && (
                                                                        <a title={this.translate(hoverText)} onClick={this.openModal({id: 'Modal1',title: submenu.clickParamObj.title,url: submenu.clickParamObj.url,width: 800,height: 500,flow:'create'})} data-wms-selector={submenu.dataWmsSelector }>
                                                                            <i className={submenu.icon}></i>
                                                                            {this.translate(submenu.title)}
                                                                        </a>
                                                                    )}
                                                                    {submenu.isNewMenu == true && (
                                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                                    )}
                                                                    {submenu.isFreeTrial && (
                                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                                    )}
                                                                </li>
                                                            )
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </div>
                    <div className="copyright" id="copyright"  onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseLeave}>
                        <div className="div_disable">
                            &copy; {this.state.currentyear + ' '+this.state.companyName}
                        </div>
                        All rights reserved
                    </div>
                    {this.state.isHoveringFooterCopyRight == true && (
                        <div className="div_tooltip">{this.state.footerTooltip}</div>
                    )}
                    </div>
                </div>

                <div className={"nav-content view-list" + (this.state.showViewList ? ' show-view-list': ' hide-view-list')  + (this.state.isDefaultHide ? ' default-view-list-hide': '')} data-wms-selector="SpacesList">
                <div className="wms-scroll-wrapper">
                    <div className="title">
                    <h3>Spaces</h3>
                        <button data-wms-selector="CloseViewListMenu" className="k-primary" onClick={this.closeViewList}>
                            <i className="far fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="menu-section">
                        <ul>
                                {this.viewList.map((menu, index) => {
                                    return (
                                        this.checkRights(menu) && (
                                            <li className={"dropdown new-white" + (this.state.currentView == menu.name ? ' active' : '')} id={"wms_" + menu.name} key={index}>
                                                <div className="spaces-title-section">
                                                    <a title={menu.title} data-wms-selector={menu.selector} onClick={(e) => this.onChangeView(menu.name)}>
                                                        <i className={menu.icon}></i>{menu.title}
                                                    </a>
                                                    {menu.isNewMenu == true && (
                                                        <span className="wms-badge">{this.translate('Label_Beta')}</span>
                                                    )}
                                                    {menu.isFreeTrial && (
                                                        <span className="wms-badge free-trial-badge">{this.translate('Label_Free_Trial')}</span>
                                                    )}
                                                </div>
                                            </li>
                                        )
                                )
                            })}
                        </ul>
                    </div>
                </div>
                </div>
                <WmsConfirmModel
                    id={"ConfirmationChangeViewModal"}
                    isOpen={this.state.isConfirmToSwitchSmall}
                    type={this.translate('Label_Confirmation')}
                    firstBtnLabel={this.translate('Label_Select_Confirm')}
                    firstBtnIcon={"fa-check-circle"}
                    firstBtnOnClickFunc={this.openSmallParcelView}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_Confirmation_For_Switch_to_small_parcel')}
                    onCloseFunc={this.onCancelSmallParcelClick}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa-times-circle-o"}
                    isLoadingStop={false}
                />
                <WmsConfirmModel
                    id={"ConfirmationChangeViewToWmsModal"}
                    isOpen={this.state.isConfirmToSwitchWms}
                    type={this.translate('Label_Confirmation')}
                    firstBtnLabel={this.translate('Label_Select_Confirm')}
                    firstBtnIcon={"fa-check-circle"}
                    firstBtnOnClickFunc={this.openWmsView}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_Confirmation_For_Switch_to_Wms')}
                    onCloseFunc={this.onCancelWmsClick}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa-times-circle-o"}
                    isLoadingStop={false}
                />
                <WmsConfirmModel
                    id={"UnprovisionedModal"}
                    isOpen={this.state.showUnprovisioned}
                    type={this.translate('Label_Confirmation_Warning_Title')}
                    firstBtnLabel={this.translate('Label_Ok')}
                    firstBtnIcon={"fa-check-circle"}
                    firstBtnOnClickFunc={this.onOpenUnprovisioned}
                    onCloseFunc={this.onOpenUnprovisioned}
                    sawCheckBox={false}
                    isSecondBtnSaw={false}
                    isThirdBtnSaw={false}
                    conFormModelText={this.translate('Label_User_Unprovisioned')}
                    isLoadingStop={false}
                    thirdBtnLabel={""}
                />
            </div>
        );
    }
}
