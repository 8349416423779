import React from 'react';
import PropTypes from 'prop-types';
import WmsButton from '../WmsButton/WmsButton.jsx';
var _URL = window.URL || window.webkitURL;
import style from './WmsFile.scss';
import Resizer from "react-image-file-resizer";
import * as GlobalService from './../../global_services';

class WmsFile extends React.PureComponent {

    constructor(props) {
        super(props);

    }

    onChangeFunc = (event) => {
        if (event.target.files.length == 0) { return }
        let reader = new FileReader();
        let fileType = event.target.files[0].type;
        let fileName = event.target.files[0].name;
        let fileSize = event.target.files[0].size;
        let fileExtension = fileName.split('.').pop();

        if (this.props.fileProcessType == 'FileRead') {

            let fileread = '';

            reader.onloadend = (loadEvent) => {

                if(fileExtension == 'xlsx' || fileExtension == 'xls'){

                    var workbook = XLSX.read(loadEvent.target.result, { type: 'binary' });
                    postMessage({t:"xlsx", d:JSON.stringify(workbook)});
                    var result = [];
                    workbook.SheetNames.forEach(function(sheetName) {
                        var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], {FS:"\t"});
                        if(csv.length){
                            result.push(csv);
                        }
                    });
                    fileread = result.join("\n");
                }else{
                fileread = loadEvent.target.result;
                if (fileType !== 'text/xml') {
                    let fileStart = fileread.slice(0, 3);
                    fileType = fileStart === 'ISA' ? 'edi' : 'other';
                }
                }
                this.props.onChangeFunc({
                    'fileType': fileType,
                    'fileName': fileName,
                    'fileread': fileread,
                    'fileSize':fileSize,
                    'fileExtension': fileExtension
                });
            }
            if(fileExtension == 'xlsx' || fileExtension == 'xls'){
                reader.readAsBinaryString(event.target.files[0]);
            }else{
            reader.readAsText(event.target.files[0]);
            }

        } else if (this.props.fileProcessType == 'FileSelect') {

            reader.onloadend = (loadEvent) => {
                var file = loadEvent.target.result;
                var bytes = new Uint8Array(file);
                this.props.onChangeFunc({
                    'fileName': fileName,
                    'FileContents': bytes,
                    'ContentType': fileType,
                    'FileSize': bytes.length,
                    'fileExtension': fileExtension
                });
            };
            reader.readAsArrayBuffer(event.target.files[0]);
        } else if (this.props.fileProcessType == "FileSelectReadAsDataUrl") {
            reader.onloadend = (loadEvent) => {
                var fileResult = loadEvent.target.result;
                // var bytes = new Uint8Array(file);
                this.props.onChangeFunc({
                    fileName: fileName,
                    // FileContents: bytes,
                    ContentType: fileType,
                    FileSize: fileSize,
                    fileExtension: fileExtension,
                    // file: event.target.files[0],
                    base64: fileResult.replace('data:', '')
                    .replace(/^.+,/, '')
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        } else if (this.props.fileProcessType == "ImageSelect") {
            if (this.props.maxWidth !== 0 || this.props.maxHeight !== 0) {
                try {
                    Resizer.imageFileResizer(
                      event.target.files[0], // file
                      this.props.maxWidth, // maxWidth
                      this.props.maxHeight, // maxHeight
                      fileExtension.toUpperCase() === "PNG" ? "PNG" : "JPEG", // compressFormat
                      100, // quality 100 for no compress
                      0, // rotation 0 for no rotation
                      (uri) => {
                        let fileResolution = {width: 0, height: 0};
                        let img = new Image();
                        img.onload = function() {
                            fileResolution = {width: this.width, height: this.height};
                        };
                        img.src = uri;
                        setTimeout(() => {
                            this.props.onChangeFunc({
                                'fileName': fileName,
                                'FileContents': uri,
                                'ContentType': fileType,
                                'FileSize': GlobalService.getFileSizeOfBase64(uri),
                                'fileExtension': fileExtension.toUpperCase() === "PNG" ? "png" : "jpg",
                                'previewUrl': uri,
                                'fileResolution': fileResolution
                            });
                        }, 500);
                      },
                      "base64", // outputType
                      null, // minWidth
                      null // minHeight
                    );
                  } catch (err) {
                    console.log(err);
                  }
            }else{
                reader.onloadend = (loadEvent) => {
                    let fileResolution = {width: 0, height: 0};
                    let img = new Image();
                    img.onload = function() {
                        fileResolution = {width: this.width, height: this.height};
                    };
                    img.src = _URL.createObjectURL(loadEvent);
                    var file = loadEvent.target.result;
                    var bytes = new Uint8Array(file);
                    setTimeout(() => {
                        this.props.onChangeFunc({
                            'file': loadEvent,
                            'fileName': fileName,
                            'FileContents': bytes,
                            'ContentType': fileType,
                            'FileSize': fileSize,
                            'fileExtension': fileExtension,
                            'previewUrl': previewUrl,
                            'fileResolution': fileResolution
                        });
                    }, 50);
                };
                reader.readAsArrayBuffer(event.target.files[0]);
            }



        }
    }

    onClickFunc = () => {
        if (this.props.clearInputOnClick) {
            $("#" + this.props.id).val('');
        }
    }

    onDragStart = (e) => {
        this.props.onDragOver(e, this.props.id)
    }
    onDragEnd = (e) => {
        this.props.onDragStop(e, this.props.id)
    }

    render() {
        return (
            <div className="wms-file-component">
                <div className={this.props.parentClassName}>
                    <input
                        type="file"
                        id={this.props.id}
                        name={this.props.fileName}
                        data-wms-selector={this.props.wmsSelector}
                        onChange={this.onChangeFunc}
                        disabled={this.props.disabled || this.props.loading}
                        onClick={this.onClickFunc}
                        accept={this.props.accept}
                        onDragOver={this.onDragStart}
                        onDragLeave={this.onDragEnd}
                    />

                    <WmsButton
                        label={this.props.label}
                        wmsSelector={"filebutton"}
                        onClickFunc={(e) => function () { }}
                        icon={this.props.icon}
                        disabled={this.props.disabled}
                        loading={this.props.loading} />

                    <label className="wms-filename-text" htmlFor={this.props.id}>{this.props.value}</label>
                </div>
            </div>
        );
    }

}
WmsFile.propTypes = {
    id: PropTypes.string, // this should write the id to the file
    disabled: PropTypes.bool, // default will be false
    maxLength: PropTypes.number, // this is the maxLength of the file
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the file
    parentClassName: PropTypes.string, //    this should parent tag class
    value: PropTypes.string, // this should control the value of
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    clearInputOnClick: PropTypes.bool, // set true for clear file input before click
    loading: PropTypes.bool, // this pass loading true/false base on process runing or complete
    accept: PropTypes.string, // set file type space saperator list, this are only selectable - like(" image/png image/jpg")
    icon: PropTypes.string, // browse button icon
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number
}

WmsFile.defaultProps = {
    disabled: false,
    required: false,
    loading: false,
    showToolTip: false,
    parentClassName: "",
    fileProcessType: 'FileRead',
    icon: 'fa-check',
    clearInputOnClick: false,
    onDragOver: () => { },
    onDragStop: () => { },
    accept: '',
    maxWidth: 0,
    maxHeight: 0
}


export default WmsFile;