
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import WmsScrollTo from '../components/WmsScrollTo/WmsScrollTo.jsx';
import { ItemsFormBasic } from './items-form-basic.component.jsx';
import { ItemsUofm } from './items-form-uofm.component.jsx';
import { ItemsAssembly } from "./items-form-assembly.component.jsx";
import { ItemsFormRecurringStorage } from "./items-form-recurring-storage.component.jsx";
import { ItemCopyRateModel } from './item-copy-rate-model.component.jsx';
import { ItemsFormStorageHandlingCharges } from "./items-form-storage-handling-charges.component.jsx";
import { ItemEditStorageChargeModel } from './item-edit-storage-charge-model.component.jsx';
import { ManageReceiptHolder } from "../receipt/manageReceipt/manageReceipt-holder.component.jsx";

//services
import * as itemService from './items.services.jsx';
import * as GlobalService from './../global_services';

export class ItemsForm extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;
        this.translate = window.translate;

        this.defaultItemObj = {
            "CustomerId": this.props.sharedCurrentCustomerObj.id,
            "CustomerName": this.props.sharedCurrentCustomerObj.name,
            "ItemId": 0,
            "Sku": "",
            "Assembly": {
                "MaterialNotes": "",
                "Components": []
            },
            "ItemBasics": {
                "Description": {
                    "ProductDescription": "",
                    "SecondaryDescription": "",
                    "Upc": null,
                    "SellPrice": null,
                    "ProductCost": null,
                    "ClassificationId": null
                },
                "InventoryOptions": {
                    "AllocationMethod": 0,
                    "MinOnHand": 0,
                    "MaxOnHand": null,
                    "ReorderQuantity": null,
                    "DaysBetweenCounts": null,
                    "StorageTemperature": null,
                    "CycleCountType": ""
                },
                "TrackingOptions": {
                    "SerialTracking": 0,
                    "LotTracking": 0,
                    "ExpirationTracking": 0,
                    "CostTracking": 0
                },
                "DirectedPutawayOptions": {
                    "LocationZones": [],
                    "MovableUnitLocationZones": [],
                    "OverAllocLocationZones": [],
                    "PreferredLocationId": null,
                    "ForceIntoPreferredLocation": false,
                    "AllowMixedItemStorage": false,
                    "AllowMixedLotStorage": false,
                    "MixedExpirationDaysThreshhold": null
                },
                "ShippingDocumentation": {
                    "CountryOfOrigin": "",
                    "HarmonizedTariffCode": null,
                    "Nmfc": null
                },
                "HazardousSubstanceInformation": {
                    "MaterialFlag": "",
                    "Class": null,
                    "ShippingName": null,
                    "MaterialId": null,
                    "LabelCode": null,
                    "PackagingGroup": null,
                    "Flashpoint": null
                },
                "ItemQualifiers": []
            },
            "UnitsOfMeasure": {
                "IsImperial": true,
                "InventoryUnitOfMeasure": {
                    "PrimaryUnitTypeId": 0,
                    "PrimaryUnitType": "",
                    "UseSecondaryUnit": false,
                    "SecondaryUnitTypeId": null,
                    "SecondaryUnitType": null,
                    "PrimaryUnitsPerSecondaryUnit": null
                },
                "PackagingUnit": {
                    "PackagingUomId": 0,
                    "PackagingUom": "",
                    "LengthMetric": null,
                    "WidthMetric": null,
                    "HeightMetric": null,
                    "WeightMetric": null,
                    "NetWeightMetric": null,
                    "InventoryUnitsPerUnit": 0
                },
                "MovableUnit": {
                    "MovableUnitUomIndex": '',
                    "MovableUnitUomId": 0,
                    "MovableUnitUom": "",
                    "Tie": 0,
                    "High": 0,
                    "LengthMetric": null,
                    "WidthMetric": null,
                    "HeightMetric": null,
                    "WeightMetric": null
                },
                "HasMovableUnit": true,
                "HasPackagingUnit": true
            },
            "totalArea": "0.0000",
            "totalVolume": "0.0000"
        }
        this.defaultStorageRateObj = {
            "CountUnitId": '1',
            "InventoryUnitsPerUnit": null,
            "MinCharge": null,
            "MonthsToSkipMinCharge": null
        };
        this.state = {
            showDiscard: false,
            saveLoader: false,
            Selector: 'Item',
            customLabels: GlobalService.getCustomLabels(),
            Rights: GlobalService.GetRights(),
            sharedItem: this.defaultItemObj,
            isItemFormsDirty: false,
            isRecurringSectionDirty: false,
            isStorageRateInitialise: false,
            isOpenCopyRateModal: false,
            isOpenStorageChargeModal: false,
            sharedCoutinngMechanismIndex: null,
            createItemKeyArr: [],
            sharedStorageRateResponse: this.defaultStorageRateObj,
            sharedStorageHandlingCharges: [],
            sharedEditItemOptions: {
                TypeOfUnitOptions: []
            },
            sharedSlectedStorageCharge: {},
            eachesPerCuft: 0,
            isSetEachesPerCuft: false,
            isUpcDuplicateModalShow: false,
            disableAssemblyTab: false,
            hideMaxAllocatePickLine: false,
        }
    }
    componentDidMount() {
        this.getItemData(this.props.sharedItemUri);
    }

    validUnitOfMeuser () {
        //If primary unit is not specified then consider it as an Integral Unit by Default
        var primaryUomOption = this.props.sharedItemOptions.TypeOfUnitOptions.find(item => item.Name === this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitType);
        var secondaryUomOption = this.props.sharedItemOptions.TypeOfUnitOptions.find(item => item.Name === this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType);

        //Do not accept decimal values if secondary unit is not specified or if both primary/secondary unit are integral
        return secondaryUomOption && secondaryUomOption.Value.IsIntegral && !!(this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitsPerSecondaryUnit% 1) && ((!primaryUomOption || primaryUomOption.Value.IsIntegral))

    }
    validInventoryUnitsPerUnit () {
        //If primary unit is not specified then consider it as an Integral Unit by Default
        var primaryUomOption = this.props.sharedItemOptions.TypeOfUnitOptions.find(item => item.Name === this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitType);
        var packaginUomOption = this.props.sharedItemOptions.TypeOfUnitOptions.find(item => item.Name === this.state.sharedItem.UnitsOfMeasure.PackagingUnit.PackagingUom);

        //Do not accept decimal values if packaging unit is not specified or if both primary/packaging unit are integral
        return packaginUomOption && packaginUomOption.Value.IsIntegral && !!(this.state.sharedItem.UnitsOfMeasure.PackagingUnit.InventoryUnitsPerUnit% 1) && ((!primaryUomOption || primaryUomOption.Value.IsIntegral))
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.sharedItemUri != nextProps.sharedItemUri) {
            this.getItemData(nextProps.sharedItemUri);
        }

        if (
            nextProps.sharedItemRateUri &&
            nextProps.sharedItemRateUri != this.props.sharedItemRateUri && !nextProps.sharedMultiSkuEnableRecurring
        ) {
            this.initStorageRateSetup(nextProps.sharedItemRateUri);
        } else if (
            nextProps.sharedMultiSkuEnableRecurring &&
            nextProps.sharedMultiSkuEnableRecurring != this.props.sharedMultiSkuEnableRecurring
        ) {
            this.setState({
                sharedStorageRateResponse: this.defaultStorageRateObj,
                sharedCoutinngMechanismIndex: null,
                isItemFormsDirty: false
            });
        }
    }

    getItemData(param_edituri) {
        if (param_edituri == null) {
            this.setState({
                isSetEachesPerCuft: false
            })
            return;
        };
        let tmpHideMaxAllocatePickLine = true
        let facilityDictList = GlobalService.getFacilityDictList()
        if (this.props.sharedCurrentCustomerObj.facilities && this.props.sharedCurrentCustomerObj.facilities.length) {
            for (let i = 0; i < this.props.sharedCurrentCustomerObj.facilities.length; i++) {
                const facilityId = this.props.sharedCurrentCustomerObj.facilities[i];
                if (facilityDictList[facilityId].replenishment) {
                    tmpHideMaxAllocatePickLine = false
                }
            }
        }
        this.setState({
            sharedItem: JSON.parse(JSON.stringify(this.defaultItemObj)),
            showDiscard: false,
            isItemFormsDirty: false,
            isRecurringSectionDirty: false,
            sharedStorageRateResponse: this.defaultStorageRateObj,
            sharedCoutinngMechanismIndex: null,
            isStorageRateInitialise: true,
            hideMaxAllocatePickLine: tmpHideMaxAllocatePickLine
        });

        if (param_edituri != '') {
            kendo.ui.progress($('#item_form_block'), true);
            itemService.getCreateEditItemsInitial(param_edituri).then((response) => {
                if (this.state.hideMaxAllocatePickLine) {
                    response.data.Item.MaxAllocateFromPickline = null
                }
                this.setGlobalDefaults(response.data);
                this.setState({
                    sharedEditItemOptions: response.data.Options
                });
            }).finally(() => {
                kendo.ui.progress($('#item_form_block'), false);
            });
        }
    }

    initStorageRateSetup = (param_itemrateuri) => {
        this.setState({ isStorageRateInitialise: false })
        itemService.storageRateSetup(param_itemrateuri).then((response) => {
            console.log("storageRateSetup response", response)
            if (response.status == 200) {

                let recurringOptions = this.props.sharedRecurringOptions.optionCountingMechanism;
                let storageResponse = response.data.RecurringStorage ? response.data.RecurringStorage : {};
                let handlingChargeResponse = response.data.StorageHandlingCharges ? response.data.StorageHandlingCharges : [];
                handlingChargeResponse = this.addRowNumber(handlingChargeResponse);
                let coutingMechanismIndex = null;

                recurringOptions.filter((item, index) => {
                    if (storageResponse.StorageCountScriptTemplateId == item.Id) {
                        coutingMechanismIndex = index;
                    }
                });
                if (storageResponse.InventoryUnitsPerUnit) {
                    this.setState({
                        isSetEachesPerCuft: true
                    })
                }

                if (!storageResponse) {
                    storageResponse = this.defaultStorageRateObj;
                } else if (storageResponse.CountUnitId == null) {
                    storageResponse.CountUnitId = 1;
                }


                this.setState({
                    sharedCoutinngMechanismIndex: coutingMechanismIndex,
                    sharedStorageRateResponse: storageResponse,
                    isStorageRateInitialise: true
                }, () => {
                    // set time out for set storage charge gridData,so grid is intialise
                    setTimeout(() => {
                        this.setState({
                            sharedStorageHandlingCharges: handlingChargeResponse
                        });
                    }, 500);
                });


            }

        }).finally(() => {
        });
    }
    addRowNumber = (param_data) => {
        var index = 0;
        for (var i = 0; i < param_data.length; i++) {
            var firstItem = param_data[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        return param_data;
    }

    onOpenCopyItemModal = () => {
        this.setState({ isOpenCopyRateModal: true });
    }

    onCloseCopyItemModal = () => {
        this.setState({ isOpenCopyRateModal: false });
    }

    onOpenEditStorageChargeModal = () => {
        this.setState({ isOpenStorageChargeModal: true });
    }

    onCloseEditStorageChargeModal = (reloadGrid) => {
        this.setState({ isOpenStorageChargeModal: false });
    }
    onStorageChargeGridDataChange = (gridData) => {
        gridData = this.addRowNumber(gridData);
        this.setState({
            isOpenStorageChargeModal: false,
            sharedStorageHandlingCharges: gridData
        });
        this.refs.storageHandlingCharges.changeNumberOfSelection();
    }

    onItemSelectFromCopy = (param_itemuri, param_itemkey_arr) => {
        this.setState({
            showDiscard: true,
            isRecurringSectionDirty: true
        }, () => {
            this.props.onLockSection('item-grid-section');
        });
        this.initStorageRateSetup(param_itemuri);
        this.onCloseCopyItemModal();
    }

    setGlobalDefaults = (responseData) => {
        var param_itemsData = responseData.Item;
        var param_itemsOptions = responseData.Options;
        const ImperialArr = ['Height', 'Length', 'Weight', 'Width'];
        const imeperialText = param_itemsData.UnitsOfMeasure.IsImperial == true ? 'Imperial' : 'Metric';

        ImperialArr.forEach(element => {
            param_itemsData['movable' + element] = parseInt(param_itemsData.UnitsOfMeasure.MovableUnit[element + imeperialText]);
            param_itemsData['packaging' + element] = parseInt(param_itemsData.UnitsOfMeasure.PackagingUnit[element + imeperialText]);
        });
        param_itemsData['packagingNetWeight'] = param_itemsData.UnitsOfMeasure.PackagingUnit['NetWeightImperial'];


        if (param_itemsData.ItemBasics == null) {
            param_itemsData.ItemBasics = {
                Description: {},
                InventoryOptions: {},
                TrackingOptions: {},
                DirectedPutawayOptions: {
                    MovableUnitLocationZones: [],
                    LocationZones: [],
                    OverAllocLocationZones: [],
                },
                ShippingDocumentation: {},
                HazardousSubstanceInformation: {},
                ItemQualifiers: []
            };
        }

        // Convert String in to Grid dataSource array
        let itemDirectPutAwayObj = param_itemsData.ItemBasics.DirectedPutawayOptions;

        itemDirectPutAwayObj.MovableUnitLocationZones = ArrayWms.convertStringToArray(itemDirectPutAwayObj.MovableUnitLocationZones, 'Name');
        itemDirectPutAwayObj.LocationZones = ArrayWms.convertStringToArray(itemDirectPutAwayObj.LocationZones, 'Name');
        itemDirectPutAwayObj.OverAllocLocationZones = ArrayWms.convertStringToArray(itemDirectPutAwayObj.OverAllocLocationZones, 'Name');
        param_itemsData.ItemBasics.ItemQualifiers = this.arrayToGridDatasourceArray(param_itemsData.ItemBasics.ItemQualifiers);

        param_itemsData.ItemBasics.DirectedPutawayOptions = itemDirectPutAwayObj

        // set index of MovableUnitUom from MovableUnitOptions
        let moveUnitIndex = "";
        if (param_itemsData.UnitsOfMeasure.MovableUnit.MovableUnitUom != null) {
            moveUnitIndex = ObjectWms.FindIndexByProperty(this.props.sharedItemOptions.MovableUnitOptions, "Name", param_itemsData.UnitsOfMeasure.MovableUnit.MovableUnitUom);
        }
        param_itemsData.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex = moveUnitIndex;

        let addRowNumberData = this.addRowNumber(param_itemsData.Assembly.Components);
        param_itemsData.Assembly.Components = addRowNumberData;
        var currNmfc = param_itemsData.ItemBasics.ShippingDocumentation.Nmfc;
        if (currNmfc) {
            var nmfcData = param_itemsOptions.NmfcOptions.find((element) => {
                if (element.NMFC.trim() == currNmfc.trim()) {
                    return element;
                }
            });
            param_itemsData.ItemBasics.ShippingDocumentation.Nmfc = nmfcData.NMFC;
            param_itemsData.ItemBasics.ShippingDocumentation.NMFCClass = nmfcData.Class;
            param_itemsData.ItemBasics.ShippingDocumentation.NMFCDescription = nmfcData.CommodityDescription;
        }
        this.setState({
            sharedItem: param_itemsData,
            showDiscard: false
        });
    }

    addRowNumber = (param_data) => {
        var index = 0;
        for (var i = 0; i < param_data.length; i++) {
            var firstItem = param_data[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        return param_data;
    }

    arrayToGridDatasourceArray(data) {
        let arrayOfarray = [];
        if (data != null && data.length > 0) {
            while (data.length) {
                let obj = data.splice(0, 1);
                if (obj[0]) {
                    arrayOfarray.push({ 'Name': obj[0] });
                }
            };
        }
        return arrayOfarray;
    }

    onItemDataset = (param_itemObj, param_reset_discard) => {

        let isDiscardShow = true;
        //Hack to rest discard flag for edit item after TotalValoume and totalArea calculation done
        if (param_reset_discard) {
            isDiscardShow = false
        }
        this.setState({
            showDiscard: isDiscardShow,
            isItemFormsDirty: isDiscardShow,
            sharedItem: Object.assign(
                {},
                this.state.sharedItem,
                param_itemObj
            ),
            disableAssemblyTab: param_itemObj.ItemBasics.ItemQualifiers.length > 0,
        }, () => {
            let hideSection = (isDiscardShow) ? 'item-grid-section' : '';
            this.props.onLockSection(hideSection);
        });
    }

    onRecurringStorageDataset = (param_obj) => {
        this.setState({
            showDiscard: true,
            isRecurringSectionDirty: true,
            sharedStorageRateResponse: param_obj
        }, () => {
            this.props.onLockSection('item-grid-section');
        });
    }
    onInitialStorageChargeGridData = (param_obj) => {
        this.setState({ sharedStorageRateResponse: param_obj });
    }

    onCountingMechnismChange = (e) => {
        let tpmtemplateId = null;
        if (e.target.value) {
            tpmtemplateId = this.props.sharedRecurringOptions.optionCountingMechanism[e.target.value].Id;
        }
        this.setState({
            showDiscard: true,
            isRecurringSectionDirty: true,
            sharedCoutinngMechanismIndex: e.target.value,
            sharedStorageRateResponse: Object.assign(
                {},
                this.state.sharedStorageRateResponse,
                {
                    StorageCountScriptTemplateId: tpmtemplateId
                }
            )
        }, () => {
            this.props.onLockSection('item-grid-section');
        });
    }

    onClickSave = () => {
        // if condition details
        // (this.props.sharedItemUri == '') - For Create item and
        // (!this.props.sharedMultiSkuEnableRecurring) -  check recurring storage sectiom is not open for multiple item
        if (this.props.sharedItemUri == '' && !this.props.sharedMultiSkuEnableRecurring
        ) {
            this.saveItem();
        }

        // else if condition details
        // (this.props.sharedItemUri != '') - For Edit item and
        // (this.state.isItemFormsDirty) - check if item object update
        else if (this.props.sharedItemUri != '' && this.state.isItemFormsDirty) {
            this.saveItem();
        }

        // if condition details
        // this.props.sharedItemUri != '' - check for not create item call, if it's create item then reccuring storage call after create item response
        // (this.props.sharedItemRateUri != null || this.props.sharedMultiSkuEnableRecurring) - check if itemRateUri set or multiple item select for recurring storage
        // (!this.props.isRecurringSectionDirty) -  check recurring object update
        if (this.props.sharedItemUri != '' &&
            (this.props.sharedItemRateUri != null || this.props.sharedMultiSkuEnableRecurring
            ) && this.state.isRecurringSectionDirty
        ) {
            this.saveRecurringStorage();

        }

    }
    saveItem = (isFrom) => {
        this.setState({ saveLoader: true });

        let itemsObj = JSON.parse(JSON.stringify(this.state.sharedItem));
        //set movableunit name and remove index propert from item object
        if (itemsObj.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex === '') {
            itemsObj.UnitsOfMeasure.MovableUnit.MovableUnitUom = null;
        } else {
            itemsObj.UnitsOfMeasure.MovableUnit.MovableUnitUom = this.props.sharedItemOptions.MovableUnitOptions[itemsObj.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex].Name;
        }
        delete itemsObj.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex;

        //convert grid data to string
        itemsObj.ItemBasics.DirectedPutawayOptions.MovableUnitLocationZones = StringWms.convertArrayOptionToString(itemsObj.ItemBasics.DirectedPutawayOptions.MovableUnitLocationZones);

        itemsObj.ItemBasics.DirectedPutawayOptions.LocationZones = StringWms.convertArrayOptionToString(itemsObj.ItemBasics.DirectedPutawayOptions.LocationZones);

        itemsObj.ItemBasics.DirectedPutawayOptions.OverAllocLocationZones = StringWms.convertArrayOptionToString(itemsObj.ItemBasics.DirectedPutawayOptions.OverAllocLocationZones);

        itemsObj.ItemBasics.ItemQualifiers = this.convertKeyPairArrayToSimpleArray(itemsObj.ItemBasics.ItemQualifiers);

        itemsObj.UnitsOfMeasure.PackagingUnit.InventoryUnitsPerUnit = parseFloat(itemsObj.UnitsOfMeasure.PackagingUnit.InventoryUnitsPerUnit);

        // WMS-17729 Changing value of CycleCountType from Unclassified to "" due to DB only holds 1 character.
        if (itemsObj.ItemBasics.InventoryOptions.CycleCountType == "Unclassified") {
            itemsObj.ItemBasics.InventoryOptions.CycleCountType = "";
        }

        if (itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitsPerSecondaryUnit == 0) {
            itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType = null;
        }

        itemsObj.UnitsOfMeasure.HasMovableUnit = true;
        itemsObj.UnitsOfMeasure.HasPackagingUnit = true;

        if (itemsObj.Assembly.Components.length == 0) {
            itemsObj.Assembly = null;
        }

        // check if "Use secondary UOM" checkbox is not check then set default value of "Secondary UOM","Primary quantity" and "Secondary quantity"
        // if (itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == false) {
        //     itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType = null;
        //     itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnit = 0;
        //     itemsObj.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnit = 0;
        // }
        var ignoreDuplicateUpc = false;
        if (isFrom == "ignoreDuplicate") {
            ignoreDuplicateUpc = true;
        }
        if (
            this.props.sharedCurrentCustomerObj &&
            this.props.sharedCurrentCustomerObj.options &&
            this.props.sharedCurrentCustomerObj.options.allowDuplicateUPCs
        ) {
            ignoreDuplicateUpc = true;
        }
        if (this.props.sharedItemUri == '') {
            itemService.saveNewItems(itemsObj, this.props.sharedCurrentCustomerObj.id, ignoreDuplicateUpc).then((response) => {
                this.setState({ saveLoader: false });
                if (typeof response != 'undefined') {

                    if (this.state.isRecurringSectionDirty) {
                        this.setState({
                            createItemKeyArr: [response.ItemId]
                        }, () => {
                            this.saveRecurringStorage('createItemBaseCall');
                        });
                    } else {
                        GlobalService.notification('success', this.translate('Label_Item_Create'));
                        this.props.onReloadGrid('reloadItemGrid');
                        this.onClickExitItemForm();
                    }
                    //set focus on edit receipt after save item
                    if (this.props.receiptObj) {
                        this.openManageReceiptWindow();
                    }
                }


            }).catch((error) => {
                if (error.response
                    && error.response.data
                    && error.response.data.Descriptions
                    && error.response.data.Descriptions.length > 0
                    && error.response.data.Descriptions[0].Descriptors.join(",").indexOf('ItemSummaryList.i=WMS.V2.Customer.Models.ItemSummaryList') >= 0
                    && error.response.data.Descriptions[0]['ErrorCode'] == "Duplicate"
                    && error.response.data.Descriptions[0]['Hint'] == "Duplicate Upc") {
                    this.setState({
                        isUpcDuplicateModalShow: true
                    });
                }
            }).finally(() => {
                this.setState({ saveLoader: false });
            });

        } else {

            itemService.updateExistingItems(itemsObj, this.props.sharedItemUri, ignoreDuplicateUpc).then((response) => {
                this.setState({ saveLoader: false });
                if (typeof response != 'undefined') {

                    GlobalService.notification('success', this.translate('Label_Item_Update'));
                    this.props.onReloadGrid('reloadItemGrid');
                    this.onClickExitItemForm();

                    //set focus on edit receipt after save item
                    if (this.props.receiptObj) {
                        this.openManageReceiptWindow();
                    }
                }

            }).catch((error) => {
                if (error.response
                    && error.response.data
                    && error.response.data.Descriptions
                    && error.response.data.Descriptions.length > 0
                    && error.response.data.Descriptions[0].Descriptors.join(",").indexOf('ItemSummaryList.i=WMS.V2.Customer.Models.ItemSummaryList') >= 0
                    && error.response.data.Descriptions[0]['ErrorCode'] == "Duplicate"
                    && error.response.data.Descriptions[0]['Hint'] == "Duplicate Upc") {
                    this.setState({
                        isUpcDuplicateModalShow: true
                    });
                }
            }).finally(() => {
                this.setState({ saveLoader: false });
            });
        }
    }
    saveRecurringStorage = (param_basecall) => {
        this.setState({ saveLoader: true });
        let param_payload = {};
        param_payload['StorageRateSetup'] = Object.assign({}, this.state.sharedStorageRateResponse);
        param_payload['StorageChargeGridRows'] = param_payload.StorageRateSetup.StorageChargeGridRows;
        delete param_payload.StorageRateSetup.Rates;
        delete param_payload.StorageRateSetup.StorageChargeGridRows;
        param_payload['CustomerId'] = this.props.sharedCurrentCustomerObj.id;

        //set null value if storage uom and mincharge flg false

        let countScriptArr = this.props.sharedRecurringOptions.StorageCountScriptsArr;
        let storageTempObj = countScriptArr[this.state.sharedCoutinngMechanismIndex];

        if (storageTempObj && !storageTempObj.IsLegacyStorageUomScript) {
            param_payload.StorageRateSetup.CountUnitId = null;
            param_payload.StorageRateSetup.InventoryUnitsPerUnit = null;
        }
        if (storageTempObj && !storageTempObj.IsMinCharge) {
            param_payload.StorageRateSetup.MinCharge = null;
            param_payload.StorageRateSetup.MonthsToSkipMinCharge = null;
        }
        if (!storageTempObj) {
            param_payload.StorageRateSetup.CountUnitId = null;
            param_payload.StorageRateSetup.InventoryUnitsPerUnit = null;
            param_payload.StorageRateSetup.MinCharge = null;
            param_payload.StorageRateSetup.MonthsToSkipMinCharge = null;
        }


        let itemIdsArr = this.state.createItemKeyArr;
        if (itemIdsArr.length == 0) {
            itemIdsArr = this.props.sharedSelectedItemIdsArr;
        }
        param_payload['ItemIds'] = itemIdsArr;

        itemService.storageRatesGroup(param_payload).then((response) => {

            if (typeof response != 'undefined') {

                let tmpMessage = this.translate('Label_Item_Update');
                if (param_basecall) {
                    this.translate('Label_Item_Create');
                    this.setState({ createItemKeyArr: [] });
                }
                GlobalService.notification('success', tmpMessage);
                this.props.onReloadGrid('reloadItemGrid');

                this.onClickExitItemForm();

            }

        }).finally(() => {
            this.setState({ saveLoader: false });
        });
    }
    openManageReceiptWindow = () => {
        this.$rootScope.OpenWindow({
            url: '<react-manage-receipt-holder></react-manage-receipt-holder>',
            reactComponent: ManageReceiptHolder,
            singleton: false,
            id: this.props.receiptObj.ReceiverId,
            title: this.translate('Label_Edit_Receipts'),
            active: true,
            useLineItem: true,
            lineItem: [{
                name: 'Ref: ',
                value: this.props.receiptObj.ReferenceNum,
            }, {
                name: 'Trans: ',
                value: this.props.receiptObj.receiverIds,
            }],
            icon: 'submenu-icon fa fa-edit',
            close: {
                'Title': this.translate('Label_Warning'),
                'Button1Title': this.translate('Label_Save_And_Exit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': 'Changes have been made, click \'Back\' to save your changes.'
            },
            receipt: this.props.receiptObj,
            flow: 'edit',
        });
    }

    convertKeyPairArrayToSimpleArray(data) {
        if (data.length == 0) {
            return null;
        }
        var returnObject = [];
        data.forEach(function (val, key) {
            returnObject.push(val.Name);
        });
        return returnObject;
    }

    onClickExitItemForm = () => {
        this.setState({
            showDiscard: false,
            isStorageRateInitialise: false
        });
        this.props.onExitItemForm();
    }
    calculateCuftForRecStorage = (data) => {
        var totalActualVolume = data.totalActualVolume;
        var InventoryUnitsPerUnit = data.UnitsOfMeasure.PackagingUnit.InventoryUnitsPerUnit;
        var tmpEachesPerCuft = 0;
        if (totalActualVolume && InventoryUnitsPerUnit) {
            tmpEachesPerCuft = (InventoryUnitsPerUnit / totalActualVolume).toFixed(10);
        }
        this.setState({
            eachesPerCuft: tmpEachesPerCuft
        })

    }
    onAssemblyTabShow = () => {
        var itemsAssemblyGrid = $('#GridItemAssemblies').data("kendoGrid");
        if (itemsAssemblyGrid) {
            itemsAssemblyGrid.resize(true);
        }
    }
    onSetSlectedStorageChargeObj = (paramStorageChargeObj) => {
        this.setState({ sharedSlectedStorageCharge: paramStorageChargeObj });
    }
    onYesReuseUpc = () => {
        this.setState({
            isUpcDuplicateModalShow: false
        });
        this.saveItem('ignoreDuplicate');
    }
    onCloseDuplicateUpcConfirmation = () => {
        this.setState({
            isUpcDuplicateModalShow: false
        })
    }
    render() {
        const itemObjCopy = Object.assign({}, this.state.sharedItem);
        let imperialMetricText = this.state.sharedItem.UnitsOfMeasure.IsImperial == true ? 'Imperial' : 'Metric';
        const PackagingUnitObj = this.state.sharedItem.UnitsOfMeasure.PackagingUnit;
        const IsPrepackagedEnable = PackagingUnitObj['Weight' + imperialMetricText] != undefined
            && PackagingUnitObj['Weight' + imperialMetricText] != ""
            && parseInt(PackagingUnitObj['Weight' + imperialMetricText]) != 0
            && PackagingUnitObj['Height' + imperialMetricText] != undefined
            && PackagingUnitObj['Height' + imperialMetricText] != ""
            && parseInt(PackagingUnitObj['Height' + imperialMetricText]) != 0
            && PackagingUnitObj['Width' + imperialMetricText] != undefined
            && PackagingUnitObj['Width' + imperialMetricText] != ""
            && parseInt(PackagingUnitObj['Width' + imperialMetricText]) != 0
            && PackagingUnitObj['Length' + imperialMetricText] != undefined
            && PackagingUnitObj['Length' + imperialMetricText] != ""
            && parseInt(PackagingUnitObj['Length' + imperialMetricText]) != 0;
        return (
            <div className={
                (
                    this.props.sharedLockedSection == 'item-form-tab-section' ?
                        "wms-overlay-opacity" : ""
                ) + " items-content-wrapper"} id="item_form_block" >
                <div className="item-form-header-group">
                    <div className="tab-listing">
                        <ul>
                            <li id="ItemBasicsTab">
                                <WmsScrollTo
                                    label={this.state.customLabels.Label_Item + 's'}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsBasics'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.state.Selector + "LabelItem"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring}
                                />
                            </li>

                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Units_Of_Measure')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsUofM'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.state.Selector + "LabelUnitsOfMeasure"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring}
                                />
                            </li>

                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Assembly')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsAssembly'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.state.Selector + "LabelAssembly"}
                                    isTabSection={true}
                                    onTabShow={this.onAssemblyTabShow}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring || this.state.disableAssemblyTab}
                                />
                            </li>
                            {this.state.Rights.hasOwnProperty('customer.items.rates') ? (
                            <li id="recurringStorageTab">
                                <WmsScrollTo
                                    label={this.translate('Item_Section_Recurring_Storage')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseRecurringStorage'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.state.Selector + "RecurringStorage"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"} />
                            </li>
                            ): null}
                            {this.state.Rights.hasOwnProperty('customer.items.rates') && (this.props.sharedItemUri == null || this.props.sharedItemUri != '') && (
                                <li>
                                    <WmsScrollTo
                                        label={this.translate('Item_Section_Storage_Handling')}
                                        windowId={this.props.windowId}
                                        scrollToId='collapseStorageHandling'
                                        scrollBoxId='ItemsScrollBox'
                                        wmsSelector={this.state.Selector + "StorageHandling"}
                                        isTabSection={true}
                                        tabCommonClassForHide={"collapseItemsTabContent_wrapper"} />
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="form-button-group">
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.Selector + 'SaveButton'}
                            onClickFunc={this.onClickSave}
                            icon='fa-floppy-o'
                            showToolTip={true}
                            tooltipText={
                                (this.state.isItemFormsDirty == false && this.state.isRecurringSectionDirty == false) ?
                                    this.translate('Tooltip_Save_Disabled') :
                                    this.translate('Tooltip_Required_Fields'
                                    )
                            }
                            disabled={
                                this.state.saveLoader ||
                                    (this.props.sharedMultiSkuEnableRecurring) ? false :
                                    (
                                        (this.state.isItemFormsDirty == false && this.state.isRecurringSectionDirty == false) ? true
                                            :
                                            this.state.sharedItem.Sku == '' ||
                                            this.state.sharedItem.ItemBasics.Description.ProductDescription == '' ||
                                            (this.validUnitOfMeuser()) ||
                                            (this.validInventoryUnitsPerUnit()) ||
                                            (this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == true &&
                                                (!this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType ||
                                                    !this.state.sharedItem.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitsPerSecondaryUnit))
                                    ) ||
                                    ((this.state.isRecurringSectionDirty == true) &&
                                        this.props.sharedRecurringOptions.StorageCountScriptsArr[this.state.sharedCoutinngMechanismIndex] &&
                                        this.props.sharedRecurringOptions.StorageCountScriptsArr[this.state.sharedCoutinngMechanismIndex].IsLegacyStorageUomScript &&
                                        this.state.sharedStorageRateResponse.CountUnitId && !this.state.sharedStorageRateResponse.InventoryUnitsPerUnit)
                                    || ((this.state.sharedItem.PicklineMin ) && !this.state.sharedItem.PicklineMax)
                                    || (this.state.Rights.hasOwnProperty('UxDirectivesPrePackedItems') && this.state.sharedItem.UnitsOfMeasure.PackagingUnit.IsPrepackaged && !IsPrepackagedEnable)

                            }
                            loading={this.state.saveLoader} />

                        {this.state.showDiscard == false && (
                            <WmsButton
                                label={this.translate('Label_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonExit'}
                                onClickFunc={this.onClickExitItemForm}
                                icon='fa-sign-out'
                                buttonType="yellow" />
                        )}

                        {this.state.showDiscard == true && (
                            <WmsButton
                                label={this.translate('Label_Discard_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonDiscard'}
                                onClickFunc={this.onClickExitItemForm}
                                icon='fa-times-circle'
                                buttonType="yellow" />
                        )}
                    </div>
                </div>

                <div id="ItemsScrollBox" className={this.props.windowId + " tab-content"}>
                    {this.props.sharedItemUri != null && (
                        <div>
                            <ItemsFormBasic
                                sharedCurrentCustomerObj={this.props.sharedCurrentCustomerObj}
                                sharedItemOptions={this.props.sharedItemOptions}
                                sharedItem={itemObjCopy}
                                windowId={this.props.windowId}
                                onItemDataset={this.onItemDataset}
                            />

                            <ItemsUofm
                                sharedItemOptions={this.props.sharedItemOptions}
                                sharedItem={itemObjCopy}
                                onItemDataset={this.onItemDataset}
                                calculateCuftForRecStorage={this.calculateCuftForRecStorage}
                                sharedCurrentCustomerObj={this.props.sharedCurrentCustomerObj}
                                validUnitOfMeuser={this.validUnitOfMeuser.bind(this)}
                                validInventoryUnitsPerUnit={this.validInventoryUnitsPerUnit.bind(this)}
                                hideMaxAllocatePickLine={this.state.hideMaxAllocatePickLine}
                            />

                            <ItemsAssembly
                                sharedCurrentCustomerObj={this.props.sharedCurrentCustomerObj}
                                sharedItem={itemObjCopy}
                                sharedItemUri={this.props.sharedItemUri}
                                windowId={this.props.windowId}
                                onItemDataset={this.onItemDataset} />
                        </div>
                    )}

                    <div className={"collapseItemsTabContent_wrapper"} id={'collapseRecurringStorage'}>
                        {this.state.Rights.hasOwnProperty('customer.items.rates') && (this.state.isStorageRateInitialise || this.props.sharedMultiSkuEnableRecurring)
                            && (
                                <ItemsFormRecurringStorage
                                    sharedCurrentCustomerObj={this.props.sharedCurrentCustomerObj}
                                    windowId={this.props.windowId}
                                    sharedRecurringOptions={this.props.sharedRecurringOptions}
                                    onRecurringStorageDataset={this.onRecurringStorageDataset}
                                    onInitialStorageChargeGridData={this.onInitialStorageChargeGridData} sharedMultiSkuEnableRecurring={this.props.sharedMultiSkuEnableRecurring}
                                    sharedCoutinngMechanismIndex={this.state.sharedCoutinngMechanismIndex}
                                    onCountingMechnismChange={this.onCountingMechnismChange}
                                    sharedStorageRateResponse={this.state.sharedStorageRateResponse}
                                    onOpenCopyItemModal={this.onOpenCopyItemModal}
                                    isSetEachesPerCuft={this.state.isSetEachesPerCuft}
                                    eachesPerCuft={this.state.eachesPerCuft} />
                            )}
                    </div>
                    <div className={"collapseItemsTabContent_wrapper"} id={'collapseStorageHandling'}>
                        {this.state.Rights.hasOwnProperty('customer.items.rates') && this.props.sharedItemUri != null && (
                            <ItemsFormStorageHandlingCharges
                                ref="storageHandlingCharges"
                                onOpenEditStorageChargeModal={this.onOpenEditStorageChargeModal}
                                sharedStorageHandlingCharges={this.state.sharedStorageHandlingCharges}
                                sharedItem={itemObjCopy}
                                onSetSlectedStorageChargeObj={this.onSetSlectedStorageChargeObj} />
                        )}
                    </div>

                    {this.state.isOpenCopyRateModal == true ? (
                        <ItemCopyRateModel
                            isOpenCopyRateModal={this.state.isOpenCopyRateModal}
                            onClose={this.onCloseCopyItemModal}
                            sharedCurrentCustomerObj={this.props.sharedCurrentCustomerObj}
                            onItemSelectFromCopy={this.onItemSelectFromCopy}
                        />
                    ) : ''}

                    {this.state.isOpenStorageChargeModal == true ? (
                        <ItemEditStorageChargeModel
                            isOpenStorageChargeModal={this.state.isOpenStorageChargeModal}
                            onClose={this.onCloseEditStorageChargeModal}
                            sharedEditItemOptions={this.state.sharedEditItemOptions}
                            sharedSlectedStorageCharge={this.state.sharedSlectedStorageCharge}
                            sharedDefaultStorageHandlingCharges={this.props.sharedDefaultStorageHandlingCharges}
                            sharedItemRateUri={this.props.sharedItemRateUri}
                            onStorageChargeGridDataChange={this.onStorageChargeGridDataChange}
                        />
                    ) : ''}
                </div>
                <WmsConfirmModel
                    id="itemUpcDuplicate"
                    isOpen={this.state.isUpcDuplicateModalShow}
                    width={500}
                    height={240}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnSelector={"OrderContinueButton"}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.onYesReuseUpc}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Item_Upc_Duplicate_Confirmation')}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseDuplicateUpcConfirmation}
                />
            </div>
        )
    }
}