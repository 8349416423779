export const getPackagingTypeGridDemoColumns = (PackagingTypes_GridHeaders) => {
    var returnColumns = {
        columns: [
        {
            field: "MaterialType",
            title: PackagingTypes_GridHeaders[14].description,
            width: 100,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesMaterialType'
            },
            template: "#= MaterialType != 1 ? 'Package' : 'Insert' #",
        },
        {
            field: "Id",
            title: PackagingTypes_GridHeaders[0].description,
            width: 100,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesGrID'
            },
        }, {
            field: "Name",
            title: PackagingTypes_GridHeaders[1].description,
            width: 345,
            wmsid: 7,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesGrPackageName'
            }
        }, {
            field: "Description",
            title: PackagingTypes_GridHeaders[2].description,
            width: 120,
            wmsid: 9,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesGrDescription'
            },

        }, {
            field: "Barcode",
            title: PackagingTypes_GridHeaders[3].description,
            width: 120,
            wmsid: 16,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesGridPackageBarcode'
            }
        }, {
            field: "IsDefault",
            title: PackagingTypes_GridHeaders[4].description,
            width: 120,
            wmsid: 20,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'PackagingTypesGridDefaultPackage'
            }
        }
        ]
    };
    return returnColumns;
}

export const getPackagingTypeGridAllColumnList = (PackagingTypes_GridHeaders) => {
    var returnColumns = [
    {
        field: "MaterialType",
        title: PackagingTypes_GridHeaders[14].description,
        width: 100,
        wmsid: 4,
        lockable: true,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesMaterialType'
        },
        template: "#= MaterialType != 1 ? 'Package' : 'Insert' #",
    },
    {
        field: "Id",
        title: PackagingTypes_GridHeaders[0].description,
        width: 100,
        wmsid: 4,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGrID'
        }
    }, {
        field: "Name",
        title: PackagingTypes_GridHeaders[1].description,
        width: 345,
        wmsid: 7,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGrPackageName'
        }
    }, {
        field: "Description",
        title: PackagingTypes_GridHeaders[2].description,
        width: 120,
        wmsid: 9,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGrDescription'
        },

    }, {
        field: "Barcode",
        title: PackagingTypes_GridHeaders[3].description,
        width: 120,
        wmsid: 16,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridPackageBarcode'
        }
    }, {
        field: "IsDefault",
        title: PackagingTypes_GridHeaders[4].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridDefaultPackage'
        }
    }, {
        field: "customerName",
        title: PackagingTypes_GridHeaders[5].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridAssignedCustomers'
        }
    }, {
        field: "LengthImperial",
        title: PackagingTypes_GridHeaders[6].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridLength(in)'
        }
    }, {
        field: "LengthMetric",
        title: PackagingTypes_GridHeaders[7].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridLength(cm)'
        }
    }, {
        field: "WidthImperial",
        title: PackagingTypes_GridHeaders[8].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWidth(in)'
        }
    }, {
        field: "WidthMetric",
        title: PackagingTypes_GridHeaders[9].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWidth(cm)'
        }
    }, {
        field: "HeightImperial",
        title: PackagingTypes_GridHeaders[10].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridHeight(in)'
        }
    }, {
        field: "HeightMetric",
        title: PackagingTypes_GridHeaders[11].description,
        width: 100,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridHeight(cm)'
        }
    }, {
        field: "WeightImperial",
        title: PackagingTypes_GridHeaders[12].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWeight(Lb)'
        }
    }, {
        field: "WeightMetric",
        title: PackagingTypes_GridHeaders[13].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWeight(Kg)'
        }
    },
    {
        field: "ItemIdentifier",
        title: PackagingTypes_GridHeaders[15].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWeight(Kg)'
        },
        template: "#= ItemIdentifier != null && ItemIdentifier.Sku != undefined ?  ItemIdentifier.Sku : '' #",
    }, {
        field: "LifespanStartDate",
        title: PackagingTypes_GridHeaders[16].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWeight(Kg)'
        },
        template: "#= LifespanStartDate != null ? kendo.toString(kendo.parseDate(LifespanStartDate), 'MM/dd/yyyy')  : '' #",
    }, {
        field: "LifespanEndDate",
        title: PackagingTypes_GridHeaders[17].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesGridWeight(Kg)'
        },
        template: "#= LifespanEndDate != null ? kendo.toString(kendo.parseDate(LifespanEndDate), 'MM/dd/yyyy')  : '' #",
    }, {
        field: "CurrentQty",
        title: PackagingTypes_GridHeaders[18].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'PackagingTypesCurrentQty'
        },
    }
    ];
    return returnColumns;
}

export const getPackagingTypeGridDemoSchema = () => {
    return ({
        model: {
            id: "PackageDefId",
            fields: {
                MaterialType: { type: "boolean" },
                PackageDefId: { type: "number" },
                Name: { type: "string" },
                Description: { type: "string" },
                Barcode: { type: "string" },
                IsDefault: { type: "boolean" },
                customerName: { type: "string" },
                AssignedCustomers: { type: "array" },
                LengthImperial: { type: "number" },
                LengthMetric: { type: "number" },
                WidthImperial: { type: "number" },
                WidthMetric: { type: "number" },
                HeightImperial: { type: "number" },
                HeightMetric: { type: "number" },
                WeightImperial: { type: "number" },
                WeightMetric: { type: "number" },
                CurrentQty: { type: "string" },
            }
        }
    })
}