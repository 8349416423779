import React from 'react';

//component
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from './../../components/WmsInput/WmsInput.jsx';

import * as inventoryservices from './../inventory.services.jsx';
import * as GlobalService from './../../global_services';
import { _ } from "./../../global_variable_service.jsx";
import style from './inventory-hold.scss';

export class InventoryHoldModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this._isMounted = false;

        this.state = {
            selector: 'Inventory',
            holdInventoryLoader: false,
            holdReasonOptions: [],
            selectedHoldReason: '',
            otherHoldReason: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let holdReasonArr = this.translate('Inventory_HoldReason').split(',');
        let holdReasonOptions = [];
        (holdReasonArr).forEach(value => {
            if (value != '') {
                holdReasonOptions.push({ "Name": value, "Value": value });
            }
        });
        this.setState({
            holdReasonOptions: holdReasonOptions,
            otherHoldReason: ''
        });

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ selectedHoldReason: '' });
    }

    holdReasonChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    holdInventory = () => {

        this.setState({ holdInventoryLoader: true });

        let receiveItemIds = [];
        if (this.props.sharedIsCombineRow) {
            let itemsObj = this.props.localKendoGrid.getDictionary().items;
            let getIdArray = _.map(itemsObj, 'receiveItemIdsAvailable');
            getIdArray.map(function (data) {
                var array = data;
                array.map(function (rid) {
                    receiveItemIds.push(rid);
                });
            });
        } else {
            let itemsObj = this.props.localKendoGrid.getDictionary().items;
            let filterObject = _.filter(itemsObj, function (o) { return !o.OnHold; });
            let getIdArray = _.map(filterObject, 'ReceiveItemId');
            getIdArray.map(function (data) {
                receiveItemIds = _.concat(receiveItemIds, data);
            });
        }

        let holdData = {
            'Reason': this.state.selectedHoldReason == 'Other' ? (this.state.selectedHoldReason + ' - ' + this.state.otherHoldReason) : this.state.selectedHoldReason,
            'putOnHold': true,
            'receiveItemIds': receiveItemIds,
        };
        inventoryservices.holdOrReleaseInventory(holdData).then((response) => {
            this.setState({ holdInventoryLoader: false });
            if (typeof response != 'undefined' && response.status == 200) {
                $("#holdInventory_" + this.props.windowId).data('kendoWindow').close();
                if (response.data.Shipped.length || response.data.InProcess.length) {
                    setTimeout(() => {
                        this.props.onOpenHoldResultMsg(response.data);
                    }, 550);
                } else {
                    GlobalService.notification('success', this.translate('Label_Place_Hold_Inventory_Success'));
                }
                this.props.resetGridSelectionAndButton();
            } else {
                GlobalService.apiFailer(response);
            }

        }).finally(() => {
            if(this._isMounted) {
                this.setState({ holdInventoryLoader: false });
            }
        });
    }

    render() {
        return (
            <WmsModel
                id={'holdInventory_' + this.props.windowId}
                title={this.translate('Label_Place_Hold_Inventory')}
                width={800}
                height={201}
                onCloseFunc={this.props.onCloseHoldInventoryModal}
                isOpen={this.props.isHoldModelOpen}
                customClass='wms-model-new-ui-wrapper'
                class="inventory-hold-reason-model-wrapper">

                <div className="model-content-wrapper">

                    <WmsCollapse
                        id={'manageInventoryReasonForHold'}
                        headerLabel={this.translate('Label_Reason_for_Hold')}
                        showCollapseText={false}
                        showCollapseIcon={false}>

                        <WmsDropdown
                            id="selectedHoldReason"
                            windowId={this.props.windowId}
                            name="selectedHoldReason"
                            label={this.translate('Label_Reason_for_Hold')}
                            wmsSelector={this.state.selector + "HoldInventoryMessage"}
                            value={this.state.selectedHoldReason}
                            onChangeFunc={this.holdReasonChange}
                            options={this.state.holdReasonOptions}
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified"
                            textField='Name'
                            valueField='Value' />

                        <WmsInput
                            id="otherHoldReason"
                            inputName="otherHoldReason"
                            label={this.translate('Label_SKU_Description')}
                            value={this.state.otherHoldReason}
                            disabled={this.state.selectedHoldReason != 'Other'}
                            extraWrapperClass={this.state.selectedHoldReason != 'Other' ? 'disable-input' : ''}
                            onChangeFunc={this.holdReasonChange}
                            wmsSelector={this.state.selector + "otherHoldReason"}
                            maxLength={240}
                        />

                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "ExitButton"}
                        onClickFunc={this.props.onCloseHoldInventoryModal}
                        icon="fa-ban"
                        buttonType="orange"
                        disabled={this.state.holdInventoryLoader}
                        loading={this.state.holdInventoryLoader}
                    />

                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.state.selector + "HoldInventoryButton"}
                        onClickFunc={this.holdInventory}
                        icon='fa-floppy-o'
                        disabled={
                            this.state.selectedHoldReason == '' ||
                            this.state.selectedHoldReason == null ||
                            this.state.holdInventoryLoader
                        }
                        loading={this.state.holdInventoryLoader} />
                </div>
            </WmsModel>
        );
    }
}