import React from "react";
import WmsFormGrid from "./../react/components/WmsFormGrid/WmsFormGrid";
import PropTypes from "prop-types";
import "./itemAlias.scss";

interface Props {
    dataItem: any;
    gridId: string;
    onSetExpandGridData: Function;
    windowId: string | undefined;
    onSelection: Function;
}

// declare var window: any;

const ItemAliasImportParseGridDetail: React.FC<Props> = (props: Props) => {
    return (
        <div id="ItemAliasImportParseGridDetailWapper">
            <WmsFormGrid
                columns={[
                    {
                        field: "index",
                        title: "",
                        lockable: false,
                        width: "30%",
                    },
                    {
                        field: "alias",
                        title: "",
                        lockable: false,
                        width: "80%",
                    },
                    {
                        field: "sku",
                        title: "sku",
                        lockable: false,
                        width: "50%",
                    },
                    {
                        field: "description",
                        title: "description",
                        lockable: false,
                        width: "100%",
                    },
                ]}
                schema={{
                    model: {
                        fields: {
                            index: {
                                type: "number",
                            },
                            alias: {
                                type: "string",
                            },
                            noOfItemsText: {
                                type: "string",
                            },
                            description: {
                                type: "string",
                            },
                            duplicate: {
                                type: "string",
                            },
                        },
                    },
                }}
                wmsSelector={`ItemAliasImportParseGridDetail_${props.gridId}_${props.dataItem.index || 0}`}
                // windowId={this.props.windowId}
                showResetMenu={false}
                isColumnsReorderable={true}
                isColumnsFilterable={true}
                isColumnsSortable={false}
                isColumnMenuenable={true}
                gridKeySelection={""}
                id={`ItemAliasImportParseGridDetail_${props.gridId}_${props.dataItem.index || 0}`}
                staticGridData={props.dataItem.skuItems}
                parentClassName=""
            />
        </div>
    );
};

ItemAliasImportParseGridDetail.propTypes = {
    dataItem: PropTypes.object.isRequired,
    gridId: PropTypes.string.isRequired,
    onSetExpandGridData: PropTypes.func,
    windowId: PropTypes.string,
    onSelection: PropTypes.func,
};

ItemAliasImportParseGridDetail.defaultProps = {
    onSetExpandGridData: (): void => {},
    windowId: null,
    onSelection: (): void => {},
};

export { ItemAliasImportParseGridDetail };
