import React, { useState, useEffect } from "react";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import { StringWms } from "../../assets/ts/StringWms";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import * as GlobalService from "./../react/global_services";
import * as GridConfig from "./item-alias-grid-configuration";
import WmsGrid from "./../react/components/WmsGrid/WmsGrid";
import ItemAliasAddNewItemModel from "./item-alias-add-item.modal.component";

import _ from "lodash";
import "./itemAlias.scss";
declare var window: any;
interface Props {
    onCloseItemAliasModal: any;
    isOpenItemAliasModal: boolean;
    wmsSelector: string;
    selectedRow: any;
    windowId: string;
    onApplySaveAlias: any;
    applyingAlias: boolean;
    isEditMode: boolean;
    customerId: number;
    CustomerOptions: any;
}

const ItemAliasModal: React.FC<Props> = (props: Props): any => {
    const [isEditMode] = useState(props.isEditMode);
    const [itemAliasData, setItemAliasData] = useState(props.selectedRow);

    const itemGrideHeaders = StringWms.convertSringToOption(window.translate("Label_item_grid_headers"));
    const itemsGridColumns = GridConfig.getItemGridColumns(itemGrideHeaders);
    const itemsGridSchema = GridConfig.getItemGridSchema().schema;
    const [searchString, setSearchString] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [aliasItemComponentSku, setAliasItemComponentSku] = useState([]);
    const [toShowAliasItemComponentSku, setToShowAliasItemComponentSku] = useState([]);
    const [isGridChangeLoading, setIsGridChangeLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [isOpenAddNewItemModel, setIsOpenAddNewItemModel] = useState(false);

    const handleSearchChange = (event: any) => {
        let { value } = event.target;
        setSearchString(value);
        filterChange();
    };
    let filterChangeWithDebounce = () => {
        applySearchItemGridData([...aliasItemComponentSku], searchString);
    };

    let filterChange = GlobalService.useFCDebounce(filterChangeWithDebounce, 1000);
    const applySearchItemGridData = (itemComponentSku, filterString) => {
        setIsGridChangeLoading(true);
        let tempAliasItemComponentSku = itemComponentSku;
        if (filterString !== "" && tempAliasItemComponentSku.length > 0) {
            tempAliasItemComponentSku = tempAliasItemComponentSku.filter((obj) => {
                return obj.Sku.includes(filterString) || obj.Description?.includes(filterString);
            });
        }
        setToShowAliasItemComponentSku(tempAliasItemComponentSku);
        setRecordsCount(tempAliasItemComponentSku.length);
        setTimeout(() => {
            setIsGridChangeLoading(false);
        }, 100);
    };
    const addItemsToAliasComponents = (skuItems) => {
        let tempAliasItemComponentSku = [...aliasItemComponentSku];
        skuItems.map((value) => {
            let obj = {
                ItemId: value.ItemId,
                Sku: value.Sku,
                Description: value.Description,
                IsActive: value.IsActive,
                Qty: 1,
            };
            if (!tempAliasItemComponentSku.some((el) => el.Sku === value.Sku)) {
                tempAliasItemComponentSku.push(obj);
            }
            return false;
        });
        setAliasItemComponentSku(tempAliasItemComponentSku);
        applySearchItemGridData(tempAliasItemComponentSku, "");
        setSearchString("");
    };
    useEffect(() => {
        let tempAliasItemComponentSku = [];
        if (props.isEditMode && props.selectedRow?.Details && props.selectedRow.Details.length > 0) {
            props.selectedRow.Details.forEach((value) => {
                let obj = {
                    ItemId: value.ItemIdentifier.Id,
                    Sku: value.ItemIdentifier.Sku,
                    Description: value.Description,
                    IsActive: true,
                    Qty: value.Qty,
                };
                if (!tempAliasItemComponentSku.some((el) => el.Sku === value.Sku)) {
                    tempAliasItemComponentSku.push(obj);
                }
            });
        }
        setAliasItemComponentSku(tempAliasItemComponentSku);
        applySearchItemGridData(tempAliasItemComponentSku, "");
        setSearchString("");
    }, [props.selectedRow, props.isEditMode]);
    const handleChangeAliasData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "Active") {
            finalValue = event.target.checked;
        }

        setItemAliasData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    const saveItemAlias = () => {
        setIsSaveLoading(true);
        var formData = {
            Active: itemAliasData.Active,
            AliasIdentifier: {
                Sku: itemAliasData.Alias,
            },
            Description: itemAliasData.Description,
            Details: [],
        };
        _.map(aliasItemComponentSku, function (value) {
            if (value.Qty > 0) {
                formData.Details.push({
                    ItemIdentifier: {
                        Sku: value.Sku,
                    },
                    Qty: value.Qty,
                });
            }
            return value;
        });
        console.log("final save data", formData);
        props.onApplySaveAlias(formData);
    };
    const allValidationDone = () => {
        return (
            itemAliasData.Alias === undefined ||
            itemAliasData.Alias === "" ||
            aliasItemComponentSku.find((obj) => obj.Qty > 0) === undefined
        );
    };
    const openAddNewItems = () => {
        setIsOpenAddNewItemModel(true);
    };
    const closeItemAliasAddItemModel = () => {
        setIsOpenAddNewItemModel(false);
    };
    const onEdit = (e) => {
        var grid = $("#" + props.wmsSelector + "selectedItemGrid").data("kendoGrid");
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName === "qty") {
            grid.closeCell();
        }
    };
    const EventSaveGridAppliedCharges = (e) => {
        var grid = $("#" + props.wmsSelector + "selectedItemGrid").data("kendoGrid");
        if (e.model && e.model.ItemId) {
            let tempAliasItemComponentSku = [...aliasItemComponentSku];
            tempAliasItemComponentSku = tempAliasItemComponentSku.map((el) => {
                if (el.ItemId === e.model.ItemId) {
                    el.Qty = e.values.Qty;
                }
                return el;
            });
            setAliasItemComponentSku(tempAliasItemComponentSku);
        }
        grid.saveChanges();
    };
    const appliedChargesGridChange = (e) => {
        //   Still nothing to do here.
    };
    useEffect(() => {
        $(document).off("click", ".remove-selected-item");
        $(document).on("click", ".remove-selected-item", function () {
            const rowIndex = parseInt($(this).data("id"));
            removeItemToAliasComponents(rowIndex);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toShowAliasItemComponentSku]);
    const removeItemToAliasComponents = (itemId) => {
        let tempAliasItemComponentSku = [...aliasItemComponentSku];
        tempAliasItemComponentSku = tempAliasItemComponentSku.filter((el) => el.ItemId !== itemId);
        setAliasItemComponentSku(tempAliasItemComponentSku);
        applySearchItemGridData(tempAliasItemComponentSku, searchString);
    };

    return (
        <>
            <WmsModel
                id="CreateItemAliasModel"
                title={isEditMode ? window.translate("Label_edit_alias") : window.translate("Label_create_new_alias")}
                width={800}
                height={800}
                onCloseFunc={props.onCloseItemAliasModal}
                isOpen={props.isOpenItemAliasModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className={"model-content-wrapper"}>
                    <div className="display-inline">
                        <WmsInput
                            id="Alias"
                            inputName="Alias"
                            wmsSelector={props.wmsSelector + "Alias"}
                            onChangeFunc={handleChangeAliasData}
                            label={window.translate("Label_alias_name")}
                            value={itemAliasData.Alias}
                            extraWrapperClass="alias-name-input width-100"
                            required={true}
                        />
                    </div>
                    <div className="display-inline">
                        <WmsInput
                            id="Description"
                            inputName="Description"
                            wmsSelector={props.wmsSelector + "Description"}
                            onChangeFunc={handleChangeAliasData}
                            label={window.translate("Label_Description")}
                            value={itemAliasData.Description}
                            extraWrapperClass="alias-description-input width-100"
                        />
                    </div>
                    <div className="display-inline">
                        <WmsDropdown
                            id="Active"
                            name="Active"
                            label={window.translate("Label_Filter_Status")}
                            wmsSelector={props.wmsSelector + "SelectActive"}
                            onChangeFunc={handleChangeAliasData}
                            options={[
                                {
                                    tLabel: "Active",
                                    value: 1,
                                },
                                {
                                    tLabel: "Inactive",
                                    value: 0,
                                },
                            ]}
                            valueField={"value"}
                            textField={"tLabel"}
                            disabled={false}
                            value={itemAliasData.Active}
                            extraWrapperClass="width-20"
                        />
                    </div>
                    <hr />
                    <div className="display-inline">
                        <label className="label-item-in-this-alises">
                            {window.translate("Label_Items_In_This_Alises")}
                        </label>
                    </div>
                    <div className="display-inline">
                        <WmsInput
                            id="itemSearch"
                            inputName="itemSearch"
                            wmsSelector={props.wmsSelector + "itemSearch"}
                            onChangeFunc={handleSearchChange}
                            label={""}
                            value={searchString}
                            extraWrapperClass="search-input width-80 hide-label"
                        />
                        <WmsButton
                            label={window.translate("Label_Add_Items")}
                            wmsSelector={props.wmsSelector + "addItems"}
                            onClickFunc={openAddNewItems}
                            icon={"fa-plus"}
                            loading={isOpenAddNewItemModel}
                            buttonType="black"
                        />
                    </div>
                    <div className="display-inline item-grid-wrapper">
                        {isGridChangeLoading ? (
                            <div className="loading-show">
                                Loading...
                                <img src="assets/images/Spinner-1s-35px.gif" alt="Loading" />
                            </div>
                        ) : (
                            <WmsGrid
                                id={props.wmsSelector + "selectedItemGrid"}
                                wmsSelector={props.wmsSelector + "selectedItemGrid"}
                                gridName={props.wmsSelector + "selectedItemGrid"}
                                gridKeySelection={"Sku"}
                                inlineSelectAll={true}
                                gridHeight={500}
                                defaultColumns={itemsGridColumns}
                                columns={itemsGridColumns}
                                schema={itemsGridSchema}
                                staticGridData={toShowAliasItemComponentSku}
                                virtual={false}
                                shawCheckbox={false}
                                isAlignIndexesByProperty={true}
                                onSelection={() => {}}
                                onSubGridSelection={() => {}}
                                resetButtonLabel={"Options"}
                                showGridSummary={true}
                                showResetButton={false}
                                onDataBound={() => {}}
                                menu={[]}
                                layoutData={""}
                                propsSelectedOrders={0}
                                metaGridConfig=""
                                totalResult={recordsCount}
                                gridEditable={{
                                    confirmation: false,
                                }}
                                onEdit={onEdit}
                                onGridSave={EventSaveGridAppliedCharges}
                                onChange={appliedChargesGridChange}
                                clickEventOfContextMenu={() => null}
                                showGridResult={false}
                                showSortColumns={false}
                            />
                        )}
                    </div>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={props.wmsSelector + "DiscardExit"}
                        onClickFunc={props.onCloseItemAliasModal}
                        buttonType="orange"
                        icon={"fa-ban"}
                    />
                    <WmsButton
                        label={window.translate("Label_Save")}
                        wmsSelector={props.wmsSelector + "SaveExit"}
                        onClickFunc={saveItemAlias}
                        icon={"fa-floppy-o"}
                        disabled={allValidationDone()}
                        loading={isSaveLoading}
                        parentClassName={"apply-adjustment-button"}
                    />
                </div>
            </WmsModel>
            {isOpenAddNewItemModel && (
                <ItemAliasAddNewItemModel
                    wmsSelector={props.wmsSelector + "AddNewItem"}
                    closeItemAliasAddItemModel={closeItemAliasAddItemModel}
                    isOpenAddNewItemModel={isOpenAddNewItemModel}
                    windowId={props.windowId}
                    customerId={props.customerId}
                    alreadyAddedItems={aliasItemComponentSku}
                    addItemsToAliasComponents={addItemsToAliasComponents}
                />
            )}
        </>
    );
};
ItemAliasModal.propTypes = {};

ItemAliasModal.defaultProps = {};

export default React.memo(ItemAliasModal);
