import React, { useState, useEffect } from "react";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import * as GlobalService from "./../react/global_services";
import * as ItemAliasServices from "./item-alias.services";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import WmsGrid from "./../react/components/WmsGrid/WmsGrid";

import "./itemAlias.scss";
declare var window: any;
var axios = require("axios");
interface Props {
    wmsSelector: string;
    closeItemAliasAddItemModel: any;
    isOpenAddNewItemModel: boolean;
    windowId: string;
    customerId: number;
    alreadyAddedItems: any;
    addItemsToAliasComponents: any;
}

const defaultSearchParams = {
    itemSearch: "",
};

const ItemAliasAddNewItemModel: React.FC<Props> = (props: Props): any => {
    const [searchParams, setSearchParams] = useState(defaultSearchParams);
    const itemsGridColumns = [
        {
            field: "Sku",
            title: "Items",
            wmsid: 1,
            width: 200,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "ItemAliasGridItemName",
            },
        },
        {
            field: "Description",
            title: "Description",
            wmsid: 2,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "ItemAliasGridItemDescription",
            },
        },
        {
            field: "",
            title: "",
            width: 50,
            wmsid: 3,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "ItemAliasGridQty",
            },
            template: `<a href='javascript: void(0);' data-id='#=data.ItemId#' class='add-selected-item' data-wms-selector='add-selected-item-for-item-alias'><span class='fa fa-plus'></span></a>`,
        },
    ];
    const itemsGridSchema = {
        model: {
            id: "RowNumber",
            fields: {
                Sku: { type: "string" },
                Description: { type: "string" },
            },
        },
    };
    const [recordsCount, setRecordsCount] = useState(0);
    const [isItemGridCountLoading, setIsItemGridCountLoading] = useState(false);
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [arregenedGridData, setArregenedGridData] = useState([]);
    const [itemsToAdd, setItemsToAdd] = useState([]);
    const [toShowArregenedGridData, setToShowArregenedGridData] = useState([]);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    let filterCountAxiosSource = null;
    let isApiCanceled = false;
    let axiosSource = null;
    const [gridPageSize] = useState(100);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);

    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        setRecordsCount(0);
        setIsItemGridCountLoading(true);

        if (clearGridSelection) {
            setIsAllDataLoad(false);
        }
        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        ItemAliasServices.getItemGridData(props.customerId, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data.Total);
                if (clearData) {
                    setArregenedGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsItemGridCountLoading(false);
            });
    };
    const resetGridAllMessage = () => {
        document.getElementById("itemSearchLabel").style.display = "block";
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };
    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(true);
        } else {
            resetGridAllMessage();
            loadAllRecords(false);
        }
    };
    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };
    const listGrid_dataBind = (data) => {
        let tempToShowArregenedGridData = [];
        data = data.map((value) => {
            let itemAlreadyIncluded =
                props.alreadyAddedItems.some((el) => el.Sku === value.Sku) ||
                itemsToAdd.some((el) => el.Sku === value.Sku);
            let obj = {
                ItemId: value.ItemId,
                Sku: value.Sku,
                Description: value.Description,
                IsActive: value.IsActive,
            };
            const searchQuery = (searchParams.itemSearch || "").toLowerCase();
            if (
                (searchQuery === "" ||
                    obj.Sku?.toLowerCase().includes(searchQuery) ||
                    obj.Description?.toLowerCase().includes(searchQuery)) &&
                !itemAlreadyIncluded
            ) {
                tempToShowArregenedGridData.push(obj);
            }
            return obj;
        });
        setToShowArregenedGridData(tempToShowArregenedGridData);
        setIsGridDataLoading(false);
        setIsAllDataLoad(true);
        setIsNewColumnAdded(false);
    };
    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj.Data, ...allData];
            });
            listGrid_dataBind(allData);
            setArregenedGridData(allData);
        } else {
            setIsGridDataLoading(false);
        }
    };
    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setArregenedGridData([]);
        setIsAllDataLoad(false);
    };
    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        document.getElementById("itemSearchLabel").style.display = "none";
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setArregenedGridData([]);
        }
        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Items/Items",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);

        Promise.all(
            sequence(totalRequest).map((i) =>
                api.post("/ItemsGridRead?sorts=[]&filters=[]&page=" + (i + 1) + "&pageSize=" + gridPageSize, {
                    customerUri: `/customers/${props.customerId}`,
                })
            )
        )
            .then((responses) => {
                let itemData = responses.map((r) => r.data);
                getAllResponseData(itemData);
            })
            .catch((error) => {
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };
    useEffect(() => {
        $(document).off("click", ".add-selected-item");
        $(document).on("click", ".add-selected-item", function () {
            const rowIndex = parseInt($(this).data("id"));
            addItemToAliasComponents(rowIndex);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arregenedGridData]);
    const addItemToAliasComponents = (rowIndex) => {
        arregenedGridData.map((value) => {
            if (value.ItemId === rowIndex) {
                setItemsToAdd((ps) => [...ps, value]);
                GlobalService.notification("success", window.translate("Label_Item_Added"));
                return false;
            }
            return true;
        });
    };

    useEffect(() => {
        listGrid_dataBind(arregenedGridData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsToAdd, searchParams]);
    useEffect(() => {
        ItemAliasServices.updateSessionFilter({
            "ShowDescription": true,
            "ShowIsActive": true,
            "ShowSku": true,
            "Sku": searchParams.itemSearch,
        }).then(() => {
            callForGetTotalCount();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customerId]);

    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordsCount]);

    const handleSearchChange = (event: any) => {
        let { name, value } = event.target;
        setSearchParams((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
        filterChange();
    };

    let filterChangeWithDebounce = () => {
        ItemAliasServices.updateSessionFilter({
            "ShowDescription": true,
            "ShowIsActive": true,
            "ShowSku": true,
            "Sku": searchParams.itemSearch,
        }).then((response) => {
            callForGetTotalCount();
        });
    };

    let filterChange = GlobalService.useFCDebounce(filterChangeWithDebounce, 1000);
    const addItemsToAliasComponents = () => {
        props.addItemsToAliasComponents(itemsToAdd);
        props.closeItemAliasAddItemModel();
    };

    return (
        <>
            <WmsModel
                id="ItemAliasAddItemModel"
                title={"Add Items"}
                width={800}
                height={800}
                onCloseFunc={props.closeItemAliasAddItemModel}
                isOpen={props.isOpenAddNewItemModel}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className={"model-content-wrapper"}>
                    <div className="display-inline" id="itemSearchLabel">
                        <label id="itemSearchLabelText">
                            {window
                                .translate("Label_Grid_Filter_Item_Message")
                                .replace(new RegExp("{dynamicAmount}"), gridPageSize)}
                        </label>
                    </div>
                    <div className="display-inline">
                        <WmsInput
                            id="itemSearch"
                            inputName="itemSearch"
                            wmsSelector={props.wmsSelector + "itemSearch"}
                            onChangeFunc={handleSearchChange}
                            label={""}
                            value={searchParams.itemSearch}
                            extraWrapperClass="search-input width-100 hide-label"
                            placeholder={"Search"}
                            icon={"fa-search"}
                            showIconAfterInput={true}
                            onIconClick={() => {}}
                        />
                    </div>
                    <div className="display-inline item-grid-wrapper">
                        <WmsGrid
                            id={props.wmsSelector + "addItemGrid"}
                            wmsSelector={props.wmsSelector + "addItemGrid"}
                            gridName={props.wmsSelector + "addItemGrid"}
                            gridKeySelection={"Sku"}
                            inlineSelectAll={true}
                            gridHeight={500}
                            defaultColumns={itemsGridColumns}
                            columns={itemsGridColumns}
                            schema={itemsGridSchema}
                            staticGridData={toShowArregenedGridData}
                            virtual={false}
                            shawCheckbox={false}
                            isAlignIndexesByProperty={true}
                            onSelection={() => {}}
                            onSubGridSelection={() => {}}
                            resetButtonLabel={"Options"}
                            showGridSummary={true}
                            showResetButton={false}
                            onDataBound={() => {}}
                            menu={[]}
                            layoutData={""}
                            loading={isItemGridCountLoading || isGridDataLoading}
                            propsSelectedOrders={0}
                            isNewColumnAdded={isNewColumnAdded}
                        />
                    </div>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Done")}
                        wmsSelector={props.wmsSelector + "DoneButton"}
                        onClickFunc={addItemsToAliasComponents}
                        icon={""}
                        loading={false}
                    />
                </div>
            </WmsModel>
        </>
    );
};
ItemAliasAddNewItemModel.propTypes = {};

ItemAliasAddNewItemModel.defaultProps = {};

export default React.memo(ItemAliasAddNewItemModel);
