
import axios from 'axios';
import React from 'react';
//component
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ConcurrencyManager } from '../../../assets/vendor/concurrencyManager/concurrencyManager.js';
import { AddEditColumnModel } from '../components/AddEditColumn/add-edit-columns.component.jsx';
import { LoadingModel } from '../components/LoadingModel/LoadingModel.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import VirtualGridComponent from '../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { InventoryCustomerTransferModel } from '../inventory/models/inventory-customer-transfer-model.component.jsx';
import { InventoryHoldModel } from '../inventory/models/inventory-hold-model.component.jsx';
//services
import * as GlobalService from './../global_services';
import * as GridConfig from './grid_configuration.jsx';
import * as inventoryservices from './inventory.services.jsx';
import * as metaDataService from './meta-data.service';
import { isNull } from 'lodash';

export class InventoryGrid extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.firstFiftyAxiosSource = null;
        this.filterCountAxiosSource = null;
        this.axiosSource = null;
        this.inventoryGridData = null;
        this.inventory_GridHeaders = StringWms.convertSringToOption(this.translate('Inventory_Beta_GrideHeaders'));
        this.manageInventoryGridId = "GridManageInventoryBeta";
        this.receiveItemIdsGroup = [];
        this.isApiCanceled = false;
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageInventoryGridId : '#' + this.manageInventoryGridId,
            selector: 'Inventory',
            inventorySummary: {
                "TotPrimaryAvailable": 0,
                "TotPrimaryAllocated": 0,
                "TotPrimaryOnHold": 0,
                "TotPrimaryOnHand": 0,
                "TotMovableUnits": 0
            },
            enableHold: false,
            enableReleaseHold: false,
            enableTransfer: false,
            enableTransferDisableCombineRowForAvaQtyZero: false,
            isHoldModelOpen: false,
            isOpenConfirmModel: false,
            isOpenHoldResultMsg: false,
            holdResultsObj: {},
            isLoadingStop: false,
            isTransferModelOpen: false,
            sharedSelectedFacilityObj:{},
            isNotQuarantineLocation: true,
            localKendoGrid: null,
            allColumnList: GridConfig.getAllColumnList(this.inventory_GridHeaders,'InventoryBeta').columns,
            allSortColumnList: [],
            defaultColumns: GridConfig.getGridDemoColumns(this.inventory_GridHeaders,'InventoryBeta').columns,
            schema: GridConfig.getGridDemoSchema().schema,
            totalInventory: 0,
            gridData: [],
            gridExportLimit: GlobalService.constants.gridExportLimit,
            manageInventoryGridConfig: metaDataService.getManageInventoryGridOptionInMetaData(),
            Rights: GlobalService.GetRights(),
            isFilterRowCountLoading: false,
            isInventoryGridDataLoading: false,
            isOpenColumnModel: false,
            filterText: "",
            fullFilterText: "",
            totalRequest: 0,
            overlayScreenShow: false,
            gridPageSize: 1000,
            isNewColumnAdded: false,
            isCombineRow: false,
            showLoadingError: false,
            selectedRowCount: 0
        }

    }

    componentDidMount() {
        let allSortColumnList = this.state.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.trim().toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.trim().toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });

        this.setState({ allSortColumnList: allSortColumnList });

        this.loadGridData();
    }


    loadGridData = (clearData = true) => {
        this.setState({
            isFilterRowCountLoading: true,
            totalInventory: 0,
            showLoadingError: false,
            inventorySummary: {
                "TotPrimaryAvailable": 0,
                "TotPrimaryAllocated": 0,
                "TotPrimaryOnHold": 0,
                "TotPrimaryOnHand": 0,
                "TotMovableUnits": 0
            },

        });
        let tmpGridData = this.state.gridData;
        if (clearData == true) {
            tmpGridData = []
        }

        let inventorySearchInfo = Object.assign({}, this.props.sharedInventoryFilterObj);
        inventorySearchInfo.StartDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.StartDate,'',false,true).isoStringUTC;
        inventorySearchInfo.EndDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.EndDate,'',false,true).isoStringUTC;
        // Clear selection of grid before get new count
        if(this.state.localKendoGrid){
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
            this.setState({
                enableHold: false,
                enableReleaseHold: false,
                enableTransfer: false,
                enableTransferDisableCombineRowForAvaQtyZero: false,
                isHoldModelOpen: false,
                isTransferModelOpen: false
            });
        }

        this.cancelLoadAllData();
        if (this.filterCountAxiosSource) {
            this.filterCountAxiosSource.cancel();
            this.filterCountAxiosSource = null;
        }
        // on filter update cancel orderDataAxios call if any running
        this.filterCountAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/Inventory/InventoryUI/FilteredRowCount', {
            inventorySearchInfo: inventorySearchInfo
        }, { cancelToken: this.filterCountAxiosSource.token }).then((response) => {
            this.filterCountAxiosSource = null;
            this.setState({
                totalInventory: response.data,
                isAllDataLoad: false,
                gridData: tmpGridData
            }, () => {
                if(response.data > 0){
                    this.callForGetNewData();
                }
            });
        }).finally(() => {
            this.setState({ isFilterRowCountLoading: false });
        });;
    }

    callForGetNewData = () => {
        if (this.state.totalInventory > 0 && this.state.totalInventory <= GlobalService.constants.virtualGridDataLimit) {
            this.loadAllRecords(false);
        } else {
            // reset selection of grid on filter data change
            // this.setState({ staticGridData: [] });
            // console.log("resetGridAllMessage : call");
            this.resetGridAllMessage();
        }

    }
    resetGridAllMessage = () => {
        if (this.state.isAllDataLoad) {
            this.setState({
                isNewColumnAdded: true
            });
        }
    }

    onClickLoadAllRecords = () => {
        this.setState({
            gridData: [],
            overlayScreenShow: true,
        });
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();

        let enableColumns = this.getEnableColumns();
        let inventorySearchInfo = Object.assign({}, this.props.sharedInventoryFilterObj);
        inventorySearchInfo.StartDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.StartDate,'',false,true).isoStringUTC;
        inventorySearchInfo.EndDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.EndDate,'',false,true).isoStringUTC;

        axios.put('/WebUI/Inventory/InventoryUI/InventoryData?sorts=[]&filters=[]&pgnum=1&pgsiz=50', {
            columns: enableColumns,
            inventorySearchInfo: inventorySearchInfo
        }, { cancelToken: this.firstFiftyAxiosSource.token }).then((response) => {
            this.firstFiftyAxiosSource = "";
            this.setState({
                gridData: response.data.Data
            },this.loadAllRecords);
        });
    }

    getEnableColumns = () => {
        var defaultParam = ['ReceiveItemId', 'OnHold', 'InQuarantineLocation', 'PrimaryAvailable', 'ItemId', 'Qualifier', 'LotNumber','CustomerName','Sku','ExpirationDate','PrimaryUom','SecondaryUom','FacilityId','FacilityName'];
        let columnList = [];

        let grid = $(this.state.gridDomSelector()).data('kendoGrid');
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            columnList = GridConfig.getGridDemoColumns(this.inventory_GridHeaders,'InventoryBeta').columns;
        }

        var enableColumns = columnList.map(el => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            })
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        }

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map((item) => {
            var title = self.state.allColumnList.find(x => x.field === item.field).title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
        });
        filterText = filterText.slice(0, -1);

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
        })
    }

    // Load all records of inventory
    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        if (isLoaderPopupShow == false) {
            this.setState({
                isInventoryGridDataLoading: true,
                gridData: []
            });
        }
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Inventory",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });
        const MAX_CONCURRENT_REQUESTS = 3;

        let totalRequest = Math.ceil(this.state.totalInventory / this.state.gridPageSize);

        this.setState({
            totalRequest: totalRequest,
        });
        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);
        let enableColumns = this.getEnableColumns();
        let inventorySearchInfo = Object.assign({}, this.props.sharedInventoryFilterObj);
        inventorySearchInfo.StartDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.StartDate,'',false,true).isoStringUTC;
        inventorySearchInfo.EndDate = StringWms.convertFilterStringToISODate(inventorySearchInfo.EndDate,'',false,true).isoStringUTC;

        Promise.all(sequence(totalRequest).map(i => api.put("/InventoryUI/InventoryData?sorts=[]&filters=[]&pgnum=" + (i + 1) + '&pgsiz=' + this.state.gridPageSize, {
            columns: enableColumns,
            inventorySearchInfo: inventorySearchInfo
        }))).then(responses => {
            let inventoryData = responses.map(r => r.data);
            this.getAllResponseData(inventoryData);
        }).catch((error) => {
            console.log("Promise.all catch",  error);
            this.setState({
                showLoadingError: true
            });
            this.cancelLoadAllData();
        }).then(() => {
            if(manager) {
                manager.detach();
            }
        });
    }

    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            this.inventoryGridData = allData;
            this.setState({
                gridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isInventoryGridDataLoading: false
            },() => {
                if(this.state.isCombineRow){
                    this.combineRowUsingGridData();
                }
            });
        } else {
            this.setState({
                isInventoryGridDataLoading: false
            });
        }
    }

    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
        }
        if(this.axiosSource){
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            isInventoryGridDataLoading: false,
            overlayScreenShow: false,
            gridData: [],
            isAllDataLoad: false
        });
    }

    //On grid row selection
    selectRow = (param_kendoGrid) => {
        let enableHold = false;
        let enableReleaseHold = false;
        let enableTransfer = false;
        let enableTransferDisableCombineRowForAvaQtyZero = false;
        var SelectedOrders = param_kendoGrid.getNumberOfSelections();
        var isHold = param_kendoGrid.valueExists("OnHold", true);
        var isRelease = param_kendoGrid.valueExists("OnHold", false);
        var isVaries = param_kendoGrid.valueExists("Varies", true);
        var c2cEnableForPrimaryAvailable = param_kendoGrid.valueExists("c2cEnableForPrimaryAvailable", true);

        // for enable/disabled Transfer
        var isNotInQuarantineLocation = param_kendoGrid.valueExists("InQuarantineLocation", false) && param_kendoGrid.valuesIdentical("InQuarantineLocation");
        var availableQtyMoreThanZero = param_kendoGrid.valueGreaterThanZero('PrimaryAvailable');
        var sameItemIds = param_kendoGrid.valuesIdentical("ItemId");
        var sameFaciltyIds = param_kendoGrid.valuesIdentical("FacilityId");
        var sameQualifier = param_kendoGrid.valuesIdentical("Qualifier");
        var sameLotNumber = param_kendoGrid.valuesIdentical("LotNumber");

        if (SelectedOrders >= 1 && isNotInQuarantineLocation == true && availableQtyMoreThanZero == true && sameItemIds == true && isHold == false && sameLotNumber == true && sameQualifier == true && sameFaciltyIds == true) {
            enableTransfer = true;
            var itemIds = param_kendoGrid.getKeys()[0];
            var warehouseObj = {};
            var itemsObj = param_kendoGrid.getDictionary().items;
            let facilityIds =  _.map(itemsObj, 'FacilityId');
            let FacilityNames =  _.map(itemsObj, 'FacilityName');
            warehouseObj.id = facilityIds[0];
            warehouseObj.name = FacilityNames[0];
            this.setState({
                sharedSelectedFacilityObj: warehouseObj
            });
            // customerEditUri = param_kendoGrid.getItem(param_kendoGrid.getKeys()).EditUri;

        }
        if (isHold == true) {
            enableReleaseHold = true;
        }
        if (isRelease == true) {
            enableHold = true;
        }
        if(this.state.isCombineRow){
            if(isVaries == true){
                enableReleaseHold = true;
                enableHold = true;
                enableTransfer = false;
            }
            if (!c2cEnableForPrimaryAvailable && !isHold && !isVaries) {
                enableTransfer = false;
                enableTransferDisableCombineRowForAvaQtyZero = true;
            }
        }
        this.setState({
            enableHold: enableHold,
            enableReleaseHold: enableReleaseHold,
            enableTransfer: enableTransfer,
            isNotQuarantineLocation: isNotInQuarantineLocation,
            localKendoGrid: param_kendoGrid,
            enableTransferDisableCombineRowForAvaQtyZero: enableTransferDisableCombineRowForAvaQtyZero,
            selectedRowCount: SelectedOrders
        });
        if (param_kendoGrid != null && param_kendoGrid.getNumberOfSelections() >=1) {
            // Count only change if selected order is 1 or more
            var SelectedRow = param_kendoGrid.getDictionary();
            this.UpdateGridSummary(Object.values(SelectedRow.items));
        }else{
            this.UpdateGridSummary(this.inventoryGridData);
        }

    }

    UpdateGridSummary = (RowsArr) => {
            var PrimaryAvailable = 0;
            var PrimaryReceived = 0;
            var TotPrimaryOnHold = 0;
            var PrimaryOnHand = 0;
            var TotMovableUnits = 0;
            var PrimaryAllocated = 0;

            if(RowsArr && RowsArr.length >= 1){
                PrimaryAvailable = RowsArr.map(i => parseFloat(i.PrimaryAvailable)).reduce((prev, next) => prev + next);
                PrimaryReceived = RowsArr.map(i => parseFloat(i.PrimaryReceived)).reduce((prev, next) => prev + next);
                TotPrimaryOnHold = RowsArr.map(i => i.OnHold ? parseFloat(i.PrimaryOnHand) : 0).reduce((prev, next) => prev + next);
                PrimaryOnHand = RowsArr.map(i => parseFloat(i.PrimaryOnHand)).reduce((prev, next) => prev + next);
                var MovableUnitLabelArr = RowsArr.filter(i => i.MovableUnitLabel !== null ).map(i =>  i.MovableUnitLabel);
                TotMovableUnits = new Set(MovableUnitLabelArr).size;
                PrimaryAllocated = RowsArr.map(i => i.OnHold ? 0 : parseFloat(i.PrimaryOnHand) - parseFloat(i.PrimaryAvailable)).reduce((prev, next) => prev + next);
            }
            this.setState({
                inventorySummary: {
                    "TotPrimaryAvailable": PrimaryAvailable,
                    "TotPrimaryAllocated": PrimaryAllocated,
                    "TotPrimaryOnHold": TotPrimaryOnHold,
                    "TotPrimaryOnHand": PrimaryOnHand,
                    "TotMovableUnits": TotMovableUnits
                }
            });
    }




    onGridDataBound = (e, kendoGrid) => {
        this.setState({
            localKendoGrid: kendoGrid
        });
        if(!(kendoGrid != null && kendoGrid.getNumberOfSelections() >=1)){
            this.UpdateGridSummary(this.inventoryGridData);
        }
    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);
    }

    // on apply saved filter data save changes in meta data object
    onApplySavedFilterData = (filterObj) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

        metaDataService.applySavedFilterDataToInventoryGrid(finalSubmission, filterObj);
    }
    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        }
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);

        }, 100);

    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setManageInventoryGridOptionInMetaData(finalSubmission);
    }



    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'hold') {
            this.openHoldInventoryModal();
        } else if (obj.wmsid == 'releaseHold') {
            this.holdOrReleaseInventoryCheck();
        }
    }

    openHoldInventoryModal = () => {
        this.setState({ isHoldModelOpen: true });
    }

    closeHoldInventoryModal = () => {
        this.setState({ isHoldModelOpen: false });
    }
    openCustomerTransferModal = () => {
        this.setState({ isTransferModelOpen: true });
    }
    closeTransferInventoryModal = () => {
        this.setState({ isTransferModelOpen: false });
    }

    holdOrReleaseInventoryCheck = () => {
        if (!this.state.isNotQuarantineLocation) {
            this.setState({
                isOpenConfirmModel: true
            });
        }
        else {
            this.holdOrReleaseInventory();
        }
    }

    holdOrReleaseInventory = () => {
        let receiveItemIds = [];

        if(this.state.isCombineRow){
            let itemsObj = this.state.localKendoGrid.getDictionary().items;
            let getIdArray =  _.map(itemsObj, 'receiveItemIdsOnHold');
            getIdArray.map(function(data){
                var array = data;
                array.map(function(rid){
                    receiveItemIds.push(rid);
                });
            });
        } else {
            let itemsObj = this.state.localKendoGrid.getDictionary().items;
            let filterObject = _.filter(itemsObj, function(o) { return o.OnHold; });
            let getIdArray =  _.map(filterObject, 'ReceiveItemId');
            getIdArray.map(function(data){
                receiveItemIds = _.concat(receiveItemIds,data);
            });
        }

        let releaseData = {
            'Reason': 'release',
            'putOnHold': false,
            'receiveItemIds': receiveItemIds,
        }
        console.log("Realse itemIDs",receiveItemIds);
        inventoryservices.holdOrReleaseInventory(releaseData).then((response) => {
            if (typeof response != 'undfined' && response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Release_Hold_Inventory_Success'));
                this.onCloseConfirmModel();
                this.resetGridSelectionAndButton();
            }
        }).finally(() => { });
    }

    resetGridSelectionAndButton = () => {
        this.loadGridData(false);
    }
    eventClickExportGrid = () => {
        $(this.state.gridDomSelector()).getKendoGrid().saveAsExcel();
    }

    clickOnContinueButton = () => {
        this.setState({
            isLoadingStop: true
        });
        this.holdOrReleaseInventory();
    }

    onCloseConfirmModel = () => {
        this.setState({ isOpenConfirmModel: false });
    }
    onOpenHoldResultMsg = (param_hold_results) => {
        setTimeout(() => {
            this.setState({
                holdResultsObj: param_hold_results,
                isOpenHoldResultMsg: true
            });
        }, 100);
    }
    onCloseHoldResultMsg = () => {
        this.setState({ isOpenHoldResultMsg: false });
    }
    copyToClipboard = () => {
        var copyText = 'Title : ' + $('.result-list').eq(0).prev('p').text() + '\n';
        copyText += $('.result-list').eq(0).find('div').eq(0).find('label').text() + '\n';
        copyText += $('.result-list').eq(0).find('div').eq(0).find('label').next('div').text() + '\n';
        copyText += $('.result-list').eq(0).find('div').eq(2).find('label').text() + '\n';
        copyText += $('.result-list').eq(0).find('div').eq(2).find('label').next('div').text() + '\n';
        if ($('.result-list').eq(1).text()) {
            copyText += '\n' + 'Title : ' + $('.result-list').eq(1).prev('p').text() + '\n';
            copyText += $('.result-list').eq(1).find('div').eq(0).find('label').text() + '\n';
            copyText += $('.result-list').eq(1).find('div').eq(0).find('label').next('div').text() + '\n';
            copyText += $('.result-list').eq(1).find('div').eq(2).find('label').text() + '\n';
            copyText += $('.result-list').eq(1).find('div').eq(2).find('label').next('div').text() + '\n';
        }
        var copyTextarea = document.querySelector('#simulate-clipboard');
        copyTextarea.appendChild(document.createTextNode(copyText));
        copyTextarea.select();
        document.execCommand('copy');
        $('#simulate-clipboard').text('');
        this.setState({ isLoadingStop: true });
        this.onCloseHoldResultMsg();
    }

    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        })
    }
    onCloseColumnModel = (e) => {
        this.setState({
            isOpenColumnModel: false
        })
    }
    onCheckCombine = (isChecked) => {
        this.setState({
            isCombineRow : isChecked
        })
        if(isChecked){

            this.setState({
                defaultColumns:GridConfig.getCombineGridColumns(this.inventory_GridHeaders,'InventoryBeta').columns,
                schema: GridConfig.getGridCombineSchema().schema,
                allColumnList: GridConfig.getCombineAllColumnList(this.inventory_GridHeaders,'InventoryBeta').columns,
                manageInventoryGridConfig: metaDataService.getManageInventoryGridOptionInMetaData(),
            },this.combineRowUsingGridData);
        }else{
            this.setState({
                gridData:this.inventoryGridData,
                totalInventory:this.inventoryGridData.length,
                defaultColumns:GridConfig.getGridDemoColumns(this.inventory_GridHeaders,'InventoryBeta').columns,
                schema: GridConfig.getGridDemoSchema().schema,
                allColumnList: GridConfig.getAllColumnList(this.inventory_GridHeaders,'InventoryBeta').columns,
                manageInventoryGridConfig: metaDataService.getManageInventoryGridOptionInMetaData(),
            })
        }
        this.state.localKendoGrid.clearSelections(() => {
            this.state.localKendoGrid.repaintSelections();
        });
    }

    combineRowUsingGridData = () => {
        this.inventoryGridData = this.state.gridData; // set previous data for set in grid for uncheck
        let list = this.state.gridData;

        var groups = _.groupBy(list, function (value) {
            if(value.Qualifier == "" || value.Qualifier == null)
            {
                value.Qualifier = "";
            }
            if(value.LotNumber == "" || value.LotNumber == null)
            {
                value.LotNumber = "";
            }
            if(value.ExpirationDate == "" || value.ExpirationDate == null)
            {
                value.ExpirationDate = "";
            }
            return value.CustomerName + '#' + value.Sku + '#' + value.Qualifier + '#' + value.LotNumber + '#' + value.ExpirationDate;
        });

        var index = -1;
        var data = _.map(groups, function (group) {
            index++;
            let tmpAvailableItemIds = [];
            let tmpHoldItemIds = [];
            var InQuarantineLocation = false;

            var c2cEnableForPrimaryAvailable = true;
            _.each(group, function (item) {
                item.PrimaryAvailable = parseFloat(item.PrimaryAvailable);
                if(!item.PrimaryAvailable) {
                    c2cEnableForPrimaryAvailable = false;
                }
                if(item.OnHold){
                    tmpHoldItemIds.push(item.ReceiveItemId);
                } else{
                    tmpAvailableItemIds.push(item.ReceiveItemId)
                }
                if(item.SecondaryAvailable){
                    item.SecondaryAvailable = parseInt(item.SecondaryAvailable)
                } else{
                    item.SecondaryAvailable = 0;
                }
                if(item.InQuarantineLocation){
                    InQuarantineLocation = true;
                }
            });

            return {
                CustomerName: group[0].CustomerName,
                Sku: group[0].Sku,
                Qualifier: group[0].Qualifier,
                LotNumber: group[0].LotNumber,
                ExpirationDate: group[0].ExpirationDate,
                OnHold: group[0].OnHold,
                FacilityId: group[0].FacilityId,
                FacilityName: group[0].FacilityName,
                InQuarantineLocation: InQuarantineLocation,
                PrimaryAvailable: _.sumBy(group, 'PrimaryAvailable'),
                c2cEnableForPrimaryAvailable: c2cEnableForPrimaryAvailable,
                ReceiverId: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.ReceiverId))).toString(),
                ReceiveItemId: Array.prototype.map.call(group, s => s.ReceiveItemId).toString(),
                SerialNumber: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SerialNumber))).toString(),
                Cost: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.Cost))).toString(),
                Upc: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.Upc))).toString(),
                SecondaryDescription: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SecondaryDescription))).toString(),
                SkuDescription: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SkuDescription))).toString(),
                LocationName: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.LocationName))).toString(),
                ImperialOnHandCatchWeight: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.ImperialOnHandCatchWeight))).toString(),
                MetricOnHandCatchWeight: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.MetricOnHandCatchWeight))).toString(),
                CatchWeight: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.CatchWeight))).toString(),
                SupplierName: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SupplierName))).toString(),
                MovableUnitLabel: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.MovableUnitLabel))).toString(),
                CustomFields: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.CustomFields))).toString(),
                OnHoldReason: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.OnHoldReason))).toString(),
                ReferenceNum: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.ReferenceNum))).toString(),
                PONum: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.PONum))).toString(),
                TrailerNumber: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.TrailerNumber))).toString(),
                PrimaryUom: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.PrimaryUom))).toString(),
                PrimaryReceived: _.sumBy(group, 'PrimaryReceived'),
                PrimaryShipped: _.sumBy(group, 'PrimaryShipped'),
                PrimaryOnHand: _.sumBy(group, 'PrimaryOnHand'),
                SecondaryReceived: _.sumBy(group, 'SecondaryReceived') ? _.sumBy(group, 'SecondaryReceived').toFixed(4) : 0,
                SecondaryAvailable: _.sumBy(group, 'SecondaryAvailable') ? _.sumBy(group, 'SecondaryAvailable') : 0 + ' ' + (group[0].SecondaryUom ? group[0].SecondaryUom : ""),
                SecondaryShipped: _.sumBy(group, 'SecondaryShipped'),
                SecondaryUom: group[0].SecondaryUom ? group[0].SecondaryUom : '',
                SecondaryOnHand: _.sumBy(group, 'SecondaryOnHand') ? _.sumBy(group, 'SecondaryOnHand').toFixed(4) : 0,
                ReceiptDate: _.uniqBy(_.compact(Array.prototype.map.call(group, s => kendo.toString(new Date(s.ReceiptDate), "MMM dd yyyy, hh:mm:ss tt")))).toString(),
                Varies:(tmpHoldItemIds.length != 0 && tmpAvailableItemIds.length != 0),
                CurrentIndex:index,
                receiveItemIdsOnHold: tmpHoldItemIds,
                receiveItemIdsAvailable: tmpAvailableItemIds
            }

        });
        this.setState({
            gridData: data,
            totalInventory:data.length
        });
    }

    render() {
        let disableTransferMsg = this.translate('Label_Hold_Inventory_Message');
        if (this.state.enableTransferDisableCombineRowForAvaQtyZero) {
            disableTransferMsg = this.translate('Label_Available_One_ReceiveItemId_QtyIsZero_Inventory_Message');
        }
        return (
            <div className="inventory-grid">
                <div className="wms-inventory-grid-holder">
                    <VirtualGridComponent
                        loading={this.state.isFilterRowCountLoading || this.state.isInventoryGridDataLoading}
                        id={this.manageInventoryGridId}
                        isNewColumnAdded={this.state.isNewColumnAdded}
                        gridName={'GridManageInventoryBeta'}
                        windowId={this.props.windowId}
                        wmsSelector={'GridManageInventoryBeta'}
                        gridKeySelection={'ReceiveItemId'}
                        virtual={true}
                        totalResult={this.state.totalInventory}
                        staticGridData={this.state.gridData}
                        showCombineCheckbox={true}
                        exportable={true}
                        // exportDisable={(this.state.totalInventory > this.state.gridExportLimit) ? true : false}
                        showGridSummary={true}
                        isInventorySummary={true}
                        inventorySummary={this.state.inventorySummary}
                        isExportSelectedData={true}
                        exportExcelObj={{
                            fileName: "inventoryGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                            allPages: true,
                            filterable: true
                        }}
                        csvExportFileName={'inventoryGridExport'}
                        eventClickExportGrid={this.eventClickExportGrid}
                        onCheckCombine={this.onCheckCombine}
                        allColumns={this.state.allColumnList}
                        defaultColumns={this.state.defaultColumns}
                        schema={this.state.schema}
                        onSelection={this.selectRow}
                        callForGetNewData={this.callForGetNewData}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        // getGridSelectionUrl={'/WebUI/inventory/ManageInventory/GridSelections'}
                        metaDataFieldsName={''}
                        metaGridConfig={this.state.manageInventoryGridConfig}
                        eventClickColumns={this.eventClickColumns}
                        onGridFilterApplied={this.onGridFilterApplied}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        loadAllRecords={this.onClickLoadAllRecords}
                        isAllDataLoad={this.state.isAllDataLoad}
                        onDataBound={this.onGridDataBound}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        showLoadingError={this.state.showLoadingError}
                        menu={[
                            {
                                "name": this.translate('Label_HOLD'),
                                "wmsSelector": this.state.selector + "Manage",
                                "value": [{
                                    "name": this.translate('Label_Place_Hold_Inventory'),
                                    "value": [],
                                    "disabled": (!this.state.enableHold || !this.state.Rights.hasOwnProperty('inventory.hold') || this.state.selectedRowCount > 1000),
                                    "disabledText": this.state.Rights.hasOwnProperty('inventory.hold') ?
                                        (this.state.selectedRowCount > 1000 ? this.translate('Label_On_Hold_Discable_For_More_Record_Selected') : this.translate('Label_Hold_Inventory_Message')):
                                        this.translate('Label_On_Hold_Not_Permission'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'hold',
                                    "wmsSelector": this.state.selector + "ActivateLocationButton",
                                    "onClickFunc": this.openHoldInventoryModal
                                }, {
                                    "name": this.translate('Label_Release_Hold_Inventory'),
                                    "value": [],
                                    "disabled": (!this.state.enableReleaseHold || !this.state.Rights.hasOwnProperty('inventory.hold') || this.state.selectedRowCount > 1000),
                                    "disabledText": this.state.Rights.hasOwnProperty('inventory.hold') ?
                                    (this.state.selectedRowCount > 1000 ? this.translate('Label_Un_Hold_Discable_For_More_Record_Selected') : this.translate('Label_Release_Inventory_Message')):
                                     this.translate('Label_On_Hold_Not_Permission'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'releaseHold',
                                    "wmsSelector": this.state.selector + "DeactivateLocationButton",
                                    "onClickFunc": this.holdOrReleaseInventoryCheck
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-pause",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': true
                            }, {
                                "name": this.translate('Label_Transfer'),
                                "wmsSelector": this.state.selector + "Transfer",
                                "value": [{
                                    "name": this.translate('Label_Customer_Transfer'),
                                    "value": [],
                                    "disabled": !this.state.enableTransfer,
                                    "disabledText": disableTransferMsg,
                                    "icon": "fa-exchange",
                                    "color": "green",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'wmsid': 'customerTransfer',
                                    "wmsSelector": this.state.selector + "CustomerTransfer",
                                    "onClickFunc": this.openCustomerTransferModal
                                }],
                                "disabled": false,
                                "icon": "fa-exchange",
                                "color": "green",
                                "isHidden": this.state.Rights.hasOwnProperty('inventory.transfer.cust') ? false : true,
                                'isContextMenu': false,
                                'isButtonMenu': true
                            }, {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField',
                                    "wmsSelector": "copyToClicpboard",
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }]}
                    />
                </div>

                <WmsConfirmModel
                    id="ConfirmModel"
                    isOpen={this.state.isOpenConfirmModel}
                    width={500}
                    height={250}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square"}
                    firstBtnOnClickFunc={this.clickOnContinueButton}
                    thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                    thirdBtnIcon={"fa fa-arrow-left"}
                    conFormModelText={this.translate('Warning_Releasing_Hold_On_Quarantine_Items')}
                    checkboxLabel={this.translate('RoleManager_Label_IUnderstand')}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseConfirmModel}
                    isLoadingStop={this.state.isLoadingStop}
                />

                {/* Display message on confirmation modal for hold operation  */}
                <WmsConfirmModel
                    id={"resultOfHoldOperation"+this.props.windowId}
                    extraWrapperClass={'result_of_hold_operation'}
                    type={this.translate('Label_Hold_Opt_MSG_Header')}
                    isOpen={this.state.isOpenHoldResultMsg}
                    width={550}
                    height={440}
                    firstBtnSelector={"inventoryCopyToClipBoard"}
                    firstBtnLabel={this.translate('Label_Copy_Clipboard')}
                    firstBtnIcon={"fa fa-copy"}
                    firstBtnOnClickFunc={this.copyToClipboard}
                    isLoadingStop={this.state.isLoadingStop}
                    thirdBtnLabel={this.translate('Label_Close')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText=''
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseHoldResultMsg}
                >
                    <div className="hold-opt-msg-wrapper">
                        <p>{this.translate('Label_Hold_Opt_MSG')}</p>
                        <hr />
                        {this.state.holdResultsObj.InProcess && this.state.holdResultsObj.InProcess.length != 0 && (
                            <div className='allocated-section'>
                                <p>Allocated To Orders Being Processed</p>
                                <div className='result-list'>
                                    <div className="child-section">
                                        <label>ReceiveItem ID</label>
                                        <div className="list-wrapper">
                                            {this.state.holdResultsObj.InProcess.map((item, index) => (
                                                <div key={'inprocess_rcv' + index}>{item.ReceiveItemId}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="child-section">
                                        <label>Transaction Numbers</label>
                                        <div className="list-wrapper">
                                            {this.state.holdResultsObj.InProcess.map((item, index) => (
                                                <div key={'inprocess_trn_' + index}>{item.AffectedOrderIds.join(",")}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.holdResultsObj.Shipped && this.state.holdResultsObj.Shipped.length != 0 && (
                            <div className='allocated-section'>
                                <p>Allocated To Shipped Orders</p>
                                <div className='result-list'>
                                    <div className="child-section">
                                        <label>ReceiveItem ID</label>
                                        <div className="list-wrapper">
                                            {this.state.holdResultsObj.Shipped.map((item, index) => (
                                                <div key={'shipped_rcv_' + index}>{item.ReceiveItemId}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="child-section">
                                        <label>Transaction Numbers</label>
                                        <div className="list-wrapper">
                                            {this.state.holdResultsObj.Shipped.map((item, index) => (
                                                <div key={'shipped_trn_' + index}>{item.AffectedOrderIds.join(",")}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </WmsConfirmModel>
                {this.state.isTransferModelOpen && (
                    <InventoryCustomerTransferModel
                        localKendoGrid={this.state.localKendoGrid}
                        warehouseObj={this.state.sharedSelectedFacilityObj}
                        onCloseTransferInventoryModal={this.closeTransferInventoryModal}
                        isTransferModelOpen={this.state.isTransferModelOpen}
                        gridReload={this.resetGridSelectionAndButton}
                        resetGridSelectionAndButton={this.resetGridSelectionAndButton}
                    />
                )}
                {this.state.isHoldModelOpen && (
                    <InventoryHoldModel
                        selectedInventoryKey={this.state.localKendoGrid == null ? [] : this.state.localKendoGrid.getKeys()}
                        onCloseHoldInventoryModal={this.closeHoldInventoryModal}
                        localKendoGrid={this.state.localKendoGrid}
                        isHoldModelOpen={this.state.isHoldModelOpen}
                        windowId={this.props.windowId}
                        onOpenHoldResultMsg={this.onOpenHoldResultMsg}
                        resetGridSelectionAndButton={this.resetGridSelectionAndButton}
                        sharedIsCombineRow={this.state.isCombineRow}
                    />
                )}
                {this.state.isOpenColumnModel && (
                <AddEditColumnModel
                    windowId={this.props.windowId}
                    id={"addEditColumnFindInventoryBeta"+this.props.windowId}
                    height={420}
                    onCloseColumnModel={this.onCloseColumnModel}
                    isOpenColumnModel={this.state.isOpenColumnModel}
                    allSortColumnList={this.state.allSortColumnList}
                    gridId={this.manageInventoryGridId}
                    updateGridData={this.callForGetNewData}
                    saveChangesInMetaData={this.saveChangesInMetaData}
                />
                )}
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                    />
                )}
            </div>
        )
    }
}