import React, { useState, useEffect } from "react";
import ItemAliasGrid from "./item-alias-grid.component";
import "./itemAlias.scss";
import * as ItemAliasServices from "./item-alias.services";
interface Props {}

declare var window: any;
var $rootScope = window;
const wmsSelector = "ItemAlias";
const defaultFilterValue = {
    customerId: 0,
    text: "",
    status: true,
};

const ItemAliasHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [sharedFilterObject, setSharedFilterObject] = useState(defaultFilterValue);
    const [loadGrid, setLoadGrid] = useState(false);

    useEffect(() => {
        async function getGridMeta() {
            await ItemAliasServices.getItemAliasClientMetaData();
            let filterData = ItemAliasServices.getItemAliasFilterMetaData();
            if (filterData) {
                setSharedFilterObject(filterData);
            }
            setLoadGrid(true);
        }
        getGridMeta();
    }, []);

    return (
        <div className="adjust-customer-charges-wrapper">
            {loadGrid && (
                <ItemAliasGrid
                    // ref={ItemAliasGridRef}
                    wmsSelector={wmsSelector}
                    windowId={windowId}
                    sharedFilterObject={sharedFilterObject}
                />
            )}
        </div>
    );
};
ItemAliasHolder.propTypes = {};

ItemAliasHolder.defaultProps = {};

export default React.memo(ItemAliasHolder);
