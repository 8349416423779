import React from 'react';

export const getGridDemoColumns = (FindOrders_GridHeaders, CustomLabels) => {
    
    var translate = window.translate;
    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable:false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "Customer",
            title: CustomLabels.Label_Customer,
            width: 120,
            wmsid: 1,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridCustomer'
            }
        },
        {
            field: "Warehouse",
            title: CustomLabels.Label_Warehouse,
            width: 120,
            wmsid: 2,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridWarehouse'
            }
        }, {
            field: "OrderId",
            title: FindOrders_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridOrderId'
            },
            filterable: {
                ui:function(element){
                    element.kendoNumericTextBox({
                        format:'{0:0}'
                    })
                }
            }
        }, {
            field: "ReferenceNum",
            title: FindOrders_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridReferenceNum'
            }
        }, {
            field: "CreationDate",
            title: FindOrders_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridCreationDate'
            },
            template: "#= (typeof CreationDate != 'undefined' && CreationDate != null) ?  kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"

        }, {
            field: "Status",
            title: FindOrders_GridHeaders[5].description,
            width: 120,
            wmsid: 6,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'OrderGridGridStatus'
            },
        }
        ]
    })
}

export const getAllColumnList = (FindOrders_GridHeaders, CustomLabels) => {
    
    var translate = window.translate;

    return ([{
        field: "RowNumber",
        title: "#",
        width: 60,
        wmsid: 49,
        locked: true,
        lockable: false,
        sortable: false,
        resizable:false,
        reorderable: false,
        filterable: false,
        isShawRowNumberColumnInList: true,        
        attributes: { style: "text-align:center;" },
        headerAttributes: { style: "text-align:center;" }
    },{
        field: "Customer",
        title: CustomLabels.Label_Customer,
        width: 120,
        wmsid: 1,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridCustomer'
        }
    },
    {
        field: "Warehouse",
        title: CustomLabels.Label_Warehouse,
        width: 120,
        wmsid: 2,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridWarehouse'
        }
    }, {
        field: "OrderId",
        title: FindOrders_GridHeaders[2].description,
        width: 120,
        wmsid: 3,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridOrderId'
        },
        filterable: {
            ui:function(element){
                element.kendoNumericTextBox({
                    format:'{0:0}'
                })
            }
        }
    }, {
        field: "ReferenceNum",
        title: FindOrders_GridHeaders[3].description,
        width: 120,
        wmsid: 4,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridReferenceNum'
        }
    }, {
        field: "CreationDate",
        title: FindOrders_GridHeaders[4].description,
        width: 120,
        wmsid: 5,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridCreationDate'
        },
        template: "#= (typeof CreationDate != 'undefined' && CreationDate != null) ?  kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"

    }, {
        field: "Status",
        title: FindOrders_GridHeaders[5].description,
        width: 120,
        wmsid: 6,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridStatus'
        },
    }
        , {
        field: "ParcelLabelType",
        title: FindOrders_GridHeaders[44].description,
        width: 120,
        wmsid: 43,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridParcelLabelType'
        },

        template: "# if(typeof ParcelLabelType != 'undefined' && ParcelLabelType === 0){# " + translate('Label_parcel_type_none') + " #}else if(typeof ParcelLabelType != 'undefined' && ParcelLabelType === 1){# " + translate('Label_parcel_type_pdf') + " #}else{# " + translate('Label_parcel_type_zpl') + " #} # "
    }, {
        field: "AsnSentDate",
        title: FindOrders_GridHeaders[20].description,
        width: 120,
        wmsid: 7,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridAsnSentDate'
        },

        template: "#= (typeof AsnSentDate != 'undefined' && AsnSentDate != null) ?  kendo.toString(kendo.parseDate(AsnSentDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "BatchName",
        title: FindOrders_GridHeaders[43].description,
        width: 120,
        wmsid: 51,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridBatchName'
        },

    }, {
        field: "BatchOrderId",
        title: FindOrders_GridHeaders[6].description,
        width: 120,
        wmsid: 8,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridGridBatchOrderId'
        },

    }, {
        field: "BillOfLading",
        title: FindOrders_GridHeaders[32].description,
        width: 120,
        wmsid: 9,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridBillOfLading'
        },

    }, {
        field: "BillingCode",
        title: FindOrders_GridHeaders[29].description,
        width: 120,
        wmsid: 10,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridBillingCode'
        },

    }, {
        field: "ShipCancelDate",
        title: FindOrders_GridHeaders[16].description,
        width: 120,
        wmsid: 11,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipCancelDate'
        },

        template: "#= (typeof ShipCancelDate != 'undefined' && ShipCancelDate != null) ? kendo.toString(kendo.parseDate(ShipCancelDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "Carrier",
        title: FindOrders_GridHeaders[27].description,
        width: 120,
        wmsid: 12,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridCarrier'
        },
        template: function (dataItem) {
            return dataItem.Carrier == "PitneyBowesStd" ? "Pitney Bowes Standard" : ( dataItem.Carrier != null ? dataItem.Carrier : '');
        },
    }, {
        field: "ProcessDate",
        title: FindOrders_GridHeaders[19].description,
        width: 120,
        wmsid: 13,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridProcessDate'
        },

        template: "#= (typeof ProcessDate != 'undefined' && ProcessDate != null) ? kendo.toString(kendo.parseDate(ProcessDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "ConfirmAsnSentDate",
        title: FindOrders_GridHeaders[21].description,
        width: 120,
        wmsid: 14,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridConfirmAsnSentDate'
        },
        template: "#= (typeof ConfirmAsnSentDate != 'undefined' && ConfirmAsnSentDate != null) ? kendo.toString(kendo.parseDate(ConfirmAsnSentDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "TransactionSource",
        title: FindOrders_GridHeaders[40].description,
        width: 120,
        wmsid: 15,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTransactionSource'
        },

    }, {
        field: "EarliestShipDate",
        title: FindOrders_GridHeaders[15].description,
        width: 120,
        wmsid: 16,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridEarliestShipDate'
        },

        template: "#= (typeof EarliestShipDate != 'undefined' && EarliestShipDate != null ) ? kendo.toString(kendo.parseDate(EarliestShipDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "EndOfDayDate",
        title: FindOrders_GridHeaders[22].description,
        width: 120,
        wmsid: 17,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridEndOfDayDate'
        },

        template: "#= (typeof EndOfDayDate != 'undefined' && EndOfDayDate != null) ? kendo.toString(kendo.parseDate(EndOfDayDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "LoadNumber",
        title: FindOrders_GridHeaders[31].description,
        width: 120,
        wmsid: 18,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridLoadNumber'
        },

    }, {
        field: "LoadOutDoneDate",
        title: FindOrders_GridHeaders[23].description,
        width: 120,
        wmsid: 19,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridLoadOutDoneDate'
        },

        template: "#= (typeof LoadOutDoneDate != 'undefined' && LoadOutDoneDate != null) ? kendo.toString(kendo.parseDate(LoadOutDoneDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "MasterBillOfLadingId",
        title: FindOrders_GridHeaders[33].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridMasterBillOfLadingId'
        },

    }, {
        field: "PackDoneDate",
        title: FindOrders_GridHeaders[25].description,
        width: 120,
        wmsid: 21,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPackDoneDate'
        },

        template: "#= (typeof PackDoneDate != 'undefined' && PackDoneDate != null) ? kendo.toString(kendo.parseDate(PackDoneDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    }, {
        field: "TotPackages",
        title: FindOrders_GridHeaders[38].description,
        width: 120,
        wmsid: 22,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotPackages'
        },

    }, {
        field: "PickJobId",
        title: FindOrders_GridHeaders[45].description,
        width: 120,
        wmsid: 44,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPickJobId'
        }
    }, {
        field: "PickTicketPrintDate",
        title: "Pick Ticket Print Date",
        width: 120,
        wmsid: 45,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPickTicketPrintDate'
        },

        template: "#= (typeof PickTicketPrintDate != 'undefined' && PickTicketPrintDate != null) ? kendo.toString(kendo.parseDate(PickTicketPrintDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    }, {
        field: "PickDoneDate",
        title: FindOrders_GridHeaders[24].description,
        width: 120,
        wmsid: 23,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPickDoneDate'
        },

        template: "#= (typeof PickDoneDate != 'undefined' && PickDoneDate != null) ? kendo.toString(kendo.parseDate(PickDoneDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    },
    {
        field: "PickupDate",
        title: FindOrders_GridHeaders[17].description,
        width: 120,
        wmsid: 24,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPickupDate'
        },

        template: "#= (typeof PickupDate != 'undefined' && PickupDate != null) ? kendo.toString(kendo.parseDate(PickupDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    },
    {
        field: "PoNum",
        title: FindOrders_GridHeaders[7].description,
        width: 120,
        wmsid: 25,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPoNum'
        },

    }, {
        field: "RetailerId",
        title: FindOrders_GridHeaders[14].description,
        width: 120,
        wmsid: 26,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridRetailerId'
        },

    }, {
        field: "SkuAndQty",
        title: CustomLabels.Label_Sku + FindOrders_GridHeaders[41].description,
        width: 120,
        wmsid: 27,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridSkuAndQty'
        },

        template: "<span style='white-space: nowrap;text-overflow: ellipsis;width:95%;display: block;overflow: hidden'>#if (typeof SkuAndQty != 'undefined') {# #= SkuAndQty# #}#</span>"
    }, {
        field: "ShipService",
        title: FindOrders_GridHeaders[28].description,
        width: 120,
        wmsid: 28,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipService'
        }
    }, {
        field: "ShipToAddress",
        title: FindOrders_GridHeaders[9].description,
        width: 120,
        wmsid: 29,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToAddress'
        },

    }, {
        field: "ShipToCity",
        title: FindOrders_GridHeaders[10].description,
        width: 120,
        wmsid: 30,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToCity'
        },

    }, {
        field: "ShipToCompany",
        title: FindOrders_GridHeaders[42].description,
        width: 120,
        wmsid: 50,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToCompany'
        },

    }, {
        field: "ShipToCountry",
        title: FindOrders_GridHeaders[12].description,
        width: 120,
        wmsid: 31,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToCountry'
        },

    }, {
        field: "ShipToName",
        title: FindOrders_GridHeaders[8].description,
        width: 120,
        wmsid: 32,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToName'
        },

    }, {
        field: "ShipToState",
        title: FindOrders_GridHeaders[13].description,
        width: 120,
        wmsid: 33,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToState'
        },

    }, {
        field: "ShipToZip",
        title: FindOrders_GridHeaders[11].description,
        width: 120,
        wmsid: 34,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToZip'
        },

    }, {
        field: "SmallParcelShipDate",
        title: FindOrders_GridHeaders[18].description,
        width: 120,
        wmsid: 35,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridSmallParcelShipDate'
        },
        template: "#= (typeof SmallParcelShipDate != 'undefined' && SmallParcelShipDate != null) ? kendo.toString(kendo.parseDate(SmallParcelShipDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "Timezone",
        title: FindOrders_GridHeaders[26].description,
        width: 120,
        wmsid: 36,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTimezone'
        },

    }, {
        field: "TrackingNumber",
        title: FindOrders_GridHeaders[30].description,
        width: 120,
        wmsid: 37,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTrackingNumber'
        },

    }, {
        field: "TotVolumeImperial",
        title: FindOrders_GridHeaders[35].description,
        width: 120,
        wmsid: 38,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotVolumeImperial'
        },

    }, {
        field: "TotVolumeMetric",
        title: FindOrders_GridHeaders[37].description,
        width: 120,
        wmsid: 39,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotVolumeMetric'
        },

    }, {
        field: "Notes",
        title: FindOrders_GridHeaders[39].description,
        width: 120,
        wmsid: 40,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridNotes'
        },

    }, {
        field: "TotWeightMetric",
        title: FindOrders_GridHeaders[36].description,
        width: 120,
        wmsid: 41,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotWeightMetric'
        },

    }, {
        field: "TotWeightImperial",
        title: FindOrders_GridHeaders[34].description,
        width: 120,
        wmsid: 42,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotWeightImperial'
        },

    }, {
        field: "TotalItemQty",
        title: FindOrders_GridHeaders[46].description,
        width: 120,
        wmsid: 46,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotalItemQty'
        },
    }, {
        field: "OrderCreator",
        title: FindOrders_GridHeaders[47].description,
        width: 120,
        wmsid: 47,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridOrderCreator'
        },
    }, {
        field: "TotalLines",
        title: FindOrders_GridHeaders[48].description,
        width: 120,
        wmsid: 48,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridTotalLines'
        },
    },{
        field: "MarkForLists",
        title: "MarkFor Lists",
        width: 120,
        wmsid: 52,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridMarkForId'
        }
    }, {
        field: "MarkForIdAndName",
        title: "MarkFor Name/ID",
        width: 120,
        wmsid: 53,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridMarkForName'
        }
    },{
        field: "AllocationStatus",
        title: FindOrders_GridHeaders[49].description,
        width: 120,
        wmsid: 54,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridAllocationStatus'
        }
    },{
        field: "LoadOutPercentage",
        title: FindOrders_GridHeaders[50].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridLoadOutPercentage'
        }
    },{
        field: "ShipToAddress2",
        title: FindOrders_GridHeaders[51].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToAddress2'
        }
    },{
        field: "ShipToEmailAddress",
        title: FindOrders_GridHeaders[52].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToEmailAddress'
        }
    },{
        field: "ShipToPhone",
        title: FindOrders_GridHeaders[53].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridShipToPhone'
        }
    },{
        field: "PickJobAssignee",
        title: FindOrders_GridHeaders[54].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGridPickJobAssignee'
        }
    },{
        field: "CustomFields",
        title: FindOrders_GridHeaders[55].description,
        width: 120,
        wmsid: 55,
        lockable: false,
        attributes: {class: "wms-custom-field-tooltips"},
        headerAttributes: {
            "data-wms-selector": 'OrderGridCustomFields'
        }

    }]
    )
}

export const getGridDemoSchema = () => {
    return ({
        model: {
            id: "OrderId",
            fields: {
                OrderId: { type: "number" },
                Customer: { type: "string" },
                Warehouse: { type: "string" },
                ReferenceNum: { type: "string" },
                CreationDate: { type: "date" },
                AsnSentDate: { type: "date" },
                ShipCancelDate: { type: "date" },
                ProcessDate: { type: "date" },
                ConfirmAsnSentDate: { type: "date" },
                EarliestShipDate: { type: "date" },
                EndOfDayDate: { type: "date" },
                LoadOutDoneDate: { type: "date" },
                PackDoneDate: { type: "date" },
                PickTicketPrintDate: { type: "date" },
                PickDoneDate: { type: "date" },
                PickupDate: { type: "date" },
                SmallParcelShipDate: { type: "date" },
                Status: { type: "string" },
                MarkForId: {
                    type: "string"
                },
                MarkForName: {
                    type: "string"
                },
                AllocationStatus:{ type: "string" },
                LoadOutPercentage:{ type: "string" },
                ShipToAddress2:{ type: "string" },
                ShipToEmailAddress:{ type: "string" },
                ShipToPhone:{ type: "string" },
                PickJobAssignee:{ type: "string" },
                TotWeightImperial: { type: "number"},
                TotWeightMetric: { type: "number"},
                TotVolumeImperial: { type: "number"},
                TotVolumeMetric: { type: "number"},
                CustomFields: { type: "string" },
            }
        }
    })
}

export const getGridDemoTransport = () => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/FindOrders/OrdersGridRead",
                type: "POST",
                dataType: 'json'
            },
            destroy: {
                url: "/WebUI/Shipping/FindOrders/OrdersGridRead",
                type: "POST",
                dataType: "JSON"
            },

            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            },
        }
    })
}

export const exportGridBaseArray = () => {
    return [
        { 'Customer': 'Customer' },
        { 'Warehouse': 'Warehouse' },
        { 'OrderId': 'OrderId' },
        { 'ReferenceNum': 'ReferenceNum' },
        { 'CreationDate': 'CreationDate' },
        { 'Status': 'Status' },
        { 'AsnSentDate': 'AsnSentDate' },
        { 'BatchOrderId': 'BatchOrderId' },
        { 'BatchName': 'BatchName' },
        { 'BillingCode': 'BillingCode' },
        { 'BillOfLading': 'BillOfLading' },
        { 'Carrier': 'Carrier' },
        { 'EarliestShipDate': 'EarliestShipDate' },
        { 'EndOfDayDate': 'EndOfDayDate' },
        { 'Timezone': 'Timezone' },
        { 'LoadNumber': 'LoadNumber' },
        { 'MasterBillOfLadingId': 'MasterBillOfLadingId' },
        { 'Notes': 'Notes' },
        { 'PickJobId': 'PickJobId' },
        { 'PickDoneDate': 'PickDoneDate' },
        { 'PackDoneDate': 'PackDoneDate' },
        { 'PickTicketPrintDate': 'PickTicketPrintDate' },
        { 'PickupDate': 'PickupDate' },
        { 'PoNum': 'PoNum' },
        { 'ProcessDate': 'ProcessDate' },
        { 'ShipCancelDate': 'ShipCancelDate' },
        { 'SmallParcelShipDate': 'SmallParcelShipDate' },
        { 'ParcelLabelType': 'ParcelLabelType' },
        { 'ShipService': 'ShipService' },
        { 'ShipToCity': 'ShipToCity' },
        { 'ShipToCompany': 'ShipToCompany' },
        { 'ShipToCountry': 'ShipToCountry' },
        { 'ShipToName': 'ShipToName' },
        { 'ShipToZip': 'ShipToZip' },
        { 'ShipToAddress': 'ShipToAddress' },
        { 'ShipToState': 'ShipToState' },
        { 'ScacCode': 'ScacCode' },
        { 'TotVolumeMetric': 'TotVolumeMetric' },
        { 'TotWeightMetric': 'TotWeightMetric' },
        { 'TotPackages': 'TotPackages' },
        { 'TotVolumeImperial': 'TotVolumeImperial' },
        { 'TotWeightImperial': 'TotWeightImperial' },
        { 'TrackingNumber': 'TrackingNumber' },
        { 'RetailerId': 'RetailerId' },
        { 'ConfirmAsnSentDate': 'ConfirmAsnSentDate' },
        { 'LoadOutDoneDate': 'LoadOutDoneDate' },
        { 'TransactionSource': 'TransactionSource' },
        { 'IsPickDone': 'IsPickDone' },
        { 'SkuAndQty': 'SkuAndQty' }];
}