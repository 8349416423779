let align_center = "text-align:center;";
export const getItemAliasColumns = (GridHeaders) => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "#",
                width: 60,
                wmsid: 1,
                hidden: false,
                locked: true,
                lockable: false,
                resizable: false,
                sortable: false,
                reorderable: false,
                filterable: false,
                attributes: { style: align_center },
                headerAttributes: { style: align_center },
            },
            {
                field: "Alias",
                title: GridHeaders[0].description,
                wmsid: 2,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "ItemAliasName",
                },
            },
            {
                field: "ComponentItems",
                title: GridHeaders[2].description,
                wmsid: 4,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "ItemAliasDescription",
                },
            },
            {
                field: "Description",
                title: GridHeaders[3].description,
                wmsid: 5,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "ItemAliasDescription",
                },
            },
            {
                field: "Active",
                title: GridHeaders[4].description,
                wmsid: 6,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "ItemAliasStatus",
                },
                template: function (dataItem: any) {
                    return (
                        dataItem.Active +
                        (dataItem.HasDeactivatedItem
                            ? "<span class='status-warning-icon'><i class='fa fa-exclamation-triangle'/></span>"
                            : "")
                    );
                },
            },
        ],
    };
};
export const getItemAliasSchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                RowNumber: { type: "number" },
                Alias: { type: "string" },
                ComponentItems: { type: "string" },
                Description: { type: "string" },
                Active: { type: "string" },
            },
        },
    };
};

export const getItemGridColumns = (GridHeaders) => {
    return [
        {
            field: "Sku",
            title: GridHeaders[0].description,
            lockable: false,
            filterable: false,
            width: 160,
            wmsid: 1,
            // headerAttributes: {
            //     "data-wms-selector": "ItemAliasGridItemName",
            // },
        },
        {
            field: "Description",
            title: GridHeaders[1].description,
            lockable: false,
            filterable: false,
            width: 160,
            wmsid: 2,
            // headerAttributes: {
            //     "data-wms-selector": "ItemAliasGridItemDescription",
            // },
        },
        {
            field: "IsActive",
            title: "Status",
            lockable: false,
            filterable: false,
            width: 160,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": "ItemAliasGridItemIncluded",
            },
            template: `#=data.IsActive ? 'Active' : 'Inactive'#`,
        },
        {
            field: "Qty",
            title: GridHeaders[2].description,
            lockable: false,
            filterable: false,
            width: 160,
            wmsid: 4,
            // headerAttributes: {
            //     "data-wms-selector": "ItemAliasGridQty",
            // },
            template: `<span className="qty-value">#=data.Qty#</span><a href='javascript: void(0);' data-id='#=data.ItemId#' class='remove-selected-item' data-wms-selector='remove-selected-item-for-item-alias'><span class='fa fa-close'></span></a>`,
        },
    ];
};
export const getItemGridSchema = () => {
    return {
        schema: {
            model: {
                fields: {
                    Sku: { type: "string", editable: false },
                    Description: { type: "string", editable: false },
                    IsActive: { type: "boolean", editable: false },
                    Qty: { type: "number", editable: true },
                },
            },
        },
    };
};

interface titleInterface {
    value: number;
    description: string;
}
export const importParseDataGridColumns: Function = (titleArray: Array<titleInterface>): Array<Object> => {
    return [
        {
            field: "index",
            title: titleArray[0].description,
            wmsid: 1,
            filterable: false,
            resizable: false,
        },
        {
            field: "alias",
            title: titleArray[1].description,
            wmsid: 2,
            filterable: false,
            resizable: false,
        },
        {
            field: "noOfItemsText",
            title: titleArray[2].description,
            wmsid: 3,
            filterable: false,
            resizable: false,
        },
        {
            field: "description",
            title: titleArray[3].description,
            wmsid: 4,
            filterable: false,
            resizable: false,
        },
        {
            field: "duplicate",
            title: titleArray[4].description,
            wmsid: 5,
            filterable: false,
            resizable: false,
            template: `#= duplicate ? 'Yes <span class="status-warning-icon"><i class="fa fa-exclamation-triangle"></i></span>' : '' #`,
        },
    ];
};

export const importParseDataGridSchema: Function = (): Object => {
    return {
        model: {
            id: "id",
            fields: {
                index: {
                    type: "number",
                },
                alias: {
                    type: "string",
                },
                noOfItemsText: {
                    type: "string",
                },
                description: {
                    type: "string",
                },
                duplicate: {
                    type: "string",
                },
            },
        },
    };
};
